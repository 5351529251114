import { WrapperInterface } from '../wrappers/wrapper.interface';
import { PeriodType } from './period-type';

export class PeriodTypeWrapper implements WrapperInterface {
  list = PeriodType;
  value: PeriodType;

  constructor(type: string = null) {
    if (type) {
      this.value = PeriodType[type];
    }
  }

  get isNormalHours() {
    return this.value === this.list.BUSINESS_HOURS ||
      this.value === this.list.BREAKFAST ||
      this.value === this.list.LUNCH ||
      this.value === this.list.DINNER ||
      this.value === this.list.HAPPY_HOUR ||
      this.value === this.list.BRUNCH ||
      this.value === this.list.LATE_NIGHT;
  }

  get isCalendar() {
    return this.value === this.list.EVENT || this.value === this.list.CLOSED;
  }

  get isSpecialHours() {
    return this.value === this.list.SPECIAL;
  }

  get isEvent() {
    return this.value === this.list.EVENT;
  }

  get isBusinessHours() {
    return this.value === this.list.BUSINESS_HOURS;
  }

  get isClosed() {
    return this.value === this.list.CLOSED;
  }
}
