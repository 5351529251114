import { Type } from 'class-transformer';
import { GeoLocation } from './geo-location';

export class SelectOption {
  value: string;
  label: string;
  relativeDistance: number;

  @Type(() => GeoLocation)
  geoLocation: GeoLocation;

  nearest: number;
  group: string;
}
