import { InstagramState } from '../models/enums/instagram-state';
import { DecoratorInjectorService } from '../services/decorator/decorator-injector.service';
import { DecoratorService } from '../services/decorator/decorator.service';

export function IgAccessToken(state: InstagramState, showImageModal: boolean, isReconnect = false): MethodDecorator {
  return (target: object, key: string, descriptor: any) => {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const service: DecoratorService = DecoratorInjectorService.getService();
      const currentUser = service.authenticationService.currentUser;

      if (currentUser.igAccessToken && !isReconnect) {
        return originalMethod.apply(this, args);
      } else {
        service.navigatorService.goToExternal(this.instagramService.getCodeUrl(state, showImageModal));
      }
    };

    return descriptor;
  };
}
