import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dine-in-modal',
  templateUrl: './dine-in-modal.component.html',
  styleUrls: []
})
export class DineInModalComponent implements OnInit {
  @Output() set = new EventEmitter<object>();

  constructor() {
  }

  ngOnInit() {
  }
}
