import { Subscription } from 'rxjs';
import { ModalType } from '../models/enums/modal-type';
import { DecoratorInjectorService } from '../services/decorator/decorator-injector.service';
import { DecoratorService } from '../services/decorator/decorator.service';

export function IsModalOpen(modalType: ModalType): PropertyDecorator {
  return (target: any, propertyKey: string) => {
    let service: DecoratorService;
    let isOpen: boolean;
    let subscriber: Subscription;

    const getter = () => {
      if (isOpen !== undefined) {
        return isOpen;
      }

      if (service === undefined) {
        service = DecoratorInjectorService.getService();
      }

      if (subscriber === undefined) {
        subscriber = service.modalService.modalIsOpenSubjects[modalType].subscribe(isOpenValue => {
          isOpen = isOpenValue;
        });
      }

      isOpen = service.modalService.modalIsOpenSubjects[modalType].getValue();

      return isOpen;
    };

    const setter = (newValue) => {
      isOpen = newValue;
    };

    Object.defineProperty(target, propertyKey, {
      get: getter,
      set: setter
    });
  };
}
