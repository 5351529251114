export enum ModalClass {
  SM = 'modal-sm',
  LG = 'modal-lg',
  TOP = 'modal-top',
  TOP_ROUNDED = 'modal-top-rounded',
  BOTTOM = 'modal-bottom',
  BOTTOM_ROUNDED = 'modal-bottom-rounded',
  CENTER = 'modal-center',
  CENTER_NARROWED = 'modal-center-narrowed',
  CENTER_SQUARE = 'modal-center-square',
  FAVORITE_SQUARE = 'modal-favorite-desktop',
  VIDEO_DESKTOP = 'modal-favorite-desktop',
  POST_SQUARE = 'modal-post-desktop',
  RESTAURANT_SQUARE = 'restaurant-desktop',
  SEARCH_SQUARE = 'modal-search-square',
  SQUARE = 'modal-squared',
  SLIDE = 'modal-slide',
  TRANSPARENT = 'modal-transparent',
  BLACK = 'modal-black',
  SHADOW_NONE = 'modal-shadow-none',
  FULL_WIDTH = 'modal-full-width',
  RESTAURANT_EDIT='modal-restaurant-edit',
  FULL_WIDTH_ABOUT = 'modal-about-restaurant',
  H_100 = 'modal-h-100',
  BORDER_RADIUS_0_125 = 'modal-border-radius-0-125',
  OVERFLOW_Y_HIDDEN = 'modal-overflow-y-hidden',
  TOP_AUTO = 'modal-top-auto',
  SLIDE_IN_BCK_CENTER = 'modal-slide-in-bck-center',
  GRADIENT = 'modal-gradient',
  GRADIENT_BLACK_GRAY = 'modal-gradient-black-gray',
  BLUR_DARK = 'modal-blur-dark',
  BLUR_GREY = 'modal-blur-grey',
  BLUR_LIGHT_GREY = 'modal-blur-light-grey',
  BLUR_LIGHT = 'modal-blur-light',
  BLUR_LIGHT_MOBILE = 'modal-light-mobile',
  WIDTH_80 = 'modal-width-80',
  WIDTH_35 = 'modal-width-35',
  HAS_FIXED = 'has-fixed',
  BLUR = 'modal-blur',
  FULL_WIDTH_DESKTOP = 'modal-full-width-desktop',
  NO_ANIMATION = 'modal-no-animation',
  DARKER = 'modal-darker',
  LOGIN = 'modal-login',
  PREVIEW = 'modal-preview',
  SWIPER = 'modal-swiper',
  SWIPER_FAV = 'modal-swiper-fav',
  TOP_SEARCH = 'modal-top-search'
}
