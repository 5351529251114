import { Type } from 'class-transformer';
import { FavoriteStatus } from '../models/enums/favorite-status';
import { View } from '../models/view';
import { Food } from './food';

export class Favorite {
  id: number;

  @Type(() => Food)
  food: Food;

  restaurantId: number;
  userId: number;
  view = new View();
  status: FavoriteStatus;
}
