import { Type } from 'class-transformer';
import { User } from './user';

export class Hero {
  id: number;
  likeCount: number;
  getstreamId: string;
  currentUserLiked: boolean;

  @Type(() => User)
  user: User;
}
