<ng-container *ngIf="isNotFound">
  <app-page-not-found></app-page-not-found>
</ng-container>

<ng-container *ngIf="!isNotFound">
  <ng-container *appIfIsMobile>
    <app-menu-router *ngIf="restaurant" [restaurant]="restaurant"></app-menu-router>
  </ng-container>

  <ng-container *appIfIsDesktop>
    <app-restaurant-desktop
      [restaurant]="restaurant"
      [menus]="menus"
      [isSearching]="isSearching"
    ></app-restaurant-desktop>
  </ng-container>
</ng-container>
