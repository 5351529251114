import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { classToPlain } from 'class-transformer';
import { environment } from '../../environments/environment';
import { Category } from '../domains/category';
import { Menu } from '../domains/menu';
import { Ordering } from '../domains/ordering';

@Injectable({providedIn: 'root'})
export class CategoryService {
  private readonly categoryUrl: string;

  constructor(private http: HttpClient) {
    this.categoryUrl = `${environment.apiEndpoint}/api/categories`;
  }

  public addCategory(category: Category, menu: Menu) {
    return this.http.post<Category>(`${this.categoryUrl}/${menu.id}`, category);
  }

  public editCategory(category: Category) {
    return this.http.put(`${this.categoryUrl}`, classToPlain(category));
  }

  public deleteCategory(category: Category) {
    return this.http.delete(`${this.categoryUrl}/${category.id}`);
  }

  public changeOrdering(foodOrderings: Array<Ordering>) {
    return this.http.put(`${this.categoryUrl}/change-ordering`, foodOrderings);
  }
}
