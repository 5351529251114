import { PeriodTypeWrapper } from './period-type-wrapper';
import { Time } from './time';

export class PeriodTypeOption {
  type: PeriodTypeWrapper;
  specialTimeStart: Time;
  closedUntil: boolean;

  constructor(periodTypeWrapper: PeriodTypeWrapper, time: Time = null, closedUntil = false) {
    this.type = periodTypeWrapper;
    this.specialTimeStart = time;
    this.closedUntil = closedUntil;
  }
}
