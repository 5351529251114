import { ModalCloseOptions } from '../models/modal-close-options';
import { DecoratorInjectorService } from '../services/decorator/decorator-injector.service';
import { DecoratorService } from '../services/decorator/decorator.service';

export function ModalClose(modalCloseOptions: ModalCloseOptions = {}): MethodDecorator {
  if (!modalCloseOptions.hasOwnProperty('runMethodBefore')) {
    modalCloseOptions.runMethodBefore = false;
  }

  if (!modalCloseOptions.hasOwnProperty('closeTimeout')) {
    modalCloseOptions.closeTimeout = 0;
  }

  return (target: object, key: string, descriptor: any) => {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const service: DecoratorService = DecoratorInjectorService.getService();

      if (modalCloseOptions.runMethodBefore) {
        originalMethod.apply(this, args);
      }

      const originalMethodApplyAfter = () => {
        if (!modalCloseOptions.runMethodBefore) {
          originalMethod.apply(this, args);
        }
      };

      const frontMostModalWindows = document.querySelectorAll('body ngb-modal-window');

      if (frontMostModalWindows.length > 0) {
        for (let i = frontMostModalWindows.length - 1; i >= 0; i--) {
          const timeout = modalCloseOptions.closeTimeout === 0 ? 1 : modalCloseOptions.closeTimeout;
          const frontMostModalWindow = frontMostModalWindows[i];
          const modal = service.modalService.getModalByElement(frontMostModalWindow as HTMLElement);

          if (modal) {
            modal.onClose().then(() => {
              if (i + 1 === frontMostModalWindows.length) {
                setTimeout(() => {
                  originalMethodApplyAfter();
                }, 1);
              }
            });

            setTimeout(() => {
              modal.close(modalCloseOptions.reason);
            }, timeout);
          } else {
            originalMethodApplyAfter();
          }

          if (!modalCloseOptions.all) {
            break;
          }
        }
      } else {
        originalMethodApplyAfter();
      }

      return;
    };

    return descriptor;
  };
}
