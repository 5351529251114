import { Component, Input, OnInit } from '@angular/core';
import { NavigatorService } from '../../../services/navigator.service';

@Component({
  selector: 'app-navbar-top-settings',
  templateUrl: './navbar-top-settings.component.html',
  styleUrls: ['../navbar.shared.scss']
})
export class NavbarTopSettingsComponent implements OnInit {
  @Input() title: string;

  constructor(private navigatorService: NavigatorService) {
  }

  ngOnInit() {
  }

  closeSettings() {
    this.navigatorService.goToUser();
  }
}
