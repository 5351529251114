<div *ngIf="edit" class="membership-card membership-card-unsubscribed card d-flex flex-column font-weight-500">
  <div [ngClass]="{'membership-margin-mobile': isMobile, 'membership-margin': !isMobile}" class="d-flex flex-row">
    <div class="flex-content-vc">
      <i class="icon main-circle-white"></i>
      <span class="font-weight-500">bership</span>
    </div>

    <div class="m-auto"></div>

    <div class="logo-icon d-flex">
      <span class="icon-v2 checkmark-white2 opacity-40"></span>
    </div>
  </div>

  <div class="px-3 pb-3">
    <app-div-rectangle [ratio]="1.586">
      <ng-template [ngTemplateOutlet]="membershipIconContainer"></ng-template>
    </app-div-rectangle>
  </div>
</div>

<div *ngIf="!edit && isSubscribed" class="membership-card membership-card-subscribed card font-weight-500">
  <div class="px-3 pt-3 pb-2">
    <div class="row">
      <div class="col-8 pr-0">
        <div class="flex-content-vc">
          <i class="icon main-circle-white"></i>
          <span class="font-weight-500">bership</span>
        </div>

        <div class="mt-1 w-100">
          Since {{ startDate.getMonth() }}/{{ startDate.getFullYear().toString().substr(-2) }}
        </div>

        <div>
          <div class="d-flex name-container">
            <div [ngClass]="{'name-subscribed': isSubscribed}" class="text-overflow-crop">
              <span>{{ user.firstName }} {{ user.lastName }}</span>
            </div>

            <div *ngIf="isSubscribed" class="icon-subscribed-white-container flex-content-vc-hc">
              <div class="icon subscribed-white"></div>
            </div>
          </div>

          <span class="d-block text-overflow-crop">@{{ user.username }}</span>
        </div>
      </div>

      <div class="col-4 pl-0">
        <app-div-rectangle>
          <div class="rounded-circle">
            <app-user-avatar
              [user]="user"
              [widthAndHeight]="200"
              [icon]="true"
            ></app-user-avatar>
          </div>
        </app-div-rectangle>
      </div>
    </div>
  </div>

  <div class="px-3 pb-3 pt-2">
    <app-div-rectangle [ratio]="1.586">
      <ng-template [ngTemplateOutlet]="membershipIconContainer"></ng-template>
    </app-div-rectangle>
  </div>
</div>

<ng-template #membershipIconContainer>
  <div class="membership-icon p-0_8rem flex-content-vc-hc">
    <div class="icon-v2 membership-gold scale-1_25"></div>
  </div>
</ng-template>
