import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class PostVoteService {
  private readonly postVotesUrl: string;

  constructor(private http: HttpClient) {
    this.postVotesUrl = `${environment.apiEndpoint}/api/post-votes`;
  }

  getPostVote(postId: number) {
    return this.http.get(`${this.postVotesUrl}/${postId}`);
  }

  togglePostVote(postId: number, toVote: boolean) {
    return this.http.put(`${this.postVotesUrl}/${postId}/${toVote}`, {});
  }
}
