import { Pipe, PipeTransform } from '@angular/core';
import { RestaurantSimple } from '../domains/restaurant-simple';

@Pipe({
  name: 'isRestaurantOwned'
})
export class IsRestaurantOwnedPipe implements PipeTransform {
  transform(restaurant: RestaurantSimple, ...args: unknown[]): unknown {
    return restaurant.isOwned;
  }
}
