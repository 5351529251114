import { Component, Input, OnInit } from '@angular/core';
import { Auth } from '../../../decorators/auth.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { Subscribe } from '../../../decorators/subscribe.decorator';
import { Restaurant } from '../../../domains/restaurant';
import { RestaurantSimple } from '../../../domains/restaurant-simple';
import { DeviceType } from '../../../models/enums/device-type';
import { TypeCategorySection } from '../../../models/type-category-section';
import { NavigatorService } from '../../../services/navigator.service';

@Component({
  selector: 'app-info-bar',
  templateUrl: './info-bar.component.html',
  styleUrls: ['./info-bar.component.scss'],
})
export class InfoBarComponent implements OnInit {
  @Input() restaurant: Restaurant | RestaurantSimple;
  @Input() isRestaurantModal = false;
  @Input() isModal = false;
  @Input() typeCategorySection: TypeCategorySection;
  @Input() restaurantPage = false;

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  addressMouseOver = false;

  constructor(
    private navigatorService: NavigatorService
  ) {
  }

  ngOnInit() {
  }

  openInfoBar() {
    if ((this.restaurantPage && this.restaurant.isOwned) || (this.isRestaurantModal && this.restaurant.isOwned)) {
      this.openMbershipModal();
    } else {
      this.openGoogleMap();
    }
  }

  openGoogleMap() {
    this.navigatorService.goToMapsRestaurant(this.restaurant);
  }

  @Auth()
  @Subscribe()
  openMbershipModal() {
  }
}
