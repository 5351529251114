import { plainToClass } from 'class-transformer';
import { Subject } from 'rxjs';
import { Order } from '../../domains/order/order';
import { Listener } from '../listener';

export class OrderListener extends Listener {
  subject = new Subject<Order>();

  constructor(userId: number) {
    super();
    this.topic = `/push/order/${userId}`;
  }

  transform(frame: any): Order {
    return plainToClass(Order, JSON.parse(frame.body));
  }
}
