import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2, RendererStyleFlags2 } from '@angular/core';

@Directive({
  selector: '[appIconRelativeZoom]'
})
export class IconRelativeZoomDirective implements OnInit {
  @Input() relativeTo: HTMLDivElement;
  @Input() iconWidth: number;

  element: HTMLElement;

  constructor(element: ElementRef, private renderer: Renderer2) {
    this.element = element.nativeElement;
  }

  ngOnInit(): void {
    this.setMembershipIconWidth();

    setTimeout(() => {
      this.setMembershipIconWidth();
    }, 100);
  }

  @HostListener('window:resize')
  onResize() {
    this.setMembershipIconWidth();
  }

  private setMembershipIconWidth() {
    const width = this.relativeTo.offsetWidth;

    this.renderer.setStyle(
      this.element,
      'zoom',
      `${width / this.iconWidth}`,
      RendererStyleFlags2.DashCase | RendererStyleFlags2.Important
    );
  }
}
