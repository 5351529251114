import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { LocalStorage } from '../local-storage/local-storage';
import { LocalStorageKey } from '../models/enums/local-storage-key';
import { SearchParams } from '../models/search-params';
import { AuthenticationService } from '../services/authentication.service';
import { NavigatorService } from '../services/navigator.service';

@Injectable({providedIn: 'root'})
class AuthChecker {
  searchParamsLocalStorage = new LocalStorage(SearchParams, LocalStorageKey.SEARCH_PARAMS);

  constructor(
    private authenticationService: AuthenticationService,
    private navigatorService: NavigatorService
  ) {
  }

  canActivateUser(): boolean {
    const currentUser = this.authenticationService.currentUser;

    if (currentUser) {
      return true;
    } else {
      this.navigatorService.goToHome();

      return false;
    }
  }

  canActivateAdmin(route: ActivatedRouteSnapshot): boolean {
    const user = this.authenticationService.currentUser;

    if (
      user &&
      user.hostnames !== undefined &&
      (user.hostnames.includes(route.params.hostname)) &&
      (!route.data.roles || route.data.roles.indexOf(user.role) !== -1)
    ) {
      return true;
    } else {
      this.navigatorService.goToRestaurant(route.params.hostname);

      return false;
    }
  }
}

export const AuthGuard: CanActivateFn = (): boolean => {
  return inject(AuthChecker).canActivateUser();
};

export const AdminAuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot): boolean => {
  return inject(AuthChecker).canActivateAdmin(route);
};
