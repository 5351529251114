import { TypeCategorySimple } from './type-category-simple';

export class RestaurantSuggestion {
  id: number;
  name: string;
  secondName: string;
  restaurantId: number;
  instagram: string;
  address: string;
  zipCode: string;
  incorrectInstagram: boolean;
  nonPublic: boolean;
  closed: boolean;
  incorrectHours: boolean;
  movedLocation: boolean;
  typeCategory: TypeCategorySimple;
  embedUrl: string;
  checked: boolean;
  userId: number;
}
