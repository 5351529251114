import { RouteParams } from './route-params';

export class UserRouteParams extends RouteParams {
  username: string = null;

  set(params: object) {
    this.username = this.getParam(params, 'username');
  }

  get path() {
    return `/${this.username.toLowerCase()}`;
  }
}
