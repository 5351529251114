export class RestaurantExplore {
  id: number;
  hostname: string;
  published: boolean;
  locationIndex: number;
  name: string;
  secondName: string;
  cityNameToRemove: string;
  display: string;
  previousRestaurantId: number = null;
  nextRestaurantId: number = null;
  reviewsCount: number;
  photosCount: number;
  hover = false;
  relativeDistance: number;

  get nameView(): string {
    return this.display || this.secondName;
  }

  get firstNameView(): string {
    return this.display;
  }

  get secondNameView(): string {
    return this.secondName || this.name;
  }

  get statRating(): number {
    return this.photosCount + this.reviewsCount;
  }

  get fullName(): string {
    let fullName = '';

    if (this.name) {
      fullName += this.name;
    }

    if (this.secondName) {
      fullName += ' ' + this.secondName;
    }

    return fullName;
  }

  get isExample(): boolean {
    return this.id === 10;
  }
}
