import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Food } from '../../../../domains/food';
import { Section } from '../../../../domains/section';
import { Tag } from '../../../../domains/tag';
import { SectionType } from '../../../../models/enums/section-type';

@Component({
  selector: 'app-additions-edit-modal',
  templateUrl: './additions-edit-modal.component.html',
  styleUrls: ['./additional-edit-modal.component.scss']
})
export class AdditionsEditModalComponent implements OnInit {
  @Input() food: Food;
  @Input() section: Section;
  @Input() tags: Array<Tag> = [];
  @Input() showWarnings: boolean;
  @Input() size: Section;
  @Input() options: Section[];

  @Output() save = new EventEmitter<boolean>();

  sectionExtrasCountMax = 3;

  constructor() {
  }

  ngOnInit(): void {
    this.setSectionExtrasCountMax(this.size);

    this.options.forEach(option => {
      this.setSectionExtrasCountMax(option);
    });

    this.fillSectionExtras(this.size);

    this.options.forEach(option => {
      this.fillSectionExtras(option);
    });
  }

  private setSectionExtrasCountMax(section: Section) {
    const length = section.type === SectionType.SIZE ? section.extras.length - 1 : section.extras.length;

    if (length > this.sectionExtrasCountMax) {
      this.sectionExtrasCountMax = length;
    }
  }

  private fillSectionExtras(section: Section) {
    const max = section.type === SectionType.SIZE ? this.sectionExtrasCountMax + 1 : this.sectionExtrasCountMax;

    section.extrasFill(max);
  }

  onMoreClick() {
    this.size.extraAdd();

    this.options.forEach(option => {
      option.extraAdd();
    });
  }
}
