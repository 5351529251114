import { Component, OnInit } from '@angular/core';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { Subscribe } from '../../../decorators/subscribe.decorator';
import { StripeSubscription } from '../../../domains/stripe-subscription';
import { User } from '../../../domains/user';
import { CheckoutService } from '../../../services/checkout.service';
import { NavigatorService } from '../../../services/navigator.service';

@Component({
  selector: 'app-subscription',
  templateUrl: 'subscription.component.html',
  styleUrls: ['subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  @CurrentUser() currentUser: User;

  subscription: StripeSubscription;
  endDate: Date;
  dataLoaded = false;

  constructor(
    private checkoutService: CheckoutService,
    private navigatorService: NavigatorService
  ) {
  }

  ngOnInit(): void {
    if (!this.currentUser) {
      this.navigatorService.goToHome();
    }

    this.checkoutService.getUserSubscriptionInfo(this.currentUser)
      .subscribe(value => {
        if (value) {
          this.subscription = value;
          this.endDate = new Date(value.currentPeriodEnd * 1000);
        }

        this.dataLoaded = true;
      });
  }

  @Subscribe()
  onSubscribeClick() {
  }
}
