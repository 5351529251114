import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IsDevice } from '../../decorators/is-device.decorator';
import { Restaurant } from '../../domains/restaurant';
import { Basket } from '../../models/basket/basket';
import { DeviceType } from '../../models/enums/device-type';
import { ModalType } from '../../models/enums/modal-type';
import { Modal } from '../../models/modal';
import { BasketService } from '../../services/basket.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss']
})
export class BasketComponent implements OnInit {
  @Input() restaurant: Restaurant;

  @ViewChild('basketModalRef') basketModalRef: ElementRef;

  @Output() openRestaurantModal = new EventEmitter<boolean>();

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  basket: Basket;
  basketModal: Modal;

  constructor(
    private modalService: ModalService,
    public basketService: BasketService,
  ) {
    basketService.basketSubject.subscribe(value => {
      this.basket = value;
    });
  }

  ngOnInit() {
  }

  openBasketModal() {
    this.basketModal = this.modalService.open(this.basketModalRef, ModalType.TOP_SHADOW_NONE);

    this.basketModal.onClose().then(() => {
      this.basketService.needShow = true;
    });

    this.basketService.needShow = false;
  }

  onTitleClick() {
    this.openRestaurantModal.emit(true);
  }
}
