import { inject, Injectable } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NavigatorService } from '../services/navigator.service';

@Injectable({providedIn: 'root'})
class BrowserChecker {
  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private navigatorService: NavigatorService
  ) {
  }

  canActivateBrowser(): boolean {
    if (this.deviceDetectorService.browser !== 'MS-Edge') {
      return true;
    } else {
      this.navigatorService.goToUrl('browser-support').then();
    }
  }
}

export const BrowserSupportGuard: CanActivateFn = (): boolean => {
  return inject(BrowserChecker).canActivateBrowser();
};
