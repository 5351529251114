import { Directive, ElementRef, OnInit } from '@angular/core';
import { nanoid } from 'nanoid';

@Directive({
  selector: '[appAutocompleteOff]'
})
export class AutocompleteOffDirective implements OnInit {
  nativeElement: any;

  constructor(element: ElementRef) {
    this.nativeElement = element.nativeElement;
  }

  ngOnInit() {
    const parentForm = this.nativeElement.closest('form');

    parentForm.autocomplete = 'off';

    this.nativeElement.autocomplete = 'new-' + (this.nativeElement.id ? this.nativeElement.id : nanoid(16));
  }
}
