import { Injectable } from '@angular/core';
import ImgixClient from '@imgix/js-core';
import { environment } from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class ImgixService {
  private client: ImgixClient;

  constructor() {
    this.client = new ImgixClient({
      domain: environment.imgixDomain,
    });
  }

  public getUrl(path: string, options?: {}) {
    return this.client.buildURL(path, {
      ...{auto: 'format', fit: 'crop'},
      ...options
    });
  }
}
