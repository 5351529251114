<div class="embed-video">
  <div
    class="d-flex justify-content-center"
    [innerHTML]="embed.html | sanitizeHtml"
    [ngClass]="{'video-container': !embed.isVertical, 'bg-white': embed.isVertical}"
  ></div>

  <div class="d-flex justify-content-between py-0-35 pl-0-4rem align-items-center bg-black">
    <ng-container *ngIf="embed.status == reviewStatus.PENDING">
      <button
        type="button"
        class="mr-3 btn btn-dark ml-2 text-transform-none px-2 py-1 font-size-0-85rem"
        (click)="cancelVideo(embed)"
      >
        Cancel
      </button>

      <div
        [ngClass]="{'mr-3': isMobile, 'mr-5': !isMobile}"
        class="text-muted font-size-0-85rem ml-auto"
      >
        Pending approval
      </div>
    </ng-container>

    <ng-container *appIfIsMobile>
      <div class="mr-3 d-flex justify-content-end w-58px ml-auto">
        <app-picker
          [classes]="'icon dots-h'"
          [picker]="picker"
          [title]="'Report video'"
          (selectedValue)="openPostModal($event);"
        ></app-picker>
      </div>
    </ng-container>

    <ng-container *appIfIsDesktop>
      <div
        ngbDropdown
        appNgbDropdownOnHover
        placement="top-right"
        class="mr-3 ml-auto"
      >
        <div ngbDropdownToggle class="icon-v2 dots-h"></div>

        <div ngbDropdownMenu class="bg-dark">
          <a appHrefNone class="dropdown-header"> Report video </a>

          <a
            appHrefNone
            class="dropdown-item"
            (click)="openPostModal(reportType.BROKEN_LINK)"
          >
            Broken link
          </a>

          <a
            appHrefNone
            class="dropdown-item"
            (click)="openPostModal(reportType.UNRELATED_VIDEO)"
          >
            Unrelated
          </a>

          <a
            appHrefNone
            class="dropdown-item"
            (click)="openPostModal(reportType.SPAM)"
          >
            Other
          </a>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #reportVideoModalRef>
  <app-report-video
    [objectId]="embed.id"
    [reportType]="currentReportType"
    [subjectId]="subjectId"
    [subjectType]="reportSubjectType"
  ></app-report-video>
</ng-template>
