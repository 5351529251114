import { Component, OnInit } from '@angular/core';
import { CurrentNavigationExtra } from '../../../../decorators/current-navigation-extra.decorator';
import { BackgroundImageService } from '../../../../services/background-image.service';
import { LayoutService } from '../../../../services/layout.service';

@Component({
  selector: 'app-layout-settings',
  templateUrl: './layout-settings.component.html',
  styleUrls: ['./layout-settings.component.scss']
})
export class LayoutSettingsComponent implements OnInit {
  // @todo where should redirect
  @CurrentNavigationExtra('referrerUrl') referrerUrl = '/';

  hasReferrer = false;

  constructor(
    private backgroundImageService: BackgroundImageService,
    private layoutService: LayoutService
  ) {
    this.backgroundImageService.setCurrentBackgroundImageForDesktop();
  }

  ngOnInit(): void {
    if (this.referrerUrl !== '/') {
      this.hasReferrer = true;
    }

    this.layoutService.removeCollapsedAddFixed();
  }
}
