import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Image } from '../../../models/image';

@Component({
  selector: 'app-restaurant-img',
  templateUrl: 'restaurant-img.component.html',
  styleUrls: []
})
export class RestaurantImgComponent implements OnInit {
  @Input() images: Array<Image>;
  @Input() elementWidth: number;
  @Input() elementHeight: number;
  @Input() width = 720;
  @Input() height= 720;
  @Input() alt = '';

  isImageLoaded = false;
  defaultRestaurantImage: string;

  constructor() {
    this.defaultRestaurantImage = environment.defaultRestaurantImage;
  }

  ngOnInit(): void {
  }
}
