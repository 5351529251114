import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Auth } from '../../../decorators/auth.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { ModalClose } from '../../../decorators/modal-close.decorator';
import { Post } from '../../../domains/post';
import { User } from '../../../domains/user';
import { DeviceType } from '../../../models/enums/device-type';
import { ReportSubjectType } from '../../../models/enums/report-subject-type';
import { ReportType } from '../../../models/enums/report-type';
import { ReportService } from '../../../services/report.service';

@Component({
  selector: 'app-report-video',
  templateUrl: './report-video.component.html',
  styleUrls: ['./report-video.component.scss']
})
export class ReportVideoComponent implements OnInit {
  @Input() subjectId: number;
  @Input() subjectType: ReportSubjectType;
  @Input() reportType: ReportType;
  @Input() objectId: number;
  @Input() currentUser: User;
  @Input() post: Post;
  @Input() isPost = false;

  @Output() postDeleted = new EventEmitter<boolean>();

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  @ViewChild('usernameChoiceModalRef') usernameChoiceModalRef: ElementRef;

  mmmmLogo: string;
  isOpenedInModal = true;
  isDataReady = false;

  constructor(private reportService: ReportService) {
  }

  ngOnInit(): void {
    this.isOpenedInModal = document.getElementsByTagName('ngb-modal-window').length > 0;

    if (this.isOpenedInModal) {
      this.isDataReady = true;
    }
  }

  @ModalClose()
  closeModal() {
  }

  @Auth()
  report() {
    this.reportService.report(this.subjectId, this.reportType, this.objectId, this.subjectType).subscribe().add(() => {
      this.closeModal();
    });
  }

  postDelete() {
    this.postDeleted.emit(true);
  }
}
