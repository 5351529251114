import { Transform, Type } from 'class-transformer';
import { MenuType } from '../models/enums/menu-type';
import { Card } from './card';
import { Menu } from './menu';
import { RestaurantSimple } from './restaurant-simple';

export class Restaurant extends RestaurantSimple {
  @Type(() => Menu)
  @Transform((menus: Menu[]) => {
    if (menus.length === 0) {
      return menus;
    }

    menus.forEach(menuIterated => {
      menuIterated.categories.forEach(categoryIterated => {
        categoryIterated.foods.forEach(foodIterated => {
          foodIterated.sectionsSortAndReorder();

          foodIterated.sections.forEach(sectionIterated => {
            sectionIterated.extrasSortAndReorder();
          });
        });
      });
    });

    return menus;
  }, {toClassOnly: true})
  menus: Array<Menu> = [];

  @Type(() => Card)
  cards: Array<Card> = [];

  menuTypes: MenuType[] = [];

  get hasMenu() {
    return this.menus.filter(value => {
      return value.categories.length > 0;
    }).length > 0;
  }
}
