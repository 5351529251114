<div class="ios-fixed-background position-fixed bg-white">
  <div class="h-44px safe-area-top">
    <div class="row pt-3">
      <div class="col-search col-4"></div>

      <div class="col-8 px-1">
        <ngx-skeleton-loader
          count="1"
          animation="progress-dark"
          [theme]="{
            'width': '53%',
            'height': '23px',
            'border-radius': '1rem',
            'background-color': '#ffffff20',
            'backdrop-filter': 'blur(10px)'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>

  <div class="justify-content-center">
    <div class="row pt-5">
      <div class="col-search col-3"></div>

      <div class="col-9 px-1">
        <ngx-skeleton-loader
          count="1"
          animation="progress-dark"
          [theme]="{
            'width': '68%',
            'height': '40px',
            'border-radius': '1rem',
            'background-color': '#ffffff20',
            'backdrop-filter': 'blur(10px)'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>

  <div class="row row-search mt-2rem">
    <ng-container *ngFor="let number of [0,1,2,3,4,5]">
      <div class="col-search col-6 mb-1">
        <app-div-rectangle>
          <ngx-skeleton-loader
            count="1"
            animation="progress-dark"
            [theme]="{
              'width': '99.5%',
              'height': '99.5%',
              'border-radius': '0',
              'background-color': '#ffffff20',
              'backdrop-filter': 'blur(10px)'
            }"
          ></ngx-skeleton-loader>
        </app-div-rectangle>
      </div>
    </ng-container>
  </div>

  <app-navbar-bottom-menus
    [restaurant]="currentRestaurant"
    [rotateMainLogo]="true"
  ></app-navbar-bottom-menus>
</div>
