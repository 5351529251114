<div *ngIf="!isDesktop">
  <app-navbar-top-special-period-event
    [title]="title"
    [period]="period"
    (closeModal)="close()"
    (savePeriod)="save()"
  ></app-navbar-top-special-period-event>

  <div *ngIf="period" class="container event-add has-navbar-top max-width px-0 safe-area-top">
    <div class="row px-2rem py-2">
      <input
        ngbAutofocus
        [(ngModel)]="period.eventTitle"
        type="text"
        class="col-7 form-control event-title text-white mr-3"
        placeholder="Event title"
      >

      <select
        [ngModel]="''"
        (ngModelChange)="period.timeEnd = $event"
        class="col-4 custom-select event-title"
      >
        <option value="" selected>End time</option>

        <option *ngFor="let time of timeList" [ngValue]="time">
          {{ time.toString() }}
        </option>
      </select>
    </div>

    <div class="post-new px-1-2rem pt-3 pb-4 h-100">
      <quill-editor
        tabindex="0"
        [(ngModel)]="period.eventDetails"
        theme="bubble"
        placeholder="Add details..."
      >
        <div quill-editor-toolbar>
          <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
          </span>
        </div>
      </quill-editor>
    </div>
  </div>
</div>

<div
  *ngIf="isDesktop"
  appModalCloseOnClick
  class="h-100 w-100 modal-body p-0 flex-content-vc-hc flex-column"
>
  <div class="w-100 h-100">
    <div class="min-h-100vh d-flex align-items-center text-center mt-5 flex-column">
      <div appModalCloseOnClickPrevent class="modal-box">
        <div class="user-authentication text-center">
          <div class="min-h-110px">
            <h2 class="mt-3">{{ weekDay }}</h2>

            <h5>{{ startTime }}</h5>

            <div *ngIf="period" class="container event-add has-navbar-top max-width safe-area-top">
              <div class="row px-0 mx-0">
                <input
                  ngbAutofocus
                  [(ngModel)]="period.eventTitle"
                  type="text"
                  [ngClass]="{'invalid-border': invalid}"
                  class="col-7 form-control event-title text-white d-flex justify-content-start"
                  placeholder="Event title"
                >

                <div
                  appNgbDropdownOnHover
                  ngbDropdown
                  [placement]="'bottom'"
                  class="col-5 cursor-pointer w-100 mt-6px pr-0"
                >
                  <div
                    ngbDropdownToggle
                    class="text-white border-bottom-white d-flex justify-content-start hide-arrow w-100 pb-7px"
                  >
                    {{ selectedEndTime ? selectedEndTime.toString() : 'End time' }}
                  </div>

                  <div ngbDropdownMenu class="bg-dark backdrop-filter-blur-5 dropdown-menu-hours">
                    <a
                      appHrefNone
                      *ngFor="let time of timeList"
                      class="dropdown-item d-inline-flex text-white"
                      (click)="onEndTimeSelected(time)"
                    >
                      {{ time.toString() }}
                    </a>
                  </div>
                </div>
              </div>

              <div class="post-new pt-3 pb-4 h-100 text-align-start">
                <quill-editor
                  tabindex="0"
                  [(ngModel)]="period.eventDetails"
                  theme="bubble"
                  placeholder="Add details..."
                >
                  <div quill-editor-toolbar>
                    <span class="ql-formats">
                      <button class="ql-bold"></button>
                      <button class="ql-italic"></button>
                      <button class="ql-underline"></button>
                    </span>
                  </div>
                </quill-editor>
              </div>
            </div>

            <div class="mt-3">
              <button
                [ngClass]="{'disabled': !period?.eventTitle}"
                type="button"
                class="btn btn-white btn-block text-uppercase"
                (click)="save()"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
