import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrentUser } from '../../decorators/current-user.decorator';
import { User } from '../../domains/user';
import { BooleanLocalStorage } from '../../local-storage/boolean-local-storage';
import { LocalStorageKey } from '../../models/enums/local-storage-key';
import { NavigatorService } from '../../services/navigator.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  @CurrentUser() currentUser: User;

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigatorService: NavigatorService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['session_id']) {
        const checkoutLocalStorage = new BooleanLocalStorage(LocalStorageKey.CHECKOUT);
        checkoutLocalStorage.setItem(true);
      }

      this.navigatorService.goToUser(this.currentUser.username);
    });
  }
}
