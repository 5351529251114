import { Type } from 'class-transformer';
import { OrderFood } from '../../domains/order/order-food';
import { BasketSection } from './basket-section';

export class BasketFood extends OrderFood {
  quantity = 1;
  hash: string;
  price: number;
  priceBase: number;
  total: number;

  @Type(() => BasketSection)
  sections: BasketSection[] = [];

  get hasSections(): boolean {
    return this.sections.length > 0;
  }
}
