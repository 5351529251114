<nav class="navbar navbar-top fixed-top bg-white max-width pr-0 safe-area-top">
  <div class="navbar-top-left navbar-width-70px">
    <a appHrefNone class="navbar-top-icon-back" (click)="goToBack()">
      <span class="icon-v2 arrow-back scale-82"></span>
    </a>
  </div>

  <a *ngIf="title" appHrefNone class="navbar-brand navbar-title mr-0">
    {{ title }}
  </a>

  <div class="navbar-top-right navbar-width-70px">
    <button
      *ngIf="period?.eventTitle"
      type="button"
      class="btn btn-primary md-btn-flat text-capitalize"
      (click)="addEvent()"
    >
      Add
    </button>
  </div>
</nav>
