<div #content [ngClass]="{'w-100 h-100 flex-content-vc-hc': content.children.length > 0}" (click)="onNgContentClicked()">
  <ng-content></ng-content>
</div>

<div
  *ngIf="content.children.length === 0 && !itemGallery && !isAdmin"
  class="flex-content-vc-hc cursor-pointer"
  (click)="clickCommonImageInput()"
>
    <span
      class="icon"
      [ngClass]="{
        'image bg-white': !postImageIcon,
        'image-camera-white scale-1_1 pb-2-5rem': postImageIcon
      }"
    ></span>
</div>

<ng-container *ngIf="itemGallery">
  <div *ngIf="content.children.length === 0 && currentUser?.igAccessToken" ngbDropdown display="dynamic">
    <div
      class="icon image scale-2_1"
      (click)="openImageSelectModal()"
    ></div>
  </div>

  <div
    *ngIf="content.children.length === 0 && !currentUser?.igAccessToken"
    class="flex-content-vc-hc cursor-pointer"
    (click)="clickCommonImageInput()"
  >
    <span class="icon image scale-2_1"></span>
  </div>
</ng-container>

<ng-container *ngIf="isAdmin">
  <div
    #dropdown="ngbDropdown"
    ngbDropdown
    appNgbDropdownOnHover
    [placement]="'bottom-left'"
    display="dynamic"
  >
    <div ngbDropdownToggle class="cursor-pointer hide-arrow">
      <span *ngIf="content.children.length === 0" class="icon image bg-white scale-085"></span>
    </div>

    <div ngbDropdownMenu class="align-items-start">
      <a appHrefNone class="dropdown-item text-default" (click)="clickCommonImageInput()">Computer</a>
      <a appHrefNone class="dropdown-item text-default" (click)="openImageSelectModal()">Instagram</a>
      <a
        *ngIf="showDeleteOption"
        appHrefNone
        class="dropdown-item"
        (click)="delete.emit(deleteEmitObject)"
      >
        Delete
      </a>
    </div>
  </div>
</ng-container>

<input
  #commonImageInput
  [accept]="fileAccept"
  [hidden]="true"
  type="file"
  (change)="onImageUpload(commonImageInput.files)"
/>

<ng-template #imageSelectModalRef>
  <app-image-select-modal
    [isInstagram]="true"
    [title]="imageSelectModalTitle"
    [image]="existImage"
    (save)="onInstagramImageSelect($event)"
  ></app-image-select-modal>
</ng-template>
