import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { DeviceType } from '../../../models/enums/device-type';
import { Place } from '../../../models/place';
import { SearchParams } from '../../../models/search-params';

@Component({
  selector: 'app-search-restaurant-name-input',
  templateUrl: './search-restaurant-name.component.html',
  styleUrls: ['../search-city/search-city-modal/search-city-input/search-city-input.component.scss']
})
export class SearchRestaurantNameComponent implements OnInit {
  @Input() inputClass: string;
  @Input() searchParams: SearchParams;
  @Input() inputHover: boolean;

  @Output() inputValue = new EventEmitter<string>();
  @Output() selectPlace = new EventEmitter<Place>();
  @Output() onFindMeClick = new EventEmitter<boolean>();

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

  searchRest : any = (text: Observable<string>) => {
  }
}
