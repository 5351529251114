export enum ModalType {
  // Templates
  BASE = 'BASE',
  BASE_ABOUT = 'FULL_WIDTH_ABOUT',
  TOP = 'TOP',
  TOP_ROUNDED = 'TOP_ROUNDED',
  TOP_SHADOW_NONE = 'TOP_SHADOW_NONE',
  FULL_TRANSPARENT = 'FULL_TRANSPARENT',
  GRAY_TRANSPARENT = 'GRAY_TRANSPARENT',
  BOTTOM_SWIPE = 'BOTTOM_SWIPE',
  CENTER = 'CENTER',
  CENTER_NARROWED = 'CENTER_NARROWED',
  CENTER_SQUARE = 'CENTER_SQUARE',
  FAVORITE_SQUARE = 'FAVORITE_SQUARE',
  VIDEO_DESKTOP = 'VIDEO_DESKTOP',
  POST_SQUARE = 'POST_SQUARE',
  SEARCH_SQUARE = 'SEARCH_SQUARE',
  // Types
  LOGIN = 'LOGIN',
  RESTAURANT_ADD_DESKTOP = 'RESTAURANT_ADD_DESKTOP',
  LOGIN_MOBILE = 'LOGIN_MOBILE',
  SUBSCRIBE = 'SUBSCRIBE',
  SUBSCRIBE_DESKTOP = 'SUBSCRIBE_DESKTOP',
  RESTAURANT_SQUARE = 'RESTAURANT_SQUARE',
  RESTAURANT = 'RESTAURANT',
  PASSWORD_DESKTOP = 'PASSWORD_DESKTOP',
  PREVIEW = 'PREVIEW',
  PREVIEW_SWIPER = 'PREVIEW_SWIPER',
  FAVORITE_SWIPER = 'FAVORITE_SWIPER',
  SEARCH_FILTERS = 'SEARCH_FILTERS',
  MEMBERSHIP = 'MEMBERSHIP',
  CS_ADDITIONS = 'CS_ADDITIONS',
  CS_MENU_LIST = 'CS_MENU_LIST',
  HOURS_EDIT = 'HOURS_EDIT',
  RESTAURANT_ADD = 'RESTAURANT_ADD',
  LOCATION_ADD_REMOVE = 'LOCATION_ADD_REMOVE',
  IMAGE_SELECT = 'IMAGE_SELECT',
  USERNAME_EXISTS = 'USERNAME_EXISTS',
  ALLERGENS = 'ALLERGENS',
  ADDITIONS_EDIT = 'ADDITIONS_EDIT',
  PICKUP = 'PICKUP',
  ORDER = 'ORDER',
  TOP_SEARCH = 'TOP_SEARCH',
  LOGIN_HELPER = 'LOGIN_HELPER'
}
