import { DistanceSliderPosition } from './distance-slider-position';
import { WrapperInterface } from './wrappers/wrapper.interface';

export class DistanceSliderPositionWrapper implements WrapperInterface {
  list = DistanceSliderPosition;
  value: DistanceSliderPosition;

  constructor(position: string = null) {
    if (position) {
      this.value = DistanceSliderPosition[position];
    }
  }

  get isNavbar() {
    return this.value === this.list.NAV_BAR;
  }

  get isModal() {
    return this.value === this.list.MODAL;
  }
}
