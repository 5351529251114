import { Component, Input, OnInit } from '@angular/core';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { ModalClose } from '../../../decorators/modal-close.decorator';
import { Subscribe } from '../../../decorators/subscribe.decorator';
import { User } from '../../../domains/user';
import { Paths } from '../../../models/enums/paths';
import { CheckoutService } from '../../../services/checkout.service';
import { ShareService } from '../../../services/share.service';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent implements OnInit {
  @Input() path: Paths;

  @CurrentUser() currentUser: User;

  paths = Paths;
  isSubscribed = false;

  constructor(
    private shareService: ShareService,
    private checkoutService: CheckoutService
  ) {
  }

  ngOnInit() {
    if (this.currentUser) {
      this.checkoutService.isUserSubscribed(this.currentUser).subscribe(value => {
        this.isSubscribed = value;
      });
    }
  }

  share() {
    this.shareService.shareGlobal();
  }

  @Subscribe()
  onSubscribeClick() {
    this.modalClose();
  }

  @ModalClose()
  modalClose() {
  }
}
