import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { IsDevice } from '../decorators/is-device.decorator';
import { DeviceType } from '../models/enums/device-type';

@Directive({
  selector: '[appAddClassIfMobile]'
})
export class AddClassIfMobileDirective implements OnInit {
  @Input() appAddClassIfMobile: string;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  nativeElement: HTMLElement;

  constructor(
    element: ElementRef
  ) {
    this.nativeElement = element.nativeElement;
  }

  ngOnInit() {
    if (this.isMobile && this.appAddClassIfMobile) {
      const classes = this.appAddClassIfMobile.split(' ');

      classes.forEach(className => {
        if (!this.nativeElement.classList.contains(className)) {
          this.nativeElement.classList.add(className);
        }
      });
    }
  }
}
