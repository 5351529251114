import { SearchFiltersPosition } from '../enums/search-filters-position';
import { WrapperInterface } from './wrapper.interface';

export class SearchFiltersPositionWrapper implements WrapperInterface {
  list = SearchFiltersPosition;
  value: SearchFiltersPosition;

  constructor(type: SearchFiltersPosition) {
    this.value = type;
  }

  get isMobile(): boolean {
    return this.value === this.list.MOBILE;
  }

  get asTitle(): boolean {
    return this.value === this.list.AS_TITLE;
  }

  get isUnderLogo(): boolean {
    return this.value === this.list.UNDER_LOGO;
  }
}
