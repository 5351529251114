<div *appIfIsMobile class="modal-top-standard bg-dark">
  <input ngbAutofocus type="hidden"/>

  <form *ngIf="dataReady" [formGroup]="restaurantAddForm" (ngSubmit)="onSubmit()">
    <div class="modal-top-standard-body">
      <div class="modal-top-standard-content h-100 d-flex flex-column p-3">
        <div class="flex-content-vc-hc px-3 pt-3rem font-weight-600 font-size-1-85rem w-100">
          {{ name.value }}
        </div>

        <div class="flex-content-vc-hc px-3 pb-3-rem font-size-1rem w-100">{{ secondName.value }}</div>

        <div class="mt-0">
          <div class="form-row">
            <div class="form-group col-6">
              <label class="form-label" for="restaurant-name">Primary name</label>

              <input
                appAutocompleteOff
                [ngClass]="{'is-invalid': submitClicked && !name.valid}"
                type="text"
                id="restaurant-name"
                value="{{restaurant.nameView}}"
                class="form-control form-control-inverted"
                formControlName="name"
              />

              <small class="invalid-feedback">
                Required
              </small>
            </div>

            <div class="form-group col-6">
              <label class="form-label" for="restaurant-second-name">
                Secondary
              </label>

              <input
                appAutocompleteOff
                type="text"
                id="restaurant-second-name"
                value="{{restaurant.secondNameView}}"
                class="form-control form-control-inverted"
                formControlName="secondName"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-6">
              <label class="form-label"> {{ restaurant.typeCategory?.title }} </label>

              <select
                #selectElement
                class="hide-arrow form-control form-control-inverted"
                style="margin-top: 2px;"
              >
                <option class="text-muted">Update category</option>

                <ng-container *ngFor="let typeCategory of typeCategories">
                  <option *ngIf="restaurant.typeCategory.id != typeCategory.id" [ngValue]="typeCategory">
                    {{ typeCategory.title | andToAmpersand }}
                  </option>
                </ng-container>
              </select>
            </div>

            <div class="form-group col-6">
              <div class="form-label w-100">
                <span>
                  <table class="w-100">
                    <tr>
                      <td class="cursor-pointer text-white w-93">
                        {{ instagramStatus.isEmpty() ? 'Instagram' : 'Incorrect instagram' }}
                      </td>

                      <td>
                        <label
                          *ngIf="restaurant.instagram"
                          class="custom-control custom-checkbox custom-checkbox-secondary mb-0"
                        >
                          <input
                            #igCheckbox
                            type="checkbox"
                            class="custom-control-input"
                            (change)="onInstagramChanged(igCheckbox.checked)"
                            [checked]="isChecked"
                          >
                          <span class="custom-control-label cursor-pointer"></span>
                        </label>
                      </td>
                    </tr>
                  </table>
                </span>
              </div>

              <div class="input-group">
                <input
                  class="form-control form-control-inverted"
                  type="text"
                  id="instagram"
                  formControlName="instagram"
                  [placeholder]="instagramStatus.isIncorrect() ? 'Update if available' : 'Restaurant username'"
                  autocomplete="off"
                  min="3"
                  (focus)="onInstagramChanged(instagramStatus.isIncorrect())"
                >
              </div>

              <small class="invalid-feedback">
                Required
              </small>
            </div>
          </div>

          <table class="w-100 checkbox-font">
            <tr>
              <div class="d-flex mt-3">
                <span class="mr-auto py-2 px-0 cursor-pointer text-white">
                  Incorrect business hours
                </span>

                <label class="py-2 custom-control custom-checkbox custom-checkbox-secondary mb-0">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    (change)="onHoursChanged($event)"
                  />

                  <span class="custom-control-label cursor-pointer"></span>
                </label>
              </div>

              <div *ngIf="hoursChanged" class="w-100 mt-2">
                <div class="d-flex justify-content-center w-100 mt-2">
                  Owners are M powered to update hours for free
                </div>

                <div class="d-flex justify-content-center w-100 mt-2">
                  Help spread the word in your town!
                </div>
              </div>
            </tr>

            <tr *ngIf="!hoursChanged" class="cursor-pointer text-white">
              <div class="d-flex">
                <span class="mr-auto py-2 px-0 cursor-pointer text-white"> Not a public restaurant </span>

                <label class="py-2 custom-control custom-checkbox custom-checkbox-secondary mb-0">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                  />

                  <span class="custom-control-label cursor-pointer"></span>
                </label>
              </div>
            </tr>

            <tr *ngIf="!hoursChanged">
              <div class="d-flex">
                <span class="mr-auto py-2 px-0 cursor-pointer text-white">
                  Permanently closed
                </span>

                <label class="py-2 custom-control custom-checkbox custom-checkbox-secondary mb-0">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                  />

                  <span class="custom-control-label cursor-pointer"></span>
                </label>
              </div>
            </tr>

            <tr *ngIf="!hoursChanged">
              <div *ngIf="!locationChanged" class="d-flex">
                <span class="mr-auto py-2 cursor-pointer text-white">
                  Moved location
                </span>

                <label class="py-2 custom-control custom-checkbox custom-checkbox-secondary mb-0">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    (change)="onLocationChanged($event)"
                  />

                  <span class="custom-control-label cursor-pointer"></span>
                </label>
              </div>

              <div *ngIf="locationChanged" class="form-row h-55px">
                <div class="form-group col-7">
                  <input
                    [ngClass]="{'is-invalid': submitClicked && !address.valid}"
                    type="text"
                    id="location-address"
                    class="form-control form-control-inverted"
                    formControlName="address"
                    placeholder="Street address"
                  />

                  <small class="invalid-feedback">
                    Required
                  </small>
                </div>

                <div class="form-group col-4">
                  <input
                    ngbAutofocus
                    [ngClass]="{inputClass: true, 'is-invalid': submitClicked && !zipCode.valid}"
                    type="text"
                    id="location-zip"
                    class="form-control form-control-inverted col-10"
                    formControlName="zipCode"
                    placeholder="Zip"
                  >

                  <small class="invalid-feedback">
                    Required
                  </small>
                </div>

                <div class="formGroup col-1 d-flex justify-content-end">
                  <label class="custom-control custom-checkbox custom-checkbox-secondary mb-0 mt-3">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      checked
                      (change)="locationChanged = false"
                    />

                    <span class="custom-control-label cursor-pointer"></span>
                  </label>
                </div>
              </div>
            </tr>
          </table>
        </div>

        <div class="mt-3 flex-fill d-flex align-items-end">
          <button
            type="button"
            class="btn rounded-pill btn-outline-white text-transform-none text-white font-size-small w-100"
            (click)="onOwnerVerification()"
          >
            I’m the owner
          </button>
        </div>
      </div>
    </div>

    <div class="modal-top-standard-footer bg-white p-3">
      <div class="modal-top-standard-footer-content">
        <input
          type="submit"
          class="btn btn-white btn-block text-uppercase"
          value="Submit"
        />
      </div>
    </div>
  </form>
</div>

<div
  *appIfIsDesktop
  appModalCloseOnClick
  class="h-100 w-100 modal-body p-0 flex-content-vc-hc flex-column"
>
  <div *ngIf="dataReady" class="w-100 h-100">
    <div class="min-h-100vh flex-content-vc-hc flex-column">
      <div appModalCloseOnClickPrevent class="modal-box">
        <div class="user-authentication text-center">
          <input ngbAutofocus type="hidden"/>

          <div class="flex-content-vc-hc px-3 pt-2rem font-weight-600 font-size-1-85rem w-100">
            {{ name.value }}
          </div>

          <div class="flex-content-vc-hc px-3 font-size-1rem w-100" [ngClass]="{'h-23-5px': !secondName.value}">
            {{ secondName.value }}
          </div>

          <form [formGroup]="restaurantAddForm" (ngSubmit)="onSubmit()">
            <div class="modal-top-standard-body modal-top-standard-body-desktop pt-4">
              <div class="modal-top-standard-content px-3 pt-3">
                <div class="mt-0">
                  <div class="form-row">
                    <div class="form-group text-left col-12">
                      <label class="form-label" for="restaurant-name1">Primary name</label>

                      <input
                        appAutocompleteOff
                        [ngClass]="{'is-invalid': submitClicked && !name.valid}"
                        type="text"
                        id="restaurant-name1"
                        value="{{restaurant.nameView}}"
                        class="form-control form-control-inverted"
                        formControlName="name"
                      />

                      <small class="invalid-feedback">
                        Required
                      </small>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group text-left col-12">
                      <label class="form-label" for="restaurant-second-name1">
                        Secondary
                      </label>

                      <input
                        appAutocompleteOff
                        type="text"
                        id="restaurant-second-name1"
                        value="{{restaurant.secondNameView}}"
                        class="form-control form-control-inverted"
                        formControlName="secondName"
                      />
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group text-left col-12">
                      <label class="form-label" for="restaurant-second-name1">
                        Cafe
                      </label>

                      <div
                        ngbDropdown
                        appNgbDropdownOnHover
                        [placement]="'right'"
                        class="form-control form-control-inverted d-inline-flex cursor-pointer"
                      >
                        <div
                          ngbDropdownToggle
                          class="availability-dropdown-toggle flex-content-vc-hc hide-arrow"
                        >
                          <span *ngIf="!restaurant.typeCategory" class="text-muted">
                            Update category
                          </span>

                          <span *ngIf="restaurant.typeCategory">
                            {{ restaurant.typeCategory.display }}
                          </span>
                        </div>

                        <div
                          ngbDropdownMenu
                          class="availability-dropdown dropdown-menu-left"
                        >
                          <a
                            *ngFor="let typeCategory of typeCategories"
                            appHrefNone
                            class="dropdown-item d-inline-flex category-dropdown"
                            (click)="onTypeCategoryChange(typeCategory)"
                          >
                            {{ typeCategory.display }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group text-left col-12">
                      <div class="form-label w-100">
                        <span>
                          <table class="w-100">
                            <tr>
                              <td class="cursor-pointer text-white w-93">
                                {{ instagramStatus.isEmpty() ? 'Instagram' : 'Incorrect Instagram' }}
                              </td>

                              <td>
                                <label
                                  *ngIf="restaurant.instagram"
                                  class="custom-control custom-checkbox custom-checkbox-secondary mb-0"
                                >
                                  <input
                                    #igCheckbox
                                    type="checkbox"
                                    class="custom-control-input"
                                    (change)="onInstagramChanged(igCheckbox.checked)"
                                    [checked]="isChecked"
                                  >

                                  <span class="custom-control-label cursor-pointer"></span>
                                </label>
                              </td>
                            </tr>
                          </table>
                        </span>
                      </div>

                      <div class="input-group">
                        <input
                          class="form-control form-control-inverted"
                          type="text"
                          id="instagram1"
                          formControlName="instagram"
                          [placeholder]="instagramStatus.isIncorrect() ? 'Update if available' : 'Restaurant username'"
                          autocomplete="off"
                          min="3"
                          (focus)="onInstagramChanged(instagramStatus.isIncorrect())"
                        >
                      </div>

                      <small class="invalid-feedback">
                        Required
                      </small>
                    </div>
                  </div>

                  <table class="w-100">
                    <tr>
                      <div class="d-flex mt-3">
                        <span class="mr-auto py-2 px-0 cursor-pointer text-white">
                          Incorrect business hours
                        </span>

                        <label class="py-2 custom-control custom-checkbox custom-checkbox-secondary mb-0">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            (change)="onHoursChanged($event)"
                          />

                          <span class="custom-control-label cursor-pointer"></span>
                        </label>
                      </div>

                      <div *ngIf="hoursChanged" class="w-100 mt-2">
                        <div class="d-flex justify-content-center w-100 mt-2">
                          Owners are M powered to update hours for free
                        </div>

                        <div class="d-flex justify-content-center w-100 mt-2">
                          Help spread the word in your town!
                        </div>
                      </div>
                    </tr>

                    <tr *ngIf="!hoursChanged" class="cursor-pointer text-white">
                      <div class="d-flex">
                        <span class="mr-auto py-2 px-0 cursor-pointer text-white"> Not a public restaurant </span>

                        <label class="py-2 custom-control custom-checkbox custom-checkbox-secondary mb-0">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                          />

                          <span class="custom-control-label cursor-pointer"></span>
                        </label>
                      </div>
                    </tr>

                    <tr *ngIf="!hoursChanged">
                      <div class="d-flex">
                        <span class="mr-auto py-2 px-0 cursor-pointer text-white">
                          Permanently closed
                        </span>

                        <label class="py-2 custom-control custom-checkbox custom-checkbox-secondary mb-0">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                          />

                          <span class="custom-control-label cursor-pointer"></span>
                        </label>
                      </div>
                    </tr>

                    <tr *ngIf="!hoursChanged">
                      <div *ngIf="!locationChanged" class="d-flex">
                        <span class="mr-auto py-2 cursor-pointer text-white">
                          Moved location
                        </span>

                        <label class="py-2 custom-control custom-checkbox custom-checkbox-secondary mb-0">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            (change)="onLocationChanged($event)"
                          />

                          <span class="custom-control-label cursor-pointer"></span>
                        </label>
                      </div>

                      <div *ngIf="locationChanged" class="form-row h-55px">
                        <div class="form-group col-7">
                          <input
                            [ngClass]="{'is-invalid': submitClicked && !address.valid}"
                            type="text"
                            id="location-address1"
                            class="form-control form-control-inverted"
                            formControlName="address"
                            placeholder="Street address"
                          />

                          <small class="invalid-feedback">
                            Required
                          </small>
                        </div>

                        <div class="form-group col-4">
                          <input
                            ngbAutofocus
                            [ngClass]="{inputClass: true, 'is-invalid': submitClicked && !zipCode.valid}"
                            type="text"
                            id="location-zip1"
                            class="form-control form-control-inverted col-10"
                            formControlName="zipCode"
                            placeholder="Zip"
                          >

                          <small class="invalid-feedback">
                            Required
                          </small>
                        </div>

                        <div class="formGroup col-1 d-flex justify-content-end">
                          <label class="custom-control custom-checkbox custom-checkbox-secondary mb-0 mt-3">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked
                              (change)="locationChanged = false"
                            />

                            <span class="custom-control-label cursor-pointer"></span>
                          </label>
                        </div>
                      </div>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="d-flex justify-content-center px-3 pb-2">
                <input
                  type="submit"
                  class="btn btn-white btn-block text-uppercase"
                  value="Submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
