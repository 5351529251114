import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { classToPlain, plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Post } from '../domains/post';
import { PostReport } from '../domains/post-report';
import { Report } from '../domains/report';
import { ReportSubjectType } from '../models/enums/report-subject-type';
import { ReportType } from '../models/enums/report-type';
import { AuthenticationService } from './authentication.service';

@Injectable({providedIn: 'root'})
export class ReportService {
  private readonly reportsUrl: string;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {
    this.reportsUrl = `${environment.apiEndpoint}/api/reports`;
  }

  public getPostReport(post: Post) {
    return this.http.get<PostReport>(`${this.reportsUrl}/post`, {params: {'post-id': post.id.toString()}})
      .pipe(map(value => plainToClass(PostReport, value)));
  }

  public report(subjectId: number, reportType: ReportType, objectId: number = 0, subjectType: ReportSubjectType) {
    const report = new Report();

    report.type = reportType;
    report.subjectId = subjectId;
    report.user = this.authenticationService.currentUser;
    report.subjectType = subjectType;

    if (objectId > 0) {
      report.objectId = objectId;
    }

    return this.http.post<Report>(`${this.reportsUrl}/restaurant`, classToPlain(report));
  }

  public reportPost(postReport: PostReport) {
    return this.http.post<PostReport>(`${this.reportsUrl}/post`, postReport);
  }
}
