<div
  *ngIf="isMobile"
  [ngClass]="{
    'distance-filter-mobile-navbar position-fixed': positionWrapper.isNavbar,
    'top-32px': searchParams.geoLocation,
    'distance-filter-mobile-footer bg-white border-bottom-radius-3': positionWrapper.isModal
  }"
  class="d-flex w-100"
>
  <div class="flex-content-vc pr-2">
    <a appHrefNone class="flex-content-vc-hc" (click)="goToCityRestaurants()">
      <i class="icon-v2 scale-08 close-circle"></i>
    </a>
  </div>

  <div class="flex-fill my-auto px-2 py-1">
    <nouislider
      [(ngModel)]="searchResult.distanceFilterMobile"
      [min]="searchResult?.distanceMin || 1"
      [max]="searchResult?.distanceMax || 100"
      [behaviour]="'tap-drag'"
      [config]="rangeConfig"
      (change)="distanceChange()"
    ></nouislider>
  </div>

  <div class="flex-content-vc pl-2">
    &nbsp;
    <app-restaurants-picker
      [position]="restaurantsPickerPosition.DISTANCE_SLIDER"
      [searchResult]="searchResult"
      (restaurantClicked)="onRestaurantSelected($event)"
    ></app-restaurants-picker>
  </div>
</div>

<div *ngIf="!isMobile" class="d-flex align-items-center">
  <div
    ngbDropdown
    appNgbDropdownOnHover
    [placement]="'bottom'"
    class="d-inline-flex cursor-pointer w-100"
  >
    <div
      appHrefNone
      ngbDropdownToggle
      class="cursor-pointer hide-arrow outline-none d-inline-flex w-100"
    >
      {{ searchResult.distanceFilterDesktop ? searchResult.distanceFilterDesktop : 0.3}} Miles
    </div>

    <div ngbDropdownMenu>
      <ng-container *ngFor="let desktopDistance of desktopDistances">
        <a
          appHrefNone
          class="dropdown-item flex-content-vc"
          (click)="desktopDistanceChange(desktopDistance.value)"
        >
          <span class="flex-fill"> {{ desktopDistance.label }} miles</span>
        </a>
      </ng-container>

      <a appHrefNone class="dropdown-item flex-content-vc" (click)="goToCityRestaurants()">
        Clear
      </a>
    </div>
  </div>
</div>
