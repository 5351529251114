import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Category } from '../domains/category';
import { Tag } from '../domains/tag';
import { CategoryTypeTags } from '../models/category-type-tags';
import { CategoryType } from '../models/enums/category-type';
import { CookieKey } from '../models/enums/cookie-key';
import { DrinkType } from '../models/enums/drink-type';
import { TagType } from '../models/enums/tag-type';

@Injectable({providedIn: 'root'})
export class TagService {
  private readonly tagUrl: string;

  activeTags: Tag[] = [];
  isInitTags = false;
  activeTagsIds: number[] = [];
  categoryTypeTags: CategoryTypeTags;
  warningTags: Tag[] = [];

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private logger: NGXLogger
  ) {
    this.tagUrl = `${environment.apiEndpoint}/api/tags`;

    const activeTagsIdsCookie = this.cookieService.get(CookieKey.TAG_FILTERS) || null;

    if (activeTagsIdsCookie) {
      this.activeTagsIds = JSON.parse(activeTagsIdsCookie);
    }
  }

  initTagsToView() {
    if (this.isInitTags) {
      return;
    }

    this.isInitTags = true;

    this.getTagsByTagType(TagType.regular)
      .subscribe({
        next: tags => {
          tags.forEach(tag => {
            const tagView = plainToClass(Tag, tag);

            if (this.activeTagsIds.length > 0 && this.activeTagsIds.includes(tagView.id)) {
              tagView.checked = true;
              this.activeTags.push(tagView);
            }
          });
        }, error: error => {
          this.logger.error('On getting tags', error);
        }
      });
  }

  getTags(): Promise<CategoryTypeTags> {
    return new Promise((resolve, reject) => {
      if (this.categoryTypeTags) {
        resolve(this.categoryTypeTags);
      } else {
        this.http
          .get<Array<Tag>>(`${this.tagUrl}`)
          .pipe(map(tag => plainToClass(Tag, tag)))
          .subscribe({
            next: tags => {
              this.categoryTypeTags = new CategoryTypeTags(tags);
              resolve(this.categoryTypeTags);
            }, error: error => {
              this.logger.error('On getting tags', error);
              reject(error);
            }
          });
      }
    });
  }

  getTagsByTagType(tagType: TagType, viewOrdering = false) {
    let options = {};

    if (viewOrdering) {
      options = {params: {'view-ordering': 'true'}};
    }

    return this.http.get<Array<Tag>>(`${this.tagUrl}/tag-type/${tagType}`, options);
  }

  getWarningTags(): Promise<Tag[]> {
    return new Promise((resolve, reject) => {
      if (this.warningTags.length === 0) {
        this.getTagsByTagType(TagType.warning, true)
          .subscribe({
            next: (tags: Tag[]) => {
              this.warningTags = plainToClass(Tag, tags);

              resolve(this.warningTags);
            }, error: error => {
              this.logger.error('On getting tags', error);

              reject(error);
            }
          });
      } else {
        resolve(this.warningTags);
      }
    });
  }

  setCategoryTags(category: Category) {
    const categoryType = category.drinkType === DrinkType.Cocktails ? CategoryType.Cocktail : CategoryType.Standard;

    category.tags = this.categoryTypeTags.tags[categoryType];
  }
}
