import { Directive, ElementRef } from '@angular/core';
import { IsDevice } from '../decorators/is-device.decorator';
import { DeviceType } from '../models/enums/device-type';

@Directive({
  selector: '[appAnimate]'
})
export class AnimateDirective {
  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  constructor(element: ElementRef) {
    const nativeElement = element.nativeElement;

    if (!nativeElement.classList.contains('d-block')) {
      nativeElement.classList.add('d-block');
    }

    const observer = new MutationObserver((mutationRecords) => {
      const mutationRecord = mutationRecords.find(mutationRecordI => mutationRecordI.type === 'characterData');

      if (!mutationRecord) {
        return;
      }

      const oldValue = mutationRecord.oldValue;
      const newValue = mutationRecord.target.nodeValue;

      if (oldValue !== '' && oldValue !== newValue) {
        nativeElement.classList.add('animate__animated');

        if (this.isMobile) {
          nativeElement.classList.add('animate__bounce');
        } else {
          nativeElement.classList.add('animate__slideInUp');
        }

        nativeElement.addEventListener('animationend', () => {
          nativeElement.classList.remove('animate__animated', 'animate__bounce', 'animate__slideInUp');
        });
      }
    });

    const config = {
      childList: true,
      characterDataOldValue: true,
      subtree: true
    };

    observer.observe(nativeElement, config);
  }
}
