<ng-container *ngIf="isMobile">
  <app-navbar-top-food-gallery
    [title]="currentRestaurant?.nameView"
    [sort]="sort"
    (sortChange)="sortChange($event)"
  ></app-navbar-top-food-gallery>

  <main>
    <div *ngIf="dataLoaded && posts.length == 0">
      <div
        class="container container-search has-navbar-both max-width bg-white px-0 ios-fixed-background safe-area-top"
      ></div>

      <div class="d-flex flex-column align-items-center plate-icon-position">
        <div class="icon plate-mmmm"></div>

        <div class="mt-n5 font-size-1rem font-weight-500"> Shoot the first photo! </div>
        <div class="mt-2 font-size-1rem font-weight-500"> {{ food.title }} </div>
      </div>
    </div>

    <div
      *ngIf="dataLoaded && posts.length > 0"
      class="container has-navbar-both max-width h-100vh px-0 bg-white"
    >
      <div class="row-items row">
        <ng-container *ngFor="let post of posts, let isLast = last;">
          <div
            *ngIf="post.view.isColumns"
            class="col-item col-6 mb-1"
            (click)="onPostClick(post, viewType.CLASSIC)"
          >
            <app-phantom [view]="post.view" [viewType]="viewType.COLUMNS"></app-phantom>

            <app-div-rectangle>
              <div class="card card-item-image">
                <app-food-img
                  [images]="post.images"
                  [width]="720"
                  [height]="720"
                  [alt]="post.caption"
                ></app-food-img>
              </div>
            </app-div-rectangle>
          </div>

          <div
            *ngIf="post.view.isClassic"
            class="col-item col-12 mb-1"
          >
            <app-phantom [view]="post.view" [viewType]="viewType.CLASSIC"></app-phantom>

            <app-classic-post-image
              [post]="post"
              [postVoted]="postVoted"
              [showIcon]="showIcon"
              (click)="onPostClick(post, viewType.COLUMNS)"
            ></app-classic-post-image>

            <div class="classic-post-controls d-flex justify-content-between px-3">
              <div class="align-self-center classic-post-options"></div>

              <div class="classic-post-controls-user-avatar">
                <app-user-avatar
                  [user]="post.user"
                  [widthAndHeight]="100"
                  (click)="goToUser(post.user)"
                ></app-user-avatar>

                <div class="d-flex flex-column justify-content-end mt-2 z-index-1">
                  <span class="font-weight-500">{{ post?.user.username }}</span>

                  <div class="align-self-center mt-2 pt-4">
                    <button
                      class="btn rounded-pill btn-block text-transform-none w-6rem bg-light-gray"
                      (click)="togglePostVote(post)"
                    >
                      <span *ngIf="post?.votesCount == 0" class="text-black">vote</span>

                      <span *ngIf="post?.votesCount == 1" class="text-black">{{ post?.votesCount }} vote</span>

                      <span *ngIf="post?.votesCount > 1" class="text-black">{{ post?.votesCount }} votes</span>
                    </button>
                  </div>
                </div>
              </div>

              <div class="classic-post-options align-self-center d-flex justify-content-end">
                <app-post-options
                  [post]="post"
                  [isDark]="true"
                  (postDeleted)="postDeleted(post)"
                ></app-post-options>
              </div>
            </div>
          </div>

          <div [ngClass]="{'list-bottom': isLast}"></div>
        </ng-container>
      </div>
    </div>
  </main>

  <app-navbar-bottom-user
    [isExampleRestaurant]="currentRestaurant?.isExample"
    [food]="food"
    [itemGallery]="true"
    (imageUploaded)="goToFoodGalleryNew($event)"
  ></app-navbar-bottom-user>
</ng-container>

<ng-container *ngIf="!isMobile">
  <div class="row-items row">
    <div class="modal-body d-flex p-0 overflow-hidden">
      <div class="d-flex flex-column w-100">
        <div class="d-flex justify-content-center bg-modal h-100vh w-100">
          <div class="d-flex flex-column w-59-5rem z-index-1">
            <h3 class="text-center my-2rem"> {{ currentRestaurant?.nameView }} </h3>

            <div
              *ngIf="dataLoaded && posts.length == 0"
              class="d-flex flex-column align-items-center plate-icon-position"
            >
              <i class="icon plate-mmmm"></i>
            </div>

            <div *ngIf="dataLoaded && posts.length > 0" class="d-flex flex-column">
              <div class="row row-favorites overflow-y">
                <ng-container *ngFor="let post of posts, let isLastPost = last;">
                  <div
                    *ngIf="post.view.isColumns"
                    [ngClass]="{'mb-2rem': !isLastPost, 'mb-4-75rem': isLastPost}"
                    class="col-favorite col-4"
                    (click)="onPostClick(post, viewType?.CLASSIC)"
                  >
                    <app-phantom [view]="post.view" [viewType]="viewType.COLUMNS"></app-phantom>

                    <app-div-rectangle>
                      <div class="card card-item-image">
                        <app-food-img
                          [images]="post.images"
                          [width]="720"
                          [height]="720"
                          [alt]="post.caption"
                        ></app-food-img>
                      </div>
                    </app-div-rectangle>
                  </div>

                  <div
                    *ngIf="post.view.isClassic"
                    [ngClass]="{'mb-1-75rem': !isLastPost, 'mb-4-75rem': isLastPost}"
                    class="col-12 px-0-85rem"
                  >
                    <div class="row">
                      <div class="col-8 px-0-85rem">
                        <app-phantom
                          [view]="post.food?.view"
                          [viewType]="viewType.CLASSIC"
                        ></app-phantom>

                        <app-classic-post-image
                          [post]="post"
                          [postVoted]="postVoted"
                          (click)="onPostClick(post, viewType.COLUMNS)"
                        ></app-classic-post-image>
                      </div>

                      <div class="col-4 px-0-85rem">
                        <div class="card w-100 h-100">
                          <app-gallery-vote
                            [postVoted]="postVoted"
                            [post]="post"
                            [showIcon]="showIcon"
                            (toggleVote)="togglePostVote(post)"
                            (postDelete)="postDeleted(post)"
                          ></app-gallery-vote>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
