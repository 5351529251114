<div
  [ngClass]="{'allergen-modal-content': isMobile, 'modal-body h-100 px-0 pb-0 pt-1-25rem': !isMobile}"
  class="d-flex flex-column"
>
  <div
    [ngClass]="{'allergen-modal-body': isMobile, 'h-100': !isMobile}"
    class="d-flex flex-column"
  >
    <div *appIfIsMobile class="restaurant-name d-flex text-center title-standard w-100 m-0 title-style">
      <input ngbAutofocus type="hidden">

      <div class="text-white">
        {{ food.title }}
      </div>
    </div>

    <ng-container *ngIf="food.hasAllergens">
      <div
        ngPreserveWhitespaces
        [ngClass]="{'mx-5 px-4': isMobile}"
        class="pt-2-5rem justify-content-center align-items-center text-uppercase text-center"
      >
        <ng-container *ngFor="let tag of allergens">
          <span
            *ngIf="tag.checked"
            [ngClass]="{
              'badge-danger': tag.value == 'peanuts',
              'badge-success': tag.value == 'tree-nuts',
              'badge-white': tag.value == 'milk',
              'badge-warning': tag.value == 'eggs',
              'badge-primary': tag.value == 'fish',
              'badge-info': tag.value == 'shellfish',
              'badge-orange': tag.value === 'wheat',
              'badge-secondary': tag.value == 'soy'
            }"
            [ngStyle]="{color: tag.value == 'eggs' ? 'black' : ''}"
            class="badge mb-1"
          >
            {{ tag.display }}
          </span>
          <span *ngIf="!tag.checked" class="badge badge-outline-white">{{ tag.display }}</span>
        </ng-container>
      </div>

      <div class="m-auto"></div>

      <div
        class="mb-3 d-flex text-white justify-content-center align-items-center cursor-pointer"
        (click)="onAllergensClicked()"
      >
        Disclaimers
      </div>
    </ng-container>

    <ng-container *ngIf="!food.hasAllergens">
      <div class="pt-3 pl-3 pr-3 text-white text-center">
        <p class="title-standard font-weight-bold mb-2 mt-4">
          Allergens unconfirmed
        </p>

        <p>
          Ask restaurant to enable
        </p>
      </div>

      <div class="m-auto"></div>

      <div
        class="mb-3 d-flex text-white justify-content-center align-items-center cursor-pointer"
        (click)="onAllergensClicked()"
      >
        Disclaimers
      </div>
    </ng-container>
  </div>
</div>
