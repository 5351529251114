<app-navbar-top-gems></app-navbar-top-gems>

<div
  [appBackgroundUrl]="gemBackgroundImage"
  class="ios-fixed-background"
></div>

<div
  class="container container-logo min-h-100vh"
  (swipeup)="openGemsModal()"
  (click)="openGemsModal()"
>
  <div class="logo flex-content-hc">
    <div class="flex-content-vc-hc mmmm-icon-rounded">
      <span appHrefNone class="icon-v2 mmmm-2x-red"></span>
    </div>
  </div>
</div>

<ng-template #gemsModalRef let-c="close">
  <app-share-modal appModalCloseOnClick [path]="paths.GEMS"></app-share-modal>
</ng-template>
