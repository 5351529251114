import { Component, Input, OnInit } from '@angular/core';
import { Restaurant } from '../../../../domains/restaurant';
import { RestaurantSimple } from '../../../../domains/restaurant-simple';
import { UnsplashImage } from '../../../../models/unsplash-image';


@Component({
  selector: 'app-restaurant-slide-gallery',
  templateUrl: './restaurant-slide-gallery.component.html',
  styleUrls: ['./restaurant-slide-gallery.component.scss'],
})
export class RestaurantSlideGalleryComponent implements OnInit {
  @Input() restaurant: RestaurantSimple | Restaurant;
  @Input() backgroundImage: UnsplashImage | string;

  constructor() {
  }

  ngOnInit(): void {
  }
}
