import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-div-rectangle',
  templateUrl: './div-rectangle.component.html',
  styleUrls: ['./div-rectangle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DivRectangleComponent implements OnInit {
  /**
   * ratio = width / height
   * -ratio = height / width
   */
  @Input() ratio = 1;

  percentage = 100;

  constructor() {
  }

  ngOnInit() {
    if (this.ratio > 0) {
      this.percentage = (1 / this.ratio) * 100;
    } else if (this.ratio < 0) {
      this.percentage = (-1 * this.ratio) * 100;
    } else {
      this.percentage = this.ratio * 100;
    }
  }
}
