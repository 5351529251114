import { Component, Input, OnInit } from '@angular/core';
import { Basket } from '../../../../models/basket/basket';
import { BasketService } from '../../../../services/basket.service';

@Component({
  selector: 'app-tax-fee-modal',
  templateUrl: './tax-fee-modal.component.html',
  styleUrls: [
    './tax-fee-modal.component.scss'
  ]
})
export class TaxFeeModalComponent implements OnInit {
  @Input() basket: Basket;

  constructor(private basketService: BasketService) {
  }

  ngOnInit() {
  }

  onFeeChange() {
    this.basket.calculate();
    this.basketService.basket = this.basket;
  }
}
