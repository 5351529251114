import { Component, Input, OnInit } from '@angular/core';
import { Section } from '../../../../domains/section';
import { SectionType } from '../../../../models/enums/section-type';

@Component({
  selector: 'app-section-view',
  templateUrl: './section-view.component.html',
  styleUrls: ['./section-view.component.scss']
})
export class SectionViewComponent implements OnInit {
  @Input() section: Section;

  sectionType = SectionType;

  constructor() {
  }

  ngOnInit() {
  }
}
