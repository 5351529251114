<div
  [appBackgroundUrl]="exploreBackgroundImage"
  class="ios-fixed-background position-fixed"
>
  <div class="container-backdrop-fixed list-backdrop"></div>
</div>

<app-navbar-top-menu-cs
  [title]="currentRestaurant.nameView | abbreviate: false: '' | city: currentRestaurant.cityNameToRemove"
  [restaurant]="currentRestaurant"
  (saveChanges)="saveChanges()"
  (enabledMenuTypesSave)="onEnabledMenuTypesSave($event)"
></app-navbar-top-menu-cs>

<ng-container *ngFor="let menu of menus; let menuIndex = index">
  <div class="menu-box">
    <div id="menu-title-{{menuIndex}}" class="media align-items-center">
      <div class="media-body font-weight-semibold z-index-1">
        <h1
          *ngIf="menu.title !== 'House'"
          class="menu-title text-center mt-5 p-0 mb-0 text-white cursor-pointer"
          [ngClass]="{
            'opacity-100': menu.expanded,
            'opacity-60': !menu.expanded
          }"
          (click)="toggleMenuCategory(menu)"
        >
          {{ menu.display(currentRestaurant) }}
        </h1>

        <div
          *ngIf="menu.expanded"
          [sortablejs]="menu.categories"
          [sortablejsOptions]="categoriesSortableOptions"
          [ngClass]="{'show': menu.categories.length >= 1}"
        >
          <ng-container
            *ngFor="let category of menu.categories; let categoryIndex = index; let first = first; let last = last;"
          >
            <div
              *ngIf="!sort"
              [ngClass]="{'pt-1-5rem': !category.hidden}"
              class="card-body cat-padding text-white"
            >
              <div *ngIf="category.title" class="form-row">
                <div class="form-group form-group-col flex-fill">
                  <div class="form-control text-white text-uppercase">
                    {{ category.title }}
                  </div>
                </div>

                <div class="form-group flex-content-vc-hr ml-0-7rem mr-03rem">
                  <div
                    ngbTooltip="Click & Drag"
                    placement="left"
                    class="icon arrange-white category-sortable"
                  ></div>
                </div>
              </div>

              <div [sortablejs]="category.foods" [sortablejsOptions]="{ handle: '.handle' }">
                <ng-container *ngFor="let food of category.foods;">
                  <ng-container *ngIf="!category.hidden">
                    <div *ngIf="food.title" class="form-row">
                      <div class="form-group form-group-col flex-fill">
                        <div class="form-control text-white">
                          {{ food.title }}
                        </div>
                      </div>

                      <div class="form-group form-group-col">
                        <div class="form-row mx-0">
                          <div class="input-group-prepend">
                            <div
                              *ngIf="!category.hidden"
                              ngbTooltip="Click & Drag"
                              placement="left"
                              class="icon sort handle"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>

            <div *ngIf="sort" [ngClass]="{'pt-1-5rem': !category.hidden}" class="card-body cat-padding">
              <div class="form-row">
                <div class="form-group form-group-col flex-fill">
                  <div class="form-control">
                    <input
                      [(ngModel)]="category.title"
                      [placeholder]="category.placeholder"
                      required
                      type="text"
                      class="mr-2rem form-control border-white form-control-inverted ng-pristine ng-valid ng-touched"
                      (input)="saveCategory(category)"
                    >
                  </div>

                  <div *ngIf="!category.hidden" class="form-control mb-3">
                    <textarea
                      [(ngModel)]="category.description"
                      class="mr-2rem form-control border-white form-control-inverted"
                      placeholder="Section notes"
                      rows="1"
                      (input)="saveCategory(category)"
                    ></textarea>
                  </div>
                </div>

                <div [ngClass]="{'mb-4rem': !category.hidden}" class="flex-content-vc-hr ml-0-7rem mr-03rem">
                  <app-picker
                    [classes]="'icon ' + getCategoryClass(category.categoryStatus)"
                    [picker]="categoryStatus"
                    (selectedValue)="onCategoryAvailabilityClick($event, category)"
                    [value]="category.categoryStatus"
                  ></app-picker>
                </div>
              </div>

              <div *ngIf="sort">
                <ng-container *ngFor="let food of category.foods;">
                  <ng-container *ngIf="!category.hidden">
                    <div class="form-row handle">
                      <span
                        *ngIf="food.title"
                        class="icon dots-v"
                        (click)="onOptionClick($event, category, food, sectionType.SIZE)"
                      ></span>

                      <div
                        [ngClass]="{'pl-1_7rem': !food.title, 'pl-1_1rem': food.title}"
                        class="form-group form-group-col flex-fill"
                      >
                        <input
                          [(ngModel)]="food.title"
                          type="text"
                          class="form-control border-white text-white"
                          placeholder="Item"
                          (change)="saveFood(category, food)"
                        >
                      </div>

                      <div class="form-group form-group-col">
                        <div class="form-row mx-0">
                          <div class="input-group-prepend">
                            <span class="input-group-text pr-2 border-white text-white">$</span>

                            <input
                              [ngModel]="food.price | number: '.2-2'"
                              [min]="0"
                              type="number"
                              class="form-control form-control-3-digit border-white text-white"
                              inputmode="decimal"
                              pattern="[0-9]*"
                              (change)="onPriceChange($event, category, food)"
                            >
                            <small class="invalid-feedback">Required</small>
                          </div>

                          <div class="flex-content-vc-hr ml-3">
                            <app-picker
                              *ngIf="!category.hidden"
                              [classes]="'icon ' + food.status.pickerClass"
                              [picker]="statusEditPicker"
                              (selectedValue)="onPickerSelected($event, food)"
                              [value]="food.status.newValue"
                            ></app-picker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>

            <div *ngIf="sort && !last && !category.hidden" class="d-flex text-muted font-weight-semibold">
              <a
                appHrefNone
                class="text-white-50 ml-2_5rem"
                (click)="addFood(categoryIndex)"
              >
                Add items
              </a>
            </div>

            <div *ngIf="sort && last && menu.expanded && !category.hidden"
                 class="d-flex text-muted font-weight-semibold">
              <a
                appHrefNone
                class="text-white-50 ml-2_5rem"
                (click)="addFood(categoryIndex)"
              >
                Add items &nbsp;| &nbsp;
              </a>

              <app-add-category-button
                [menu]="menu"
                (addCategory)="addCategory($event)"
              ></app-add-category-button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<app-navbar-bottom-menus-cs
  [restaurant]="currentRestaurant"
  [menu]="menu"
  [sort]="sort"
  (sortChanged)="switchSort($event)"
></app-navbar-bottom-menus-cs>

<ng-template #additionsCsModal let-c="close" let-d="dismiss">
  <app-additions-cs-modal
    [user]="currentUser"
    [restaurant]="currentRestaurant"
    [food]="currentFood"
    [tags]="currentCategory.tags"
    [showWarnings]="showWarningTags"
    (save)="saveFood(currentCategory, currentFood)"
  ></app-additions-cs-modal>
</ng-template>
