import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Auth } from '../../../../../../decorators/auth.decorator';
import { CurrentUser } from '../../../../../../decorators/current-user.decorator';
import { ModalClose } from '../../../../../../decorators/modal-close.decorator';
import { Post } from '../../../../../../domains/post';
import { User } from '../../../../../../domains/user';
import { NavigatorService } from '../../../../../../services/navigator.service';

@Component({
  selector: 'app-gallery-vote',
  templateUrl: './gallery-vote.component.html',
  styleUrls: ['./gallery-vote.component.scss']
})
export class GalleryVoteComponent implements OnInit {
  @Input() postVoted = false;
  @Input() post: Post;
  @Input() showIcon: boolean;

  @Output() toggleVote = new EventEmitter<boolean>();
  @Output() postDelete = new EventEmitter<Post>();

  @CurrentUser() currentUser: User;

  constructor(
    private navigatorService: NavigatorService,
  ) {
  }

  ngOnInit(): void {
  }

  togglePostVote(event) {
    this.toggleVote.emit(event);
  }

  postDeleted(post: Post) {
    this.postDelete.emit(post);
  }

  @Auth()
  goToUser() {
    this.navigatorService.goToUser();

    this.closeModal();
  }

  @ModalClose()
  closeModal() {
  }
}
