export enum MenuType {
  BREAKFAST = 'BREAKFAST',
  ALL_DAY = 'ALL_DAY', // only on front
  LUNCH = 'LUNCH',
  HAPPY_HOUR = 'HAPPY_HOUR',
  DINNER = 'DINNER',
  BRUNCH = 'BRUNCH',
  KIDS = 'KIDS',
  DESSERT = 'DESSERT',
  LATE_NIGHT = 'LATE_NIGHT',

  ALE = 'ALE',
  LAGER = 'LAGER',

  RED = 'RED',
  WHITE = 'WHITE',
  ROSE = 'ROSE',
  SPARKLING = 'SPARKLING',

  DONUTS = 'DONUTS',
  BAKERY = 'BAKERY',
  BAGELS = 'BAGELS',

  BITES = 'BITES',
  DRINKS = 'DRINKS',
  BEER_WINE = 'BEER_WINE',

  HOUSE = 'HOUSE'
}
