import { ActivatedRouteSnapshot } from '@angular/router';
import { GeoLocation } from '../geo-location';
import { RouteParams } from './route-params';

export class ExploreRouteParams extends RouteParams {
  stateKey: string = null;
  countySlug: string = null;
  regionSlug: string = null;
  citySlug: string = null;
  districtSlug: string = null;
  geoLocation: GeoLocation = null;
  path: string;

  constructor(snapshot: ActivatedRouteSnapshot) {
    super();

    this.path = '/' + snapshot.url.join('/');

    const params = snapshot.params;

    if (params) {
      this.stateKey = this.getParam(params, 'stateKey');
      this.countySlug = this.getParam(params, 'countySlug');
      this.regionSlug = this.getParam(params, 'regionSlug');
      this.citySlug = this.getParam(params, 'citySlug');
      this.districtSlug = this.getParam(params, 'districtSlug');

      const latitude = this.getParam(params, 'lat');
      const longitude = this.getParam(params, 'lon');

      if (latitude && longitude) {
        this.geoLocation = new GeoLocation(latitude, longitude);
      }
    }
  }

  get isEmpty(): boolean {
    return !this.stateKey && !this.countySlug && !this.citySlug;
  }
}
