<div
  #mmmmButtonRef
  *ngIf="isDesktop"
  class="d-flex w-100"
>
  <div
    *ngIf="!favoriteModal"
    ngbDropdown
    appNgbDropdownOnHover
    [placement]="'bottom'"
    class="d-flex w-100 justify-content-center"
  >
    <a
      appHrefNone
      ngbDropdownToggle
      class="cursor-pointer hide-arrow outline-none d-flex"
    >
      <span
        [ngClass]="{
          'heart-empty-white opacity-05-to-1-on-hover': food.favoriteStatus.isNotEaten(),
          'heart-red': food.favoriteStatus.isFavorite(),
          'goal-red': food.favoriteStatus.isTryMe(),
          'goal-white-reached': food.favoriteStatus.isEaten(),
        }"
        class="icon-v2"
      ></span>
    </a>

    <div ngbDropdownMenu class="bg-dark backdrop-filter-blur-5">
      <ng-container *ngFor="let favoriteStatus of food.favoriteStatus.statuses">
        <a
          appHrefNone
          class="dropdown-item flex-content-vc"
          (click)="toggleHeart(favoriteStatus.value)"
        >
          <span class="heart-dropdown-icon" [ngClass]="favoriteStatus.icon"></span>

          <span class="ml-3 flex-fill text-white">{{ favoriteStatus.title }}</span>
        </a>
      </ng-container>
    </div>
  </div>

  <div
    *ngIf="favoriteModal"
    ngbDropdown
    [open]="imageHovered"
    [placement]="'bottom'"
    class="d-flex w-100 justify-content-center"
  >
    <a
      appHrefNone
      ngbDropdownToggle
      class="scale-1_5 cursor-pointer hide-arrow outline-none d-flex"
    >
      <span
        [ngClass]="{
          'heart-fill': food.favoriteStatus.isFavorite(),
          'goal-white': food.favoriteStatus.isTryMe(),
          'goal-white-reached': food.favoriteStatus.isEaten(),
          'star-empty-white': food.favoriteStatus.isNotEaten()
        }"
        class="icon-v2"
      ></span>
    </a>

    <div ngbDropdownMenu [ngClass]="{'heart-dropdown': imageHovered}" class="bg-dark backdrop-filter-blur-5">
      <ng-container *ngFor="let favoriteStatus of food.favoriteStatus.statuses">
        <a
          appHrefNone
          class="dropdown-item d-flex align-items-start justify-content-center"
          (click)="toggleHeart(favoriteStatus.value)"
        >
          <span class="heart-dropdown-icon pb-065-rem" [ngClass]="favoriteStatus.icon"></span>

          <span class="ml-3 flex-fill text-white">{{ favoriteStatus.title }}</span>
        </a>
      </ng-container>
    </div>
  </div>
</div>

<a #mmmmButtonRef *ngIf="!isDesktop" appHrefNone class="d-inline-flex flex-content-vc">
  <app-picker
    [classes]="food.favoriteStatus.pickerClass"
    [picker]="foodFavoritePicker"
    [value]="food.favoriteStatus.value"
    (selectedValue)="toggleHeart($event)"
  ></app-picker>
</a>
