import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { IsLoggedIn } from '../../../decorators/is-logged-in.decorator';
import { LocalStorageValue } from '../../../decorators/local-storage-value.decorator';
import { CountryWithChild } from '../../../domains/country-with-child';
import { Restaurant } from '../../../domains/restaurant';
import { BooleanLocalStorage } from '../../../local-storage/boolean-local-storage';
import { LocalStorageKey } from '../../../models/enums/local-storage-key';
import { ModalType } from '../../../models/enums/modal-type';
import { SearchFiltersPosition } from '../../../models/enums/search-filters-position';
import { Picker } from '../../../models/picker';
import { PickerConfig } from '../../../models/picker-config';
import { PickerGroup } from '../../../models/picker-group';
import { SearchParams } from '../../../models/search-params';
import { SearchFiltersPositionWrapper } from '../../../models/wrappers/search-filters-position.wrapper';
import { ModalService } from '../../../services/modal.service';
import { RestaurantService } from '../../../services/restaurant.service';
import { RouterService } from '../../../services/router.service';

@Component({
  selector: 'app-navbar-top-explore',
  templateUrl: './navbar-top-explore.component.html',
  styleUrls: [
    '../navbar.shared.scss',
    './navbar-top-explore.component.scss'
  ]
})
export class NavbarTopExploreComponent implements OnInit {
  @Input() menuHours = false;
  @Input() country: CountryWithChild;
  @Input() searchParams: SearchParams;
  @Input() findMeLoader: boolean;

  @Output() editRestaurantMenus = new EventEmitter<Restaurant>();
  @Output() openSearchModal = new EventEmitter<boolean>();
  @Output() onSearchClicked = new EventEmitter<boolean>();
  @Output() videoModalClicked = new EventEmitter<boolean>();

  @ViewChild('hoursEditModalRef') hoursEditModalRef: ElementRef;

  @IsLoggedIn() isLoggedIn: boolean;

  @LocalStorageValue(BooleanLocalStorage, LocalStorageKey.IS_EDIT_MODE, {restaurant: true, user: true})
  isEditModeLocalStorage: BooleanLocalStorage;

  isEditMode: boolean;
  restaurant: Restaurant;
  userRestaurants: Restaurant[] = [];
  userRestaurantsPicker: Picker;
  searchFiltersPositionAsTitle = new SearchFiltersPositionWrapper(SearchFiltersPosition.AS_TITLE);

  constructor(
    private restaurantService: RestaurantService,
    private modalService: ModalService,
    private routerService: RouterService
  ) {
  }

  ngOnInit() {
    this.isEditMode = this.isEditModeLocalStorage.getItem();

    if (this.isLoggedIn) {
      this.restaurantService.getCurrentUserRestaurants().subscribe(restaurants => {
        this.userRestaurants = restaurants;
        const pickerGroups = [];

        this.userRestaurants.forEach((restaurant: Restaurant) => {
          const pickerGroup = new PickerGroup(restaurant.nameView);
          pickerGroup.addOption(restaurant.id.toString(), restaurant.localityDisplay, restaurant);
          pickerGroups.push(pickerGroup);
        });

        this.userRestaurantsPicker = new Picker(plainToClass(PickerConfig, {groups: pickerGroups}));
      });
    }
  }

  onMenuPickerSelected(restaurant: Restaurant) {
    this.openMenuEdit(restaurant);
  }

  onHoursPickerSelected(restaurant: Restaurant) {
    this.openHoursEditModal(restaurant);
  }

  openHoursEditModal(restaurant: Restaurant) {
    this.restaurant = restaurant;
    this.routerService.setRestaurantHostnameAndLocationId(restaurant.hostname, restaurant.locationIndex);
    const modal = this.modalService.open(this.hoursEditModalRef, ModalType.HOURS_EDIT);

    modal.onClose().then(() => {
      this.routerService.setRestaurantHostnameAndLocationId(null, null);
    });
  }

  openMenuEdit(restaurant: Restaurant) {
    this.restaurant = restaurant;
    this.editRestaurantMenus.emit(restaurant);
  }

  openCitySearchModal() {
    this.openSearchModal.emit(true);
  }

  searchClicked() {
    this.onSearchClicked.emit(true);
  }

  openVideoModal() {
    this.videoModalClicked.emit(true);
  }
}
