import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { environment } from '../../environments/environment';
import { GeoLocation } from '../models/geo-location';
import { LoggerService } from './logger.service';
import { NotificationService } from './notification.service';

@Injectable({providedIn: 'root'})
export class GeoLocationService {
  private readonly defaultIpLocation = plainToClass(GeoLocation, {latitude: 37.507160, longitude: -122.260521});

  constructor(
    private http: HttpClient,
    private loggerService: LoggerService,
    private notificationService: NotificationService
  ) {
  }

  private getIPAddressInfo() {
    return this.http.get(`https://extreme-ip-lookup.com/json/?key=${environment.ipLookupKey}`);
  }

  getGeoLocationByIpOrDefault(): Promise<GeoLocation> {
    return new Promise(resolve => {
      this.getIPAddressInfo()
        .subscribe({
          next: ipValue => {
            if (ipValue['status'] === 'success') {
              const ipLocation = plainToClass(
                GeoLocation,
                {latitude: ipValue['lat'], longitude: ipValue['lon']},
                {excludeExtraneousValues: true}
              );

              resolve(ipLocation);
            } else {
              resolve(this.defaultIpLocation);
            }
          }, error: error => {
            this.loggerService.errorLog(error, 'On getting ip');

            resolve(this.defaultIpLocation);
          }
        });
    });
  }

  getGeoLocation(accessLocation: boolean): Promise<GeoLocation> {
    return new Promise(resolve => {
      if (!accessLocation) {
        this.getGeoLocationByIpOrDefault().then(geoLocation => resolve(geoLocation));
      } else if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          resolve(plainToClass(GeoLocation, position.coords));
        }, error => {
          this.notificationService.locationDisabled();
          this.loggerService.errorLog(error, 'On getting geolocation');

          this.getGeoLocationByIpOrDefault().then(geoLocation => resolve(geoLocation));
        });
      } else {
        this.loggerService.newErrorLog('Geolocation is not supported by this browser.');

        this.getGeoLocationByIpOrDefault().then(geoLocation => resolve(geoLocation));
      }
    });
  }

  areSameGeoLocations(geoLocation1: GeoLocation, geoLocation2: GeoLocation) {
    return geoLocation1?.latitude === geoLocation2?.latitude && geoLocation1?.longitude === geoLocation2?.longitude;
  }
}
