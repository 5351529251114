import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

export class PostFoodFormGroup {
  form: UntypedFormGroup = new UntypedFormGroup({
    title: new UntypedFormControl(null, [Validators.required]),
    price: new UntypedFormControl(null, [Validators.required]),
  });

  get title() {
    return this.form.get('title');
  }

  get price() {
    return this.form.get('price');
  }
}
