import { Component, Input, OnInit } from '@angular/core';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { StripeSubscription } from '../../../domains/stripe-subscription';
import { User } from '../../../domains/user';
import { DeviceType } from '../../../models/enums/device-type';
import { CheckoutService } from '../../../services/checkout.service';

@Component({
  selector: 'app-membership-card',
  templateUrl: './membership-card.component.html',
  styleUrls: ['./membership-card.component.scss']
})
export class MembershipCardComponent implements OnInit {
  @Input() edit = false;
  @Input() user: User;
  @Input() price: number;
  @Input() subscription: StripeSubscription;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  startDate: Date;
  isSubscribed = false;

  constructor(private checkoutService: CheckoutService) {
  }

  ngOnInit(): void {
    this.checkoutService.isUserSubscribed(this.user).subscribe(value => {
      this.isSubscribed = value;

      if (!this.edit && this.isSubscribed) {
        this.startDate = new Date(this.subscription.currentPeriodStart * 1000);
        const endDate = new Date(this.subscription.currentPeriodEnd * 1000);
        const months = ((endDate.getUTCFullYear() - this.startDate.getUTCFullYear()) * 12) +
          (endDate.getUTCMonth() - this.startDate.getUTCMonth());

        this.price = this.subscription.price / months / 100;
      }
    });
  }
}
