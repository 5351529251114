import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import { environment } from '../../environments/environment';
import { Listener } from '../websocket/listener';

@Injectable({providedIn: 'root'})
export class WebSocketService {
  webSocketEndPoint: string;
  stompClient: any;
  subscribedTopics = [];

  constructor(private logger: NGXLogger) {
    this.webSocketEndPoint = environment.apiEndpoint + '/ws';
    this.stompClient = Stomp.over(new SockJS(this.webSocketEndPoint));

    if (environment.production) {
      this.stompClient.debug = null;
    }

    this.connect();
  }

  registerListener(listener: Listener) {
    if (this.stompClient.connected) {
      let subscriber = this.subscribedTopics[listener.hash];

      if (subscriber) {
        subscriber.unsubscribe();
      }

      subscriber = this.stompClient.subscribe(listener.topic, frame => {
        listener.subject.next(listener.transform(frame));
      });

      this.subscribedTopics[listener.hash] = subscriber;
    } else {
      setTimeout(() => {
        this.registerListener(listener);
      }, 100);
    }

    return listener;
  }

  unsubscribeListener(listener: Listener) {
    const subscriber = this.subscribedTopics[listener.hash];

    if (subscriber) {
      subscriber.unsubscribe();
      delete this.subscribedTopics[listener.hash];
    }
  }

  private connect() {
    this.stompClient.connect({}, () => {
      this.logger.debug('Websocket Connected');
    }, error => {
      this.logger.error('Websocket' + error);

      setTimeout(() => {
        this.connect();
      }, 5000);
    });
  }

  private disconnect() {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
  }
}
