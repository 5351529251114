import { CroppedImageDimensions } from '../models/cropped-image-dimensions';

export class ImageUtils {
  public static isDataOrURL(path: string) {
    if (path?.indexOf('https://') === 0 || path?.indexOf('assets/img') !== -1) {
      return true;
    }

    return !!path?.match(/^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i);
  }

  public static downloadImage(src: string) {
    const image = new Image();
    image.src = src;

    return new Promise(resolve => image.onload = resolve);
  }

  public static cropImage(
    imageSrc: string,
    rotation = 0,
    pixelCrop: CroppedImageDimensions = null,
    flip = {horizontal: false, vertical: false}
  ): Promise<any> {
    const createImage = (url): Promise<any> => {
      return new Promise((resolve, reject) => {
        const newImage = new Image();
        newImage.addEventListener('load', () => resolve(newImage));
        newImage.addEventListener('error', (error) => reject(error));
        newImage.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
        newImage.src = url;
      });
    };

    const getRadianAngle = (degreeValue) => {
      return (degreeValue * Math.PI) / 180;
    };

    const rotateSize = (width, height, rotationAngle) => {
      const rotationRadian = getRadianAngle(rotationAngle);

      return {
        width:
          Math.abs(Math.cos(rotationRadian) * width) + Math.abs(Math.sin(rotationRadian) * height),
        height:
          Math.abs(Math.sin(rotationRadian) * width) + Math.abs(Math.cos(rotationRadian) * height),
      };
    };

    return new Promise((resolve, reject) => {
      createImage(imageSrc).then(image => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          return null;
        }

        const rotRad = getRadianAngle(rotation);

        // calculate bounding box of the rotated image
        const {width: bBoxWidth, height: bBoxHeight} = rotateSize(
          image.width,
          image.height,
          rotation
        );

        // set canvas size to match the bounding box
        canvas.width = bBoxWidth;
        canvas.height = bBoxHeight;

        // translate canvas context to a central location to allow rotating and flipping around the center
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
        ctx.rotate(rotRad);
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
        ctx.translate(-image.width / 2, -image.height / 2);

        // draw rotated image
        ctx.drawImage(image, 0, 0);

        if (pixelCrop) {
          // croppedAreaPixels values are bounding box relative
          // extract the cropped image using these values
          const data = ctx.getImageData(
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height
          );

          // set canvas width to final desired crop size - this will clear existing context
          canvas.width = pixelCrop.width;
          canvas.height = pixelCrop.height;

          // paste generated rotate image at the top the left corner
          ctx.putImageData(data, 0, 0);
        }

        // As Base64 string
        resolve(canvas.toDataURL('image/jpeg'));
      });
    });
  }
}
