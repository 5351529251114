<app-navbar-top-settings [title]="'Settings'"></app-navbar-top-settings>

<div #settings class="container has-navbar-top max-width min-h-100vh">
  <div class="card h-100 user-settings container-row bg-white navbar-bottom-bg">
    <ul class="list-group mt-2">
      <li
        class="list-group-item flex-content-vc justify-content-between border-0 cursor-pointer"
        (click)="openProfile()"
      >
        <span class="font-weight-semibold">Account</span>
        <span class="icon-v2 arrow-forward"></span>
      </li>
      <li
        class="list-group-item flex-content-vc justify-content-between border-0 cursor-pointer"
        (click)="openPasswords()"
      >
        <span class="font-weight-semibold">Password</span>
        <span class="icon-v2 arrow-forward"></span>
      </li>

      <li
        class="list-group-item flex-content-vc justify-content-between border-0 cursor-pointer"
        (click)="openSubscription()"
      >
        <span class="font-weight-semibold">Mbership</span>
        <span class="icon-v2 arrow-forward"></span>
      </li>

      <li
        *ngIf="hasCsRestaurants"
        class="list-group-item flex-content-vc justify-content-between border-0 cursor-pointer"
        (click)="openHero()"
      >
        <span class="font-weight-semibold">Hero</span>
        <span class="icon-v2 arrow-forward"></span>
      </li>

      <li style="border-bottom: 1px solid #ccc; display: block" class="my-2"></li>

      <ng-container *ngIf="restaurant">
        <li
          class="list-group-item flex-content-vc justify-content-between border-0 cursor-pointer"
          routerLink="/{{restaurantAdminPath}}/dashboard"
        >
          <span class="font-weight-semibold">Dashboard</span>
          <span class="icon-v2 arrow-forward"></span>
        </li>

        <li
          class="list-group-item flex-content-vc justify-content-between border-0 cursor-pointer"
          routerLink="/{{restaurantAdminPath}}/locations"
        >
          <span class="font-weight-semibold">My restaurant</span>
          <span class="icon-v2 arrow-forward"></span>
        </li>

        <li
          class="list-group-item flex-content-vc justify-content-between border-0 cursor-pointer"
          routerLink="/{{restaurantAdminPath}}/about"
        >
          <span class="font-weight-semibold">About</span>
          <span class="icon-v2 arrow-forward"></span>
        </li>

        <li
          class="list-group-item flex-content-vc justify-content-between border-0 cursor-pointer"
          routerLink="/{{restaurantAdminPath}}/upgrade"
        >
          <span class="font-weight-semibold">Marketing</span>
          <span class="icon-v2 arrow-forward"></span>
        </li>

        <li
          class="list-group-item flex-content-vc justify-content-between border-0 cursor-pointer"
          routerLink="/{{restaurant.hostname}}"
        >
          <span class="font-weight-semibold">{{ restaurant.hostname }}.mmmm.menu</span>
        </li>
      </ng-container>

      <li
        class="list-group-item flex-content-vc justify-content-between border-0 cursor-pointer"
        (click)="logout()"
      >
        <span class="font-weight-semibold">Logout</span>
      </li>
    </ul>
  </div>
</div>
