import { SearchModalState } from '../enums/search-modal-state';
import { WrapperInterface } from './wrapper.interface';

export class SearchModalStateWrapper implements WrapperInterface {
  list = SearchModalState;
  value: SearchModalState;

  constructor(value: SearchModalState) {
    this.value = value;
  }

  isInitial(): boolean {
    return this.value === SearchModalState.INITIAL;
  }

  isFavorite() {
    return this.value === SearchModalState.FAVORITE;
  }

  isVideo() {
    return this.value === SearchModalState.VIDEO;
  }

  isTargets() {
    return this.value === SearchModalState.TARGETS;
  }

  setValue(value: SearchModalState) {
    this.value = value;
  }
}
