import Compressor from 'compressorjs';

export class FileUtils {
  public static getFileData(file: File | Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (_event) => {
        resolve(reader.result.toString());
      };

      reader.onerror = error => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }

  public static getFileDataCompressed(file: File | Blob, options: Compressor.Options) {
    return new Promise<string>((resolve, reject) => {
      options.success = (result) => {
        FileUtils.getFileData(result).then(fileData => {
          resolve(fileData);
        });
      };

      options.error = (err) => {
        reject(err);
      };

      const compressor = new Compressor(file, options);
    });
  }
}
