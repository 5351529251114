<nav class="navbar navbar-top fixed-top max-width pr-0 safe-area-top" [ngClass]="{'bg-black': editMode}">
  <ng-container *ngIf="!editMode">
    <div class="navbar-top-left navbar-width-30px"></div>

    <a [ngClass]="{'cursor-pointer': restaurantPath}" class="title-standard text-white">{{ username }}</a>

    <div class="navbar-top-right navbar-width-30px"></div>
  </ng-container>

  <ng-container *ngIf="editMode">
    <a appHrefNone class="text-white" (click)="cancelEdited()">Cancel</a>
    <a appHrefNone class="text-white" (click)="rightButtonClicked()">Save</a>
  </ng-container>
</nav>
