import { NgModule } from '@angular/core';
import { AbbreviatePipe } from '../pipes/abbreviate.pipe';
import { AndToAmpersandPipe } from '../pipes/and-to-ampersand.pipe';
import { CityPipe } from '../pipes/city.pipe';
import { CommaToDotPipe } from '../pipes/comma-to-dot.pipe';
import { FoodsEmptyPipe } from '../pipes/foods-empty.pipe';
import { GoogleImagePipe } from '../pipes/google-image.pipe';
import { ImgixPipe } from '../pipes/imgix.pipe';
import { IsRestaurantOwnedPipe } from '../pipes/is-restaurant-owned.pipe';
import { MeridiemPipe } from '../pipes/meridiem.pipe';
import { PrecisionPipe } from '../pipes/precision.pipe';
import { ReplacePipe } from '../pipes/replace.pipe';
import { SanitizeHtmlPipe } from '../pipes/sanitize-html.pipe';
import { ShortNumberFormatPipe } from '../pipes/short-number-format.pipe';

@NgModule({
  declarations: [
    AbbreviatePipe,
    AndToAmpersandPipe,
    CityPipe,
    GoogleImagePipe,
    CommaToDotPipe,
    FoodsEmptyPipe,
    ImgixPipe,
    IsRestaurantOwnedPipe,
    MeridiemPipe,
    PrecisionPipe,
    ReplacePipe,
    SanitizeHtmlPipe,
    ShortNumberFormatPipe
  ],
  exports: [
    AbbreviatePipe,
    AndToAmpersandPipe,
    CityPipe,
    CommaToDotPipe,
    FoodsEmptyPipe,
    ImgixPipe,
    IsRestaurantOwnedPipe,
    MeridiemPipe,
    PrecisionPipe,
    ReplacePipe,
    SanitizeHtmlPipe,
    ShortNumberFormatPipe,
    GoogleImagePipe
  ]
})
export class PipeModule {
}
