import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Entity } from '../models/entity';

@Injectable({providedIn: 'root'})
export class EntityService {
  private readonly url: string;

  constructor(private http: HttpClient) {
    this.url = `${environment.apiEndpoint}/api/entity`;
  }

  public checkType(entityName: string) {
    return this.http.get<Entity>(`${this.url}/check-type/${encodeURIComponent(entityName)}/`)
      .pipe(map(value => plainToClass(Entity, value)));
  }
}
