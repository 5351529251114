import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-membership-desktop',
  templateUrl: './membership-desktop.component.html',
  styleUrls: ['./membership-desktop.component.scss']
})
export class MembershipDesktopComponent implements OnInit {
  membershipBackground: string;

  constructor() {
    this.membershipBackground = environment.membershipDesktopBackground;
  }

  ngOnInit(): void {
  }
}
