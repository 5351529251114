import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { Post } from '../../../domains/post';
import { DeviceType } from '../../../models/enums/device-type';

@Component({
  selector: 'app-feed',
  templateUrl: 'feed.component.html',
  styleUrls: []
})
export class FeedComponent implements OnInit {
  @Input() posts: Array<Post>;

  @Output() postClick = new EventEmitter<Post>();
  @Output() scrolled = new EventEmitter<boolean>();

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  columns = [];
  delayed = false;

  ngOnInit(): void {
    this.columns = [0, 1];

    if (this.isDesktop) {
      this.columns.push(2);
    }

    setTimeout(() => {
      this.delayed = true;
    }, 50);
  }

  goToPost(post: Post) {
    this.postClick.emit(post);
  }

  onScroll() {
    this.scrolled.emit(true);
  }
}
