import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Auth } from '../../../../decorators/auth.decorator';
import { IsDevice } from '../../../../decorators/is-device.decorator';
import { ModalClose } from '../../../../decorators/modal-close.decorator';
import { LocationEmbed } from '../../../../domains/location-embed';
import { DeviceType } from '../../../../models/enums/device-type';
import { ModalType } from '../../../../models/enums/modal-type';
import { TourType } from '../../../../models/enums/tour-type';
import { Modal } from '../../../../models/modal';
import { SearchParams } from '../../../../models/search-params';
import { UnsplashImage } from '../../../../models/unsplash-image';
import { EmbedService } from '../../../../services/embed.service';
import { ModalService } from '../../../../services/modal.service';
import { UnsplashService } from '../../../../services/unsplash.service';

@Component({
  selector: 'app-search-video-modal',
  templateUrl: './search-video-modal.component.html',
  styleUrls: [
    '../search-modal/search-modal.component.scss',
    '../../../navbar/navbar.shared.scss'
  ]
})
export class SearchVideoModalComponent implements OnInit {
  @Input() searchParams: SearchParams;

  @ViewChild('videoModalRef') videoModalRef: ElementRef;
  @ViewChild('videoList') videoListRef: ElementRef;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  backgroundImage: UnsplashImage;
  tourType: TourType = TourType.DINING;
  videoModal: Modal;
  TourType = TourType;
  locationEmbeds: Array<LocationEmbed>;
  hasEmbeds = false;

  constructor(
    private unsplashService: UnsplashService,
    private modalService: ModalService,
    private embedService: EmbedService
  ) {
  }

  ngOnInit(): void {
    this.unsplashService.randomPhoto(true).subscribe(value => {
      this.backgroundImage = value;
    });

    this.embedService.getAllLocationEmbeds(this.searchParams.locationId, this.searchParams.locationType)
      .subscribe((embeds) => {
        this.locationEmbeds = embeds;

        this.checkEmbedExistence();
      });
  }

  @Auth()
  openVideoModal() {
    this.videoModal = this.modalService.open(this.videoModalRef, ModalType.TOP_ROUNDED);
  }

  @ModalClose()
  onVideoAdded(embed: LocationEmbed) {
    this.locationEmbeds.unshift(embed);

    this.videoListRef?.nativeElement.scrollIntoView({ block: 'start', behavior: 'smooth' });

    this.checkEmbedExistence();
  }

  onSwitcherChange() {
    this.tourType = this.tourType === TourType.WALKING  ? TourType.DINING : TourType.WALKING;

    this.checkEmbedExistence();
  }

  onDeleteEmbed(embed: LocationEmbed) {
    this.locationEmbeds = this.locationEmbeds.filter(value => value.id !== embed.id);

    this.checkEmbedExistence();
  }

  private checkEmbedExistence() {
    this.hasEmbeds = this.locationEmbeds.some(embed => embed.tourType === this.tourType);
  }
}
