import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Auth } from '../../../decorators/auth.decorator';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { IsLoggedIn } from '../../../decorators/is-logged-in.decorator';
import { RouteParamsValue } from '../../../decorators/route-params-value.decorator';
import { Food } from '../../../domains/food';
import { Instruction } from '../../../domains/instruction';
import { Post } from '../../../domains/post';
import { Restaurant } from '../../../domains/restaurant';
import { Tag } from '../../../domains/tag';
import { User } from '../../../domains/user';
import { DeviceType } from '../../../models/enums/device-type';
import { ModalType } from '../../../models/enums/modal-type';
import { RouteParamsType } from '../../../models/enums/route-params-type';
import { Modal } from '../../../models/modal';
import { Picker } from '../../../models/picker';
import { RestaurantRouteParams } from '../../../models/route-params/restaurant-route-params';
import { UnsplashImage } from '../../../models/unsplash-image';
import { InstructionService } from '../../../services/instruction.service';
import { ModalService } from '../../../services/modal.service';
import { NavigatorService } from '../../../services/navigator.service';
import { NotificationService } from '../../../services/notification.service';
import { ShareService } from '../../../services/share.service';
import { TagService } from '../../../services/tag.service';
import { UnsplashService } from '../../../services/unsplash.service';

@Component({
  selector: 'app-classic-food-controls',
  templateUrl: './classic-food-controls.component.html',
  styleUrls: ['./classic-food-controls.component.scss']
})
export class ClassicFoodControlsComponent implements OnInit, OnDestroy {
  @Input() restaurant: Restaurant;
  @Input() food: Food;
  @Input() isLast: boolean;
  @Input() house = false;
  @Input() post: Post;
  @Input() postVoted = false;

  @Output() addClick = new EventEmitter<Food>();
  @Output() openPostModal = new EventEmitter<boolean>();

  @ViewChild('instructionsModalRef') instructionsModalRef: ElementRef;
  @ViewChild('allergensModalRef') allergensModalRef: ElementRef;
  @ViewChild('restaurantModalRef') restaurantModalRef: ElementRef;
  @ViewChild('sectionModalRef') sectionModalRef: ElementRef;

  @IsLoggedIn() isLoggedIn: boolean;
  @CurrentUser() currentUser: User;

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;
  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  @RouteParamsValue(RouteParamsType.RESTAURANT) restaurantRouteParams: RestaurantRouteParams;

  instruction: Instruction;
  allergens: Tag[] = [];
  allergensModal: Modal;
  clickOnAllergen = false;
  titleHover = false;
  backgroundImage: UnsplashImage;
  restaurantModal: Modal;
  favoriteClickSubjectSubscription: Subscription;
  plusIconHover = false;
  foodAllergenPicker = new Picker();

  constructor(
    private instructionService: InstructionService,
    private tagService: TagService,
    private logger: NGXLogger,
    private modalService: ModalService,
    private notificationService: NotificationService,
    private navigatorService: NavigatorService,
    private shareService: ShareService,
    private unsplashService: UnsplashService
  ) {
  }

  ngOnInit(): void {
    this.instruction = new Instruction(this.food.id);

    if (this.isLoggedIn && !this.food.gallery) {
      this.instructionService
        .getByFoodId(this.food.id)
        .subscribe({
          next: value => {
            if (value) {
              this.instruction = plainToClass(Instruction, value);
            }
          }, error: error => {
            this.logger.error('On getting instructions by food id', error);
          }
        });

      this.tagService
        .getWarningTags()
        .then((tags: Tag[]) => {
          tags.forEach((tag: Tag) => {
            const foodTag = this.food.tags.find(tagI => tagI.value === tag.value);
            tag.checked = foodTag !== undefined;

            if (tag.value !== 'allergens' && tag.value !== 'adv') {
              this.allergens.push(plainToClass(Tag, tag));
            }
          });
        }, error => {
          this.logger.error('On getting tags', error);
        });
    }

    this.unsplashService.randomPhoto(true).subscribe(image => {
      this.backgroundImage = image;
    });

    this.foodAllergenPicker.addDefaultOption('disclaimer', 'Disclaimers');
    this.foodAllergenPicker.addDefaultOption('allergens', 'Allergens');
  }

  ngOnDestroy(): void {
    this.favoriteClickSubjectSubscription?.unsubscribe();
  }

  optionSelected(value: string) {
    switch (value) {
      case 'allergens':

        if (this.food.hasAllergens) {
          const allergenClasses = {
            peanuts: 'badge-danger',
            'tree-nuts': 'badge-success',
            milk: 'badge-white',
            eggs: 'badge-warning',
            fish: 'badge-primary',
            shellfish: 'badge-info',
            wheat: 'badge-orange',
            soy: 'badge-secondary'
          };

          let html = '';

          for (const allergen of this.allergens) {
            let classes = allergen.checked ? (allergenClasses[allergen.value] || '') : 'badge-outline-white';

            if (allergen.id === 204) {
              classes += ' ml-10px';
            }

            const colorStyle = allergen.value === 'eggs' ? 'color: black' : '';

            html += `<span class="mb-1 badge ${classes}" style="${colorStyle}">${allergen.display}</span>&nbsp`;
          }

          this.notificationService.onAllergensDesktopClicked(html);
        } else {
          this.notificationService.onNoAllergensDesktopClicked();
        }
        break;
      case 'disclaimer':
        this.notificationService.onAllergensClicked();
        break;
    }
  }

  addClickEmit(food: Food) {
    if (food.hasSelections) {
      food.sectionsOpen = true;
    }

    this.addClick.emit(food);
  }

  addMeal(food: Food) {
    if (this.isDesktop && food.hasSections) {
      this.modalService.open(this.sectionModalRef, ModalType.CENTER_SQUARE);
    } else {
      this.addClickEmit(food);
    }
  }

  @Auth()
  goToFoodGallery(food: Food) {
    if (this.isDesktop) {
      if (this.restaurant.isOwned) {
        this.notificationService.settingsAndCameraClicked();
      } else {
        this.openPostModal.emit(true);
      }
    } else {
      this.navigatorService.goToFoodGallery(this.restaurantRouteParams, food, null, 100);
    }
  }

  @Auth()
  onInstructionClick() {
    if (!environment.paidFeaturesEnabled) {
      this.notificationService.temporaryToast();
    } else {
      this.modalService.open(this.instructionsModalRef, ModalType.CENTER_NARROWED);
    }
  }

  onAllergenModalClick() {
    if (!this.food.gallery) {
      this.allergensModal = this.modalService.open(this.allergensModalRef, ModalType.PREVIEW_SWIPER);
    }
  }

  onChangeInstruction(instruction: Instruction) {
    this.instruction = instruction;
  }

  shareFood(social: string = null) {
    this.shareService.shareFood(this.restaurant, this.food, social);
  }

  copyLink() {
    navigator.clipboard.writeText(`${location.href}`).then();
    this.notificationService.showCopied();
  }

  openRestaurantModal() {
    this.restaurantModal = this.modalService.open(this.restaurantModalRef, ModalType.RESTAURANT_SQUARE);
  }
}
