import { Token } from './token';

export class ResetPassword extends Token {
  password: string;

  constructor(id: number, token: string, password: string) {
    super(id, token);
    this.password = password;
  }
}
