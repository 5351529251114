import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Food } from '../../../../../domains/food';
import { Restaurant } from '../../../../../domains/restaurant';
import { ViewType } from '../../../../../models/enums/view-type';
import { Image } from '../../../../../models/image';

@Component({
  selector: 'app-classic-food',
  templateUrl: './classic-food.component.html',
  styleUrls: ['./classic-food.component.scss']
})
export class ClassicFoodComponent implements OnInit {
  @Input() restaurant: Restaurant;
  @Input() food: Food;
  @Input() srcView: ViewType;
  @Input() isLast: boolean;
  @Input() house = false;

  @Output() view = new EventEmitter<boolean>();
  @Output() basketAdd = new EventEmitter<Food>();

  viewType = ViewType;

  ngOnInit() {
  }

  toView() {
    this.view.emit(true);
  }

  addMeal(food: Food) {
    if (!food.hasSections || food.sectionsOpen) {
      this.basketAdd.emit(food);
    } else {
      food.sectionsOpen = true;
    }
  }
}
