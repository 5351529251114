import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AppService } from '../app.service';
import { CityWithChild } from '../domains/city-with-child';
import { CountyWithChild } from '../domains/county-with-child';
import { District } from '../domains/district';
import { Food } from '../domains/food';
import { Post } from '../domains/post';
import { RegionWithChild } from '../domains/region-with-child';
import { Restaurant } from '../domains/restaurant';
import { StateWithChild } from '../domains/state-with-child';
import { User } from '../domains/user';
import { ImgixService } from './imgix.service';
import { RestaurantService } from './restaurant.service';
import { RouterService } from './router.service';

@Injectable({providedIn: 'root'})
export class SeoService {
  constructor(
    private appService: AppService,
    private restaurantService: RestaurantService,
    private imgIxService: ImgixService,
    private routerService: RouterService
  ) {
  }

  private static getUserTitle(user: User) {
    if (user) {
      return `${user.firstName} ${user.lastName || ''} (${user.username})`;
    }

    return 'MMMM';
  }

  setRestaurantMetaTags(restaurant: Restaurant) {
    const city = restaurant.localityDisplay;
    const state = restaurant.state.key;
    let name = restaurant.name;
    const address = restaurant.address;

    if (restaurant.secondName) {
      name += ` • ${restaurant.secondName}`;
    }

    let pageTitle = name;

    if (restaurant.instagram) {
      let instagram = restaurant.instagram;

      if (instagram[0] !== '@') {
        instagram = '@' + instagram;
      }

      pageTitle += ` • ${instagram} Instagram`;
    }

    this.appService.pageTitle = `${pageTitle} • Video Reviews • Photos`;
    this.appService.url = `https://${environment.domain}${this.routerService.restaurantBasePath}`;
    this.appService.typeTag = 'website';
    this.appService.image = '';
    this.appService.url = location.href;
    this.appService.description = `Menu favorites & event calendar for ${name} ` +
      `${restaurant.yelpCategories} ${address}, ${city}, ${state} ${restaurant.zipCode} View menu, ` +
      `hours, phone number, address, map, location`;

    this.appService.keywords = `${name} in ${city} ${state}, ${name} ${address} ${city} ${state}, ${name} ${city}` +
      ` menu, ${name}, ${city} hours, ${name} ${city} delivery, ${name} ${city} takeout, ${name} ${city}` +
      ` catering`;
  }

  setFoodMetaTags(restaurant: Restaurant, food: Food) {
    this.appService.pageTitle = `${food.title} • ${restaurant.nameView}`;
    this.appService.description = food.description || '';
    this.appService.url = `https://${environment.domain}/${restaurant.hostname}${food.profileUrl}`;
    this.appService.image = environment.defaultImage;
    this.appService.typeTag = 'website';

    if (food.images[0]?.path) {
      this.appService.image = this.imgIxService.getUrl(food.images[0].path);
    }
  }

  setDefaultMetaTags() {
    this.appService.pageTitle = 'Restaurant menus • Restaurant Instagram • Restaurant locations • Food ' +
      'recommendations and places to eat near me';
    this.appService.description = 'Latest video recommendations from TikTok • Youtube • takeout, delivery,' +
      ' open now, sushi, pizza, Mexican food, bars, best of Michelin • Zagat • Eater • Thrillest • Bon Appetit' +
      ' • Food Network • Timeout • Popsugar • USA Today';
    this.appService.url = location.href;
    this.appService.image = this.imgIxService.getUrl('mmmm-main-3.png');
    this.appService.typeTag = 'website';
    this.appService.keywords = 'restaurant menus, restaurant instagram, restaurant locations, food' +
      ' recommendations, food places, food near me, restaurants near me';
  }

  setExploreMetaTags(
    state: StateWithChild,
    county: CountyWithChild,
    region: RegionWithChild,
    city: CityWithChild,
    district: District
  ) {
    if (!city && !district) {
      return;
    }

    const locality = district ? district.display : city.display;

    let title = `A directory of every restaurant’s Instagram in ${locality} ${state.display} • ${county.display}`;

    if (region) {
      title += ` • ${region.display}`;
    }

    this.appService.pageTitle =
      `${title} | Highlights of your neighborhood’s menu by YouTube | IG | TikTok creators!`;

    this.appService.description = `Latest place to eat recommendations from TikTok • Youtube • Instagram Reels for ${locality} ${state.key}
Best places to eat in San Carlos CA for breakfast • lunch • dinner • brunch • dessert • happy hour Recommendations | reviews from Michelin • Zagat • Eater • Thrillest • Bon Appetit • Food Network • USA Today • New York Times • Los Angeles Times • Popsugar
`;
    this.appService.keywords = 'restaurant reviews, restaurant menus, food recommendations, places to eat near ' +
      'me, food near me, restaurants near me';

    this.appService.image = this.imgIxService.getUrl('mmmm-main-3.png');
  }

  setUserMetaTags(user: User) {
    this.appService.pageTitle = SeoService.getUserTitle(user);
    this.appService.typeTag = 'profile';
    this.appService.description = user.bio || '';
    this.appService.url = location.href;

    if (user?.profileImage) {
      this.appService.image = this.imgIxService.getUrl(user.profileImage);
    }
  }

  setPostMetaTags(post: Post) {
    let title = SeoService.getUserTitle(post.user);

    if (post.food) {
      title += ` posted on ${post.food.title}`;
    }

    this.appService.pageTitle = title;
    this.appService.description = post.caption || '';
    this.appService.url = location.href;
    this.appService.typeTag = 'article';
    this.appService.image = '';

    if (post.images[0]?.path) {
      this.appService.image = this.imgIxService.getUrl(post.images[0].path);
    }
  }
}
