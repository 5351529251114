<ng-container *ngIf="order && basketService.needShow">
  <div
    [ngClass]="{'animate__animated bounceReverse': needAnimate}"
    [ngStyle]="{background: order.accepted || order.declined ? 'rgba(196, 38, 46, 1)' : 'rgba(0, 0, 0, 0.7)'}"
    class="receipt max-width"
    (click)="openOrderModal()"
  >
    <div class="receipt-box">
      <div class="row">
        <div class="col-12 text-center">
          <span class="mr-0 d-block">
            <ng-container *ngIf="!order.accepted && !order.declined">
                 {{ 'Awaiting confirmation' | uppercase }}
            </ng-container>
            <ng-container *ngIf="order.declined">
                 {{ 'Declined' | uppercase }}
            </ng-container>
            <ng-container *ngIf="order.accepted">
                {{ (order.readyTime | date:'h:mm a' | lowercase) }} {{ 'Confirmed' | uppercase }}
            </ng-container>
          </span>
        </div>
      </div>
    </div>
    <ng-template #orderModalRef>
      <app-receipt-modal [order]="order"></app-receipt-modal>
    </ng-template>
  </div>
</ng-container>
