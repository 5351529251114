import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../../../environments/environment';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { Restaurant } from '../../../domains/restaurant';
import { RestaurantSimple } from '../../../domains/restaurant-simple';
import { TypeCategorySimple } from '../../../domains/type-category-simple';
import { User } from '../../../domains/user';
import { DeviceType } from '../../../models/enums/device-type';
import { ModalType } from '../../../models/enums/modal-type';
import { ViewType } from '../../../models/enums/view-type';
import { Image as ImageModel } from '../../../models/image';
import { ModalService } from '../../../services/modal.service';
import { NavigatorService } from '../../../services/navigator.service';
import { RestaurantDetailsService } from '../../../services/restaurant-details.service';
import { RestaurantService } from '../../../services/restaurant.service';
import { TypeCategoryService } from '../../../services/type-category.service';

@Component({
  selector: 'app-admin-restaurant',
  templateUrl: './restaurant-edit.component.html',
  styleUrls: ['./restaurant-edit.component.scss']
})
export class RestaurantEditComponent implements OnInit, OnDestroy {
  @CurrentUser() currentUser: User;

  @ViewChild('restaurantEditModalRef') restaurantEditModalRef: ElementRef;

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  restaurant: Restaurant;
  dataLoaded = false;
  isImagePresent: boolean;
  isMobileImagePresent: boolean;
  locations: RestaurantSimple[] = [];
  locationId: number;
  activeLocationId: number;
  typeCategories: TypeCategorySimple[] = [];
  typeCategorySelected: TypeCategorySimple;
  defaultAvatar: string;
  darkLogoHover = false;
  coloredLogoHover = false;
  whiteLogoHovered = false;
  desktopLogoHover = false;
  mobileLogoHover = false;
  elementWidth = 621.328;
  elementHeight = 298.664;

  protected readonly viewType = ViewType;

  constructor(
    private restaurantService: RestaurantService,
    private logger: NGXLogger,
    private restaurantDetailsService: RestaurantDetailsService,
    private navigatorService: NavigatorService,
    private activatedRoute: ActivatedRoute,
    private typeCategoryService: TypeCategoryService,
    private modalService: ModalService,
  ) {
    this.defaultAvatar = environment.defaultAvatar;
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {
      this.restaurantService.getCurrentUserRestaurant().subscribe((restaurant: Restaurant) => {
        this.restaurant = restaurant;
        this.isImagePresent = restaurant.backgroundImages?.length > 0;
        this.isMobileImagePresent = restaurant.mobileBackgroundImages?.length > 0;

        this.dataLoaded = true;

        this.restaurantService.getLocations().subscribe(locations => {
          this.locations = [];

          locations.forEach(location => {
            if (location.id !== this.restaurant.id) {
              this.locations.push(location);
            }
          });
        });

        this.locationId = this.restaurant.locationIndex;
        this.activeLocationId = this.locationId;

        this.typeCategoryService
          .getAll()
          .subscribe({
            next: typeCategories => {
              this.typeCategories = typeCategories;

              this.typeCategorySelected = this.typeCategories
                .find(typeCategory => typeCategory.id === restaurant.typeCategory.id);
            }, error: error => {
              this.logger.error('On getting type categories', error);
            }
          });
      });
    });
  }

  ngOnDestroy(): void {
  }

  setAvatar(image: ImageModel) {
    this.restaurantDetailsService
      .uploadAvatar(image)
      .subscribe(avatar => {
        this.restaurant.details.avatar = avatar;
      });
  }

  removeAvatar() {
    this.restaurantDetailsService.deleteAvatar().subscribe(() => {
      this.restaurant.details.avatar = null;
    });
  }

  setLogo(image: ImageModel) {
    this.restaurantDetailsService
      .uploadLogo(image)
      .subscribe(logo => {
        this.restaurant.details.logo = logo;
      });
  }

  removeLogo() {
    this.restaurantDetailsService.deleteLogo().subscribe(() => {
      this.restaurant.details.logo = null;
    });
  }

  setLogoDark(image: ImageModel) {
    this.restaurantDetailsService
      .uploadLogoDark(image)
      .subscribe(logo => {
        this.restaurant.details.logoDark = logo;
      });
  }

  removeLogoDark() {
    this.restaurantDetailsService.deleteLogoDark().subscribe(() => {
      this.restaurant.details.logoDark = null;
    });
  }

  setBackgroundImage(event) {
    if (!this.isImagePresent) {
      this.restaurant.backgroundImages = [];
    }

    this.restaurant.backgroundImages[0] = new ImageModel();
    this.restaurant.backgroundImages[0].isMediaLoaded = false;
    this.restaurant.backgroundImages[0].path = event.path;
    this.isImagePresent = true;

    this.restaurantService.uploadBackgroundImage(event, DeviceType.DESKTOP)
      .subscribe(value => this.restaurant.backgroundImages[0] = value);
  }

  setMobileBackgroundImage(event) {
    if (!this.isMobileImagePresent) {
      this.restaurant.mobileBackgroundImages = [];
    }

    this.restaurant.mobileBackgroundImages[0] = new ImageModel();
    this.restaurant.mobileBackgroundImages[0].isMediaLoaded = false;
    this.restaurant.mobileBackgroundImages[0].path = event.path;
    this.isMobileImagePresent = true;

    this.restaurantService.uploadBackgroundImage(event, DeviceType.MOBILE)
      .subscribe(value => this.restaurant.mobileBackgroundImages[0] = value);
  }

  removeBackgroundImage() {
    this.restaurant.backgroundImages.shift();
    this.restaurantService.deleteBackgroundImage(DeviceType.DESKTOP).subscribe();
    this.isImagePresent = false;
  }

  removeBackgroundMobileImage() {
    this.restaurant.mobileBackgroundImages.shift();

    this.restaurantService.deleteBackgroundImage(DeviceType.MOBILE).subscribe();
    this.isMobileImagePresent = false;
  }

  onQRClick() {
    this.restaurantService.downloadQRPdf(this.restaurant);
  }

  goToAdmin(locationIndex: number) {
    this.navigatorService.goToAdminSettings(locationIndex);
  }

  openEditRestaurantModal() {
    this.modalService.open(this.restaurantEditModalRef, ModalType.BASE);
  }

  whiteLogoError() {
    this.restaurant.details.avatar = null;
  }

  darkLogoError() {
    this.restaurant.details.logoDark = null;
  }

  colorLogoError() {
    this.restaurant.details.logo = null;
  }
}

