import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { CurrentUser } from '../../decorators/current-user.decorator';
import { IsLoggedIn } from '../../decorators/is-logged-in.decorator';
import { Order } from '../../domains/order/order';
import { User } from '../../domains/user';
import { ModalType } from '../../models/enums/modal-type';
import { BasketService } from '../../services/basket.service';
import { ModalService } from '../../services/modal.service';
import { OrderService } from '../../services/order.service';
import { WebSocketService } from '../../services/web-socket.service';
import { OrderListener } from '../../websocket/listeners/order-listener';

@Component({
  selector: 'app-flash-receipt',
  templateUrl: './flash-receipt.component.html',
  styleUrls: ['./flash-receipt.component.scss']
})
export class FlashReceiptComponent implements OnInit {
  @ViewChild('orderModalRef') orderModalRef: ElementRef;

  @IsLoggedIn() isLoggedIn: boolean;
  @CurrentUser() currentUser: User;

  needAnimate = false;
  order: Order = null;

  constructor(
    private modalService: ModalService,
    private orderService: OrderService,
    public basketService: BasketService,
    private webSocketService: WebSocketService,
    private logger: NGXLogger
  ) {
  }

  ngOnInit(): void {
    if (this.isLoggedIn) {
      this.orderService
        .getUserLastOrder()
        .subscribe({
          next: order => {
            this.order = order;
            this.webSocketService
              .registerListener(new OrderListener(this.currentUser.id))
              .subject
              .subscribe(orderPush => {
                if (orderPush) {
                  this.order = orderPush;

                  if (orderPush.accepted) {
                    this.needAnimate = true;

                    setTimeout(() => {
                      this.needAnimate = false;
                    }, 2000);
                  }
                }
              });
          }, error: error => {
            this.logger.error('On getting last orders', error);
          }
        });
    }
  }

  openOrderModal() {
    const orderModal = this.modalService.open(this.orderModalRef, ModalType.ORDER);

    orderModal.onClose().then(() => {
      this.basketService.needShow = true;
    });

    this.basketService.needShow = false;
  }
}
