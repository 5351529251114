<nav class="navbar navbar-top fixed-top bg-white max-width pr-0 safe-area-top" style="transform: translate3d(0, 0, 0);">
  <div class="navbar-top-left navbar-width-70px">
    <a
      *ngIf="showBack"
      appHrefNone
      class="navbar-top-icon-back"
      (click)="goToUser()"
    >
      <span class="icon-v2 arrow-back scale-82"></span>
    </a>
  </div>

  <ng-container *ngIf="title">
    <a appHrefNone class="navbar-brand navbar-title mr-0">
      {{ title }}
    </a>
  </ng-container>

  <ng-container *ngIf="!title">
    <div class="navbar-brand navbar-title mr-0 font-size-1-05rem">
      <span>
        {{ userFirstName }} {{ userLastName }}
      </span>
    </div>
  </ng-container>

  <div class="navbar-top-right navbar-width-70px">
    <button
      *ngIf="edited"
      type="button"
      class="btn btn-primary md-btn-flat"
      (click)="save()"
    >
      Save
    </button>

    <a *ngIf="!edited && isOwner" appHrefNone class="navbar-icon right flex-content-vc-hc">
      <app-picker
        [picker]="userOptionsPicker"
        [classes]="'icon dots-vert scale-82'"
        (selectedValue)="onPickerSelected($event)"
        [title]="currentUser.username"
      ></app-picker>
    </a>
  </div>
</nav>

<ng-template #hoursEditModalRef let-c="close">
  <app-hours-edit-modal
    [restaurant]="userRestaurant"
  ></app-hours-edit-modal>
</ng-template>
