import { Transform, Type } from 'class-transformer';
import { SectionType } from '../models/enums/section-type';
import { SectionManagerInterface } from '../models/sections/section-manager-interface';
import { Transformer } from '../utils/transformer';
import { Extra } from './extra';

export class Section {
  id: number;
  title: string;
  min: number;
  type: SectionType;

  @Type(() => Extra)
  @Transform(value => Transformer.reorderExtras(value), {toClassOnly: true})
  extras: Array<Extra> = [];

  ordering = Number.MAX_SAFE_INTEGER;
  max = 1;

  categoryId: number;
  foodId: number;
  selected: Array<string> = [];
  checked = {};
  manager: SectionManagerInterface;

  get buttons(): boolean {
    return this.max > 1;
  }

  constructor(type: SectionType = null) {
    if (type) {
      this.type = type;
      this.min = 0;

      const extrasCount = type === SectionType.SIZE ? 4 : 3;

      for (let i = 1; i < extrasCount; i++) {
        this.extras.push(new Extra(i));
      }
    }
  }

  extraAdd() {
    const extra = new Extra();
    extra.ordering = Math.max.apply(Math, [1].concat(this.extras.map(extraI => extraI.ordering))) + 1;

    this.extras.push(extra);
  }

  extrasSortAndReorder() {
    const beforeArrange = this.extras.filter(sectionI => sectionI.id).map(extraI => extraI.ordering);

    this.extras = this.extras.sort((extra1, extra2) => {
      if (extra1.ordering === extra2.ordering) {
        return 0;
      } else {
        return (extra1.ordering > extra2.ordering) ? 1 : -1;
      }
    });

    this.extras.forEach((extraItered, index) => {
      extraItered.ordering = index + 1;
    });

    const afterArrange = this.extras.filter(sectionI => sectionI.id).map(extraI => extraI.ordering);

    return !beforeArrange.every((beforeElement, index) => beforeElement === afterArrange[index]);
  }

  extrasFill(max = 3) {
    for (let i = this.extras.length; i < max; i++) {
      this.extraAdd();
    }
  }

  get select(): number {
    let diff;

    if (this.min > 1) {
      diff = this.min - this.manager.extrasQuantity();
    } else if (this.min === 0 && this.max > 1) {
      diff = this.max - this.manager.extrasQuantity();
    } else {
      diff = 0;
    }

    return diff > 0 ? diff : 0;
  }

  isPriceVisible(extra: Extra): boolean {
    return extra.price > 0;
  }
}
