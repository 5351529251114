import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../../../../environments/environment';
import { CurrentUser } from '../../../../decorators/current-user.decorator';
import { IsDevice } from '../../../../decorators/is-device.decorator';
import { ModalClose } from '../../../../decorators/modal-close.decorator';
import { RestaurantEmbed } from '../../../../domains/restaurant-embed';
import { RestaurantSimple } from '../../../../domains/restaurant-simple';
import { RestaurantSuggestion } from '../../../../domains/restaurant-suggestion';
import { TypeCategorySimple } from '../../../../domains/type-category-simple';
import { User } from '../../../../domains/user';
import { DeviceType } from '../../../../models/enums/device-type';
import { Picker } from '../../../../models/picker';
import { InstagramStatusWrapper } from '../../../../models/wrappers/instagram-status.wrapper';
import { LoaderService } from '../../../../services/loader.service';
import { NotificationService } from '../../../../services/notification.service';
import { RestaurantService } from '../../../../services/restaurant.service';
import { TypeCategoryService } from '../../../../services/type-category.service';

@Component({
  selector: 'app-video-report-modal',
  templateUrl: './video-report-modal.component.html',
  styleUrls: ['./video-report-modal.component.scss', './../add-rest-video-report.component.scss']
})
export class VideoReportModalComponent implements OnInit {
  @Input() restaurant: RestaurantSimple;

  @Output() videoAdded = new EventEmitter<RestaurantEmbed>();
  @Output() ownerVerification = new EventEmitter<boolean>();

  @ViewChild('selectElement', {static: true}) selectElement: ElementRef;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  @CurrentUser() user: User;

  submitClicked = false;
  typeCategories: TypeCategorySimple[] = [];
  restaurantAddForm: FormGroup;
  picker: Picker;
  instagramStatus = new InstagramStatusWrapper();
  restaurantInstagram: string;
  locationChanged = false;
  hoursChanged = false;
  isChecked = false;
  loginBackground: string;
  restaurantSuggestion: RestaurantSuggestion;
  dataReady = false;
  restaurantTypeCategory: TypeCategorySimple;

  constructor(
    private typeCategoryService: TypeCategoryService,
    private logger: NGXLogger,
    private notificationService: NotificationService,
    private restaurantService: RestaurantService,
    private loaderService: LoaderService,
  ) {
    typeCategoryService
      .getAll()
      .subscribe({
        next: typeCategories => {
          this.typeCategories = typeCategories;
        }, error: error => {
          this.logger.error('On getting type categories', error);
        }
      });

    this.loginBackground = environment.loginBackgroundImage;
  }

  ngOnInit(): void {
    this.restaurantService.getSuggestion(this.restaurant)
      .subscribe(restaurantSuggestion => {
        this.restaurantSuggestion = restaurantSuggestion;

        this.restaurantAddForm = new FormGroup({
          name: new FormControl(
            this.restaurantSuggestion?.name || this.restaurant.name, [Validators.required]
          ),
          secondName: new FormControl(this.restaurantSuggestion?.secondName || this.restaurant.secondName),
          instagram: new FormControl(
            this.restaurantSuggestion?.instagram || this.restaurant.instagram, [Validators.pattern('^[a-zA-Z0-9._]+$')]
          ),
          address: new FormControl(this.restaurantSuggestion?.address || null, [Validators.required]),
          zipCode: new FormControl(this.restaurantSuggestion?.zipCode || null, [Validators.required]),
          incorrectInstagram: new FormControl(this.restaurantSuggestion?.incorrectInstagram || false),
          isPrivate: new FormControl(this.restaurantSuggestion?.nonPublic || false),
          closed: new FormControl(this.restaurantSuggestion?.closed || false),
          incorrectHours: new FormControl(this.restaurantSuggestion?.incorrectHours || false),
          movedLocation: new FormControl(this.restaurantSuggestion?.movedLocation || false),
          typeCategoryId: new FormControl(this.restaurantSuggestion?.typeCategory?.id || null),
          embedUrl: new FormControl(null)
        });

        this.dataReady = true;
      });

    this.typeCategoryService.getByRestaurantId(this.restaurant.id).subscribe(typeCategory => {
      this.restaurantTypeCategory = typeCategory;
    });

    this.setInstagramStatus();

    this.restaurantInstagram = this.restaurant.instagram;
  }

  get name() {
    return this.restaurantAddForm.get('name');
  }

  get secondName() {
    return this.restaurantAddForm.get('secondName');
  }

  get instagram() {
    return this.restaurantAddForm.get('instagram');
  }

  get category() {
    return this.restaurantAddForm.get('category');
  }

  get movedLocation() {
    return this.restaurantAddForm.get('movedLocation');
  }

  get closed() {
    return this.restaurantAddForm.get('closed');
  }

  get incorrectHours() {
    return this.restaurantAddForm.get('incorrectHours');
  }

  get embedUrl() {
    return this.restaurantAddForm.get('embedUrl');
  }

  get incorrectInstagram() {
    return this.restaurantAddForm.get('incorrectInstagram');
  }

  get zipCode() {
    return this.restaurantAddForm.get('zipCode');
  }

  get address() {
    return this.restaurantAddForm.get('address');
  }
  get typeCategoryId() {
    return this.restaurantAddForm.get('typeCategoryId');
  }

  get isPrivate() {
    return this.restaurantAddForm.get('isPrivate');
  }

  onSubmit() {
    if (!this.restaurantSuggestion) {
      this.restaurantSuggestion = new RestaurantSuggestion();
    }

    this.restaurantSuggestion.name = this.name.value;
    this.restaurantSuggestion.secondName = this.secondName.value;

    this.restaurantSuggestion.restaurantId = this.restaurant.id;

    this.restaurantSuggestion.closed = this.closed.value;
    this.restaurantSuggestion.movedLocation = this.movedLocation.value;
    this.restaurantSuggestion.incorrectHours = this.incorrectHours.value;
    this.restaurantSuggestion.instagram = this.instagram.value;

    this.restaurantSuggestion.address = this.address.value;
    this.restaurantSuggestion.embedUrl = this.embedUrl.value;
    this.restaurantSuggestion.zipCode = this.zipCode.value;
    this.restaurantSuggestion.incorrectInstagram = this.incorrectInstagram.value;

    const typeCategoryId = this.typeCategoryId.value || this.restaurantTypeCategory;
    this.typeCategories.find(typeCategory => typeCategoryId === typeCategory.id);

    this.restaurantSuggestion.typeCategory = this.typeCategories.find(
      typeCategory => typeCategoryId === typeCategory.id
    );

    this.restaurantSuggestion.nonPublic = this.isPrivate.value;

    this.restaurantSuggestion.userId = this.user.id;

    this.loaderService.showLoader();

    this.restaurantService.addSuggestion(this.restaurantSuggestion)
      .subscribe(() => {
        this.modalClose();
        this.notificationService.suggestionSubmitted();
      }, error => {
        this.logger.error('ERROR on adding restaurant suggestion: ', error);
      }).add(() => {
      this.loaderService.hideLoader();
    });
  }

  @ModalClose()
  modalClose() {
  }

  onLocationChanged(event) {
    if (event.currentTarget.checked) {
      this.locationChanged = true;
    }
  }

  onHoursChanged(event) {
    this.hoursChanged = event.currentTarget.checked;
  }

  onInstagramChanged(checked: boolean) {
    this.isChecked = checked;

    if (checked) {
      this.instagramStatus.setIncorrect();

      this.instagram.setValue('', {onlySelf: true});
    } else {
      this.setInstagramStatus();

      this.instagram.setValue(this.restaurantInstagram, {onlySelf: true});
    }
  }

  private setInstagramStatus() {
    if (this.restaurant.instagram) {
      this.instagramStatus.setIncorrect();
    } else {
      this.instagramStatus.setEmpty();
    }
  }

  onTypeCategoryChange(typeCategory: TypeCategorySimple) {
    this.restaurant.typeCategory = typeCategory;
  }

  onOwnerVerification() {
    this.closeModal();

    this.ownerVerification.emit(true);
  }

  @ModalClose()
  closeModal() {
  }
}
