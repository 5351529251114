import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../domains/user';
import { CookieKey } from '../models/enums/cookie-key';
import { Image } from '../models/image';
import { ImageList } from '../models/image-list';
import { DateTransform } from '../utils/date-transform';
import { AuthenticationService } from './authentication.service';
import { UserService } from './user.service';

@Injectable({providedIn: 'root'})
export class InstagramService {
  codeUrl: string;
  instagramUrl: string;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router
  ) {
    this.codeUrl = 'https://api.instagram.com/oauth/authorize' +
      `?app_id=${environment.instagramAppId}` +
      `&redirect_uri=https://${environment.domain}/instagram` +
      `&scope=user_profile,user_media` +
      `&response_type=code`;
    this.instagramUrl = `${environment.apiEndpoint}/api/ig`;
  }

  getCodeUrl(state: string = '', showImageModal = false) {
    this.cookieService.set(
      CookieKey.REDIRECT_URL,
      this.router.url,
      DateTransform.plus(0, 0, 5),
      '/',
      null,
      null,
      'Lax'
    );

    if (showImageModal) {
      this.cookieService.set(
        CookieKey.SHOW_IMAGE_SELECT_MODAL,
        'true',
        DateTransform.plus(0, 0, 10),
        '/',
        null,
        null,
        'Lax'
      );
    }

    return this.codeUrl + (state ? '&state=' + state : '');
  }

  getMedia(igAccessToken: string) {
    return this.http.get(
      `https://graph.instagram.com/me/media?fields=id,caption,media_url,media_type,timestamp,permalink&access_token=${igAccessToken}`
    );
  }

  connect(code: string) {
    return this.http.get<any>(`${this.instagramUrl}/connect?code=${code}`);
  }

  loadImages(igAccessToken): Observable<ImageList> {
    return this.getMedia(igAccessToken)
      .pipe(map((response) => {
        return this.responseImageList(response);
      }));
  }

  setEmail(email: string) {
    return this.http.post(`${this.instagramUrl}/set-email`, email);
  }

  addInstagramToUser(code: string, user: User) {
    return this.http.post(`${this.instagramUrl}/add-ig-user?code=${code}`, user)
      .pipe(map(userData => {
        this.authenticationService.saveUserDataToCookie(userData);

        return userData;
      }));
  }

  setUser(user: User, code: string) {
    return this.http
      .post(`${this.instagramUrl}/set-user?code=${code}`, user)
      .pipe(map(userData => {
        this.authenticationService.saveUserDataToCookie(userData);

        return userData;
      }));
  }

  login(user: User) {
    return this.http.post(`${this.instagramUrl}/login`, user)
      .pipe(map(userData => {
        this.authenticationService.saveUserDataToCookie(userData);

        return userData;
      }));
  }

  loadImagesNext(imagesNextUrl): Observable<ImageList> {
    if (imagesNextUrl) {
      return this.http
        .get(imagesNextUrl)
        .pipe(map(response => {
          return this.responseImageList(response);
        }));
    } else {
      return this.emptyImageList();
    }
  }

  disconnect() {
    const user = this.authenticationService.currentUser;

    return this.userService.removeIgId()
      .pipe(map(() => {
        user.igId = null;
        user.igUsername = null;
        user.igAccessToken = null;
        this.authenticationService.currentUser = user;

        return user;
      }));
  }

  private emptyImageList(): Observable<ImageList> {
    const images: Image[] = [];
    const imagesNextUrl: string = null;

    return new Observable((observer) => {
      observer.next(new ImageList(images, imagesNextUrl));
      observer.complete();
    });
  }

  private responseImageList(response): ImageList {
    const images: Image[] = [];
    let imagesNextUrl: string;

    if (response['data']) {
      response['data'].forEach(imageData => {
        if (imageData['media_type'] === 'IMAGE') {
          const image = new Image();

          image.igId = +imageData['id'];
          image.path = imageData['media_url'];

          images.push(image);
        }
      });
    }

    imagesNextUrl = null;

    if (response['paging']) {
      imagesNextUrl = response['paging']['next'] || null;
    }

    return new ImageList(images, imagesNextUrl);
  }
}
