import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LayoutEntityComponent } from '../components/layout/layout-main/layout-front/layout-entity/layout-entity.component';
import { LayoutRestaurantComponent } from '../components/layout/layout-main/layout-front/layout-entity/layout-restaurant/layout-restaurant.component';
import { LayoutUserComponent } from '../components/layout/layout-main/layout-front/layout-entity/layout-user/layout-user.component';
import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';
import { LocalStorage } from '../local-storage/local-storage';
import { Entity } from '../models/entity';
import { EntityType } from '../models/enums/entity-type';
import { LocalStorageKey } from '../models/enums/local-storage-key';
import { EntityService } from '../services/entity.service';
import { GuardService } from '../services/guard.service';
import { UrlUtils } from '../utils/url-utils';

@Injectable({providedIn: 'root'})
export class EntityChecker {
  private entitiesLocalStorage = new LocalStorage(Entity, LocalStorageKey.ENTITIES);

  constructor(
    private entityService: EntityService,
    private guardService: GuardService
  ) {
  }

  canActivateChild(
    layoutRestaurantRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    if (
      layoutRestaurantRoute.component !== LayoutRestaurantComponent &&
      layoutRestaurantRoute.component !== LayoutUserComponent
    ) {
      return true;
    }

    const layoutEntityRoute = layoutRestaurantRoute.parent;

    const segment = UrlUtils.getSplitPathname(state.url);

    if (segment.length >= 2) {
      const segmentFirst = segment[1];
      const entities = this.entitiesLocalStorage.getItems();

      const entityExist = entities.find(entityI => entityI.name === segmentFirst);

      if (entityExist === undefined) {
        return this.entityService.checkType(segmentFirst)
          .pipe(
            map(entityNew => {
              if (entityNew) {
                entities.push(entityNew);

                this.entitiesLocalStorage.setItems(entities);
              }

              return this.checkEntity(layoutEntityRoute, entityNew.type, state.url);
            }),
            catchError(() => {
              return of(this.checkEntity(layoutEntityRoute, null, state.url));
            })
          );
      } else {
        return this.checkEntity(layoutEntityRoute, entityExist.type, state.url);
      }
    } else {
      return false;
    }
  }

  private checkEntity(layoutEntityRoute: ActivatedRouteSnapshot, entityType: EntityType, url: string): boolean {
    const childRoute = layoutEntityRoute.firstChild;

    switch (entityType) {
      case EntityType.RESTAURANT:
        if (!this.isLayoutEntityChildrenReversed(childRoute)) {
          return true;
        }

        layoutEntityRoute.routeConfig.children.reverse();

        this.guardService.redirectWithLimit(url);

        return false;
      case EntityType.USER:
        if (this.isLayoutEntityChildrenReversed(childRoute)) {
          return true;
        }

        layoutEntityRoute.routeConfig.children.reverse();

        this.guardService.redirectWithLimit(url);

        return false;
      default:
        const layoutFrontRoute = layoutEntityRoute.parent;

        if (this.layoutFrontChildrenReversed(layoutFrontRoute)) {
          return true;
        }

        layoutFrontRoute.routeConfig.children.reverse();

        this.guardService.redirectWithLimit(url, () => {
          layoutFrontRoute.routeConfig.children.reverse();
        });

        return false;
    }
  }

  isLayoutEntityChildrenReversed(childRoute: ActivatedRouteSnapshot): boolean {
    return childRoute.component === LayoutUserComponent;
  }

  layoutFrontChildrenReversed(layoutFrontRoute: ActivatedRouteSnapshot): boolean {
    return layoutFrontRoute.component === LayoutEntityComponent &&
      layoutFrontRoute.firstChild.component === PageNotFoundComponent;
  }
}

export const EntityCheckGuard: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | Observable<boolean> => {
  return inject(EntityChecker).canActivateChild(route, state);
};
