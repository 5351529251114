import { Type } from 'class-transformer';
import { GlobalBaseLocation } from '../models/global-base-location';
import { CityWithChild } from './city-with-child';
import { District } from './district';

export class RegionWithChild extends GlobalBaseLocation {
  @Type(() => CityWithChild)
  cities: CityWithChild[] = [];

  @Type(() => District)
  districts: District[];

  slug: string;

  citiesLoaded: boolean;
}
