import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { LayoutRestaurantLocationComponent } from '../components/layout/layout-main/layout-front/layout-entity/layout-restaurant/layout-restaurant-location/layout-restaurant-location.component';
import { LayoutRestaurantComponent } from '../components/layout/layout-main/layout-front/layout-entity/layout-restaurant/layout-restaurant.component';
import { GuardService } from '../services/guard.service';

@Injectable({providedIn: 'root'})
export class LocationChecker {
  constructor(
    private guardService: GuardService
  ) {
  }

  canActivate(
    layoutRestaurantRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (layoutRestaurantRoute.component !== LayoutRestaurantComponent) {
      return true;
    }

    const childRoute = layoutRestaurantRoute.firstChild;
    const segment = state.url.split('/');
    const hasLocationId = segment.length >= 3 && !isNaN(+segment[2]);

    if (!hasLocationId && !this.reversed(childRoute) || hasLocationId && this.reversed(childRoute)) {
      return true;
    } else {
      layoutRestaurantRoute.routeConfig.children.reverse();

      this.guardService.redirectWithLimit(state.url);

      return false;
    }
  }

  reversed(childRoute: ActivatedRouteSnapshot) {
    return childRoute.component === LayoutRestaurantLocationComponent;
  }
}

export const LocationCheckGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  return inject(LocationChecker).canActivate(route, state);
};
