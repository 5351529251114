import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SidenavType } from '../models/enums/sidenav-type';

@Injectable({providedIn: 'root'})
export class LayoutSidenavService {
  sidenavIsMouseOverSubjects: { [sidenavType: string]: BehaviorSubject<boolean> } = {};

  constructor() {
    for (const sidenavTypeKey in SidenavType) {
      this.sidenavIsMouseOverSubjects[sidenavTypeKey] = new BehaviorSubject<boolean>(false);
    }
  }
}
