import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Order } from '../domains/order/order';

@Injectable({providedIn: 'root'})
export class OrderService {
  private readonly orderUrl: string;

  constructor(private http: HttpClient) {
    this.orderUrl = `${environment.apiEndpoint}/api/orders`;
  }

  public getUserLastOrder() {
    return this.http
      .get(this.orderUrl)
      .pipe(map(order => plainToClass(Order, order)));
  }

  public getOrdersForAdmin() {
    return this.http
      .get<Order[]>(`${this.orderUrl}/admin`)
      .pipe(map(ordersObjects => {
        return plainToClass(Order, ordersObjects);
      }));
  }

  public saveOrder(order: Order) {
    return this.http
      .post<Order>(`${this.orderUrl}`, order)
      .pipe(map(ordersObject => {
        return plainToClass(Order, ordersObject);
      }));
  }

  public process(order: Order, accepted: boolean, minutes: string = null) {
    const params = {'order-id': `${order.id}`, accepted: `${accepted}`};

    if (accepted) {
      params['minutes'] = minutes;
    }

    return this.http
      .put<Order>(`${this.orderUrl}/process`, null, {params})
      .pipe(map(orderObject => {
        return plainToClass(Order, orderObject);
      }));
  }
}
