import { Type } from 'class-transformer';
import { Period } from '../../domains/period';

export class RestaurantHoursGroup {
  title: string;

  index: string;

  @Type(() => Period)
  periods: Period[] = [];

  ordering: number;

  constructor() {
  }
}
