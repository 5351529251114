import { environment } from '../../environments/environment';

export class BaseImage {
  width: number;
  height: number;
  blurHash: string;
  url: string;
  path: string;

  get externalUrl() {
    if (this.url) {
      return this.url;
    }

    return `https://${environment.imgixDomain}/${this.path}`;
  }
}
