import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IgUsernameSuggestion } from '../domains/ig-username-suggestion';
import { ReviewStatus } from '../models/enums/review-status';

@Injectable({providedIn: 'root'})
export class IgUsernameSuggestionService {
  private readonly igSuggestionUrl: string;

  constructor(private http: HttpClient) {
    this.igSuggestionUrl = `${environment.apiEndpoint}/api/ig-username-suggestion`;
  }

  public getCurrentUserSuggestion(restaurantId: number): Observable<IgUsernameSuggestion | null> {
    return this.http.get<IgUsernameSuggestion | null>(`${this.igSuggestionUrl}/${restaurantId}/current-user`)
      .pipe(map(value => {
        if (value) {
          return plainToClass(IgUsernameSuggestion, value);
        }

        return null;
      }));
  }

  public add(igUsernameSuggestion: IgUsernameSuggestion): Observable<IgUsernameSuggestion> {
    return this.http.post<IgUsernameSuggestion>(this.igSuggestionUrl, igUsernameSuggestion)
      .pipe(map(value => plainToClass(IgUsernameSuggestion, value)));
  }

  public delete(igUsernameSuggestion: IgUsernameSuggestion) {
    return this.http.delete(`${this.igSuggestionUrl}/${igUsernameSuggestion.id}`);
  }

  public createNewSuggestionObject(restaurantId: number, username: string): IgUsernameSuggestion {
    const igUsernameSuggestion = new IgUsernameSuggestion();

    igUsernameSuggestion.restaurantId = restaurantId;
    igUsernameSuggestion.suggestion = username;
    igUsernameSuggestion.status = ReviewStatus.PENDING;

    return igUsernameSuggestion;
  }
}
