import { Type } from 'class-transformer';
import { TypeCategorySection } from './type-category-section';

export class TypeCategorySectionGroup {
  section: number;

  @Type(() => TypeCategorySection)
  sections: TypeCategorySection[] = [];

  get hasRestaurants() {
    return this.sections.some(section => section.hasRestaurants);
  }

  get hasChains() {
    return this.sections.some(section => section.hasChain);
  }
}
