import { BehaviorSubject } from 'rxjs';
import { HashLocationId } from './hash-location-id';

export class SearchFiltersSync {
  subject = new BehaviorSubject<HashLocationId>(null);
  lock = false;

  constructor() {
  }

  setId(hash: string, stateId: number) {
    if (stateId && !this.lock) {
      this.lock = true;

      setTimeout(() => {
        this.lock = false;
      }, 100);

      const hashLocationId = new HashLocationId();

      hashLocationId.hash = hash;
      hashLocationId.id = stateId;

      this.subject.next(hashLocationId);
    }
  }
}
