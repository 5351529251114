import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appModalCloseOnClickPrevent]'
})
export class ModalCloseOnClickPreventDirective {
  constructor(element: ElementRef) {
    element.nativeElement.dataset.modalClosePrevent = 'true';
  }
}
