import { Injectable } from '@angular/core';

import { plainToClass } from 'class-transformer';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { Place } from '../models/place';
import AutocompleteService = google.maps.places.AutocompleteService;
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import PlacesServiceStatus = google.maps.places.PlacesServiceStatus;

@Injectable({providedIn: 'root'})
export class GoogleMapsService {
  private service: AutocompleteService;

  constructor(private logger: NGXLogger) {
    this.service = new AutocompleteService();
  }

  getPredictions(word: string, type: string): Observable<Place[]> {
    return new Observable(subscriber => {
      if (!word) {
        subscriber.next([]);
        subscriber.complete();
      }

      let types: string[];

      switch (type) {
        case 'name':
          types = [
            'geocode'
          ];

          break;
        case 'zip':
          types = [
            'postal_code'
          ];

          break;
        default:
          subscriber.next([]);
          subscriber.complete();
      }

      const callback = (results: AutocompletePrediction[], status: PlacesServiceStatus) => {
        if (status !== PlacesServiceStatus.OK) {
          this.logger.error(status);
          subscriber.error(status);
        }

        if (results) {
          subscriber.next(results.map(result => plainToClass(Place, result)));
        } else {
          subscriber.next(null);
        }

        subscriber.complete();
      };

      this.service.getPlacePredictions({
        input: word,
        types,
        componentRestrictions: {country: 'us'}
      }, callback);
    });
  }

  formatter = (place: Place) => place.description;
}
