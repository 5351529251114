import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Food } from '../../../../../domains/food';
import { Restaurant } from '../../../../../domains/restaurant';
import { Section } from '../../../../../domains/section';
import { Tag } from '../../../../../domains/tag';
import { User } from '../../../../../domains/user';
import { SectionType } from '../../../../../models/enums/section-type';
import { Image } from '../../../../../models/image';
import { FoodService } from '../../../../../services/food.service';

@Component({
  selector: 'app-additions-cs-modal',
  templateUrl: './additions-cs-modal.component.html',
  styleUrls: ['./additions-cs-modal.component.scss']
})
export class AdditionsCsModalComponent implements OnInit {
  @Input() user: User;
  @Input() restaurant: Restaurant;
  @Input() food: Food;
  @Input() tags: Tag[];
  @Input() showWarnings: boolean;

  @Output() save = new EventEmitter<boolean>();

  size: Section;
  options: Section[] = [];
  sectionType = SectionType;
  isAdmin = false;
  delayed = false;

  constructor(private foodService: FoodService) {
  }

  ngOnInit(): void {
    const size = this.food.sections.find(sectionI => sectionI.type === SectionType.SIZE);

    if (size !== undefined) {
      this.size = size;
    }

    this.food
      .sections
      .filter(sectionI => sectionI.type === SectionType.OPTION)
      .forEach(sectionI => {
        if (
          (!this.size || sectionI.id !== this.size.id)
          &&
          this.options.length < 3
        ) {
          this.options.push(sectionI);
        }
      });

    this.isAdmin = this.user.isAdmin(this.restaurant);

    setTimeout(() => {
      this.delayed = true;
    }, 100);
  }

  updateFoodImage($event: Image) {
    if ($event != null) {
      if (this.food.images.length === 0) {
        this.food.images.push($event);
      } else {
        this.food.images[0] = $event;
      }

      this.foodService
        .updateFoodImage(this.food)
        .subscribe(value => {
          this.food.images[0] = value;
        });
    } else {
      this.foodService
        .deleteFoodImage(this.food)
        .subscribe(_ => {
          this.food.images = new Array<Image>();
        });
    }
  }

  onSave() {
    this.save.emit(true);
  }
}
