import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { decodeBlurHash, getBlurHashAverageColor } from 'fast-blurhash';
import { environment } from '../../environments/environment';
import { IsDevice } from '../decorators/is-device.decorator';
import { Restaurant } from '../domains/restaurant';
import { RestaurantSimple } from '../domains/restaurant-simple';
import { LocalStorage } from '../local-storage/local-storage';
import { DeviceType } from '../models/enums/device-type';
import { LocalStorageKey } from '../models/enums/local-storage-key';
import { UnsplashImage } from '../models/unsplash-image';
import { ImageUtils } from '../utils/image-utils';
import { ImgixService } from './imgix.service';
import { RestaurantService } from './restaurant.service';
import { UnsplashService } from './unsplash.service';

@Injectable({providedIn: 'root'})
export class BackgroundImageService {
  @IsDevice(DeviceType.MOBILE) isMobile: boolean;
  decodedPixels: Array<Uint8ClampedArray> = [];

  private backgroundImageLocalStorage: LocalStorage<UnsplashImage>;

  constructor(
    private imgixService: ImgixService,
    private unsplashService: UnsplashService,
    private restaurantService: RestaurantService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.backgroundImageLocalStorage = new LocalStorage(
      UnsplashImage,
      this.isMobile ? LocalStorageKey.BACKGROUND_IMAGE_MOBILE : LocalStorageKey.BACKGROUND_IMAGE
    );
  }

  private setRandomPhoto() {
    this.unsplashService.randomPhoto(this.isMobile).subscribe((image) => {
      this.backgroundImageLocalStorage.setItem(image);

      this.setCurrentBackgroundImage();
    });
  }

  setNewRandomPhoto() {
    if (!this.isMobile) {
      this.setRandomPhoto();
    }
  }

  setAdminBackgroundImageForDesktop() {
    if (!this.isMobile) {
      const element = this._document.getElementById('layout-background');
      const adminImage = environment.adminBackgroundImage;

      this.setBlurHash(adminImage.blurHash, element);

      const adminBackgroundImage = `${adminImage.url}&w=${window.innerWidth}`;

      ImageUtils.downloadImage(adminBackgroundImage).then(() => {
        this.removeBlurHash(element);

        this.setBackgroundImage(adminBackgroundImage, element);
      });
    }
  }

  setCurrentBackgroundImageForDesktop() {
    if (!this.isMobile) {
      this.setCurrentBackgroundImage();
    }
  }

  setCurrentBackgroundImageForMobile() {
    this.setRandomPhoto();
  }

  setCurrentBackgroundImage() {
    const currentImage = this.backgroundImageLocalStorage.getItem();

    const width = window.innerWidth;

    const element = this._document.getElementById('layout-background');

    if (currentImage) {
      if (currentImage.blurHash) {
        this.setBlurHash(currentImage.blurHash, element);
      } else {
        this.setBackgroundColor(currentImage.color, element);

        this.setBackgroundImage(`${currentImage.url}&w=10`, element);
      }

      const backgroundImage = `${currentImage.url}&w=${width}`;

      ImageUtils.downloadImage(backgroundImage).then(() => {
        this.removeBlurHash(element);

        element.style.backgroundColor = 'none';

        this.setBackgroundImage(backgroundImage, element);
      });
    } else {
      this.setBackgroundImage(`${environment.exploreBackgroundDesktopImage}?w=${width}`, element);
    }
  }

  setRestaurantBackgroundImageForDesktop(restaurant: Restaurant) {
    const element = this._document.getElementById('layout-background');

    if (!this.isMobile) {
      const imagePath = restaurant.backgroundImages[0]?.path;

      if (imagePath) {
        this.setBackgroundColor('none', element);

        if (imagePath.startsWith('http')) {
          this.setBackgroundImage(imagePath + '=s' + window.innerWidth, element);
        } else {
          this.setBackgroundImage(
            this.imgixService.getUrl(restaurant.backgroundImages[0].path, {w: window.innerWidth}),
            element
          );
        }
      } else {
        this.setCurrentBackgroundImageForDesktop();
      }
    }
  }

  getRestaurantBgImageOrRandom(
    restaurant: Restaurant | RestaurantSimple,
    isMobile = true
  ): Promise<string | UnsplashImage> {
    return new Promise(resolve => {
      if (restaurant?.backgroundImages.length) {
        resolve(this.imgixService.getUrl(restaurant.backgroundImages[0].path, {w: window.innerWidth}));
      } else {
        // temporary commented (we will use it in future)
        // this.restaurantService.getGoogleBackgroundImage(restaurant.id).subscribe(googleImage => {
        //   if (googleImage) {
        //     resolve(googleImage);
        //   } else {
            this.unsplashService.randomPhoto(isMobile).subscribe(image => {
              resolve(image);
            });
          // }
        // });
      }
    });
  }

  private setBackgroundImage(url: string, element: HTMLElement) {
    element.style.backgroundImage = `url(${url})`;
  }

  removeBlurHash(element: HTMLElement) {
    const canvasElement = element.querySelectorAll('.canvas-background');

    if (canvasElement.length > 0) {
      canvasElement[0].remove();
    }

    element.style.backgroundImage = 'none';
  }

  setBlurHash(blurHash: string, element: HTMLElement) {
    // TODO - It's disabled temporary because of performance issues. It should be fixed in the future.
    if (false && this.isMobile) {
      const width = element.clientWidth;
      const height = element.clientHeight;
      const key = blurHash + width + height;

      let pixels: Uint8ClampedArray;

      if (key in this.decodedPixels) {
        pixels = this.decodedPixels[key];
      } else {
        pixels = decodeBlurHash(blurHash, width, height);
        this.decodedPixels[key] = pixels;
      }

      const div = document.createElement('div');
      div.classList.add('canvas-background');

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      const imageData = ctx.createImageData(width, height);

      imageData.data.set(pixels);
      ctx.putImageData(imageData, 0, 0);

      div.append(canvas);

      element.append(div);
    } else {
      const colors = getBlurHashAverageColor(blurHash);

      element.style.backgroundColor = `rgb(${colors.join(',')})`;
    }
  }

  private setBackgroundColor(color: string, element: HTMLElement) {
    element.style.backgroundColor = color;
  }
}
