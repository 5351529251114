<h6 class="extra-mb">
  {{ section.title || (section.min === 0 ? 'Add Ons' : section.select === 0 ? 'Completed' : '') }}

  <ng-container *ngIf="section.min > 1 && section.select > 0">
    (Select {{ section.select }})
  </ng-container>
</h6>

<ng-container *ngFor="let extra of section.extras">
  <div *ngIf="section.manager.canAddExtra() || extra.quantity > 0" class="flex-content-vc extra-mb">
    <div class="flex-fill">
      <div class="flex-content-vc mb-0">
        <div *ngIf="section.manager.canAddExtra()" class="flex-content-vc">
          <a
            appHrefNone
            class="extra-add icon-v2 add-circle"
            (click)="extraAdd(extra)"
          ></a>
        </div>

        <div
          *ngIf="extra.quantity > 0"
          [ngClass]="{'ml-3': section.manager.canAddExtra()}"
          class="flex-content-vc"
        >
          <a
            appHrefNone
            class="extra-remove icon-v2 remove-circle"
            (click)="extraRemove(extra)"
          ></a>
        </div>

        <div class="flex-content-vc ml-0-4rem">
          <span class="d-inline-block-vertical-middle">
            <span *ngIf="extra.quantity" class="d-inline-block extra-width">
                {{ extra.quantity }}
            </span>

            {{ extra.name }}
          </span>
        </div>
      </div>
    </div>

    <div class="d-inline-flex">
      <p class="mb-0 text-right">
        <span *ngIf="section.isPriceVisible(extra)">{{ extra.price }}</span>
      </p>
    </div>
  </div>
</ng-container>
