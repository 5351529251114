export class MathRound {
  /**
   * @src https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Math/round
   *
   * Adjusting decimal rounding
   *
   * @param {String} type     Adjustment type.
   * @param {Number} value    Number.
   * @param {Number} exp      Exponent (decimal logarithm of the basis of adjustment).
   * @returns {Number}        Adjusted value.
   */
  private static decimalAdjust(type, value, exp) {
    // If the degree is not defined, or is equal to zero...
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }

    value = +value;
    exp = +exp;

    // If the value is not a number, or the degree is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }

    // Bit shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Reverse shift
    value = value.toString().split('e');

    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
  }

  static round10(value, exp) {
    return MathRound.decimalAdjust('round', value, exp);
  }

  static floor10(value, exp) {
    return MathRound.decimalAdjust('floor', value, exp);
  }

  static ceil10(value, exp) {
    return MathRound.decimalAdjust('ceil', value, exp);
  }
}
