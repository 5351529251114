import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Navigation } from '@angular/router';
import { classToPlain, plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Post } from '../domains/post';
import { PostPage } from '../domains/post-page';
import { PostTypeWrapper } from '../models/types/post-type-wrapper';

@Injectable({providedIn: 'root'})
export class PostService {
  private readonly postUrl: string;

  constructor(private http: HttpClient) {
    this.postUrl = `${environment.apiEndpoint}/api/posts`;
  }

  public getPostsByUsername(username: string, page: number = 0) {
    let httpParams = new HttpParams();
    httpParams = httpParams
      .set('page', `${page}`);

    return this.http.get<PostPage>(`${this.postUrl}/user/${username}/`, {params: httpParams})
      .pipe(map(value => plainToClass(PostPage, value)));
  }

  public getPostsByFoodId(foodId: number, page: number = 0) {
    let httpParams = new HttpParams();
    httpParams = httpParams
      .set('food', `${foodId}`)
      .set('page', `${page}`);

    return this.http.get<PostPage>(`${this.postUrl}`, {params: httpParams})
      .pipe(map(value => plainToClass(PostPage, value)));
  }

  public getPostsByType(type: PostTypeWrapper, page: number = 0) {
    let httpParams = new HttpParams();
    httpParams = httpParams
      .set('page', `${page}`);

    return this.http.get<PostPage>(
      `${this.postUrl}/restaurant/${type.value}`,
      {params: httpParams}
    ).pipe(map(value => plainToClass(PostPage, value)));
  }

  public getPost(postHash: string) {
    return this.http.get<Post>(`${this.postUrl}/hash/${postHash}`)
      .pipe(map(value => plainToClass(Post, value)));
  }

  public addPost(post: Post) {
    if (post.caption) {
      post.caption = post.caption.replace(/(<([^>]+)>)/gi, '');
    }

    return this.http.post<Post>(`${this.postUrl}`, classToPlain(post));
  }

  public editPost(post: Post) {
    if (post.caption) {
      post.caption = post.caption.replace(/(<([^>]+)>)/gi, '');
    }

    return this.http.put(`${this.postUrl}`, classToPlain(post))
      .pipe(map(value => plainToClass(Post, value)));
  }

  public deletePost(post: Post) {
    return this.http.delete(`${this.postUrl}/${post.id}`);
  }

  getPostsFromState(currentNavigation: Navigation): Promise<Post[]> {
    return new Promise(resolve => {
      if (currentNavigation?.extras.state) {
        resolve(currentNavigation.extras.state.posts.map(value => plainToClass(Post, value)));
      } else {
        resolve(null);
      }
    });
  }
}
