<div
  class="card-favorite card card-item-image h-100 w-100"
  (click)="onDesktopRestaurantClick(restaurant)"
  (mouseover)="imageHover = true"
  (mouseleave)="imageHover = false"
>
  <app-food-img
    [images]="food.images"
    [foodGallery]="true"
    [width]="720"
    [height]="720"
    [alt]="food.title"
  ></app-food-img>

  <div
    class="position-absolute-0"
  >
    <div
      [ngClass]="{'grayscale': grayscaleAdd}"
      class="position-absolute-0"
    ></div>

    <div
      [ngClass]="{'backdrop-filter-brightness-0-5': imageHover}"
      class="flex-content-vc-hc w-100 h-100 font-weight-500 font-size-1-25rem text-white top-0"
    ></div>

    <div
      [ngClass]="{'opacity-0': !imageHover, 'opacity-1': imageHover}"
      class="position-absolute-0 flex-content-vc-hc"
    >
      <app-heart
        *ngIf="food"
        [food]="food"
        [favoriteModal]="true"
        [imageHovered]="imageHover"
        (favoriteStatusChanged)="onFavoriteStatusChanged($event)"
      ></app-heart>
    </div>
  </div>

  <div
    *ngIf="imageHover"
    class="card-item-image-title text-center"
  >
    {{ food.title }}
  </div>
</div>

<div
  class="favorite-food-restaurant-name flex-content-hc bg-white cursor-pointer"
  (click)="onDesktopRestaurantClick(restaurant)"
  (mouseover)="imageHover = true"
  (mouseleave)="imageHover = false"
>
  <span class="mt-1 font-weight-500 font-size-0-88rem">
    {{ restaurant.nameView }}
  </span>
</div>

