import { Type } from 'class-transformer';
import { Favorite } from '../domains/favorite';
import { CityWithParent } from '../domains/locations/city-with-parent';
import { DistrictWithParent } from '../domains/locations/district-with-parent';
import { RestaurantFavorites } from '../domains/restaurant-favorites';

export class LocationFavorites {
  @Type(() => CityWithParent)
  city: CityWithParent;

  @Type(() => DistrictWithParent)
  district: DistrictWithParent;

  @Type(() => RestaurantFavorites)
  restaurantFavorites: RestaurantFavorites[];

  eatenCount: number;
  favoritesCount: number;
  tryMeCount: number;

  setFavoriteFoodsViewColumnExcept(favoriteExcept: Favorite): void {
    this.restaurantFavorites.forEach(restaurantFavorites => {
      restaurantFavorites.favorites.forEach(favorite => {
        if (favoriteExcept.id !== favorite.id) {
          favorite.view.setColumns();
        }
      });
    });
  }
}
