import { formatDate } from '@angular/common';
import { Type } from 'class-transformer';
import { DateTransform } from '../../utils/date-transform';
import { HoursSectionTypeWrapper } from './hours-section-type-wrapper';
import { RestaurantHoursGroup } from './restaurant-hours-group';

export class RestaurantHoursSection {
  @Type(() => HoursSectionTypeWrapper)
  type: HoursSectionTypeWrapper;

  index: string;

  title: string;

  ordering: number;

  @Type(() => RestaurantHoursGroup)
  groups: RestaurantHoursGroup[] = [];

  closedDays: string;

  setTitle(view = false) {
    let minDate: Date;
    let maxDate: Date;

    this.groups.forEach(group => {
      group.periods.forEach(period => {
        if (minDate === undefined || period.startDate < minDate) {
          minDate = period.startDate;
        }

        if (maxDate === undefined || period.startDate > maxDate) {
          maxDate = period.startDate;
        }

        if (period.closedUntil) {
          let latestDate = period.closedUntil;

          if (view) {
            const twoMonths = DateTransform.plus(60);

            if (latestDate > twoMonths) {
              latestDate = twoMonths;
            }
          }

          if (latestDate > maxDate) {
            maxDate = latestDate;
          }
        }
      });
    });

    if (maxDate === undefined) {
      maxDate = minDate;
    }

    const formatDateEn = (date: Date, format: string) => formatDate(date, format, 'en-US', undefined);

    if (minDate.getMonth() === maxDate.getMonth()) {
      this.title = formatDateEn(minDate, 'MMMM');
    } else {
      this.title = `${formatDateEn(minDate, 'MMM')} - ${formatDateEn(maxDate, 'MMM')}`;
    }
  }
}
