<ng-container *ngIf="section.type === sectionType.OPTION">
  <ng-container *ngIf="section.min === 0">
    <ng-container *ngIf="!section.buttons">
      <app-section-checkbox [section]="section"></app-section-checkbox>
    </ng-container>

    <ng-container *ngIf="section.buttons">
      <app-section-buttons [section]="section"></app-section-buttons>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="section.min === 1">
    <app-section-radio [section]="section"></app-section-radio>
  </ng-container>

  <ng-container *ngIf="section.min > 1">
    <app-section-buttons [section]="section"></app-section-buttons>
  </ng-container>
</ng-container>

<ng-container *ngIf="sectionType.SIZE === section.type">
  <app-section-radio [section]="section"></app-section-radio>
</ng-container>
