import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { User } from '../../../domains/user';
import { LocalStorage } from '../../../local-storage/local-storage';
import { DeviceType } from '../../../models/enums/device-type';
import { InstagramState } from '../../../models/enums/instagram-state';
import { LocalStorageKey } from '../../../models/enums/local-storage-key';
import { InstagramService } from '../../../services/instagram.service';
import { NavigatorService } from '../../../services/navigator.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-username-choice-modal',
  templateUrl: 'username-choice-modal.component.html',
  styleUrls: ['username-choice-modal.component.scss']
})
export class UsernameChoiceModalComponent implements OnInit {
  @Input() user: User;

  @Output() username = new EventEmitter<string>();

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  usernameForm: UntypedFormGroup;
  submitted = false;
  usernameChecked = false;
  showIgLoginButton = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private navigatorService: NavigatorService,
    private instagramService: InstagramService
  ) {
  }

  ngOnInit(): void {
    this.usernameForm = this.formBuilder.group({
      username: [
        '',
        {
          validators: [Validators.required, Validators.pattern(/^[a-zA-Z0-9._]+$/)],
          asyncValidators: [this.usernameValidator()],
          updateOn: 'blur'
        }
      ],
    });

    this.usernameControl.statusChanges.subscribe((status) => {
      this.submitted = true;

      if (status === 'VALID') {
        this.username.emit(this.usernameControl.value);
      }
    });
  }

  get usernameControl() {
    return this.usernameForm.controls.username;
  }

  instagramClick() {
    const tempUserLocalStorage = new LocalStorage(User, LocalStorageKey.TEMP_USER);
    tempUserLocalStorage.setItem(this.user);

    this.navigatorService.goToExternal(this.instagramService.getCodeUrl(InstagramState.USERNAME));
  }

  private usernameValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      this.showIgLoginButton = false;

      return this.userService.checkUsernameToExists(control.value)
        .pipe(map(value => {
          this.usernameChecked = true;

          if (value.instagram) {
            this.showIgLoginButton = true;
          }

          return value.user || value.instagram || value.restaurant ? {exists: true} : null;
        }));
    };
  }
}
