<div
  *ngIf="(!isDesktop && explore) || (!isDesktop && !explore)"
  class="restaurant-preview-content safe-area-top"
  (swipeup)="close()"
>
  <app-navbar-top *ngIf="!isDesktop && explore" [title]="restaurant.nameView"></app-navbar-top>

  <app-navbar-top-profile *ngIf="!isDesktop && !explore" [title]="restaurant.nameView"></app-navbar-top-profile>

  <div
    *ngIf="!isDesktop || (isDesktop && explore)"
    class="restaurant-preview-body d-flex flex-column bg-black position-relative"
  >
    <div
      [appBackgroundUrl]="backgroundImage"
      id="restaurant-preview-hours"
      class="restaurant-preview-hours card border-radius-none mt-0 d-flex h-100 flex-column"
    >
      <div class="hours-modal-backdrop"></div>

      <app-restaurant-hours
        [restaurantHoursSections]="restaurant.hoursSections"
        [isEdit]="true"
        class="text-white"
        (removePeriod)="removePeriod($event)"
      ></app-restaurant-hours>

      <div
        *ngIf="delayed && (!isAdmin || periodTypeOption.type.isNormalHours)"
        class="hours-nouislider py-1"
      >
        <nouislider
          class="noUi-inverted"
          [(ngModel)]="dateRangeSelected"
          [behaviour]="'tap-drag'"
          [tooltips]="tooltips"
          [config]="dateRangeOptions"
        ></nouislider>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isDesktop || (isDesktop && explore)" class="modal-top-standard-footer border-bottom-radius-3 bg-white">
  <div class="modal-top-standard-footer-content bg-white border-bottom-radius-3 p-3">
    <div class="form-row">
      <div class="form-group col-4">
        <select
          class="custom-select"
          [(ngModel)]="periodTypeOption"
          (ngModelChange)="periodTypeOptionSelected($event)"
        >
          <optgroup label="Normal hours">
            <ng-container *ngFor="let periodTypeOption of periodTypeOptions">
              <option
                *ngIf="periodTypeOption.type.isNormalHours"
                [ngValue]="periodTypeOption"
                class="text-capitalize"
              >
                {{ periodTypeOption.type.value | titlecase | replace : '_' : ' ' }}
              </option>
            </ng-container>
          </optgroup>

          <optgroup label="Calendar" class="text-capitalize">
            <ng-container *ngFor="let periodTypeOption of periodTypeOptions">
              <option
                *ngIf="periodTypeOption.type.isCalendar"
                [selected]="periodTypeOption.type.isEvent"
                [ngValue]="periodTypeOption"
                class="text-capitalize"
              >
                <ng-container *ngIf="periodTypeOption.type.isEvent">
                  Event
                </ng-container>

                <ng-container *ngIf="periodTypeOption.type.isClosed && !periodTypeOption.closedUntil">
                  Closed on
                </ng-container>

                <ng-container *ngIf="periodTypeOption.type.isClosed && periodTypeOption.closedUntil">
                  Closed from
                </ng-container>
              </option>
            </ng-container>
          </optgroup>

          <optgroup label="Special hours" class="text-capitalize">
            <ng-container *ngFor="let periodTypeOption of periodTypeOptions">
              <option *ngIf="periodTypeOption.type.isSpecialHours" [ngValue]="periodTypeOption">
                {{ periodTypeOption.specialTimeStart?.toString() }}
              </option>
            </ng-container>
          </optgroup>
        </select>
      </div>

      <div
        *ngIf="periodTypeOption?.type.isNormalHours || periodTypeOption?.type.isEvent"
        class="form-group col-4"
      >
        <select
          [ngModel]="timeStartSelected"
          class="custom-select"
          (ngModelChange)="onStartTimeChange($event)"
        >
          <option *ngIf="periodTypeOption.type.isBusinessHours" value="24hours">
            24 Hours
          </option>

          <option *ngFor="let time of timeListStart" [ngValue]="time">
            {{ time.toString() }}
          </option>
        </select>
      </div>

      <div
        *ngIf="(periodTypeOption?.type.isNormalHours && !allDaySelected) || periodTypeOption.type.isSpecialHours"
        class="form-group col-4"
      >
        <select [(ngModel)]="timeEndSelected" class="custom-select">
          <option *ngFor="let time of timeListEnd" [ngValue]="time">
            {{ time.toString() }}
          </option>
        </select>
      </div>

      <div
        *ngIf="periodTypeOption.type.isEvent || periodTypeOption.type.isSpecialHours"
        class="form-group col-4"
      >
        <input [(ngModel)]="dateSelected" [min]="today" type="date" class="form-control"/>
      </div>

      <ng-container *ngIf="periodTypeOption.type.isClosed">
        <div class="form-group col-4">
          <input
            [(ngModel)]="closedFromSelected"
            [min]="today"
            (ngModelChange)="onClosedFromChange()"
            type="date"
            class="form-control"
          />
        </div>

        <div *ngIf="periodTypeOption.closedUntil" class="form-group col-4">
          <input
            [(ngModel)]="closedUntilSelected"
            [min]="closedUntilMin"
            type="date"
            class="form-control"
          />
        </div>
      </ng-container>
    </div>

    <button class="btn btn-dark btn-block text-uppercase" (click)="addPeriod()">
      <span>Add</span>
    </button>
  </div>
</div>

<div *ngIf="isDesktop && !explore" class="restaurant-preview-content p-3 mx-3 pt-0">
  <div
    *ngIf="isDesktop"
    appModalCloseOnClick
    class="h-100 w-100 modal-body p-0 flex-column"
  >
    <div class="w-100 h-100">
      <div class="modal-height mt-2rem flex-column">
        <div appModalCloseOnClickPrevent class="modal-box bg-dark backdrop-filter-blur-10">
          <div class="user-authentication">
            <div class="min-h-110px">
              <div class="d-flex flex-column position-relative mx-n3 mb-15rem">
                <app-restaurant-hours
                  [restaurantHoursSections]="restaurant.hoursSections"
                  [isEdit]="true"
                  class="text-white"
                  (removePeriod)="removePeriod($event)"
                ></app-restaurant-hours>

                <div
                  *ngIf="delayed && periodTypeOption.type.isNormalHours"
                  class="hours-nouislider-desktop py-1"
                >
                  <nouislider
                    class="noUi-inverted"
                    [(ngModel)]="dateRangeSelected"
                    [behaviour]="'tap-drag'"
                    [tooltips]="tooltips"
                    [config]="dateRangeOptions"
                  ></nouislider>
                </div>
              </div>

              <div class="form-row text-white mx-0">
                <div class="form-group col-4 w-100">
                  <div
                    appNgbDropdownOnHover
                    ngbDropdown
                    class="dropdown mt-6"
                    style="margin-top: 6px;"
                  >
                    <div
                      ngbDropdownToggle
                      class="text-white border-bottom-white hide-arrow cursor-pointer w-100"
                      style="padding-bottom: calc(0.5rem - 1px);"
                    >
                      {{
                        periodTypeOption.type.isNormalHours ?
                          (periodTypeOption.type.value | titlecase | replace : '_' : ' ') :
                        periodTypeOption.type.isSpecialHours ?
                          periodTypeOption.specialTimeStart?.toString():
                        periodTypeOption.type.isEvent ? 'Event' :
                        periodTypeOption.type.isClosed && !periodTypeOption.closedUntil ? 'Closed on' :
                          'Closed from'
                      }}
                    </div>

                    <div
                      ngbDropdownMenu
                      class="bg-dark backdrop-filter-blur-5 dropdown-menu-hours"
                    >
                      <div class="dropdown-header"> Normal hours</div>

                      <ng-container *ngFor="let periodTypeOption of periodTypeOptions">
                        <a
                          *ngIf="periodTypeOption.type.isNormalHours"
                          class="text-capitalize dropdown-item d-inline-flex text-white"
                          (click)="periodTypeOptionSelected(periodTypeOption)"
                        >
                          {{ periodTypeOption.type.value | titlecase | replace : '_' : ' ' }}
                        </a>
                      </ng-container>

                      <div class="dropdown-header"> Calendar </div>

                      <ng-container *ngFor="let periodTypeOption of periodTypeOptions">
                        <a
                          *ngIf="periodTypeOption.type.isCalendar"
                          class="text-capitalize dropdown-item d-inline-flex text-white"
                          (click)="periodTypeOptionSelected(periodTypeOption)"
                        >
                          <ng-container *ngIf="periodTypeOption.type.isEvent">
                            Event
                          </ng-container>

                          <ng-container *ngIf="periodTypeOption.type.isClosed && !periodTypeOption.closedUntil">
                            Closed on
                          </ng-container>

                          <ng-container *ngIf="periodTypeOption.type.isClosed && periodTypeOption.closedUntil">
                            Closed from
                          </ng-container>
                        </a>
                      </ng-container>

                      <div class="dropdown-header"> Special hours </div>
                      <ng-container *ngFor="let periodTypeOption of periodTypeOptions">
                        <a
                          class="dropdown-item d-inline-flex text-white"
                          *ngIf="periodTypeOption.type.isSpecialHours"
                          (click)="periodTypeOptionSelected(periodTypeOption)"
                        >
                          {{ periodTypeOption.specialTimeStart?.toString() }}
                        </a>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="periodTypeOption?.type.isNormalHours || periodTypeOption?.type.isEvent"
                  class="form-group col-4 w-100"
                >
                  <div
                    appNgbDropdownOnHover
                    ngbDropdown
                    [placement]="'bottom'"
                    class="d-inline-flex cursor-pointer w-100"
                    style="margin-top: 6px;"
                  >
                    <div
                      ngbDropdownToggle
                      class="text-white border-bottom-white hide-arrow cursor-pointer w-100"
                      style="padding-bottom: calc(0.5rem - 1px);"
                    >
                      {{ timeStartSelected }}
                    </div>

                    <div ngbDropdownMenu class="bg-dark backdrop-filter-blur-5 dropdown-menu-hours">
                      <a
                        appHrefNone
                        *ngIf="periodTypeOption.type.isBusinessHours"
                        class="dropdown-item d-inline-flex text-white"
                        (click)="onStartTimeChange('24hours')"
                      >
                        24 Hours
                      </a>

                      <a
                        appHrefNone
                        *ngFor="let time of timeListStart"
                        class="dropdown-item d-inline-flex text-white"
                        (click)="onStartTimeChange(time)"
                      >
                        {{ time.toString() }}
                      </a>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="(periodTypeOption?.type.isNormalHours && !allDaySelected) || periodTypeOption.type.isSpecialHours"
                  class="form-group col-4 w-100"
                >
                  <div
                    appNgbDropdownOnHover
                    ngbDropdown
                    [placement]="'bottom'"
                    class="text-white border-bottom-white cursor-pointer w-100"
                    style="margin-top: 6px;"
                  >
                    <div
                      ngbDropdownToggle
                      class="text-white hide-arrow w-100"
                      style="padding-bottom: calc(0.5rem - 1px);"
                    >
                      {{ timeEndSelected }}
                    </div>

                    <div ngbDropdownMenu class="bg-dark backdrop-filter-blur-5 dropdown-menu-hours">
                      <a
                        appHrefNone *ngFor="let time of timeListEnd"
                        class="dropdown-item d-inline-flex text-white"
                      >
                        {{ time.toString() }}
                      </a>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="periodTypeOption.type.isEvent || periodTypeOption.type.isSpecialHours"
                  class="form-group col-4"
                >
                  <input
                    [(ngModel)]="dateSelected"
                    [min]="today"
                    type="date"
                    class="form-control border-bottom-white text-white cursor-pointer"
                    style="color-scheme: dark"
                    (click)="showDatePicker($event)"
                  />
                </div>

                <ng-container *ngIf="periodTypeOption.type.isClosed">
                  <div class="form-group col-4">
                    <input
                      [(ngModel)]="closedFromSelected"
                      [min]="today"
                      (ngModelChange)="onClosedFromChange()"
                      type="date"
                      class="form-control text-white border-bottom-white cursor-pointer"
                    />
                  </div>

                  <div *ngIf="periodTypeOption.closedUntil" class="form-group col-4">
                    <input
                      [(ngModel)]="closedUntilSelected"
                      [min]="closedUntilMin"
                      type="date"
                      class="form-control text-white cursor-pointer border-bottom-white"
                    />
                  </div>
                </ng-container>
              </div>

              <button class="btn btn-dark btn-block text-uppercase cursor-pointer" (click)="addPeriod()">
                <span>Add</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #eventAddModalRef>
  <app-period-event-details-modal
    [period]="period"
    [dateSelected]="dateSelected"
    [timeList]="timeListEnd"
    (savePeriod)="addPeriodTypeEvent($event)"
  ></app-period-event-details-modal>
</ng-template>
