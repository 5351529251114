import { Injectable } from '@angular/core';
import { DecoratorService } from './decorator.service';

@Injectable()
export class DecoratorInjectorService {
  private static service: DecoratorService | undefined = undefined;

  public constructor(service: DecoratorService) {
    DecoratorInjectorService.service = service;
  }

  public static getService(): DecoratorService {
    if (!DecoratorInjectorService.service) {
      throw new Error('DecoratorService not initialized');
    }

    return DecoratorInjectorService.service;
  }
}
