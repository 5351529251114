import { Transform, Type } from 'class-transformer';
import { nanoid } from 'nanoid';
import { Image } from '../models/image';
import { PostType } from '../models/types/post-type';
import { PostTypeWrapper } from '../models/types/post-type-wrapper';
import { View } from '../models/view';
import { Wrapper } from '../models/wrappers/wrapper';
import { Food } from './food';
import { User } from './user';

export class Post {
  id: number;
  caption: string;
  user: User;
  prepend = false;
  hash: string;
  likeCount: number;
  getstreamId: string;
  userSubscribed: boolean;
  restaurantPath: string;
  restaurantTitle: string;
  restaurantCityState: string;
  currentUserLiked: boolean;
  view = new View();
  votesCount: number;

  @Type(() => PostTypeWrapper)
  @Transform(value => Wrapper.toClass(value, PostTypeWrapper), {toClassOnly: true})
  @Transform(value => Wrapper.toPlain(value, PostTypeWrapper), {toPlainOnly: true})
  type: PostTypeWrapper;

  constructor(postType: PostType = null) {
    this.hash = nanoid(10);

    this.type = new PostTypeWrapper(postType);
  }

  @Type(() => Image)
  images: Array<Image> = [];

  @Type(() => Food)
  food: Food;

  isCropped = false;

  isIg(): boolean {
    return this.images.length > 0 && this.images[0].igId != null;
  }

  get isFullScreen() {
    return (!this.isIg() && this.images.length > 0 && this.images[0].width < this.images[0].height);
  }
}
