import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorage } from '../local-storage/local-storage';
import { Basket } from '../models/basket/basket';
import { LocalStorageKey } from '../models/enums/local-storage-key';
import { RouterService } from './router.service';

@Injectable({providedIn: 'root'})
export class BasketService {
  needShow = true;
  basketSubject: BehaviorSubject<Basket> = new BehaviorSubject<Basket>(new Basket());
  basketLocalStorage: LocalStorage<Basket>;

  constructor(private routerService: RouterService) {
    this.setBasketLocalStorage();
    this.basketSubject.next(this.basket);
  }

  set basket(basket: Basket) {
    this.basketLocalStorage.setItem(basket);

    this.basketSubject.next(basket);
  }

  get basket(): Basket {
    let basket = this.basketLocalStorage.getItem();

    if (basket) {
      basket.calculate();
    } else {
      basket = new Basket();
    }

    this.basketSubject.next(basket);

    return this.basketSubject.value;
  }

  setBasketLocalStorage() {
    this.basketLocalStorage = new LocalStorage(
      Basket,
      LocalStorageKey.BASKET,
      {hostname: this.routerService.restaurantHostname, locationId: this.routerService.restaurantLocationId}
    );

    this.basketSubject.next(this.basket);
  }
}
