import { Type } from 'class-transformer';
import { GlobalBaseLocation } from '../../models/global-base-location';
import { CountyWithParent } from './county-with-parent';
import { RegionWithParent } from './region-with-parent';

export class CityWithParent extends GlobalBaseLocation {
  @Type(() => CountyWithParent)
  county: CountyWithParent;

  slug: string;

  airport: boolean;

  @Type(() => RegionWithParent)
  region: RegionWithParent;
}
