import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appHrefNone]'
})
export class HrefNoneDirective {
  constructor(element: ElementRef) {
    const nativeElement = element.nativeElement;

    nativeElement.href = 'javascript:void(0)';
  }
}
