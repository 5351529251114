import { Injectable } from '@angular/core';
import { SearchFiltersSync } from '../models/search-filters-sync';

@Injectable({providedIn: 'root'})
export class SearchFiltersService {
  initiator: string;
  lockOthers = false;

  stateIdSync = new SearchFiltersSync();
  countyIdSync = new SearchFiltersSync();
  regionIdSync = new SearchFiltersSync();
  cityIdSync = new SearchFiltersSync();
  districtIdSync = new SearchFiltersSync();

  constructor() {
  }

  lock(hash: string) {
    if (hash) {
      this.initiator = hash;
      this.lockOthers = true;

      setTimeout(() => {
        this.lockOthers = false;
      }, 100);
    }
  }
}
