import { ErrorHandler, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { LoggerService } from '../services/logger.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private logger: NGXLogger, private loggerService: LoggerService) {
  }

  handleError(error: any) {
    this.loggerService.errorLog(error);
  }
}
