import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Post } from '../../../domains/post';
import { PostFoodFormGroup } from '../../../models/form-groups/post-food-form-group';
import { PostPageTypeWrapper } from '../../../models/types/post-page-type-wrapper';

@Component({
  selector: 'app-navbar-top-post-new',
  templateUrl: './navbar-top-post-new.component.html',
  styleUrls: ['../navbar.shared.scss'],
  styles: ['.navbar-title {max-width: 59%}']
})
export class NavbarTopPostNewComponent implements OnInit {
  @Input() title: any;
  @Input() postPageType: PostPageTypeWrapper;
  @Input() post: Post;
  @Input() postRestaurantFormGroup: PostFoodFormGroup;

  @Output() shareClick = new EventEmitter<boolean>();
  @Output() backClick = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit() {
  }

  goBack() {
    this.backClick.emit(true);
  }

  share() {
    this.shareClick.emit(true);
  }
}
