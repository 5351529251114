import { Type } from 'class-transformer';
import { PostReportType } from '../models/enums/post-report-type';
import { Post } from './post';
import { User } from './user';

export class PostReport {
  id: number;
  description: string;
  type: PostReportType;
  checked: boolean;

  @Type(() => Post)
  post: Post;

  @Type(() => User)
  user: User;
}
