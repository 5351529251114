import { Component, OnInit } from '@angular/core';
import { IsDevice } from '../../decorators/is-device.decorator';
import { DeviceType } from '../../models/enums/device-type';
import { NavigatorService } from '../../services/navigator.service';
import { RouterService } from '../../services/router.service';

@Component({
  selector: 'app-admin',
  template: '',
  styles: []
})
export class AdminComponent implements OnInit {
  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  constructor(
    private navigatorService: NavigatorService,
    private routerService: RouterService
  ) {
  }

  ngOnInit(): void {
    const locationId = this.routerService.restaurantLocationId;

    if (!this.isMobile) {
      this.navigatorService.goToMenuTable(locationId);
    } else {
      this.navigatorService.goToSettingsPage();
    }
  }
}
