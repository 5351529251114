<ng-container *ngIf="restaurant">
  <div *appIfIsDesktop class="menu-table position-relative">
    <div class="d-flex">
      <div class="width-tab"></div>

      <div class="flex-fill">
        <table class="table-header table table-borderless card-table">
          <thead>
          <tr>
            <th class="text-left">
              <div class="d-flex align-items-center address">
                <div
                  ngbDropdown
                  *ngIf="locations.length > 1"
                  [placement]="'bottom-left'"
                  class="d-inline-flex cursor-pointer"
                >
                  <div
                    ngbDropdownToggle
                    class="availability-dropdown-toggle flex-content-vc-hc text-white"
                  >
                    {{ restaurant.address }}
                  </div>

                  <div
                    ngbDropdownMenu
                    class="availability-dropdown dropdown-menu-left"
                  >
                    <a
                      *ngFor="let location of locations"
                      appHrefNone
                      class="dropdown-item d-inline-flex"
                      (click)="goToMenuTableFromLocation(location.locationIndex)"
                    >
                      {{ location.address }}
                    </a>
                  </div>
                </div>
              </div>
            </th>
          </tr>
          </thead>
        </table>
      </div>
    </div>

    <div class="d-flex">
      <div class="width-tab">
        <ul
          ngbNav
          #nav="ngbNav"
          [activeId]="activeTab"
          [orientation]="'vertical'"
          class="nav-tabs mb-4 position-fixed width-tab"
          (navChange)="onMenuTabChanged($event)"
        >
          <li class="nav-item">
            <div class="d-flex">
              <div class="flex-fill text-right">
                <div
                  appNgbDropdownOnHover
                  ngbDropdown
                  [autoClose]="'outside'"
                  [placement]="'right-top'"
                  class="d-inline-flex cursor-pointer"
                >
                  <div
                    ngbDropdownToggle
                    class="flex-content-vc-hc hide-arrow"
                  >
                    <span class="nav-link text-uppercase">Menus</span>
                  </div>

                  <div
                    ngbDropdownMenu
                    class="dropdown-menu-left bg-dark backdrop-filter-blur-5"
                  >
                    <app-menu-list [restaurant]="restaurant"></app-menu-list>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <ng-container *ngFor="let menu of menus;">
            <li *ngIf="!menu.menuType.isHouse()" [ngbNavItem]="menu.id">
              <div class="d-flex">
                <div class="flex-fill">
                  <a ngbNavLink class="text-right">
                    {{ menu.display(restaurant) }}
                  </a>
                </div>
              </div>

              <ng-template ngbNavContent>
                <ng-container *ngIf="activeMenu && (activeMenu.title != 'Dinner' || !sameAsLunch)">
                  <div [sortablejs]="menu.categories" [sortablejsOptions]="categoriesSortableOptions">
                    <div *ngFor="let category of menu.categories" [id]="category.id" class="card mb-4">
                      <app-category-edit
                        [activeMenu]="activeMenu"
                        [category]="category"
                        [sizes]="sizes"
                        [options]="options"
                      ></app-category-edit>
                    </div>
                  </div>
                </ng-container>

                <app-add-category-button
                  *ngIf="activeMenu"
                  [menu]="activeMenu"
                  [isAdmin]="true"
                  (addCategory)="addCategory($event)"
                ></app-add-category-button>
              </ng-template>
            </li>
          </ng-container>
        </ul>
      </div>

      <div [ngbNavOutlet]="nav" class="tab-content flex-fill"></div>
    </div>
  </div>
</ng-container>
