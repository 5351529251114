import { Component, OnInit } from '@angular/core';
import { Auth } from '../../../decorators/auth.decorator';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { ModalClose } from '../../../decorators/modal-close.decorator';
import { StripeSession } from '../../../domains/stripe-session';
import { StripeSubscription } from '../../../domains/stripe-subscription';
import { User } from '../../../domains/user';
import { DeviceType } from '../../../models/enums/device-type';
import { CheckoutService } from '../../../services/checkout.service';
import { LoaderService } from '../../../services/loader.service';
import { NavigatorService } from '../../../services/navigator.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-subscribe-modal',
  templateUrl: './subscribe-modal.component.html',
  styleUrls: [
    './subscribe-modal.component.scss'
  ]
})
export class SubscribeModalComponent implements OnInit {
  @CurrentUser() currentUser: User;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;
  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  editMode: boolean;
  selectedPrice = 8;
  subscription: StripeSubscription;
  sliderOptions = {
    start: this.selectedPrice,
    behaviour: 'tap-drag',
    range: {
      min: 5,
      max: 101,
    },
    step: 1,
    keyboardSupport: true
  };
  showMinPlaceHolder = false;

  constructor(
    private checkoutService: CheckoutService,
    private notificationService: NotificationService,
    private navigatorService: NavigatorService,
    private loaderService: LoaderService,
  ) {
  }

  ngOnInit(): void {
    this.checkoutService.getUserSubscriptionInfo(this.currentUser)
      .subscribe(value => {
        this.subscription = value;
        this.editMode = !!value;
        this.selectedPrice = value ? value.price / 1200 : 8;

        if (this.editMode) {
          this.sliderOptions.range.min = 0;
          this.sliderOptions.start = this.selectedPrice;
        }

        setTimeout(() => {
          this.onInputChange();
        }, 100);
      });
  }

  @Auth()
  onClickSubscribe() {
    this.loaderService.showLoader();

    if (this.editMode) {
      this.checkoutService.update(this.selectedPrice)
        .subscribe(() => {
          this.navigatorService.goToUser(this.currentUser.username);
          this.notificationService.subscription(this.selectedPrice !== 0);

          this.modalClose();
        });
    } else {
      this.checkoutService.getSession(this.selectedPrice)
        .subscribe((session: StripeSession) => {
          // @ts-ignore
          stripe.redirectToCheckout({
            sessionId: session.id
          });
        });
    }

    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 2000);
  }

  onInputChange() {
    const length = this.getLength();

    const customInput = document.getElementById('custom-input') as HTMLInputElement;
    customInput.style.width = `${length}.5rem`;

    this.showMinPlaceHolder = this.selectedPrice < 5;
  }

  onSliderChange(value: number) {
    if (value > 100) {
      this.selectedPrice = null;
      this.focusInput();
    } else {
      this.selectedPrice = value;
      this.blurInput();
    }

    this.onInputChange();
  }

  focusInput() {
    const customInput = document.getElementById('custom-input');

    setTimeout(() => {
      customInput.focus();
    }, 10);
  }

  private getLength() {
    let length = `${this.selectedPrice}`.length;

    if (length === 0) {
      length = 1;
    }

    return length;
  }

  private blurInput() {
    const customInput = document.getElementById('custom-input');
    customInput.blur();
  }

  @ModalClose()
  modalClose() {
  }
}
