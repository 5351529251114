import { Component, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { RouteParamsValue } from '../../decorators/route-params-value.decorator';
import { Menu } from '../../domains/menu';
import { Restaurant } from '../../domains/restaurant';
import { RouteParamsType } from '../../models/enums/route-params-type';
import { RestaurantRouteParams } from '../../models/route-params/restaurant-route-params';
import { BackgroundImageService } from '../../services/background-image.service';
import { BasketService } from '../../services/basket.service';
import { LoaderService } from '../../services/loader.service';
import { MenuService } from '../../services/menu.service';
import { RestaurantService } from '../../services/restaurant.service';
import { SeoService } from '../../services/seo.service';
import { WebSocketService } from '../../services/web-socket.service';
import { Listener } from '../../websocket/listener';
import { RestaurantListener } from '../../websocket/listeners/restaurant-listener';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styles: []
})
export class RestaurantComponent implements OnInit, OnDestroy {
  @RouteParamsValue(RouteParamsType.RESTAURANT) restaurantRouteParams: RestaurantRouteParams;

  restaurant: Restaurant;
  menus: Menu[];
  urlHasMenu: boolean;
  isNotFound = false;
  restaurantSubscription: Subscription;
  websocketRestaurantListener: Listener;
  isSearching = true;

  constructor(
    private menuService: MenuService,
    private seoService: SeoService,
    private logger: NGXLogger,
    private backgroundImageService: BackgroundImageService,
    private webSocketService: WebSocketService,
    private basketService: BasketService,
    private restaurantService: RestaurantService,
    private loaderService: LoaderService
  ) {
    this.loaderService.showLoader();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 500);

    this.urlHasMenu = this.restaurantRouteParams.hasMenu;

    this.restaurantSubscription = this.restaurantService.getRestaurant(
      this.restaurantRouteParams.hostname,
      this.restaurantRouteParams.locationId
    ).subscribe({
      next: restaurant => {
        if (restaurant) {
          this.restaurant = restaurant;

          this.basketService.setBasketLocalStorage();

          this.backgroundImageService.setRestaurantBackgroundImageForDesktop(this.restaurant);

          if (!this.restaurantRouteParams.hasFood) {
            this.seoService.setRestaurantMetaTags(restaurant);
          }

          this.menus = this.menuService.getMenus(this.restaurant);

          this.websocketRestaurantListener = this.webSocketService
            .registerListener(new RestaurantListener(restaurant));

          this.websocketRestaurantListener.subject.subscribe(restaurantNew => {
            this.restaurant = restaurantNew;
          });

          this.isSearching = false;
        }
      }, error: error => {
        this.isNotFound = true;
        this.logger.error('On getting current restaurant', error);

        this.isSearching = false;
      }
    });
  }

  ngOnDestroy() {
    this.restaurantSubscription?.unsubscribe();

    if (this.websocketRestaurantListener) {
      this.webSocketService.unsubscribeListener(this.websocketRestaurantListener);
    }
  }
}
