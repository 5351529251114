import { MenuType } from './enums/menu-type';
import { WrapperInterface } from './wrappers/wrapper.interface';

export class MenuTypeWrapper implements WrapperInterface {
  list = MenuType;
  value: MenuType;

  constructor(type: string = null) {
    if (type) {
      this.value = MenuType[type];
    }
  }

  isLunch() {
    return this.value === this.list.LUNCH;
  }

  isHouse() {
    return this.value === this.list.HOUSE;
  }
}
