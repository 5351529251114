import { Component, ElementRef, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ModalType } from '../../models/enums/modal-type';
import { Paths } from '../../models/enums/paths';
import { Modal } from '../../models/modal';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  @ViewChild('gemsModalRef') gemsModalRef: ElementRef;

  paths = Paths;
  gemsModal: Modal;
  gemBackgroundImage: string;
  mmmmLogo: string;

  constructor(
    private modalService: ModalService
  ) {
    this.gemBackgroundImage = environment.gemBackgroundImage;
    this.mmmmLogo = environment.mmmmLogo;
  }

  openGemsModal() {
    this.gemsModal = this.modalService.open(this.gemsModalRef, ModalType.FULL_TRANSPARENT);
  }
}
