import { plainToClass } from 'class-transformer';
import { LocalStorageOptions } from '../models/local-storage-options';
import { BaseLocalStorage } from './base-local-storage';

export class LocalStorage<ClassType> extends BaseLocalStorage {
  classType: new (...args: any[]) => ClassType;

  constructor(
    classType: new (...args: any[]) => ClassType,
    localStorageKey: string,
    options: LocalStorageOptions = {}
  ) {
    super(localStorageKey, options);

    this.classType = classType;
  }

  setItem(value: ClassType) {
    super.setObject(value);
  }

  getItem(): ClassType {
    return plainToClass(this.classType, this.getObject());
  }

  setItems(value: ClassType[]) {
    super.setObject(value);
  }

  getItems(): ClassType[] {
    const objects = this.getObject();

    if (Array.isArray(objects)) {
      return objects.map(item => plainToClass(this.classType, item));
    } else if (objects) {
      return [plainToClass(this.classType, objects)];
    }

    return [];
  }
}
