import { plainToClass } from 'class-transformer';
import { Subject } from 'rxjs';
import { Order } from '../../domains/order/order';
import { Listener } from '../listener';

export class AdminOrderListener extends Listener {
  subject = new Subject<Order>();

  constructor(hostname: string) {
    super();
    this.topic = `/push/admin/order/${hostname}`;
  }

  transform(frame: any): Order {
    return plainToClass(Order, JSON.parse(frame.body));
  }
}
