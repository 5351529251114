<div class="search-modal-favorites-slide d-flex flex-column">
  <app-search-modal-favorites-navbar-top
    [title]="searchParams.locationName"
    [eatenCount]="locationFavorites?.eatenCount"
  ></app-search-modal-favorites-navbar-top>

  <app-favorites-list
    [locationFavorites]="locationFavorites"
    [searchResult]="searchResult"
    [favoriteList]="true"
    (setPreviewSlideRestaurant)="onSetPreviewSlideRestaurant($event)"
    (restaurantNameClick)="onRestaurantNameClick()"
    (favoriteStatusChanged)="onFavoriteStatusChange()"
  ></app-favorites-list>
</div>
