import { v4 as uuidv4 } from 'uuid';
import { ViewType } from './enums/view-type';

export class View {
  id = uuidv4();
  type: ViewType = ViewType.COLUMNS;

  get isColumns() {
    return this.type === ViewType.COLUMNS;
  }

  get isClassic() {
    return this.type === ViewType.CLASSIC;
  }

  get isList() {
    return this.type === ViewType.LIST;
  }

  setColumns() {
    this.type = ViewType.COLUMNS;
  }

  setClassic() {
    this.type = ViewType.CLASSIC;
  }

  setList() {
    this.type = ViewType.LIST;
  }

  toString() {
    return this.type;
  }
}
