import { Type } from 'class-transformer';
import { ReportSubjectType } from '../models/enums/report-subject-type';
import { ReportType } from '../models/enums/report-type';
import { User } from './user';

export class Report {
  id: number;
  subjectId: number;
  subjectType: ReportSubjectType;
  type: ReportType;
  checked: boolean;
  accepted: boolean;
  objectId: number;

  @Type(() => User)
  user: User;
}
