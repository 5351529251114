<ng-container *ngIf="isMobile">
  <div class="bg-white position-absolute-0">
    <ng-container *ngIf="!loaderWasHide">
      <app-user-loader></app-user-loader>
    </ng-container>

    <ng-container *ngIf="loaderWasHide">
      <app-navbar-top-profile
        [showBack]="!isOwner"
        [isOwner]="isOwner"
        [userFirstName]="user.firstName"
        [userLastName]="user.lastName"
      ></app-navbar-top-profile>

      <ng-container *ngIf="this.user">
        <div class="container has-navbar-both max-width px-0">
          <div class="card card-user content card-box-shadow">
            <div class="user flex-content-hc">
              <div class="user-avatar rounded-circle">
                <app-image-picker *ngIf="isOwner" accept="gif, jpeg, png" (save)="onImageUploaded($event)">
                  <ng-container *ngTemplateOutlet="userAvatar"></ng-container>
                </app-image-picker>

                <ng-container *ngIf="!isOwner">
                  <ng-container *ngTemplateOutlet="userAvatar"></ng-container>
                </ng-container>

                <ng-template #userAvatar>
                  <app-user-avatar
                    [user]="user"
                    [widthAndHeight]="400"
                    [isImageUploading]="isImageUploading"
                    [isImageLoadError]="isProfileImageLoadError"
                    class="w-100 h-100"
                    (imageClick)="onImageClick()"
                  ></app-user-avatar>
                </ng-template>
              </div>
            </div>

            <div class="mt-3 px-3 flex-content-hc font-weight-bold w-100">
              <div class="d-flex name-container text-center">
                <div *ngIf="isSubscribed" class="icon-subscribed-blue-container"></div>

                <div *ngIf="isSubscribed" class="icon-subscribed-blue-container flex-content-vc-hc">
                  <div class="icon subscribed-gold"></div>
                </div>
              </div>
            </div>

            <small class="username text-muted text-center">
              @{{ user.username }}
            </small>

            <div class="flex-content-hc mt-3">
              <div class="user-followers text-center" (click)="onShareClick(paths.USER)">
                <span class="text-big font-weight-bolder">0</span>

                <br>

                <span class="text-muted small">following</span>
              </div>

              <div class="user-followers text-center" (click)="onShareClick(paths.USER)">
                <span class="text-big font-weight-bolder">0</span>

                <br>

                <span class="text-muted small">followers</span>
              </div>

              <div class="user-followers text-center">
                <span class="text-big font-weight-bolder">{{ user.likesCount || 0 }}</span>

                <br>

                <span class="text-muted small">mmmm</span>
              </div>
            </div>

            <div class="m-3 px-3 text-center">
              {{ user.bio }}
            </div>

            <div *ngIf="posts.length > 0" class="mt-3 p-0 navbar-bottom-bg">
              <app-feed [posts]="posts" (postClick)="goToPost($event)" (scrolled)="onScroll()"></app-feed>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <app-navbar-bottom-user
      [user]="user"
      [isOwner]="isOwner"
      (chatClick)="onShareClick(paths.MESSAGE)"
      (imageUploaded)="goToPostNew($event)"
      (subscriptionModalOpen)="openMembershipModal()"
    ></app-navbar-bottom-user>

    <ng-template #shareModalRef let-c="close">
      <app-share-modal appModalCloseOnClick [path]="shareModalPath"></app-share-modal>
    </ng-template>

    <ng-template #membership let-c="close">
      <app-membership-modal
        [user]="user"
      ></app-membership-modal>
    </ng-template>
  </div>
</ng-container>

<ng-container *ngIf="!isMobile">
  <app-search-desktop-topnav [isUserPage]="true"></app-search-desktop-topnav>

  <div class="restaurant-desktop-container position-relative w-100 pt-58px">
    <div *ngIf="isSidenavLeftOrRightMouseOver" class="desktop-text pt-5 d-flex">
      <h1 class="text-white">{{ user.firstName }} {{ user.lastName }} @{{ user.username }}</h1>
    </div>

    <div [ngClass]="{'opacity-0' : isSidenavLeftOrRightMouseOver}" class="restaurant-desktop d-flex">
      <ng-container *ngIf="!loaderWasHide">
        <app-user-loader class="w-100 h-100"></app-user-loader>
      </ng-container>

      <div *ngIf="loaderWasHide" class="restaurant-desktop-main flex-fill">
        <div class="restaurant-desktop-header d-flex">
          <ng-container *ngIf="user">
            <div class="max-width-header px-0">
              <div class="card card-user pb-2rem content card-box-shadow bg-transparent">
                <div class="row">
                  <div class="col-4 user flex-content-hc">
                    <div class="mt-2rem user-avatar user-avatar-desktop rounded-circle">
                      <app-image-picker accept="gif, jpeg, png" (save)="onImageUploaded($event)">
                        <app-user-avatar
                          [user]="user"
                          [widthAndHeight]="400"
                          [isImageUploading]="isImageUploading"
                          [isImageLoadError]="isProfileImageLoadError"
                          [userPage]="true"
                          class="w-100 h-100"
                          (imageClick)="onImageClick()"
                        ></app-user-avatar>
                      </app-image-picker>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="mt-2-8rem px-3 flex-content-hc font-weight-bold w-100 text-white">
                      <div class="d-flex name-container name-container-desktop text-center">
                        <div *ngIf="isSubscribed" class="icon-subscribed-blue-container"></div>

                        <div [ngClass]="{'name-subscribed': isSubscribed}" class="text-overflow-crop">
                          <span>
                            {{ user.firstName }} {{ user.lastName }}
                          </span>
                        </div>

                        <div *ngIf="isSubscribed" class="icon-subscribed-blue-container flex-content-vc-hc">
                          <div class="icon subscribed-gold"></div>
                        </div>
                      </div>
                    </div>

                    <small class="flex-content-hc username text-muted text-center text-white">
                      @{{ user.username }}
                    </small>


                    <div class="flex-content-hc mt-4 text-white">
                      <div class="user-followers text-center cursor-pointer" (click)="onShareClickDesktop()">
                        <span class="text-big">0</span>

                        <br>

                        <span class="text-muted small">following</span>
                      </div>

                      <div class="user-followers text-center cursor-pointer" (click)="onShareClickDesktop()">
                        <span class="text-big">0</span>

                        <br>

                        <span class="text-muted small">followers</span>
                      </div>

                      <div class="user-followers text-center">
                        <span class="text-big">{{ user.likesCount || 0 }}</span>

                        <br>

                        <span class="text-muted small">mmmm</span>
                      </div>
                    </div>

                    <div class="mt-4 text-center text-white">
                      {{ user.bio }}
                    </div>
                  </div>

                  <div class="col-4"></div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="restaurant-desktop-content bg-white d-flex">
          <div class="max-width-feeds px-0">
            <div class="card card-user pb-2rem content card-box-shadow bg-transparent">
              <div *ngIf="posts.length > 0" class="p-0">
                <app-feed [posts]="posts" (postClick)="goToPostDesktop($event)"></app-feed>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #postDesktopModalRef>
  <app-post-desktop
    [posts]="posts"
    [currentPost]="currentPost"
    [user]="user"
    [username]="user.username"
  ></app-post-desktop>
</ng-template>
