<div class="d-flex">
  <div class="addition-tags">
    <div class="addition-header bg-secondary">{{ food.title }}</div>

    <div class="p-2">
      <app-tags-edit
        [food]="food"
        [tags]="tags"
        [showWarnings]="showWarnings"
        (save)="save.emit(true)"
      ></app-tags-edit>
    </div>
  </div>

  <div class="addition-options">
    <div class="addition-header bg-secondary">Sizes | Options</div>

    <div class="p-2">
      <app-options-edit
        [food]="food"
        [section]="size"
      ></app-options-edit>
    </div>

    <div>
      <button class="btn-more btn btn-secondary md-btn-flat" (click)="onMoreClick()">More</button>
    </div>
  </div>

  <ng-container *ngFor="let i of [0, 1, 2]">
    <div class="addition-options">
      <div class="addition-header bg-secondary">Combos | Extras</div>

      <div class="p-2">
        <app-options-edit
          [food]="food"
          [section]="options[i]"
        ></app-options-edit>
      </div>
    </div>
  </ng-container>
</div>
