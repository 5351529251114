import { Expose, Type } from 'class-transformer';
import { OrderSection } from './order-section';

export class OrderFood {
  @Expose() id: number;
  @Expose() title: string;
  @Expose() quantity: number;
  @Expose() instruction: string;
  @Expose() priceBaseTotal: number;

  @Type(() => OrderSection)
  @Expose() sections: OrderSection[] = [];
}
