import { Injectable } from '@angular/core';
import { HashUtils } from '../utils/hash-utils';
import Timeout = NodeJS.Timeout;

@Injectable({providedIn: 'root'})
export class TimeoutService {
  timeouts: Timeout[] = [];

  constructor() {
  }

  setTimeoutWithRelayRace(callback: () => void, timeInMilliseconds: number) {
    const hash = HashUtils.getCodePositionUniqueHash();

    if (this.timeouts[hash] !== undefined) {
      clearTimeout(this.timeouts[hash]);
    }

    this.timeouts[hash] = setTimeout(() => {
      return callback();
    }, timeInMilliseconds);
  }
}
