<ng-container *ngIf="isMobile">
  <app-navbar-top-post
    [restaurantPath]="restaurantPath"
    [username]="currentPost?.user.username"
    [restaurantTitle]="restaurantTitle"
    [editMode]="editMode"
    (cancelClick)="cancelMode()"
    (rightButtonClick)="rightButtonClick()"
  ></app-navbar-top-post>

  <div class="container max-width px-0">
    <div class="container-post bg-black">
      <ng-container *ngIf="posts.length > 0 && currentPost">
        <swiper
          [config]="swiperConfig"
          (slideChange)="slideChange($event)"
        >
          <ng-container *ngFor="let post of posts">
            <ng-template swiperSlide>
              <div
                class="post-body d-flex flex-column w-100 h-100 bg-black"
                data-id="{{post.id}}"
              >
                <div
                  [ngClass]="{'post-background-full': post.isFullScreen}"
                  class="post-background pt-44px"
                >
                  <ng-container *ngIf="post.images.length > 0">
                    <span class="position-absolute-0 z-index-10 post-bg-gradient"></span>

                    <img
                      *ngIf="post.images.length > 0"
                      [src]="post.images[0].path | imgix:{w: 720}"
                      class="w-100"
                    />
                  </ng-container>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </swiper>
      </ng-container>
    </div>
  </div>

  <div [ngClass]="{'post-gradient-darker': captionExpanded}" class="post-gradient d-flex flex-column max-width">
    <div *ngIf="editMode" [ngClass]="{'post-edit-dark': editMode}" class="post-edit flex-grow-1">
      <div *ngIf="currentPost.food" class="row mx-4 mt-3 mb-n2">
        <div class="col-11 text-white p-0 font-weight-500">
          {{ currentPost.food.title }}
        </div>
      </div>

      <div class="p-4">
        <quill-editor
          [(ngModel)]="captionEdited"
          theme="bubble"
          placeholder="Add caption..."
          (onEditorCreated)="setFocus($event)"
        >
          <div quill-editor-toolbar>
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
            </span>
          </div>
        </quill-editor>
      </div>
    </div>

    <ng-container *ngIf="!editMode">
      <div class="post-header"></div>

      <div class="post-actions-container max-width">
        <div *ngIf="currentPost?.id" class="post-actions text-white">
          <ng-container>
            <div *ngIf="!hideCaption" class="mx-4 font-weight-500 flex-content-vc">
              <span *ngIf="currentPost.userSubscribed">
                <i class="icon subscribed-white"></i>
              </span>
            </div>

            <div [ngClass]="{'mb-3': !currentPost.caption, 'mb-1': currentPost.caption}" class="mt-1 mx-4">
              <div class="post-caption cursor-pointer">
                <button
                  *ngIf="restaurantTitle"
                  class="btn btn-xs btn-default text-white food-btn pointer-events-all"
                  (click)="goToRestaurant()"
                >
                  {{ getTitle() }}
                </button>
              </div>
            </div>

            <div *ngIf="currentPost.caption" class="row mx-4 mb-3" [innerHTML]="currentPost.caption"></div>

            <div class="row m-0 pb-3">
              <div class="actions p-0 col-10">
                <div class="d-flex">
                  <div class="item item-share text-center pointer-events-all">
                    <span class="post-icon" (click)="onShareClick()">
                      <span class="icon-v2 direct"></span>
                    </span>
                  </div>

                  <div class="item item-comment text-center pointer-events-all">
                    <span class="post-icon" routerLink="/">
                      <span class="icon-v2 explore-white-post"></span>
                    </span>
                  </div>

                  <app-like
                    [object]="currentPost"
                    [verb]="getstreamVerb.POST"
                    class="like pointer-events-all"
                  ></app-like>

                  <div class="user-item rounded-circle pointer-events-all">
                    <app-user-avatar
                      [user]="currentPost.user"
                      [widthAndHeight]="100"
                      (imageClick)="goToUser(currentPost.user.username)"
                    ></app-user-avatar>
                  </div>
                </div>
              </div>

              <div class="px-3 pb-3 col-2 btn-more pointer-events-all text-default">
                <app-post-options
                  [post]="currentPost"
                  [postsMobile]="true"
                  [title]="currentPost.user.username"
                  (postEdit)="postEdit()"
                  (postDeleted)="postDeleted()"
                ></app-post-options>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
