<div class="m-1 row">
  <div *ngFor="let order of orders" class="col-sm-6 col-md-6 col-lg-6 col-xl-4 animate__animated animate__fadeIn">
    <div
      [ngClass]="{'mb-2': order.accepted || order.declined, 'mb-3': !order.accepted && !order.declined}"
      class="card mb-sm-4"
    >
      <div
        *ngIf="order.accepted || order.declined"
        class="order d-flex flex-row p-3 font-size-1rem"
        (click)="openOrdersModal(order)"
      >
        <div class="pl-3 py-1">
          <span class="font-weight-semibold">{{ order.hashIdNumber }}</span>
        </div>
        <div class="ml-2 py-1" style="width: 2rem;">
          <span *ngIf="order.accepted" class="badge badge-pill badge-default">{{ order.hashIdLetter }}</span>
        </div>
        <ng-container *ngIf="order.accepted">
          <div class="pl-3 py-1">
            <span>{{ (order.readyTime | date:'hh:mma') | meridiem }}</span>
          </div>
          <div class="pl-3 py-1">
            <span>{{ order.customerName }}</span>
          </div>
        </ng-container>
        <div *ngIf="order.declined" class="pl-3 py-1">Declined</div>
      </div>
      <div *ngIf="!order.accepted && !order.declined" class="order-details">
        <div class="px-4 pt-4 pb-3">
          <table>
            <ng-container *ngFor="let food of order.foods">
              <tr>
                <td class="order-item-quantity">
                  <span>{{ food.quantity }}</span>
                </td>
                <td>
                  <span>{{ food.title }}</span>
                </td>
                <td class="order-item-price text-right">
                  <span *ngIf="food.priceBaseTotal > 0">{{ food.priceBaseTotal | precision }}</span>
                </td>
              </tr>
              <ng-container *ngFor="let section of food.sections">
                <ng-container *ngIf="section.extras.length > 0">
                  <tr *ngFor="let extra of section.extras">
                    <td></td>
                    <td>
                      <span class="order-item-quantity d-inline-block">
                          <span *ngIf="extra.quantity > 1">{{ extra.quantity }}</span>
                      </span>
                      <span>{{ extra.name }}</span>
                    </td>
                    <td class="order-item-price text-right">
                      <span *ngIf="extra.showPrice && extra.total > 0">
                          {{ extra.total | precision }}
                      </span>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
              <tr *ngIf="food.instruction">
                <td class="text-danger" colspan="3">
                  {{ food.instruction }}
                </td>
              </tr>
            </ng-container>
          </table>
        </div>
        <hr class="m-0">
        <div class="px-4 py-3">
          <div class="row">
            <div class="col-6">
              Tax<br>
              Tip {{ order.tipPercentage }}%<br>
              Total
            </div>
            <div class="col-6 text-right">
              {{ order.salesTax | precision }}<br>
              {{ order.tip | precision }}<br>
              {{ order.totalWithoutFee | precision }}
            </div>
          </div>
        </div>
        <hr class="m-0">
        <div class="px-4 pt-3 pb-4">
          <div class="row">
            <div class="col-6">
              {{ (order.createdAt | date:'LL/dd - hh:mma') | meridiem }}<br>
              <ng-container *ngIf="!order.asap">
                {{ (order.requestTime | date:'LL/dd - hh:mma') | meridiem }}
              </ng-container>
              <ng-container *ngIf="order.asap">ASAP</ng-container>
            </div>
            <div class="col-6 text-right">
              {{ order.customerName }}<br>
              {{ order.phone | mask: '(000) 000-0000' }}
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-6 text-center">
              <app-picker
                [title]="'Accept'"
                [picker]="picker"
                [classes]="'btn btn-primary btn-block'"
                (selectedValue)="accept(order, $event)"
              ></app-picker>
            </div>
            <div class="col-6">
              <button type="button" class="btn btn-secondary btn-block" (click)="decline(order)">
                Decline
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #orderModalRef>
  <app-orders-modal [order]="orderAccepted"></app-orders-modal>
</ng-template>
