<div [ngClass]="{'p-2': !isCs}">
  <form [formGroup]="tagForm" (ngSubmit)="onSubmit()" class="form">
    <div [ngClass]="{'d-flex': !isCs, 'row': isCs}">
      <div [ngClass]="{'tags-column-left': !isCs, 'col-6': isCs}">
        <ng-container
          *ngFor="let tag of regularTagsForm.controls; let i = index;"
          formArrayName="regularTagsControl"
        >
          <ng-container *ngIf="regularTags[i].value !== 'spicy'">
            <div class="form-group">
              <label [ngClass]="{'switcher-sm': isCs}" class="switcher switcher-secondary mr-0">
                <input
                  *ngIf="!regularTags[i].exclusive"
                  [formControl]="tag"
                  type="checkbox"
                  class="switcher-input"
                  (change)="onSubmit()"
                />

                <input
                  *ngIf="regularTags[i].exclusive"
                  appRadioGroupNotRequired
                  [checked]="tag.value"
                  type="radio"
                  name="{{regularTags[i].tagType}}"
                  class="switcher-input"
                  (click)="setExclusiveTags($event, regularTagsForm, regularTags, regularTags[i].value, tag)"
                />

                <span class="switcher-indicator"></span>

                <span [ngClass]="{'text-white font-size-0-96rem': isCs}" class="switcher-label">
                  {{ regularTags[i].display }}
                </span>
              </label>
            </div>
          </ng-container>

          <ng-container *ngIf="regularTags[i].value === 'spicy' && regularTags[i].degree === 1">
            <div class="form-group form-inline">
              <label [ngClass]="{'switcher-sm': isCs}" class="switcher switcher-secondary mr-0">
                <input
                  [(ngModel)]="spicyCheckBox"
                  [ngModelOptions]="{standalone: true}"
                  type="checkbox"
                  class="switcher-input"
                  (ngModelChange)="spicyCheckBoxChanged()"
                  (change)="onSubmit()"
                />

                <span class="switcher-indicator"></span>

                <span [ngClass]="{'text-white font-size-0-96rem': isCs}" class="switcher-label">
                  <ng-container>{{ regularTags[i].display }}</ng-container>
                </span>
              </label>

              <select
                *ngIf="spicyCheckBox"
                [(ngModel)]="spicyDegree"
                [ngModelOptions]="{standalone: true}"
                [ngClass]="{
                  'form-control-spicy': !isCs,
                  'form-control-sm form-control-inverted font-size-0-96rem mb-0': isCs
                }"
                id="require"
                class="form-control ml-1 w-auto"
                (ngModelChange)="spicyDegreeChanged()"
              >
                <option *ngFor="let i of [1, 2, 3, 4, 5]" [ngValue]="i">{{ i }}</option>
              </select>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div [ngClass]="{'tags-column-right': !isCs, 'col-6': isCs}" class="">
        <ng-container
          *ngFor="let tag of tempTagsForm.controls; let i = index;"
          formArrayName="regularTagsControl"
        >
          <div *ngIf="tempTags[i]" class="form-group">
            <label [ngClass]="{'switcher-sm': isCs}" class="switcher switcher-secondary mr-0">
              <input
                *ngIf="!tempTags[i].exclusive"
                [formControl]="tempTagsForm.controls[i]"
                type="checkbox"
                class="switcher-input"
                (change)="onSubmit()"
              />

              <input
                *ngIf="tempTags[i].exclusive"
                appRadioGroupNotRequired
                [checked]="tag.value"
                type="radio"
                name="{{tempTags[i].tagType}}"
                class="switcher-input"
                (click)="setExclusiveTags($event, tempTagsForm, tempTags, tempTags[i].value, tag)"
              />

              <span class="switcher-indicator"></span>

              <span [ngClass]="{'text-white font-size-0-96rem': isCs}" class="switcher-label">
                {{ tempTags[i].display }}
              </span>
            </label>
          </div>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="showWarnings">
      <ng-container *ngFor="let warningTag of warningTags; let i = index; let even = even;">
        <div [ngClass]="{'d-flex': !isCs, 'row': isCs}" *ngIf="even">
          <ng-container *ngFor="let index of [i, i + 1];">
            <ng-container *ngIf="warningTags[index]; let tag">
              <div
                *ngIf="tag.value == 'allergens' || tag.value == 'adv' || allergensTagForm.value"
                [ngClass]="{
                  'tags-column-left': !isCs && index == i,
                  'tags-column-right': !isCs && index == i + 1,
                  'col-6': isCs
                }"
              >
                <div class="form-group">
                  <label [ngClass]="{'switcher-sm': isCs}" class="switcher switcher-secondary mr-0">
                    <input
                      [formControl]="warningTagsForm.controls[index]"
                      type="checkbox"
                      class="switcher-input"
                      (change)="onSubmit(tag)"
                    />
                    <span
                      class="switcher-indicator"
                      [ngClass]="{'switcher-indicator-red': !allergensTagForm.value && tag.value == 'allergens'}"
                    ></span>

                    <span [ngClass]="{'text-white font-size-0-96rem': isCs}" class="switcher-label">
                      <span *ngIf="!(tag.value == 'allergens' && allergensTagForm.value)">
                          {{ tag.display }}
                      </span>

                      <span *ngIf="tag.value == 'allergens' && allergensTagForm.value" class="text-success">
                          Enabled
                      </span>

                      <span *ngIf="tag.value === 'adv'">*</span>
                    </span>
                  </label>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </form>
</div>
