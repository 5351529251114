import { Component, OnInit } from '@angular/core';
import { NavigatorService } from '../../../services/navigator.service';

@Component({
  selector: 'app-navbar-top-gems',
  templateUrl: './navbar-top-gems.component.html',
  styleUrls: ['../navbar.shared.scss']
})
export class NavbarTopGemsComponent implements OnInit {
  constructor(private navigatorService: NavigatorService) {
  }

  ngOnInit() {
  }

  goBack() {
    this.navigatorService.goToHome();
  }
}
