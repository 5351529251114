import { Type } from 'class-transformer';
import { Food } from '../../domains/food';
import { OrderBase } from '../../domains/order/order-base';
import { Section } from '../../domains/section';
import { HashUtils } from '../../utils/hash-utils';
import { MathRound } from '../../utils/math-round';
import { SectionType } from '../enums/section-type';
import { BasketExtra } from './basket-extra';
import { BasketFood } from './basket-food';
import { BasketSection } from './basket-section';

export class Basket extends OrderBase {
  @Type(() => BasketFood)
  foods: BasketFood[] = [];

  quantity = 0;
  tipPercentage = 18;
  salesTaxPercentage = 8.75;
  salesTaxAndFee: number;
  pickUpEnabled = true;

  private calculateExtra(basketFood: BasketFood, basketSection: BasketSection, basketExtra: BasketExtra) {
    let priceTotal: number;

    if (basketSection.type === SectionType.SIZE) {
      priceTotal = basketExtra.price === 0 ? basketFood.price : basketExtra.price;
    } else {
      if (basketSection.min === 0) {
        priceTotal = basketSection.max > 1 ? basketExtra.price * basketExtra.quantity : basketExtra.price;
      } else if (basketSection.min === 1) {
        priceTotal = basketExtra.price;
      } else if (basketSection.min > 1) {
        priceTotal = basketExtra.price * basketExtra.quantity;
      } else {
        priceTotal = 0;
      }
    }

    basketExtra.priceTotal = MathRound.round10(priceTotal, -2);
    basketExtra.total = MathRound.round10(basketFood.quantity * basketExtra.priceTotal, -2);
  }

  private calculateSection(basketFood: BasketFood, basketSection: BasketSection) {
    let sectionSum = 0;

    basketSection.extras.forEach(basketExtra => {
      this.calculateExtra(basketFood, basketSection, basketExtra);
      sectionSum += basketExtra.priceTotal;
    });

    basketSection.total = MathRound.round10(sectionSum, -2);
  }

  private calculateFood(basketFood: BasketFood) {
    if (basketFood.hasSections) {
      basketFood.sections.forEach(basketSection => this.calculateSection(basketFood, basketSection));
    }

    const sectionSize = basketFood.sections.find(value => value.type === SectionType.SIZE);

    basketFood.priceBase = sectionSize === undefined ? basketFood.price : sectionSize.total;
    basketFood.priceBaseTotal = MathRound.round10(basketFood.quantity * basketFood.priceBase, -2);

    let total = basketFood.priceBaseTotal;

    basketFood.sections.forEach(section => {
      if (section.type !== SectionType.SIZE) {
        total += (basketFood.quantity * section.total);
      }
    });

    basketFood.total = MathRound.round10(total, -2);
  }

  private calculateFee() {
    let fee: number;
    const remain = MathRound.round10(this.totalWithoutFee - MathRound.floor10(this.totalWithoutFee, 0), -2);

    if (!this.feeEnabled || remain === 0 || remain === 0.5) {
      fee = 0;
    } else if (remain < 0.5) {
      fee = MathRound.round10(0.5 - remain, -2);
    } else {
      fee = MathRound.round10(1 - remain, -2);
    }

    return fee;
  }

  calculate() {
    this.foods.forEach(basketFoodI => this.calculateFood(basketFoodI));
    this.subtotal = MathRound.round10(this.foods.reduce((sum, basketFood) => sum + basketFood.total, 0), -2);
    this.tip = MathRound.round10((this.subtotal / 100) * this.tipPercentage, -2);
    this.salesTax = MathRound.round10((this.subtotal / 100) * this.salesTaxPercentage, -2);
    this.totalWithoutFee = MathRound.round10(this.subtotal + this.tip + this.salesTax, -2);
    this.fee = this.calculateFee();
    this.salesTaxAndFee = MathRound.round10(this.salesTax + this.fee, -2);
    this.total = MathRound.round10(this.totalWithoutFee + (this.feeEnabled ? this.fee : 0), -2);
  }

  isEmpty() {
    return this.quantity === 0 && this.subtotal === 0;
  }

  addFood(food: Food, categoryId) {
    const sections: Section[] = [];

    let basketFoodIndex = `c${categoryId}:f${food.id}`;

    let canAdd: boolean;

    if (food.hasSections) {
      food.sections.forEach((section: Section) => {
        let sectionExists = false;

        const extraIds = section.manager.getExtrasIds();

        if (extraIds.length > 0) {
          canAdd = true;
          sectionExists = true;
          basketFoodIndex += `:sc${section.id}(${extraIds.join('-')})`;
        }

        if (sectionExists) {
          sections.push(section);
        }

        canAdd = true;
      });
    } else {
      canAdd = true;
    }

    const basketFoodHash = HashUtils.getHashFromString(basketFoodIndex);

    if (canAdd) {
      this.quantity++;

      let basketFood = this.foods.find(basketFoodI => basketFoodI.hash === basketFoodHash);

      if (basketFood === undefined) {
        basketFood = new BasketFood();

        basketFood.id = food.id;
        basketFood.hash = basketFoodHash;

        if (food.hasSections) {
          sections.forEach(section => {
            const basketSection = new BasketSection();
            basketSection.id = section.id;
            basketSection.min = section.min;
            basketSection.max = section.max;
            basketSection.type = section.type;

            section.selected.forEach(selectedIndex => basketSection.selected.push(selectedIndex));

            const extras = section.manager.getExtras();

            if (extras.length > 0) {
              extras.forEach(extraItered => {
                const basketExtra = new BasketExtra();

                basketExtra.id = extraItered.id;
                basketExtra.name = extraItered.name;
                basketExtra.price = extraItered.price;
                basketExtra.quantity = extraItered.quantity;
                basketExtra.showPrice = section.type !== SectionType.SIZE;

                basketSection.extras.push(basketExtra);
              });
            }

            basketFood.sections.push(basketSection);
          });
        }

        basketFood.title = food.title;
        basketFood.price = food.price;

        this.foods.push(basketFood);
      } else {
        basketFood.quantity++;
      }

      this.calculate();
    }
  }

  removeFood(basketFood: BasketFood) {
    const index = this.foods.indexOf(basketFood);

    if (index > -1) {
      this.foods.splice(index, 1);

      this.quantity -= basketFood.quantity;

      this.calculate();
    }
  }

  removeExtra(basketFood: BasketFood, section: BasketSection, extra: BasketExtra) {
    const sectionIndex = basketFood.sections.indexOf(section);

    if (sectionIndex !== -1) {
      const extraIndex = section.extras.indexOf(extra);

      if (extraIndex !== -1) {
        section.extras.splice(extraIndex, 1);

        this.calculate();
      }
    }
  }

  clear() {
    this.foods = [];
    this.quantity = 0;
    this.pickUpEnabled = false;
    this.feeEnabled = true;
    this.asap = false;
    this.requestTime = null;
    this.phone = null;

    this.calculate();
  }
}
