import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { IsDevice } from '../../../../decorators/is-device.decorator';
import { ModalClose } from '../../../../decorators/modal-close.decorator';
import { Restaurant } from '../../../../domains/restaurant';
import { TypeCategory } from '../../../../domains/type-category';
import { TypeCategorySimple } from '../../../../domains/type-category-simple';
import { DeviceType } from '../../../../models/enums/device-type';
import { LoaderService } from '../../../../services/loader.service';
import { NotificationService } from '../../../../services/notification.service';
import { RestaurantService } from '../../../../services/restaurant.service';
import { TypeCategoryService } from '../../../../services/type-category.service';

@Component({
  selector: 'app-restaurant-add-modal',
  templateUrl: './restaurant-add-modal.component.html',
  styleUrls: ['./restaurant-add-modal.component.scss', './../add-rest-video-report.component.scss']
})
export class RestaurantAddModalComponent implements OnInit {
  @Output() restaurantCreated = new EventEmitter<Restaurant>();

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  submitClicked = false;
  typeCategories: TypeCategorySimple[] = [];
  restaurantNewForm: UntypedFormGroup;
  secondNamePlaceholder = 'Bar & Belly';
  namePlaceholder = 'Golden Jon\'s';
  restaurantTypeCategory: TypeCategorySimple;

  constructor(
    private typeCategoryService: TypeCategoryService,
    private logger: NGXLogger,
    private restaurantService: RestaurantService,
    private notificationService: NotificationService,
    private loaderService: LoaderService
  ) {
    this.typeCategoryService
      .getAll()
      .subscribe({
        next: typeCategories => {
          this.typeCategories = typeCategories;
        }, error: error => {
          this.logger.error('On getting type categories', error);
        }
      });
  }

  ngOnInit(): void {
    this.restaurantNewForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      secondName: new UntypedFormControl(''),
      address: new UntypedFormControl(null, [Validators.required]),
      zipCode: new UntypedFormControl(null, [Validators.required]),
      email: new UntypedFormControl(null, [Validators.email]),
      phone: new UntypedFormControl(null),
      website: new UntypedFormControl(null, [
        Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')
      ]),
      category: new UntypedFormControl('', [Validators.required]),
      instagram: new UntypedFormControl(null, [Validators.pattern('^[a-zA-Z0-9._]+$')]),
      accessEmail: new UntypedFormControl(false)
    });

    this.name.valueChanges.subscribe(() => {
      this.secondNamePlaceholder = this.name.value === '' ? this.secondNamePlaceholder : '';
    });
  }

  get name() {
    return this.restaurantNewForm.get('name');
  }

  get secondName() {
    return this.restaurantNewForm.get('secondName');
  }

  get zipCode() {
    return this.restaurantNewForm.get('zipCode');
  }

  get address() {
    return this.restaurantNewForm.get('address');
  }

  get phone() {
    return this.restaurantNewForm.get('phone');
  }

  get email() {
    return this.restaurantNewForm.get('email');
  }

  get website() {
    return this.restaurantNewForm.get('website');
  }

  get instagram() {
    return this.restaurantNewForm.get('instagram');
  }

  get category() {
    return this.restaurantNewForm.get('category');
  }

  get accessEmail() {
    return this.restaurantNewForm.get('accessEmail');
  }

  onSubmit() {
    this.submitClicked = true;

    if (this.restaurantNewForm.invalid) {
      return;
    }

    this.loaderService.showLoader();

    const restaurant = new Restaurant();

    restaurant.typeCategory = new TypeCategory();

    if (this.phone.value) {
      restaurant.internationalPhoneNumber = this.phone.value;
    }

    restaurant.name = this.name.value;
    restaurant.secondName = this.secondName.value;
    restaurant.address = this.address.value;
    restaurant.zipCode = this.zipCode.value;
    restaurant.email = this.email.value;
    restaurant.website = this.website.value;
    restaurant.instagram = this.instagram.value;
    restaurant.typeCategory.id = this.restaurantTypeCategory.id;
    restaurant.hostname = this.website.value ? this.website.value.toLowerCase() : null;
    restaurant.published = this.accessEmail.value;

    this.restaurantService.addToReview(restaurant)
      .subscribe({
        next: () => {
          this.loaderService.hideLoader();
          this.notificationService.addRestaurant();
        }, error: error => {
          this.logger.error(`On creating restaurant with id: ${restaurant.id}`, error);
        }
      })
      .add(() => {
        this.closeModal();
      });
  }

  get secondNamePreview() {
    if (this.secondName.value) {
      return this.secondName.value;
    }

    if (this.name.value) {
      return '';
    }

    return this.secondNamePlaceholder;
  }

  hidePlaceholder() {
    this.secondNamePlaceholder = '';
    this.namePlaceholder = '';
  }

  @ModalClose()
  closeModal() {
  }

  onTypeCategoryChange(typeCategory) {
    this.restaurantTypeCategory = typeCategory;
  }
}
