import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Chain } from '../domains/chain';
import { CityWithChild } from '../domains/city-with-child';
import { CountryWithChild } from '../domains/country-with-child';
import { CountyWithChild } from '../domains/county-with-child';
import { District } from '../domains/district';
import { RegionWithChild } from '../domains/region-with-child';
import { StateWithChild } from '../domains/state-with-child';
import { TopFood } from '../domains/top-foods';
import { LocalStorage } from '../local-storage/local-storage';
import { LocalStorageKey } from '../models/enums/local-storage-key';
import { GeoLocation } from '../models/geo-location';
import { SearchParams } from '../models/search-params';
import { SearchResult } from '../models/search-result';
import { SeoService } from './seo.service';

@Injectable({providedIn: 'root'})
export class ExploreService {
  private readonly exploreUrl: string;

  searchParamsLocalStorage = new LocalStorage(SearchParams, LocalStorageKey.SEARCH_PARAMS);
  searchResultSubject = new BehaviorSubject<SearchResult>(null);

  constructor(
    private http: HttpClient,
    private seoService: SeoService,
  ) {
    this.exploreUrl = `${environment.apiEndpoint}/api/explore`;
  }

  public getLocations(searchParams: SearchParams = null) {
    let httpParams = new HttpParams();

    if (searchParams) {
      if (searchParams.stateId) {
        httpParams = httpParams.set('state-id', searchParams.stateId);

        if (searchParams.countyId) {
          httpParams = httpParams.set('county-id', searchParams.countyId);

          if (searchParams.regionId) {
            httpParams = httpParams.set('region-id', searchParams.regionId);
          }

          if (searchParams.cityId) {
            httpParams = httpParams.set('city-id', searchParams.cityId);
          }

          if (searchParams.districtId) {
            httpParams = httpParams.set('district-id', searchParams.districtId);
          }
        }
      }
    }

    return this.http
      .get<Array<CountryWithChild>>(`${this.exploreUrl}/countries`, {params: httpParams})
      .pipe(map(value => plainToClass(CountryWithChild, value)));
  }

  public getCountiesByStateId(stateId: number) {
    return this.http
      .get<Array<CountyWithChild>>(`${this.exploreUrl}/state/${stateId}/counties`)
      .pipe(map(value => plainToClass(CountyWithChild, value)));
  }

  public getCountyRegions(countyId: number) {
    return this.http
      .get<RegionWithChild[]>(`${this.exploreUrl}/county/${countyId}/regions`)
      .pipe(map(value => plainToClass(RegionWithChild, value)));
  }

  public getCitiesByCountyId(countyId: number) {
    return this.http
      .get<CityWithChild[]>(`${this.exploreUrl}/county/${countyId}/cities`)
      .pipe(map(value => plainToClass(CityWithChild, value)));
  }

  public getCitiesByRegionId(regionId: number) {
    return this.http
      .get<CityWithChild[]>(`${this.exploreUrl}/region/${regionId}/cities`)
      .pipe(map(value => plainToClass(CityWithChild, value)));
  }

  public getDistrictsByCityId(cityId: number) {
    return this.http
      .get<District[]>(`${this.exploreUrl}/city/${cityId}/districts`)
      .pipe(map(value => plainToClass(District, value)));
  }

  public search(searchParams: SearchParams = null) {
    if (!searchParams) {
      searchParams = this.searchParamsLocalStorage.getItem();
    }

    return this.http
      .get<SearchResult>(
        `${this.exploreUrl}/search`,
        {params: {'search-params': JSON.stringify(searchParams)}}
      )
      .pipe(
        map(value => plainToClass(SearchResult, value)),
        map(searchResult => {
          searchResult.init();

          return searchResult;
        })
      );
  }

  public getChains(typeCategoryIds: [number], isCity: boolean, entityId: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('tc-ids', typeCategoryIds.join(','));
    httpParams = httpParams.set('is-c', isCity ? '1' : '0');
    httpParams = httpParams.set('id', entityId.toString());

    return this.http
      .get<Chain[]>(`${this.exploreUrl}/chains`, {params: httpParams})
      .pipe(map(value => plainToClass(Chain, value)));
  }

  public getTopFoods(restaurantId: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('restaurant-id', restaurantId.toString());

    return this.http
      .get<Chain[]>(`${this.exploreUrl}/top-foods`, {params: httpParams})
      .pipe(map(value => plainToClass(TopFood, value)));
  }

  getExplorePath(
    state: StateWithChild,
    county: CountyWithChild,
    region: RegionWithChild,
    city: CityWithChild,
    district: District,
    geoLocation: GeoLocation = null
  ) {
    this.seoService.setExploreMetaTags(state, county, region, city, district);

    let url = `/${state.key.toLowerCase()}/${county.slug}`;

    if (region) {
      url += `/r/${region.slug}`;
    }

    if (city) {
      url += `/${city.slug}`;
    }

    if (district) {
      url += `/${district.slug}`;
    }

    if (geoLocation) {
      url += `/lat/${geoLocation.latitude}/lon/${geoLocation.longitude}`;
    }

    url += '/restaurants';

    return url;
  }
}
