import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { classToPlain, plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Category } from '../domains/category';
import { Food } from '../domains/food';
import { Menu } from '../domains/menu';
import { Restaurant } from '../domains/restaurant';
import { CategoryType } from '../models/enums/category-type';
import { MenuType } from '../models/enums/menu-type';
import { SectionType } from '../models/enums/section-type';
import { SectionButtons } from '../models/sections/section-buttons';
import { SectionCheckbox } from '../models/sections/section-checkbox';
import { SectionRadio } from '../models/sections/section-radio';
import { Types } from '../models/types';

@Injectable({providedIn: 'root'})
export class MenuService {
  private readonly menuUrl: string;

  constructor(
    private http: HttpClient
  ) {
    this.menuUrl = `${environment.apiEndpoint}/api/menus`;
  }

  getMenus(restaurant: Restaurant) {
    const menus = restaurant.menus.filter(menu => menu.categories.length > 0);

    let menuList = [];

    menus.forEach(menu => {
      menuList.push(this.getMenu(restaurant, menu));
    });

    menuList = menuList.filter(menuView => menuView.referenceId === null);

    return menuList;
  }

  getMenu(restaurant: Restaurant, menu: Menu) {
    menu.categories = menu.categories.filter(category => !category.hidden && category.foods.length > 0);

    menu.categories.forEach(category => {
      category.foods = category.foods.filter(food => food.id >= 0 && !food.status.isHidden);

      category.foods.forEach((food, index) => {
        food.sections.forEach(section => {
          section.categoryId = category.id;
          section.foodId = food.id;

          section.extras = section.extras.filter(extra => extra.name !== null && extra.name !== '');

          section.extrasSortAndReorder();

          if (section.type === SectionType.OPTION) {
            if (section.min === 0) {
              if (section.buttons) {
                section.manager = new SectionButtons(section);
              } else {
                section.manager = new SectionCheckbox(section);
              }
            } else if (section.min === 1) {
              section.manager = new SectionRadio(section);
            } else if (section.min > 1) {
              section.manager = new SectionButtons(section);
            } else {
              section.manager = new SectionRadio(section);
            }
          } else {
            section.manager = new SectionRadio(section);
          }
        });

        food.sections = food.sections.filter(section => section.extras.length > 0);

        food.sectionsSortAndReorder();

        category.originalFoods.push(food);
      });

      menu.originalCategories.push(category);
    });

    if (menu.referredId && !menu.referredTitleIsAdded) {
      const referred = restaurant.menus.find(menuI => menuI.id === menu.referredId);

      if (referred) {
        menu.title = menu.title + ' | ' + referred.title;
        menu.referredTitleIsAdded = true;
      }
    }

    return menu;
  }

  public addMenu(menu: Menu) {
    const menuObject = classToPlain(menu);

    return this.http.post<Menu>(this.menuUrl, menuObject).pipe();
  }

  public editMenu(menu: Menu) {
    const menuObject = classToPlain(menu);

    return this.http.put(`${this.menuUrl}`, menuObject).pipe();
  }

  public deleteMenu(menu: Menu) {
    return this.http.delete(`${this.menuUrl}/${menu.id}`);
  }

  public getFromRedisByMenuId(menuId: number) {
    return this.http
      .get<Menu>(`${this.menuUrl}/redis/${menuId}`)
      .pipe(map(value => plainToClass(Menu, value)))
      .pipe(map(menu => {
        if (menu) {
          if (menu.categories && menu.categories.length > 0) {
            menu.categories.forEach(category => {
              if (category.foods) {
                category.foods.forEach(food => {
                  if (food.sections) {
                    food.sectionsSortAndReorder();

                    food.sections.forEach(section => {
                      section.extrasSortAndReorder();
                    });
                  }
                });
              }
            });
          } else {
            if (menu.title !== 'Drink') {
              this.addCategory(menu, new Types(CategoryType.Standard, null), true);
            }
          }
        }

        return menu;
      }));
  }

  addCategory(menu: Menu, types: Types, isCs: boolean): Category {
    const category = new Category();
    category.itemCount = isCs ? 7 : 3;
    category.type = types.categoryType;
    category.drinkType = types.drinkType;

    category.voluntary = isCs;

    for (let i = 0; i < category.itemCount; i++) {
      const food = new Food();
      food.ordering = i + 1;
      category.foods.push(food);
    }

    let ordering: number;

    if (!menu.categories) {
      ordering = 1;
      menu.categories = [];
    } else {
      ordering = Math.max.apply(Math, [0].concat(menu.categories.map(value => value.ordering))) + 1;
    }

    category.ordering = ordering;
    menu.categories.push(category);

    return category;
  }

  public hasChangesInRedis() {
    return this.http.get<Array<number>>(`${this.menuUrl}/has-changes`);
  }

  public setToRedis(menu: Menu) {
    return this.http.post(`${this.menuUrl}/redis`, menu);
  }

  setFilteredMenus(menusAll: Menu[], enabledMenuTypes: MenuType[]) {
    return menusAll.filter(menu => {
      const restaurantMenus = enabledMenuTypes
        .filter(enabledMenuType => {
          return enabledMenuType === MenuType.ALL_DAY && menu.menuType.isLunch() ||
            enabledMenuType === menu.menuType.value;
        });

      return restaurantMenus.length > 0 ? true : null;
    });
  }
}
