import { ClassType } from 'class-transformer/ClassTransformer';
import { WrapperInterface } from './wrapper.interface';

export class Wrapper {
  static toPlain(obj: WrapperInterface, classType: ClassType<WrapperInterface>): string {
    return obj instanceof classType ? obj.value :  obj;
  }

  static toClass(obj: object, classType: ClassType<WrapperInterface>): WrapperInterface {
    return obj instanceof classType ? obj : new classType(obj);
  }
}
