import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ExploreComponent } from '../components/explore/explore.component';
import { LayoutAdminLocationComponent } from '../components/layout/layout-main/layout-admin/layout-admin-location/layout-admin-location.component';
import { LayoutAdminComponent } from '../components/layout/layout-main/layout-admin/layout-admin.component';
import { LayoutEntityComponent } from '../components/layout/layout-main/layout-front/layout-entity/layout-entity.component';
import { LayoutFoodComponent } from '../components/layout/layout-main/layout-front/layout-entity/layout-restaurant/layout-menu/layout-food/layout-food.component';
import { LayoutMenuComponent } from '../components/layout/layout-main/layout-front/layout-entity/layout-restaurant/layout-menu/layout-menu.component';
import { LayoutRestaurantLocationComponent } from '../components/layout/layout-main/layout-front/layout-entity/layout-restaurant/layout-restaurant-location/layout-restaurant-location.component';
import { LayoutRestaurantComponent } from '../components/layout/layout-main/layout-front/layout-entity/layout-restaurant/layout-restaurant.component';
import { LayoutUserComponent } from '../components/layout/layout-main/layout-front/layout-entity/layout-user/layout-user.component';
import { LayoutFrontComponent } from '../components/layout/layout-main/layout-front/layout-front.component';
import { LayoutMainComponent } from '../components/layout/layout-main/layout-main.component';
import { Restaurant } from '../domains/restaurant';
import { RouteHelper } from '../helpers/route-helper';
import { ExploreRouteParams } from '../models/route-params/explore-route-params';
import { RestaurantRouteParams } from '../models/route-params/restaurant-route-params';
import { UserRouteParams } from '../models/route-params/user-route-params';
import { RestaurantService } from './restaurant.service';

@Injectable({providedIn: 'root'})
export class RouterService {
  private restaurantSubjectSubscription: Subscription;

  navigationEnd = new BehaviorSubject<NavigationEnd>(null);

  activatedRouteSnapshotSubject = new BehaviorSubject<ActivatedRouteSnapshot>(null);
  exploreRouteParamsSubject = new BehaviorSubject<ExploreRouteParams>(null);
  restaurantRouteParamsSubject = new BehaviorSubject<RestaurantRouteParams>(null);
  userRouteParamsSubject = new BehaviorSubject<UserRouteParams>(null);
  restaurantHostnameSubject = new BehaviorSubject<string>(null);
  restaurantLocationIdSubject = new BehaviorSubject<number>(null);
  restaurantSubject = new BehaviorSubject<Restaurant>(null);
  urlSubject = new BehaviorSubject<string>(null);

  exploreRouteParams: ExploreRouteParams;
  restaurantRouteParams: RestaurantRouteParams;
  userRouteParams: UserRouteParams;
  restaurantHostname: string;
  restaurantLocationId: number;

  restaurantBasePath: string;

  constructor(
    private router: Router,
    private _location: Location,
    private restaurantService: RestaurantService
  ) {
    this.exploreRouteParamsSubject.subscribe(exploreRouteParams => {
      this.exploreRouteParams = exploreRouteParams;
    });

    this.restaurantRouteParamsSubject.subscribe(restaurantRouteParams => {
      this.restaurantRouteParams = restaurantRouteParams;
    });

    this.userRouteParamsSubject.subscribe(userRouteParams => {
      this.userRouteParams = userRouteParams;
    });

    this.restaurantHostnameSubject.subscribe(hostname => {
      this.restaurantHostname = hostname;
    });

    this.restaurantLocationIdSubject.subscribe(locationId => {
      this.restaurantLocationId = locationId;
    });

    this._location.onUrlChange((url, state) => {
      this.urlSubject.next(url.split('?')[0]);
    });

    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        const layoutMainRoute = event.snapshot;

        if (layoutMainRoute.component === LayoutMainComponent) {
          let exploreRouteParams = null;
          let restaurantRouteParams = null;
          let userRouteParams = null;
          let restaurantHostname = null;
          let restaurantLocationId = null;
          this.restaurantBasePath = null;

          const layoutMainChildRoute = layoutMainRoute.firstChild;

          switch (layoutMainChildRoute.component) {
            case LayoutFrontComponent:
              const layoutFrontChildRoute = layoutMainChildRoute.firstChild;

              switch (layoutFrontChildRoute.component) {
                case ExploreComponent:
                  exploreRouteParams = new ExploreRouteParams(layoutFrontChildRoute);

                  break;

                case LayoutEntityComponent:
                  const layoutEntityChild = layoutFrontChildRoute.firstChild;

                  if (layoutEntityChild.component === LayoutRestaurantComponent) {
                    restaurantRouteParams = this.getRestaurantRouteParams(layoutEntityChild);
                    restaurantHostname = restaurantRouteParams.hostname;
                    restaurantLocationId = restaurantRouteParams.locationId;
                  }

                  if (layoutEntityChild.component === LayoutUserComponent) {
                    userRouteParams = new UserRouteParams();
                    userRouteParams.set(layoutEntityChild.params);
                  }

                  break;
              }

              break;

            case LayoutAdminComponent:
              restaurantHostname = layoutMainChildRoute.params['hostname'];

              const layoutAdminChildRoute = layoutMainChildRoute.firstChild;

              if (layoutAdminChildRoute.component === LayoutAdminLocationComponent) {
                restaurantLocationId = +layoutAdminChildRoute.params['locationId'];
              }

              break;
          }

          if (restaurantHostname) {
            this.restaurantBasePath = '/' + restaurantHostname;
          }

          if (restaurantLocationId) {
            this.restaurantBasePath += '/' + restaurantLocationId;
          }

          this.activatedRouteSnapshotSubject.next(RouteHelper.getRouteSnapshotLastChild(layoutMainRoute));
          this.exploreRouteParamsSubject.next(exploreRouteParams);
          this.restaurantRouteParamsSubject.next(restaurantRouteParams);
          this.userRouteParamsSubject.next(userRouteParams);

          this.setRestaurant(restaurantHostname, restaurantLocationId);
        }
      } else if (event instanceof NavigationEnd) {
        this.navigationEnd.next(event);
      }
    });
  }

  private getRestaurantRouteParams(layoutRestaurant) {
    const restaurantRouteParams = new RestaurantRouteParams();

    const restaurantHostname = layoutRestaurant.params['hostname'].split('?')[0];
    restaurantRouteParams.hostname = restaurantHostname;
    restaurantRouteParams.restaurantPath = '/' + restaurantHostname;

    const layoutRestaurantLocationChild = layoutRestaurant.firstChild;

    if (layoutRestaurantLocationChild.component === LayoutRestaurantLocationComponent) {
      const restaurantLocationId = +layoutRestaurantLocationChild.params['locationId'];
      restaurantRouteParams.locationId = restaurantLocationId;
      restaurantRouteParams.restaurantPath += '/' + restaurantLocationId;
    }

    const layoutRestaurantChild = layoutRestaurantLocationChild.firstChild;

    if (layoutRestaurantChild.component === LayoutMenuComponent) {
      restaurantRouteParams.menuSlug = layoutRestaurantChild.params['menu'];

      const layoutMenuChild = layoutRestaurantChild.firstChild;

      if (layoutMenuChild.component === LayoutFoodComponent) {
        restaurantRouteParams.setFood(layoutMenuChild.params['food']);
      }
    }

    return restaurantRouteParams;
  }

  setRestaurant(hostname: string, locationId: number) {
    this.restaurantHostnameSubject.next(hostname);
    this.restaurantLocationIdSubject.next(locationId);

    this.restaurantSubjectSubscription?.unsubscribe();

    if (!hostname) {
      this.restaurantSubject.next(null);
    } else {
      this.restaurantSubjectSubscription = this.restaurantService.getRestaurant(hostname, locationId)
        .subscribe(restaurant => {
          this.restaurantSubject.next(restaurant);
        });
    }
  }

  setRestaurantHostnameAndLocationId(hostname: string, locationId: number) {
    this.restaurantHostnameSubject.next(hostname);
    this.restaurantLocationIdSubject.next(locationId);
  }
}
