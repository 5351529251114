import { classToPlain } from 'class-transformer';
import { LocalStorageOptions } from '../models/local-storage-options';

export class BaseLocalStorage {
  key: string;

  constructor(
    localStorageKey: string,
    options: LocalStorageOptions = {}
  ) {
    let key = '';

    if (options?.hasOwnProperty('hostname') && options['hostname']) {
      key += options['hostname'] + '_';

      if (options?.hasOwnProperty('locationId')) {
        key += (options['locationId'] || '1') + '_';
      }
    }

    if (options?.hasOwnProperty('userId') && options['userId']) {
      key += 'U' + options['userId'] + '_';
    }

    this.key = key + localStorageKey;
  }

  setItem(value: any) {
    localStorage.setItem(this.key, value);
  }

  getItem(): any {
    return localStorage.getItem(this.key);
  }

  removeItem() {
    localStorage.removeItem(this.key);
  }

  protected setObject(value: any) {
    localStorage.setItem(this.key, JSON.stringify(classToPlain(value)));
  }

  protected getObject(): object | object[] {
    const object = localStorage.getItem(this.key);

    return object ? JSON.parse(object) : object;
  }
}
