import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-navbar-bottom-semicircle-notch',
  templateUrl: './navbar-bottom-semicircle-notch.component.html',
  styleUrls: ['./navbar-bottom-semicircle-notch.component.scss']
})
export class NavbarBottomSemicircleNotchComponent implements OnInit {
  @Output() menuClick = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onMenuClick() {
    this.menuClick.emit(true);
  }
}
