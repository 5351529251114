import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Auth } from '../../decorators/auth.decorator';
import { IsDevice } from '../../decorators/is-device.decorator';
import { Hero } from '../../domains/hero';
import { Like } from '../../domains/like';
import { Post } from '../../domains/post';
import { User } from '../../domains/user';
import { DeviceType } from '../../models/enums/device-type';
import { GetstreamVerb } from '../../models/enums/getstream-verb';
import { LikeService } from '../../services/like.service';

@Component({
  selector: 'app-like',
  templateUrl: 'like.component.html',
  styleUrls: ['like.component.scss']
})
export class LikeComponent implements OnInit, OnChanges {
  @Input() object: Post | Hero;
  @Input() verb: GetstreamVerb;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  @Output() ready = new EventEmitter<boolean>();

  @ViewChild('mmmmButtonRef') mmmmButtonRef: ElementRef;

  user: User;
  delay = 500;
  clickTime = 0;
  clickSound: any;

  constructor(
    private likeService: LikeService
  ) {
    this.clickSound = new Audio(environment.mmmGirlSound);
  }

  ngOnInit(): void {
  }

  @Auth()
  onLikeClicked() {
    const time = new Date().getTime();

    if (time - this.clickTime < this.delay) {
      return;
    }

    this.clickTime = time;
    this.object.currentUserLiked = !this.object.currentUserLiked;
    this.object.likeCount += this.object.currentUserLiked ? 1 : -1;

    if (this.object.currentUserLiked) {
      this.mmmmButtonRef.nativeElement.classList.add('pulsate-fwd');

      this.clickSound.play().then();
    } else {
      this.mmmmButtonRef.nativeElement.classList.remove('pulsate-fwd');
    }

    if (this.object.likeCount < 0) {
      this.object.likeCount = 0;
    }

    const like = new Like();

    like.user = this.user;
    like.getstreamId = this.object.getstreamId;

    this.likeService.like(like, this.verb, this.object.currentUserLiked).subscribe(() => {
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('object')) {
      this.object = changes.object.currentValue;

      this.mmmmButtonRef?.nativeElement.classList.remove('pulsate-fwd');
    }
  }
}
