import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Auth } from '../../../../../decorators/auth.decorator';
import { CurrentRestaurant } from '../../../../../decorators/current-restaurant.decorator';
import { CurrentUser } from '../../../../../decorators/current-user.decorator';
import { Subscribe } from '../../../../../decorators/subscribe.decorator';
import { Restaurant } from '../../../../../domains/restaurant';
import { User } from '../../../../../domains/user';
import { UnsplashImage } from '../../../../../models/unsplash-image';
import { UnsplashService } from '../../../../../services/unsplash.service';

@Component({
  selector: 'app-add-to-home-screen-modal',
  templateUrl: './add-to-home-screen-modal.component.html',
  styleUrls: ['add-to-home-screen-modal.component.scss']
})
export class AddToHomeScreenModalComponent implements OnInit {
  @ViewChild('membership') membershipModalRef: ElementRef;

  @CurrentUser() currentUser: User;
  @CurrentRestaurant() currentRestaurant: Restaurant;

  backgroundImage: UnsplashImage;

  constructor(
    private unsplashService: UnsplashService,
  ) {
  }

  ngOnInit(): void {
    this.unsplashService.randomPhoto(true).subscribe(value => {
      this.backgroundImage = value;
    });
  }

  @Auth()
  @Subscribe()
  openMembershipModal() {
  }
}
