import { Component, Input, OnInit } from '@angular/core';
import { Extra } from '../../../../../domains/extra';
import { Section } from '../../../../../domains/section';

@Component({
  selector: 'app-section-buttons',
  templateUrl: './section-buttons.component.html',
  styleUrls: ['../section-view.component.scss']
})
export class SectionButtonsComponent implements OnInit {
  @Input() section: Section;

  constructor() {
  }

  ngOnInit() {
  }

  extraAdd(extra: Extra) {
    extra.quantity++;
  }

  extraRemove(extra: Extra) {
    extra.quantity--;
  }
}
