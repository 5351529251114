import { Component, Input, OnInit } from '@angular/core';
import { IsDevice } from '../../../../../../decorators/is-device.decorator';
import { Food } from '../../../../../../domains/food';
import { Restaurant } from '../../../../../../domains/restaurant';
import { Tag } from '../../../../../../domains/tag';
import { DeviceType } from '../../../../../../models/enums/device-type';
import { NotificationService } from '../../../../../../services/notification.service';
import { ShareService } from '../../../../../../services/share.service';

@Component({
  selector: 'app-allergens',
  templateUrl: './allergens.component.html',
  styleUrls: ['./allergens.component.scss']
})
export class AllergensComponent implements OnInit {
  @Input() food: Food;
  @Input() allergens: Tag[];
  @Input() restaurant: Restaurant;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  constructor(
    private notificationService: NotificationService,
    private shareService: ShareService,
  ) {
  }

  ngOnInit() {
  }

  notReadyClicked() {
    this.notificationService.temporaryToast();
  }

  shareFood(social: string = null) {
    this.shareService.shareFood(this.restaurant, this.food, social);
  }

  copyLink() {
    navigator.clipboard.writeText(`${location.href}`);
    this.notificationService.showCopied();
  }

  onAllergensClicked() {
    this.notificationService.onAllergensClicked();
  }
}
