export class DateTransform {
  static datePlus(dateInput: Date, days: number = 0, hours: number = 0, minutes: number = 0) {
    const date = new Date(dateInput.getTime());

    if (days > 0) {
      date.setDate(date.getDate() + days);
    }

    if (hours > 0) {
      date.setHours(date.getHours() + hours);
    }

    if (minutes > 0) {
      date.setMinutes(date.getMinutes() + minutes);
    }

    return date;
  }

  static plus(days: number = 0, hours: number = 0, minutes: number = 0) {
    return this.datePlus(new Date(), days, hours, minutes);
  }

  static relativeEternity() {
    const date = new Date();

    date.setFullYear(9999);

    return date;
  }
}
