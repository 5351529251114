import { Subject } from 'rxjs';
import { HashUtils } from '../utils/hash-utils';
import { ListenerInterface } from './listener-interface';

export class Listener implements ListenerInterface {
  topic: string;
  subject: Subject<any>;
  hash: string;

  constructor() {
    this.hash = HashUtils.getCodePositionUniqueHash();
  }

  transform(frame: any): any {
    return frame.body;
  }
}
