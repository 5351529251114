import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { defer, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LocationEmbed } from '../domains/location-embed';
import { RestaurantEmbed } from '../domains/restaurant-embed';
import { EmbedType } from '../models/enums/embed-type';
import { LocationType } from '../models/enums/location-type';
import { ScriptLoaderUtils } from '../utils/script-loader-utils';

@Injectable({providedIn: 'root'})
export class EmbedService {
  private restaurantEmbedsCache$ = new Map<number, Observable<Array<RestaurantEmbed>>>();
  private locationEmbedsCache$ = new Map<string, Observable<Array<LocationEmbed>>>();
  readonly embedUrl: string;

  constructor(private http: HttpClient) {
    this.embedUrl = `${environment.apiEndpoint}/api/embeds`;
  }

  getAllRestaurantEmbeds(restaurantId: number): Observable<Array<RestaurantEmbed>> {
    return defer(() => {
      let cachedRestaurantEmbeds$ = this.restaurantEmbedsCache$.get(restaurantId);

      if (!cachedRestaurantEmbeds$) {
        cachedRestaurantEmbeds$ = this.http.get<Array<RestaurantEmbed>>(`${this.embedUrl}/${restaurantId}`).pipe(
          map(embeds => plainToClass(RestaurantEmbed, embeds)),
          shareReplay(1)
        );

        this.restaurantEmbedsCache$.set(restaurantId, cachedRestaurantEmbeds$);
      }

      return cachedRestaurantEmbeds$.pipe(
        tap(restaurantEmbeds => {
          restaurantEmbeds.forEach(async restaurantEmbed => {
            if (restaurantEmbed.html.indexOf('<script') !== -1) {
              restaurantEmbed.isVertical = true;
              await ScriptLoaderUtils.loadScriptFromHtml(restaurantEmbed.html, restaurantEmbed.providerName);
            }
          });
        })
      );
    });
  }

  getAllLocationEmbeds(locationId: number, locationType: LocationType): Observable<Array<LocationEmbed>> {
    return defer(() => {
      const key = `${locationId}_${locationType}`;
      let cachedLocationEmbeds$ = this.locationEmbedsCache$.get(key);

      if (!cachedLocationEmbeds$) {
        cachedLocationEmbeds$ = this.http.get<Array<LocationEmbed>>(
          `${this.embedUrl}/${locationType}/${locationId}`
        ).pipe(
          map(embeds => plainToClass(LocationEmbed, embeds)),
          shareReplay(1)
        );

        this.locationEmbedsCache$.set(key, cachedLocationEmbeds$);
      }

      return cachedLocationEmbeds$.pipe(
        tap(embeds => {
          embeds.forEach(async userEmbed => {
            if (userEmbed.html.indexOf('<script') !== -1) {
              userEmbed.isVertical = true;
              await ScriptLoaderUtils.loadScriptFromHtml(userEmbed.html, userEmbed.providerName);
            }
          });
        })
      );
    });
  }

  add<T>(embed: T, type: EmbedType): Observable<T> {
    const classType = (type === EmbedType.RESTAURANT ? RestaurantEmbed : LocationEmbed) as new (...args: any[]) => T;

    return this.http.post(`${this.embedUrl}/${type}`, embed)
      .pipe(map(value => plainToClass(classType, value)));
  }

  delete(embed: RestaurantEmbed | LocationEmbed): Observable<any> {
    let embedType: EmbedType;

    if (embed instanceof RestaurantEmbed) {
      embedType = EmbedType.RESTAURANT;

      this.restaurantEmbedsCache$.clear();
    } else {
      embedType = EmbedType.LOCATION;

      this.locationEmbedsCache$.clear();
    }

    return this.http.delete(`${this.embedUrl}/${embedType}/${embed.id}`);
  }
}
