import { FavoriteStatus } from '../enums/favorite-status';
import { WrapperInterface } from './wrapper.interface';

export class FavoriteStatusWrapper implements WrapperInterface {
  list = FavoriteStatus;
  value: FavoriteStatus;

  statuses = [
    {
      value: FavoriteStatus.NOT_EATEN,
      title: 'Uneaten',
      icon: 'icon-v2 star-empty-white scale-08',
      pickerIcon: 'icon-v2 star-empty'
    }, {
      value: FavoriteStatus.TRY_ME,
      title: 'Want to try',
      icon: 'icon-v2 goal-white scale-08',
      pickerIcon: 'icon-v2 goal-red'
    }, {
      value: FavoriteStatus.EATEN,
      title: 'Eaten',
      icon: 'icon-v2 goal-white-reached scale-08',
      pickerIcon: 'icon-v2 goal-black'
    }, {
      value: FavoriteStatus.FAVORITE,
      title: 'Favorite',
      icon: 'icon-v2 heart-fill scale-08',
      pickerIcon: 'icon-v2 heart-red'
    },
  ];

  constructor(type: FavoriteStatus = null) {
    this.setStatus(type ? this.list[type] : this.list.NOT_EATEN);
  }

  isFavorite(): boolean {
    return this.value === this.list.FAVORITE;
  }

  isEaten(): boolean {
    return this.value === this.list.EATEN;
  }

  isTryMe(): boolean {
    return this.value === this.list.TRY_ME;
  }

  isNotEaten(): boolean {
    return this.value === this.list.NOT_EATEN;
  }

  setStatus(status: FavoriteStatus): void {
    this.value = status;
  }

  get pickerClass() {
    const status = this.statuses.find(title => title.value === this.value);

    return status ? status.pickerIcon : '';
  }
}
