import { Type } from 'class-transformer';
import { Tag } from './tag';

export class Extra {
  id: number;
  name: string;
  price: number;

  quantity = 0;
  hasRequiredError = false;

  @Type(() => Tag)
  tags: Array<Tag>;

  ordering = Number.MAX_SAFE_INTEGER;

  constructor(ordering = null) {
    if (ordering) {
      this.ordering = ordering;
    }
  }

  add() {
    this.quantity++;
  }

  remove() {
    this.quantity--;
  }
}
