import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'city'})
export class CityPipe implements PipeTransform {
  transform(restaurantName: string, currentCityName: string): any {
    if (restaurantName?.trim().toLowerCase().endsWith(currentCityName?.toLowerCase())) {
      let regex = new RegExp('-' + currentCityName, 'gi');
      restaurantName = restaurantName.replace(regex, '');

      regex = new RegExp(' - ' + currentCityName, 'gi');
      restaurantName = restaurantName.replace(regex, '');

      regex = new RegExp(' -' + currentCityName, 'gi');
      restaurantName = restaurantName.replace(regex, '');

      regex = new RegExp('- ' + currentCityName, 'gi');
      restaurantName = restaurantName.replace(regex, '');

      regex = new RegExp(' ' + currentCityName, 'gi');
      restaurantName = restaurantName.replace(regex, '');

      regex = new RegExp(' of ' + currentCityName, 'gi');
      restaurantName = restaurantName.replace(regex, '');
    }

    return restaurantName;
  }
}
