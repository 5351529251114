import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Menu } from '../../../domains/menu';
import { Restaurant } from '../../../domains/restaurant';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-navbar-bottom-menus-cs',
  templateUrl: './navbar-bottom-menus-cs.component.html',
  styleUrls: [
    '../navbar.shared.scss',
  ]
})
export class NavbarBottomMenusCsComponent implements OnInit {
  @Input() restaurant: Restaurant;
  @Input() menu: Menu;
  @Input() sort = false;

  @Output() sortChanged = new EventEmitter<boolean>();

  constructor(
    public modalService: ModalService
  ) {
  }

  ngOnInit() {
  }

  onSortChanged() {
    this.sortChanged.emit(!this.sort);
  }
}
