import { InstagramStatus } from '../enums/instagram-status';
import { WrapperInterface } from './wrapper.interface';

export class InstagramStatusWrapper implements WrapperInterface {
  list = InstagramStatus;
  value: InstagramStatus;

  constructor(value: InstagramStatus = null) {
    if (value === null) {
      this.value = InstagramStatus.EMPTY;
    } else {
      this.value = value;
    }
  }

  isExist(): boolean {
    return this.value === InstagramStatus.EXIST;
  }

  isEmpty(): boolean {
    return this.value === InstagramStatus.EMPTY;
  }

  isIncorrect(): boolean {
    return this.value === InstagramStatus.INCORRECT;
  }

  setExist(): void {
    this.value = InstagramStatus.EXIST;
  }

  setEmpty(): void {
    this.value = InstagramStatus.EMPTY;
  }

  setIncorrect(): void {
    this.value = InstagramStatus.INCORRECT;
  }
}
