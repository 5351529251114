import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Auth } from '../../../decorators/auth.decorator';
import { IsLoggedIn } from '../../../decorators/is-logged-in.decorator';

@Component({
  selector: 'app-navbar-bottom-explore',
  templateUrl: './navbar-bottom-explore.component.html',
  styleUrls: [
    '../navbar.shared.scss'
  ]
})
export class NavbarBottomExploreComponent implements OnInit {
  @Input() rotateMainLogo = false;
  @Input() expanded: boolean;

  @Output() mainMenuClick = new EventEmitter<boolean>();
  @Output() toggleButtonClick = new EventEmitter<boolean>();
  @Output() favoritesClick = new EventEmitter<boolean>();
  @Output() videoClick = new EventEmitter<boolean>();

  @IsLoggedIn() isLoggedIn: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  onToggleClick() {
    this.toggleButtonClick.emit(true);
  }

  onMainMenuClick() {
    this.mainMenuClick.emit(true);
  }

  @Auth()
  onFavoritesClick() {
    this.favoritesClick.emit(true);
  }

  onVideoClick() {
    this.videoClick.emit(true);
  }
}
