import { ReviewStatus } from '../models/enums/review-status';

export abstract class Embed {
  id: number;
  url: string;
  html: string;
  providerName: string;
  width: string;
  height: string;
  isVertical = false;
  title: string;
  status: ReviewStatus;
}
