import { Component, Input, OnInit } from '@angular/core';
import { Order } from '../../../../domains/order/order';

@Component({
  selector: 'app-orders-modal',
  templateUrl: './orders-modal.component.html',
  styleUrls: [
    '../orders.shared.scss',
    './orders-modal.component.scss'
  ]
})
export class OrdersModalComponent implements OnInit {
  @Input() order: Order;

  constructor() {
  }

  ngOnInit() {
  }
}
