<div>
  <div class="form-row">
    <div class="form-group col-12">
      <input
        [(ngModel)]="phone"
        [formControl]="phoneControl"
        [validation]="true"
        [showMaskTyped]="true"
        [ngClass]="{ 'is-invalid': phoneControl.errors }"
        type="tel"
        name="phone"
        id="phone"
        class="form-control"
        mask="(000) 000-0000"
      />
    </div>
  </div>
  <button type="submit" class="btn btn-primary btn-block text-capitalize" (click)="setPhone()">Save</button>
</div>
