import { Type } from 'class-transformer';
import { GlobalBaseLocation } from '../../models/global-base-location';
import { State } from './state';

export class RegionWithParent extends GlobalBaseLocation {
  @Type(() => State)
  state: State;

  slug: string;
}
