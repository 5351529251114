import { Tag } from '../domains/tag';
import { CategoryType } from './enums/category-type';

export class CategoryTypeTags {
  tags = [];

  constructor(tags: Tag[]) {
    this.tags[CategoryType.Standard] = [];
    this.tags[CategoryType.Cocktail] = [];

    tags.forEach(tag => {
      this.tags[tag.categoryType].push(tag);
    });
  }
}
