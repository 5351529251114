import { User } from '../domains/user';
import { DecoratorInjectorService } from '../services/decorator/decorator-injector.service';
import { DecoratorService } from '../services/decorator/decorator.service';

export function CurrentUser(): PropertyDecorator {
  return (target: any, propertyKey: string) => {
    let service: DecoratorService;
    let currentUser: User;

    const getter = () => {
      if (service === undefined) {
        service = DecoratorInjectorService.getService();
      }

      currentUser = service.authenticationService.currentUser;

      return currentUser;
    };

    const setter = () => {
    };

    Object.defineProperty(target, propertyKey, {
      get: getter,
      set: setter
    });
  };
}
