import { SocialUser } from '@abacritt/angularx-social-login';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ConfirmEmailToken } from '../domains/confirm-email-token';
import { Password } from '../domains/password';
import { ResetPassword } from '../domains/reset-password';
import { UsernameCheck } from '../domains/username-check';
import { Role, User } from '../models';
import { AdminPickerOption } from '../models/enums/user-admin-options';
import { Image } from '../models/image';
import { Picker } from '../models/picker';
import { PickerConfig } from '../models/picker-config';
import { PickerGroup } from '../models/picker-group';

@Injectable({providedIn: 'root'})
export class UserService {
  readonly userUrl: string;

  constructor(private http: HttpClient) {
    this.userUrl = `${environment.apiEndpoint}/api/users`;
  }

  getCurrent(): Observable<User> {
    return this.http.get(`${this.userUrl}`)
      .pipe(map(value => plainToClass(User, value)));
  }

  getById(id: number) {
    return this.http
      .get<User>(`${this.userUrl}/${id}`)
      .pipe(map(value => plainToClass(User, value)));
  }

  getByUsername(username: string) {
    return this.http.get<User>(`${this.userUrl}/username/${username}/`)
      .pipe(map(value => plainToClass(User, value)));
  }

  getByEmail(email: string) {
    return this.http.get<User>(`${this.userUrl}/email/${email}`);
  }

  getPickerOptions(isAdmin = false) {
    const groups = [];

    if (isAdmin) {
      const group = new PickerGroup('Admin');

      group.addOption(AdminPickerOption.DASHBOARD, AdminPickerOption.DASHBOARD);
      group.addOption(AdminPickerOption.BRAND, AdminPickerOption.BRAND);

      groups.push(group);
    }

    const group2 = new PickerGroup('Settings');

    group2.addOption(AdminPickerOption.ACCOUNT, AdminPickerOption.ACCOUNT);
    group2.addOption(AdminPickerOption.PASSWORD, AdminPickerOption.PASSWORD);
    group2.addOption(AdminPickerOption.LOGOUT, AdminPickerOption.LOGOUT);

    groups.push(group2);

    return new Picker(plainToClass(PickerConfig, {groups}));
  }

  checkSocialUserToExists(socialUser: SocialUser) {
    return this.http.post<boolean>(`${this.userUrl}/check-social`, socialUser);
  }

  removeIgId() {
    return this.http.delete(`${this.userUrl}/ig`);
  }

  save(user: User) {
    return this.http.post(`${this.userUrl}/save`, user);
  }

  changePassword(password: Password) {
    return this.http.put(`${this.userUrl}/change-password`, password);
  }

  resetPasswordSendingEmail(email: string, role: Role) {
    return this.http.get(`${this.userUrl}/reset-send/${email}/role/${role}`);
  }

  resetPassword(resetPassword: ResetPassword) {
    return this.http.put(`${this.userUrl}/reset-password`, resetPassword);
  }

  confirmEmail(token: ConfirmEmailToken) {
    return this.http.put<any>(`${this.userUrl}/confirm`, token);
  }

  uploadProfileImage(image: Image) {
    return this.http.post<User>(`${this.userUrl}/profile-image`, image)
      .pipe(map(value => plainToClass(User, value)));
  }

  checkUsernameToExists(username: string) {
    username = username.toLowerCase().trim();

    return this.http.get<UsernameCheck>(`${this.userUrl}/check-username/${username}/`);
  }

  checkEmailToExists(email: string) {
    return this.http.get<number>(`${this.userUrl}/check-email/${email}/`);
  }

  sendConfirmationEmail() {
    return this.http.get(`${this.userUrl}/send-confirmation`);
  }

  resendConfirmationEmail(id: number, email: string) {
    return this.http.get(`${this.userUrl}/resend-confirmation/${id}/${email}`);
  }
}
