import { Type } from 'class-transformer';
import { Post } from './post';

export class PostPage {
  hasNext: boolean;
  page: number;

  @Type(() => Post)
  posts: Post[];
}
