import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { ModalClose } from '../../../decorators/modal-close.decorator';
import { User } from '../../../domains/user';
import { DeviceType } from '../../../models/enums/device-type';
import { AuthenticationService } from '../../../services/authentication.service';
import { InstagramService } from '../../../services/instagram.service';
import { NavigatorService } from '../../../services/navigator.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-email-modal',
  templateUrl: 'email-modal.component.html',
  styleUrls: ['email-modal.component.scss']
})
export class EmailModalComponent implements OnInit {
  @CurrentUser() user: User;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  emailForm: UntypedFormGroup;
  submitted = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private instagramService: InstagramService,
    private authenticationService: AuthenticationService,
    private navigatorService: NavigatorService,
    private notificationService: NotificationService,
    private logger: NGXLogger
  ) {
  }

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  get emailControl() {
    return this.emailForm.controls['email'];
  }

  onSubmit() {
    this.submitted = true;

    if (this.emailForm.invalid) {
      return;
    }

    this.user.email = this.emailControl.value;

    this.instagramService
      .setEmail(this.user.email)
      .subscribe({
        next: userData => {
          this.authenticationService.saveUserDataToCookie(userData);

          this.closeModal();

          this.navigatorService.goToUrl('/').then(() => {
            this.notificationService.confirmationEmailSent(this.emailControl.value);
          });
        }, error: error => {
          this.notificationService.emailExists();

          this.logger.error('On setting user email', error);
        }
      });
  }

  @ModalClose()
  closeModal() {
  }
}
