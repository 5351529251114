import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable({providedIn: 'root'})
export class PwaService {
  promptEvent: any;

  constructor(private swUpdate: SwUpdate, private platform: Platform) {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(event => {
        if (confirm('New version available. Load New Version?')) {
          window.location.reload();
        }
      });
    }

    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.promptEvent = event;
      });
    }
  }
}
