import { Subscription } from 'rxjs';
import { DecoratorInjectorService } from '../services/decorator/decorator-injector.service';
import { DecoratorService } from '../services/decorator/decorator.service';

export function WaitForRestaurant(): MethodDecorator {
  return (target: object, key: string, descriptor: any) => {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      let loaded = false;
      let subscription: Subscription;
      const service: DecoratorService = DecoratorInjectorService.getService();

      if (subscription) {
        subscription.unsubscribe();
      } else {
        subscription = service.routerService.restaurantSubject.subscribe(restaurant => {
          if (restaurant && !loaded) {
            setTimeout(() => {
              loaded = true;
              return originalMethod.apply(this, args);
            }, 1);

            setTimeout(() => {
              if (subscription !== undefined) {
                subscription.unsubscribe();
              }
            }, 2);
          }
        });
      }
    };

    return descriptor;
  };
}
