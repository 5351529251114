<div
  *ngIf="!isEdit"
  [ngClass]="{'restaurant-hours-desktop': isDesktop && !isModal, 'font-size-0-98rem': isDesktop && isModal}"
  class="restaurant-hours"
>
  <ng-container *ngTemplateOutlet="sections"></ng-container>
</div>

<div *ngIf="isEdit" class="font-size-1rem restaurant-preview-hours-content text-white ml-5 mt-5 card-body">
  <ng-container *ngTemplateOutlet="sections"></ng-container>
</div>

<ng-template #sections>
  <ng-container *ngFor="let section of restaurantHoursSections; let index = index">
    <div
      [ngClass]="{
        'mb-3': section.type.isSpecialHours,
        'mt-0': isEdit && index == 0,
        'mt-4': (isEdit && section.type.isNormalHours && index != 0) || (!isEdit && section.type.isSpecialHours && index != 0),
        'restaurant-hours-main': !isEdit,
        'restaurant-hours-edit': isEdit,
        'restaurant-hours-edit-special': isEdit && section.type.isSpecialHours,
        'restaurant-hours-edit-regular pl-2': isEdit && section.type.isNormalHours
      }"
      class="restaurant-hours-periods"
    >
      <div *ngIf="section.title" class="flex-content-hc w-100">
        <div class="restaurant-hours-td restaurant-hours-title"></div>

        <div class="restaurant-hours-td restaurant-hours-value">
          <span>{{ section.title }}</span>
        </div>

        <div *ngIf="isEdit" class="restaurant-hours-td restaurant-hours-empty flex-fill"></div>
      </div>

      <ng-container *ngFor="let normalHoursGroup of section.groups">
        <div
          *ngFor="let period of normalHoursGroup.periods; let index = index"
          [ngClass]="{'flex-content-hc': !isEdit || !period.removed, 'd-none': isEdit && period.removed}"
          class="w-100"
        >
          <div class="restaurant-hours-td restaurant-hours-title flex-content-vc-hr text-capitalize">
            <span *ngIf="index == 0">
              <ng-container *ngIf="period.type.isNormalHours">
                <ng-container *ngIf="!period.type.isBusinessHours">
                  {{ period.type.value | lowercase | replace : '\_' : '\ ' }}
                </ng-container>

                <ng-container *ngIf="period.type.isBusinessHours">{{ period.weekDaysToString() }}</ng-container>
              </ng-container>

              <ng-container *ngIf="!period.type.isNormalHours">
                <ng-container
                  *ngIf="!period.closedUntil || period.closedFrom.toString() === period.closedUntil.toString()"
                >
                  {{ period.startDate | date : 'E dd' }}
                </ng-container>

                <ng-container
                  *ngIf="period.closedUntil && period.closedFrom.toString() !== period.closedUntil.toString()"
                >
                  {{ period.startDate | date : 'dd' }} - {{ period.closedUntil | date : 'dd' }}
                </ng-container>
              </ng-container>
            </span>
          </div>

          <div class="restaurant-hours-td restaurant-hours-value flex-content-vc">
            <span *ngIf="period.type.isNormalHours">{{ period.timeToString(true) }}</span>

            <ng-container *ngIf="!period.type.isNormalHours">
              <span *ngIf="!period.type.isEvent">{{ period.closed ? 'Closed' : period.timeToString(true) }}</span>

              <span *ngIf="period.closedUntil">Closed</span>

              <a
                *ngIf="period.type.isEvent"
                appHrefNone
                appModalCloseOnClickPrevent
                class="restaurant-hours-event-badge text-white"
                (click)="periodEventToast(period)"
              >
                EVENT - {{ period.timeStart.toString(true) }}
              </a>
            </ng-container>
          </div>

          <div *ngIf="isEdit" class="restaurant-hours-td restaurant-hours-empty flex-content-vc-hr flex-fill">
            <a appHrefNone class="icon-v2-btn outline-none" (click)="removeRegularPeriodEmit(period.id)">
              <span class="icon-v2 delete scale-1_2"></span>
            </a>
          </div>
        </div>
      </ng-container>

      <div
        *ngIf="!isEdit && section.type.isClosed"
        class="flex-content-hc w-100"
      >
        <div class="restaurant-hours-td restaurant-hours-title flex-content-vc-hr">
          <span>{{ section.closedDays }}</span>
        </div>

        <div class="restaurant-hours-td restaurant-hours-value flex-content-vc">
          <span>Closed</span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
