import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Token } from '../../domains/token';
import { AuthenticationService } from '../../services/authentication.service';
import { NavigatorService } from '../../services/navigator.service';
import { RestaurantService } from '../../services/restaurant.service';

@Component({
  selector: 'app-verify-ownership',
  templateUrl: './verify-ownership.component.html',
  styleUrls: ['./verify-ownership.component.scss']
})
export class VerifyOwnershipComponent implements OnInit {
  hostname: string;
  verificationProcess = false;
  verificationError = false;
  mmmmLogo: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private restaurantService: RestaurantService,
    private navigatorService: NavigatorService,
    private authenticationService: AuthenticationService
  ) {
    this.mmmmLogo = environment.mmmmLogo;
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.hostname = params['hostname'];

      const id = this.activatedRoute.snapshot.queryParams['id'];
      const token = this.activatedRoute.snapshot.queryParams['token'];

      if (id && token) {
        this.restaurantService
          .verificationTokenCheck(new Token(id, token))
          .subscribe({
            next: tokenCheckResponse => {
              if (tokenCheckResponse['userData']) {
                this.authenticationService.saveUserDataToCookie(tokenCheckResponse['userData']);
              }

              if (tokenCheckResponse['valid'] === true) {
                this.verificationProcess = true;

                this.restaurantService
                  .verifyOwnership()
                  .subscribe(verifyResponse => {
                    this.verificationError = !verifyResponse['verified'];
                    this.authenticationService.saveUserDataToCookie(verifyResponse['userData']);
                  });
              } else {
                return this.navigatorService.goToRestaurantFromSubPages();
              }
            }, error: () => {
              return this.navigatorService.goToRestaurantFromSubPages();
            }
          });
      } else {
        return this.navigatorService.goToRestaurantFromSubPages();
      }
    });
  }
}
