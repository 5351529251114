import { WrapperInterface } from '../wrappers/wrapper.interface';
import { PostPageType } from './post-page-type';

export class PostPageTypeWrapper implements WrapperInterface {
  list = PostPageType;
  value: PostPageType;

  constructor(type: string = null) {
    if (type) {
      this.value = PostPageType[type];
    }
  }

  get isUser() {
    return this.value === this.list.USER;
  }

  get isGallery() {
    return this.value === this.list.GALLERY;
  }

  get isRestaurant() {
    return this.value === this.list.RESTAURANT;
  }
}
