<nav class="navbar navbar-top fixed-top bg-white max-width pr-0 safe-area-top">
  <div class="navbar-top-left navbar-width-30px">
    <a appHrefNone class="navbar-top-icon-back" (click)="closeSettings()">
      <span class="icon-v2 arrow-back scale-82"></span>
    </a>
  </div>

  <ng-container *ngIf="title">
    <a appHrefNone class="navbar-brand navbar-title mr-0">
      {{ title }}
    </a>
  </ng-container>

  <div class="navbar-top-right navbar-width-30px"></div>
</nav>
