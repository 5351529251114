import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ModalClose } from '../../../../decorators/modal-close.decorator';
import { Restaurant } from '../../../../domains/restaurant';
import { RestaurantSimple } from '../../../../domains/restaurant-simple';
import { RestaurantService } from '../../../../services/restaurant.service';
import { RouterService } from '../../../../services/router.service';

@Component({
  selector: 'app-qr-modal',
  templateUrl: './qr-modal.component.html',
  styleUrls: ['qr-modal.component.scss']
})
export class QrModalComponent implements OnInit, OnDestroy {
  @Input() restaurant: Restaurant | RestaurantSimple;

  domain: string;

  constructor(private restaurantService: RestaurantService, private routerService: RouterService) {
    this.domain = environment.domain;
  }

  ngOnInit() {
    if (!this.restaurant.qr) {
      this.routerService.setRestaurantHostnameAndLocationId(this.restaurant.hostname, this.restaurant.locationIndex);

      this.restaurantService.getQR().subscribe(image => {
        this.restaurant.qr = image;
      });
    }
  }

  ngOnDestroy() {
    this.routerService.setRestaurantHostnameAndLocationId(null, null);
  }

  @ModalClose()
  onSwipe() {
  }
}
