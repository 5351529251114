<div class="owner-content">
  <app-navbar-top [title]="restaurant.nameView"></app-navbar-top>

  <div class="owner-content-body d-flex flex-column">
    <div class="card border-radius-none mt-0 d-flex h-100">
      <div [appBackgroundUrl]="backgroundImage ? backgroundImage: ''" class="h-100 w-100"></div>

      <div class="position-absolute-0 backdrop-filter-brightness-0-35"></div>

      <div class="position-absolute-0 font-size-1rem d-flex flex-column">
        <div class="w-100 d-flex justify-content-center">
          <div class="icon m-large-white scale-7 mt-10-5rem"></div>
        </div>

        <div class="text-white w-100 d-flex justify-content-center mt-7rem">The Mber community</div>

        <div class="text-white w-100 d-flex justify-content-center mt-3">M powers owners to share</div>

        <div class="text-white w-100 d-flex justify-content-center mt-3">* Complete menus with updates *</div>

        <div class="text-white w-100 d-flex justify-content-center mt-3">* Holiday hours & events *</div>

        <div class="text-white w-100 d-flex justify-content-center mt-3">* Allergen warnings *</div>

        <div class="m-auto"></div>

        <input autofocus class="visibility-hidden">

        <div class="mb-4 mx-4">
          <button
            class="btn rounded-pill btn-outline-white text-transform-none font-size-small mr-4 w-45"
            (click)="onClickShare()"
          >
            Tell this owner
          </button>

          <button
            class="btn rounded-pill btn-outline-white text-transform-none font-size-small w-45"
            (click)="onOwnVerificationClicked()"
          >
            Get owner access
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-top-standard-footer border-bottom-radius-3 bg-white p-3">
    <div class="modal-top-standard-footer-content">
      <input
        type="submit"
        class="btn btn-white btn-block text-uppercase"
        value="Learn about Mbership"
        (click)="openMembershipModal()"
      />
    </div>
  </div>
</div>

<ng-template #membership let-c="close">
  <app-membership-modal
    [user]="user"
  ></app-membership-modal>
</ng-template>
