import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ModalClose } from '../../../../decorators/modal-close.decorator';
import { Chain } from '../../../../domains/chain';
import { TypeCategorySection } from '../../../../models/type-category-section';
import { UnsplashImage } from '../../../../models/unsplash-image';
import { ChainService } from '../../../../services/chain.service';
import { NavigatorService } from '../../../../services/navigator.service';
import { UnsplashService } from '../../../../services/unsplash.service';

@Component({
  selector: 'app-divider-modal',
  templateUrl: './divider-modal.component.html',
  styleUrls: ['./divider-modal.component.scss']
})
export class DividerModalComponent implements OnInit {
  @Input() typeCategorySection: TypeCategorySection;
  @Input() cityName: string;
  @Input() hasNext: boolean;
  @Input() hasPrev: boolean;

  @Output() previousClick = new EventEmitter<boolean>();
  @Output() nextClick = new EventEmitter<boolean>();

  backgroundImage: UnsplashImage;
  chains: Array<Chain> = [];
  arrowHover: boolean;

  constructor(
    private unsplashService: UnsplashService,
    private navigatorService: NavigatorService,
    private chainService: ChainService
  ) {
  }

  ngOnInit(): void {
    this.unsplashService.randomPhoto().subscribe(value => {
      this.backgroundImage = value;
    });

    this.chainService.getAllByTypeCategorySection(this.typeCategorySection).subscribe(chains => {
      this.chains = chains;
    });
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === 'ArrowLeft') {
      this.previousClick.emit(true);
    } else if (event.code === 'ArrowRight') {
      this.nextClick.emit(true);
    }
  }

  @ModalClose()
  closeModal() {
  }

  onChainClick(chain: Chain) {
    this.navigatorService.goToMaps(chain.title + ', ' + this.cityName + ', CA');
  }
}
