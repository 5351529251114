import { Type } from 'class-transformer';
import { GlobalBaseLocation } from '../../models/global-base-location';
import { CityWithParent } from './city-with-parent';
import { RegionWithParent } from './region-with-parent';

export class DistrictWithParent extends GlobalBaseLocation {
  @Type(() => CityWithParent)
  city: CityWithParent;

  slug: string;

  @Type(() => RegionWithParent)
  region: RegionWithParent;
}
