import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { classToPlain, plainToClass } from 'class-transformer';
import { NGXLogger } from 'ngx-logger';
import { Options } from 'sortablejs';
import { environment } from '../../../../../environments/environment';
import { CurrentUser } from '../../../../decorators/current-user.decorator';
import { Category } from '../../../../domains/category';
import { Food } from '../../../../domains/food';
import { Menu } from '../../../../domains/menu';
import { Ordering } from '../../../../domains/ordering';
import { Restaurant } from '../../../../domains/restaurant';
import { Section } from '../../../../domains/section';
import { User } from '../../../../domains/user';
import { BooleanLocalStorage } from '../../../../local-storage/boolean-local-storage';
import { CategoryTypeTags } from '../../../../models/category-type-tags';
import { CategoryStatus } from '../../../../models/enums/category-status';
import { CategoryType } from '../../../../models/enums/category-type';
import { DrinkType } from '../../../../models/enums/drink-type';
import { FoodStatus } from '../../../../models/enums/food-status';
import { LocalStorageKey } from '../../../../models/enums/local-storage-key';
import { MenuType } from '../../../../models/enums/menu-type';
import { ModalType } from '../../../../models/enums/modal-type';
import { SectionType } from '../../../../models/enums/section-type';
import { Picker } from '../../../../models/picker';
import { Types } from '../../../../models/types';
import { CategoryService } from '../../../../services/category.service';
import { FoodService } from '../../../../services/food.service';
import { MenuService } from '../../../../services/menu.service';
import { ModalService } from '../../../../services/modal.service';
import { TagService } from '../../../../services/tag.service';

@Component({
  selector: 'app-food-menus-cs',
  templateUrl: './food-menus-cs.component.html',
  styleUrls: [
    './food-menus-cs.component.scss'
  ]
})
export class FoodMenusCsComponent implements OnInit {
  @Input() currentRestaurant: Restaurant;

  @Output() savedChanges = new EventEmitter<boolean>();
  @Output() enabledMenuTypesSave = new EventEmitter<MenuType[]>();

  @ViewChild('additionsCsModal') additionsCsModalRef: ElementRef;

  @CurrentUser() currentUser: User;

  csMenuIds: Array<number>;
  exploreBackgroundImage: string;
  csHasChangesLocalStorage: BooleanLocalStorage;
  categoryControls = [];
  sectionType = SectionType;
  currentCategory: Category;
  currentFood: Food;
  currentSection: Section;
  showWarningTags: boolean;
  categoryTypeTags: CategoryTypeTags;
  showCategories = false;
  statusEditPicker = new Picker();
  categoryStatus = new Picker();
  menu: Menu;
  sort = true;
  menusAll: Menu[] = [];
  menus: Menu[] = [];
  saveNewCategoryTimeout: any;

  categoriesSortableOptions: Options = {
    animation: 150,
    handle: '.category-sortable',
    onUpdate: () => {
      this.categoriesOrderingChange();
    }
  };

  constructor(
    private modalService: ModalService,
    private menuService: MenuService,
    private logger: NGXLogger,
    private foodService: FoodService,
    private categoryService: CategoryService,
    private tagService: TagService
  ) {
    this.statusEditPicker.addDefaultOption(FoodStatus.AVAILABLE, 'Available');
    this.statusEditPicker.addDefaultOption(FoodStatus.SOLD_OUT, 'Sold out');
    this.statusEditPicker.addDefaultOption(FoodStatus.HIDDEN, 'Hidden');

    this.categoryStatus.addDefaultOption(CategoryStatus.AVAILABLE, 'Available');
    this.categoryStatus.addDefaultOption(CategoryStatus.HIDDEN, 'Hidden');
  }

  ngOnInit() {
    this.menusAll = this.currentRestaurant.menus;
    this.menus = this.menuService.setFilteredMenus(this.menusAll, this.currentRestaurant.enabledMenuTypes);

    this.csHasChangesLocalStorage = new BooleanLocalStorage(
      LocalStorageKey.CROWDSOURCE_HAS_CHANGES,
      {hostname: this.currentRestaurant.hostname, locationId: this.currentRestaurant.locationIndex}
    );

    this.exploreBackgroundImage = environment.favoritesBackgroundImage;

    this.menuService
      .hasChangesInRedis()
      .subscribe({
        next: value => {
          this.csMenuIds = value;
        }, error: error => {
          this.logger.error('On checking changes in redis', error);
        }
      });

    this.tagService
      .getTags()
      .then(categoryTypeTags => {
        this.categoryTypeTags = categoryTypeTags;

        this.showCategories = true;
      });
  }

  private categoriesOrderingChange() {
    const orderArray: Array<number> = [];

    this.menu.categories.forEach(category => {
      if (category.id) {
        orderArray.push(category.ordering);
      }
    });

    orderArray.sort((a, b) => a > b ? 1 : -1);
    const categoryOrderings: Array<Ordering> = [];
    let j = 0;

    this.menu.categories.forEach(category => {
      if (category.id) {
        const order = orderArray[j++];
        category.ordering = order;
        categoryOrderings.push(new Ordering(category.id, order));
      }
    });

    this.categoryService
      .changeOrdering(categoryOrderings)
      .subscribe({
        next: () => {
          this.logger.debug('Successfully updated category orderings');
        }, error: error => {
          this.logger.error('On changing category orderings', error);
        }
      });
  }

  toggleMenuCategory(menu) {
    menu.expanded = !menu.expanded;

    if (menu.expanded) {
      this.menu = menu;

      this.currentRestaurant.menus.filter(menuI => menu.id !== menuI.id).forEach(menuI => {
        menuI.expanded = false;
      });

      if (menu.categories.length === 0 && menu.title !== 'Drinks') {
        this.addCategory(new Types(CategoryType.Standard, null));
        this.categoryControls.push(new UntypedFormControl());
      } else {
        menu.categories.forEach(category => {
          this.tagService.setCategoryTags(category);

          category.itemCount = 4;

          category.foods = category.foods.filter(food => food.id);

          category.foods = category.foods.sort((a, b) => {
            return (a.ordering < b.ordering ? -1 : a.ordering === b.ordering ? 0 : 1);
          });

          let order = category.foods.length ? category.foods[category.foods.length - 1].ordering + 1 : 1;

          for (let i = category.foods ? category.foods.length : 0; i < category.itemCount; i++) {
            const food = new Food();
            food.ordering = order++;
            category.foods.push(food);
          }

          category.foods.forEach(food => {
            if (!food.title) {
              food.price = null;
            }
          });

          this.categoryControls.push(new UntypedFormControl());
        });
      }
    } else {
      this.menu = null;
    }
  }

  saveFood(category: Category, food: Food) {
    if (!this.currentRestaurant.published) {
      this.saveMenuToRedis();
    } else {
      if (!category.id) {
        this.saveCategory(category, food);
      } else {
        if (food.title) {
          if (food.id) {
            this.foodService
              .editFood(food)
              .subscribe({
                next: () => {
                  food.isInvalid = false;
                  this.setHasChangesLocal();
                }, error: error => {
                  food.isInvalid = true;
                  this.logger.error('On editing food', error);
                }
              });
          } else {
            this.foodService
              .addFood(food, category)
              .subscribe({
                next: addedFood => {
                  food.id = addedFood.id;
                  food.ordering = addedFood.ordering;
                  food.isInvalid = false;
                  this.setHasChangesLocal();
                }, error: error => {
                  food.isInvalid = true;
                  this.logger.error('On adding new food', error);
                }
              });
          }
        } else if (food.id) {
          this.foodService
            .deleteFood(food)
            .subscribe({
              next: () => {
                this.saveCategory(category);
              }, error: error => {
                this.logger.error('On deleting food', error);
              }
            });
        }
      }
    }
  }

  setHasChangesLocal() {
    this.csHasChangesLocalStorage.setItem(true);
  }

  saveCategory(category: Category, food: Food = null) {
    if (!this.currentRestaurant.published) {
      this.saveMenuToRedis();
    } else {
      if (category.id) {
        if (category.isEmpty) {
          this.categoryService
            .deleteCategory(category)
            .subscribe({
              error: error => {
                this.logger.error('On deleting category', error);
              }
            });

          for (let i = this.menu.categories.length - 1; i >= 0; i--) {
            if (this.menu.categories[i].id === category.id) {
              this.menu.categories.splice(i, 1);
            }
          }

          if (this.menu.categories.length === 0 && this.menu.title !== 'Drinks') {
            this.addCategory(new Types(CategoryType.Standard, null));
          }
        } else {
          this.categoryService
            .editCategory(category)
            .subscribe({
              next: () => {
                this.setHasChangesLocal();
              }, error: error => {
                this.logger.error('On editing category', error);
              }
            });
        }
      } else {
        if (this.saveNewCategoryTimeout !== undefined) {
          clearTimeout(this.saveNewCategoryTimeout);
        }

        this.saveNewCategoryTimeout = setTimeout(() => {
          const categoryCloned = plainToClass(Category, classToPlain(category));
          categoryCloned.foods = categoryCloned.foods.filter(foodI => foodI.title);

          this.categoryService
            .addCategory(categoryCloned, this.menu)
            .subscribe({
              next: addedCategory => {
                category.id = addedCategory.id;

                if (food) {
                  const tempFood = addedCategory.foods.find(foodI => foodI.uuid === food.uuid);
                  food.id = tempFood.id;
                }

                this.setHasChangesLocal();
              }, error: error => {
                this.logger.error('On adding category', error);
              }
            });
        }, 100);
      }
    }
  }

  private saveMenuToRedis() {
    this.menuService
      .setToRedis(this.menu)
      .subscribe({
        error: error => {
          this.logger.error('On saving menu to redis', error);
        }
      });
  }

  onPriceChange(event, category: Category, food: Food) {
    food.price = event.target.value;
    this.saveFood(category, food);
  }

  addCategory(types: Types) {
    if (this.menu) {
      this.menuService.addCategory(this.menu, types, true);

      this.categoryControls.push(new UntypedFormControl());
    }
  }

  onOptionClick(event, category: Category, food: Food, sectionType: SectionType) {
    if (food.title) {
      if (!food.sections || !food.sections.find(value => value.type === sectionType)) {
        food.sectionAdd(sectionType);
      }

      this.currentCategory = category;
      this.currentFood = food;
      this.currentSection = food.sections.find(value => value.type === sectionType);

      this.showWarningTags = category.drinkType !== DrinkType.Cocktails;

      this.modalService.open(this.additionsCsModalRef, ModalType.CS_ADDITIONS);
    } else {
      event.stopPropagation();
    }
  }

  addFood(categoryIndex: number) {
    for (let i = 0; i < 3; i++) {
      const food = new Food();
      const foods = this.menu.categories[categoryIndex].foods;
      food.ordering = foods[foods.length - 1].ordering + 1;
      this.menu.categories[categoryIndex].foods.push(food);
    }
  }

  onPickerSelected(value: FoodStatus, food: Food) {
    food.status.newValue = value;

    this.foodService.editFood(food).subscribe();
  }

  onCategoryAvailabilityClick(value, category: Category) {
    switch (value) {
      case 'AVAILABLE':
        category.categoryStatus = CategoryStatus.AVAILABLE;
        category.hidden = false;

        this.onCategoryChange(category);

        break;
      case 'HIDDEN':
        category.categoryStatus = CategoryStatus.HIDDEN;
        category.hidden = true;

        this.onCategoryChange(category);

        break;
    }
  }

  onCategoryChange(category: Category, food: Food = null) {
    if (category.id) {
      this.categoryService
        .editCategory(category)
        .subscribe({
          error: error => {
            this.logger.error('On editing category', error);
          }
        });
    } else {
      const categoryCloned = plainToClass(Category, classToPlain(category));
      categoryCloned.foods = categoryCloned.foods.filter(foodI => foodI.title);

      this.categoryService
        .addCategory(categoryCloned, this.menu)
        .subscribe({
          next: addedCategory => {
            category.id = addedCategory.id;
            category.isInvalid = false;

            if (food) {
              const tempFood = addedCategory.foods.find(foodI => foodI.title === food.title);
              food.id = tempFood.id;
            }
          }, error: error => {
            this.logger.error('On adding category', error);
          }
        });
    }
  }

  getCategoryClass(categoryStatus) {
    if (categoryStatus === CategoryStatus.AVAILABLE) {
      return 'eye';
    }
    if (categoryStatus === CategoryStatus.HIDDEN) {
      return 'eye-hidden';
    }
  }

  switchSort(event) {
    this.sort = event;
  }

  onEnabledMenuTypesSave(enabledMenus: MenuType[]) {
    this.currentRestaurant.enabledMenuTypes = enabledMenus;
    this.menus = this.menuService.setFilteredMenus(this.menusAll, enabledMenus);
  }

  saveChanges() {
    this.savedChanges.emit(true);
  }
}
