<div [ngClass]="{'h-100': isDesktop}" class="basket modal-top-standard px-0" (swipeup)="onSwipeUp()">
  <div [ngClass]="{'px-0 h-100': isDesktop}" class="basket-modal modal-top-standard-body">
    <div
      [appBackgroundUrl]="backgroundImage"
      class="border-radius-none mt-0 d-flex h-100"
    >
      <div [ngClass]="{'bg-dark-on-modal': isDesktop}" class="basket-modal-backdrop position-relative w-100 h-100">
        <div
          [ngClass]="{'mt-2rem': !isDesktop}"
          class="text-white d-flex justify-content-center z-index-1 ml-1-75rem mr-1-75rem"
        >
          <div [ngClass]="{'mt-3': isDesktop}" class="text-center align-items-center cursor-pointer">
            <div
              *ngIf="isMobile"
              class="rest-name"
            >
              {{
                currentRestaurant?.nameView |
                  abbreviate: false: '' |
                  city: currentRestaurant?.cityNameToRemove
              }}
            </div>
          </div>
        </div>

        <div
          class="basket-modal-body modal-top-standard-content"
          (swipeup)="onSwipeUp()"
          [ngClass]="{'h-80vh px-0': isDesktop, 'h-55vh': !isDesktop, 'overflow-hidden': !isDesktop && isHoursEnabled}"
        >
          <app-restaurant-hours
            *ngIf="!isDesktop && isHoursEnabled"
            [restaurantHoursSections]="currentRestaurant.hoursSectionsView"
            class="text-white"
          ></app-restaurant-hours>

          <div [ngClass]="{'visibility-hidden': !isDesktop && isHoursEnabled, 'pr-2-5-rem pl-5': isDesktop}">
            <table class="basket-items text-white">
              <ng-container *ngFor="let basketFood of basket.foods">
                <tr class="item-row">
                  <td class="basket-quantity">
                    <div class="py-1 pl-1">
                      <span>{{ basketFood.quantity }}</span>
                    </div>
                  </td>

                  <td>
                    <div class="py-1">
                      <span>
                          &nbsp;{{ basketFood.title }}
                      </span>
                    </div>
                  </td>

                  <td class="basket-sum">
                    <div class="py-1 pr-1">
                      <span *ngIf="basketFood.priceBaseTotal > 0">
                          {{ basketFood.priceBaseTotal | precision }}
                      </span>
                    </div>
                  </td>

                  <td *ngIf="!isDesktop && !isHoursEnabled" class="basket-trash text-right">
                    <div class="icon-delete flex-content-vc-hr">
                      <a
                        appHrefNone
                        class="icon-a d-inline-flex"
                        (click)="removeBasketFood(basketFood)"
                      >
                        <span class="icon-v2 delete scale-1_2 opacity-delete-icon"></span>
                      </a>
                    </div>
                  </td>

                  <td *ngIf="isDesktop" class="basket-trash text-right">
                    <div class="icon-delete flex-content-vc-hr">
                      <a
                        appHrefNone
                        class="icon-a d-inline-flex"
                        (click)="removeBasketFood(basketFood)"
                      >
                        <span class="icon-v2 delete scale-1_2 opacity-delete-icon"></span>
                      </a>
                    </div>
                  </td>
                </tr>

                <ng-container *ngIf="basketFood.hasSections">
                  <ng-container *ngFor="let section of basketFood.sections">
                    <tr *ngFor="let extra of section.extras">
                      <td class="basket-quantity">
                        <div class="py-1 pl-1"></div>
                      </td>

                      <td>
                        <div class="py-1">
                          <span class="extra-quantity d-inline-block">
                            <span *ngIf="extra.quantity > 0">{{ extra.quantity }}</span>

                            <span
                              *ngIf="extra.quantity == 0"
                              class="size-dot"
                            >
                              -
                            </span>
                          </span>

                          <span class="d-inline-block">{{ extra.name }}</span>
                        </div>
                      </td>

                      <td class="basket-sum">
                        <div class="py-1 pr-1">
                          <span *ngIf="extra.showPrice && extra.total > 0">
                            {{ extra.total | precision }}
                          </span>
                        </div>
                      </td>

                      <td class="basket-trash text-right">
                        <ng-container *ngIf="extra.showPrice && section.min === 0">
                          <div class="icon-delete flex-content-vc-hr">
                            <a
                              appHrefNone
                              class="icon-a d-inline-flex"
                              (click)="removeBasketFood(basketFood, section, extra)"
                            >
                              <span class="icon-v2 delete scale-1_2 opacity-delete-icon"></span>
                            </a>
                          </div>
                        </ng-container>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="instructions[basketFood.id]">
                  <tr>
                    <td colspan="3">
                      <span class="text-danger">{{ instructions[basketFood.id].text }}</span>
                    </td>

                    <td>
                      <div
                        class="float-right instruction-icon"
                        (click)="onSelectedFoodForInstruction(basketFood.id.toString())"
                      ></div>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </table>
          </div>

          <div
            [ngClass]="{
              'visibility-hidden': !isDesktop && isHoursEnabled,
              'pr-2-5-rem pl-5': isDesktop
            }"
            class="pb-0"
          >
            <table class="basket-items">
              <tr [ngClass]="{'subtotal-color': isDesktop, 'subtotal-color-mobile': !isDesktop}">
                <td>
                  <div class="py-1 pl-1 pt-2">
                    <span>Subtotal</span>
                  </div>
                </td>

                <td class="basket-sum">
                  <div class="py-1 pr-1 mr-n1-6rem">
                    <span>{{ basket.subtotal | precision }}</span>
                  </div>
                </td>

                <td class="basket-trash text-right"></td>

                <td class="basket-trash text-right">
                  <div class="icon-delete flex-content-vc-hr">
                    <a
                      appHrefNone
                      *ngIf="!orderingEnabled && !isHoursEnabled"
                      class="icon-a d-inline-flex"
                      (click)="clearBasketAndClose()"
                    >
                      <span class="icon-v2 delete scale-1_2 opacity-delete-icon"></span>
                    </a>
                  </div>
                </td>
              </tr>

              <ng-container *ngIf="orderingEnabled && basket.pickUpEnabled">
                <tr>
                  <td>
                    <div class="py-1 pl-1 flex-content-vc">
                      <span>Tax {{ basket.feeEnabled ? '& fee' : '' }}</span>&nbsp;

                      <div class="d-inline-flex">
                        <a appHrefNone class="icon-v2 information" (click)="openTaxFeeModal()"></a>
                      </div>
                    </div>
                  </td>

                  <td class="basket-sum">
                    <div class="py-1 pr-1">
                      <span>{{ basket.salesTaxAndFee | precision }}</span>
                    </div>
                  </td>

                  <td class="basket-trash text-right"></td>
                </tr>

                <tr>
                  <td>
                    <div class="py-1 pl-1">
                      <span>Tip {{ basket.tipPercentage }}%</span>
                    </div>
                  </td>

                  <td class="basket-sum">
                    <div class="py-1 pr-1">
                      <span>{{ basket.tip | precision }}</span>
                    </div>
                  </td>

                  <td class="basket-trash text-right"></td>
                </tr>

                <tr>
                  <td>
                    <div class="py-1 pl-1">
                      <span>Total</span>
                    </div>
                  </td>

                  <td class="basket-sum">
                    <div class="py-1 pr-1">
                      <span>${{ basket.total | precision }}</span>
                    </div>
                  </td>

                  <td class="basket-trash text-right"></td>
                </tr>

                <tr *ngIf="locations.length > 0">
                  <td colspan="3" class="pl-1">
                    <div class="form-row">
                      <div class="form-group col">
                        <select
                          [(ngModel)]="activeLocationId"
                          class="custom-select custom-select-no-border"
                          (ngModelChange)="goToLocation($event)"
                        >
                          <option disabled [value]="locationId">
                            {{ currentRestaurant.fullAddress }}
                          </option>

                          <option *ngFor="let location of locations" [value]="location.locationIndex">
                            {{ location.fullAddress }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </td>
                </tr>

                <ng-container *ngIf="basket.requestTime || basket.asap">
                  <tr>
                    <td colspan="2">
                      <div class="py-1 pl-1">
                        <a appHrefNone class="text-body" (click)="openPickUpModal()">
                          <ng-container *ngIf="basket.requestTime">
                            {{ basket.requestTime | date:'E M/dd' }}
                            @ {{ (basket.requestTime | date:'h:mm a' | lowercase) }}
                          </ng-container>
                          <ng-container *ngIf="basket.asap">
                            ASAP
                          </ng-container>
                        </a>
                      </div>
                    </td>

                    <td class="basket-trash text-right">
                      <a appHrefNone class="text-body" (click)="openPickUpModal()">
                        <i class="ion ion-ios-arrow-dropdown-circle d-inline-block-vertical-middle"></i>
                      </a>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </table>
          </div>

          <div #basketTotalEndRef></div>

          <div *ngIf="isHoursEnabled" class="street-line">
            <div class="row" (click)="openGoogleMap()">
              <div class="col-12 text-center text-white">
                {{ currentRestaurant.addressStreet }}
              </div>
            </div>
          </div>

          <div class="mt-2rem p-3">
            <button
              *ngIf="!isMobile && !orderingEnabled && !isHoursEnabled"
              class="btn btn-dark btn-block text-uppercase bg-black"
            >
              <span>Vote to M power checkout</span>
            </button>
          </div>
        </div>

        <div
          [ngClass]="{'clear-button-padding': !orderingEnabled && !isDesktop && !isHoursEnabled}"
          class="modal-top-standard-footer text-center"
        >
          <ng-container *ngIf="orderingEnabled">
            <div *ngIf="basket.pickUpEnabled" class="basket-slider mb-2">
              <nouislider
                [(ngModel)]="basket.tipPercentage"
                [min]="0"
                [max]="100"
                [behaviour]="'tap-drag'"
                [config]="rangeConfig"
                (update)="sliderUpdate()"
                (change)="sliderChange()"
              ></nouislider>
            </div>

            <div
              ngbDropdown
              [placement]="'bottom-right'"
              [autoClose]="'outside'"
              class="btn-group inline-flex w-100"
            >
              <button
                *ngIf="!basket.pickUpEnabled"
                ngbDropdownToggle
                type="button"
                class="btn btn-dark btn-block dropdown-toggle"
              >
                Select
              </button>

              <ng-container *ngIf="basket.pickUpEnabled">
                <button type="button" class="btn btn-primary btn-block" (click)="checkout()">Checkout</button>
                <button ngbDropdownToggle class="btn btn-primary dropdown-toggle-split"></button>
              </ng-container>

              <div ngbDropdownMenu class="w-100 text-center dropdown-menu-right">
                <ng-container *ngIf="!isLoggedIn">
                  <a appHrefNone class="dropdown-item" (click)="loginNeed()">Add Instructions</a>
                </ng-container>

                <ng-container *ngIf="isLoggedIn">
                  <a
                    *ngIf="picker.defaultGroup.options.length === 1"
                    appHrefNone
                    class="dropdown-item"
                    (click)="onSelectedFoodForInstruction(picker.defaultGroup.options[0].value)"
                  >
                    Add Instructions
                  </a>

                  <app-picker
                    *ngIf="picker.defaultGroup.options.length > 1"
                    [picker]="picker"
                    [title]="'Add Instructions'"
                    [classes]="'dropdown-item'"
                    (selectedValue)="onSelectedFoodForInstruction($event)"
                  ></app-picker>
                </ng-container>

                <div class="dropdown-divider"></div>

                <a appHrefNone class="dropdown-item" (click)="openDineInModal()">Dine in</a>

                <a
                  appHrefNone
                  [ngClass]="{'active': basket.pickUpEnabled}"
                  class="dropdown-item"
                  (click)="openPickUpModal()"
                >
                  Pickup
                </a>
              </div>
            </div>
          </ng-container>

          <button
            *ngIf="isMobile && !orderingEnabled && !isHoursEnabled"
            class="btn btn-dark btn-block text-uppercase bg-black"
          >
            <span>Vote to M power checkout</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <app-preview-modal-footer
    *ngIf="currentRestaurant && isMobile"
    [isBasket]="true"
    [isHoursEnabled]="isHoursEnabled"
    [restaurant]="currentRestaurant"
    (hoursToggle)="setIsHoursEnabled($event)"
  ></app-preview-modal-footer>
</div>

<ng-template #instructionsModalRef let-c="close">
  <app-instructions
    [instruction]="currentInstruction"
    (changeInstruction)="onChangeInstruction($event)"
  ></app-instructions>
</ng-template>

<ng-template #taxFeeModalRef>
  <app-tax-fee-modal [basket]="basket"></app-tax-fee-modal>
</ng-template>

<ng-template #pickUpModalRef>
  <app-pick-up-modal
    [restaurant]="currentRestaurant"
    [basket]="basket"
    (set)="setPickUpTime($event)"
  ></app-pick-up-modal>
</ng-template>

<ng-template #dineInModalRef>
  <app-dine-in-modal (set)="setDineIn($event)"></app-dine-in-modal>
</ng-template>

<ng-template #phoneModalRef>
  <app-phone-modal [restaurant]="currentRestaurant" [basket]="basket" (set)="setPhone($event)"></app-phone-modal>
</ng-template>
