import { WrapperInterface } from '../wrappers/wrapper.interface';
import { HoursSectionType } from './hours-section-type';

export class HoursSectionTypeWrapper implements WrapperInterface {
  list = HoursSectionType;
  value: HoursSectionType;

  constructor(type: string = null) {
    if (type) {
      this.value = HoursSectionType[type];
    }
  }

  get isNormalHours() {
    return this.value === HoursSectionType.NORMAL_HOURS;
  }

  get isClosed() {
    return this.value === HoursSectionType.CLOSED;
  }

  get isSpecialHours() {
    return this.value === HoursSectionType.SPECIAL_HOURS;
  }
}
