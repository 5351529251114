<div class="modal-body h-100 w-100 d-flex p-0">
  <div
    class="content-container h-100 w-100 d-flex divider-background"
    [appBackgroundUrl]="backgroundImage"
  >
    <div class="text-white divider-backdrop w-100 h-100 pr-4 d-flex flex-column">
      <div class="mt-4 font-weight-bold section-title">{{ cityName }}</div>

      <div class="city-name">
        {{ cityName }}
      </div>

      <div class="m-auto"></div>

      <ng-container *ngFor="let chain of chains; let last = last;">
        <div class="mt-4">
          <a
            appHrefNone
            class="search-desktop-chains-title small text-white chain-title"
            (click)="onChainClick(chain)"
          >
            {{ chain.shortTitle || chain.title }}
          </a>
        </div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="hasPrev" class="arrow-bar left-n3rem d-flex flex-column" (click)="previousClick.emit(true)">
    <div class="m-auto"></div>

    <div class="icon-v2 arrow-forward-white side-arrow-right opacity-35-to-1-on-hover cursor-pointer"></div>

    <div class="m-auto"></div>
  </div>

  <div *ngIf="hasNext" class="arrow-bar right-n3rem d-flex flex-column" (click)="nextClick.emit(true)">
    <div class="m-auto"></div>

    <div class="icon-v2 arrow-forward-white rotate-180 side-arrow-left opacity-35-to-1-on-hover cursor-pointer"></div>

    <div class="m-auto"></div>
  </div>
</div>

