<app-navbar-top *appIfIsMobile title="Unavailable"></app-navbar-top>

<a
  appAddClassIfMobile="has-navbar-top"
  routerLink="/"
  [state]="{clearSearchParams: true}"
  class="container not-found-container flex-content-hc w-100 h-100"
>
  <div
    *ngIf="!logoHover"
    appAddClassIfDesktop="not-found-background-desktop"
    appAddClassIfMobile="not-found-background-mobile"
    class="not-found-background"
  ></div>

  <div class="not-found text-center">
    <h1 *appIfIsDesktop class="not-found-title text-white font-weight-bold mb-0">
      Unavailable
    </h1>

    <div appAddClassIfMobile="px-5" class="not-found-text text-white mt-4">
      It may be a broken link or the page may have been removed
    </div>

    <ng-container *appIfIsMobile>
      <div class="flex-content-hc btn-box mber-button row">
        <div class="col-12">
          <button
            class="btn rounded-pill btn-outline-white mt-14rem w-15rem text-uppercase"
            routerLink="/"
          >
            Explore
          </button>
        </div>
      </div>
    </ng-container>

    <div *appIfIsDesktop class="not-found-logo flex-content-hc">
      <div class="not-found-logo-icon flex-content-vc-hc">
        <a
          routerLink="/"
          [state]="{clearSearchParams: true}"
          [ngClass]="{'mmmm-2x-gray': !logoHover, 'mmmm-2x-red': logoHover}"
          class="icon-v2"
          (mouseenter)="logoHover = true"
          (mouseleave)="logoHover = false"
        ></a>
      </div>
    </div>
  </div>
</a>

<app-navbar-bottom *appIfIsMobile></app-navbar-bottom>
