import { Type } from 'class-transformer';
import { OrderSection } from '../../domains/order/order-section';
import { SectionType } from '../enums/section-type';
import { BasketExtra } from './basket-extra';

export class BasketSection extends OrderSection {
  selected: string[] = [];
  min: number;
  max: number;
  type: SectionType;
  total: number;

  @Type(() => BasketExtra)
  extras: BasketExtra[] = [];
}
