<nav class="navbar navbar-top fixed-top bg-white max-width safe-area-top">
  <div class="navbar-top-left navbar-width-30px">
    <a
      *ngIf="!editMode && isLoggedIn && isAdmin"
      appHrefNone
      (click)="toggleEditMode(true)"
      class="btn btn-primary md-btn-flat pl-0 text-capitalize"
    >
      Edit
    </a>
  </div>

  <a *ngIf="title" appHrefNone class="navbar-brand navbar-title mr-0 text-center" (click)="goBack()">
    {{ title }}
  </a>

  <div class="navbar-top-right navbar-width-30px">
    <div *ngIf="editMode">
      <a appHrefNone (click)="toggleEditMode(false)" class="btn btn-primary md-btn-flat pr-0"> Save </a>
    </div>

    <a
      *ngIf="!editMode && isLoggedIn && isAdmin"
      appHrefNone
      (click)="openHoursEditModal()"
      class="btn btn-primary md-btn-flat pr-0 text-capitalize"
    >
      Hours
    </a>
  </div>
</nav>

<ng-template #hoursEditModalRef let-c="close">
  <app-hours-edit-modal
    [restaurant]="restaurant"
    [explore]="true"
  ></app-hours-edit-modal>
</ng-template>
