import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({providedIn: 'root'})
export class AppService {
  constructor(private title: Title, private meta: Meta) {
  }

  // Set page title
  set pageTitle(value: string) {
    this.title.setTitle(`${value}`);

    this.titleMeta = value;
  }

  // Check for RTL layout
  get isRTL() {
    return document.documentElement.getAttribute('dir') === 'rtl' ||
      document.body.getAttribute('dir') === 'rtl';
  }

  // Check if IE10
  get isIE10() {
    return typeof document['documentMode'] === 'number' && document['documentMode'] === 10;
  }

  // Layout navbar color
  get layoutNavbarBg() {
    return 'navbar-theme';
  }

  // Layout sidenav color
  get layoutSidenavBg() {
    return 'dark';
  }

  // Layout footer color
  get layoutFooterBg() {
    return 'footer-theme';
  }

  // Animate scrollTop
  scrollTop(to: number, duration: number, element = document.scrollingElement || document.documentElement) {
    if (element.scrollTop === to) {
      return;
    }

    const start = element.scrollTop;
    const change = to - start;
    const startDate = +new Date();

    // t = current time; b = start value; c = change in value; d = duration
    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;

      if (t < 1) {
        return c / 2 * t * t + b;
      }

      t--;

      return -c / 2 * (t * (t - 2) - 1) + b;
    };

    const animateScroll = () => {
      const currentDate = +new Date();
      const currentTime = currentDate - startDate;
      element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration), 10);

      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        element.scrollTop = to;
      }
    };

    animateScroll();
  }

  setAddressBarColor(color: string) {
    this.meta.updateTag({name: 'theme-color', content: color});
  }

  set titleMeta(title: string) {
    this.meta.updateTag({name: 'title', content: title});
  }

  set url(url: string) {
    this.meta.updateTag({name: 'url', content: url});
  }

  set image(image: string) {
    this.meta.updateTag({name: 'image', content: image});
  }

  set typeTag(typeTag: string) {
    this.meta.updateTag({name: 'type', content: typeTag});
  }

  set description(description: string) {
    this.meta.updateTag({name: 'description', content: description});
  }

  set themeColor(color: string) {
    this.meta.updateTag({name: 'theme-color', content: color});
  }

  set keywords(keywords: string) {
    this.meta.updateTag({name: 'keywords', content: keywords});
  }
}
