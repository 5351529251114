import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../../environments/environment';
import { Auth } from '../../decorators/auth.decorator';
import { IsLoggedIn } from '../../decorators/is-logged-in.decorator';
import { Food } from '../../domains/food';
import { RestaurantSimple } from '../../domains/restaurant-simple';
import { User } from '../../domains/user';
import { ModalType } from '../../models/enums/modal-type';
import { Paths } from '../../models/enums/paths';
import { Size } from '../../models/enums/size';
import { Modal } from '../../models/modal';
import { FavoritesService } from '../../services/favorites.service';
import { ModalService } from '../../services/modal.service';
import { NavigatorService } from '../../services/navigator.service';

@Component({
  selector: 'app-favorites',
  templateUrl: 'favorites.component.html',
  styleUrls: [
    'favorites.component.scss',
    '../restaurant/menu-router/menu/menu.component.scss'
  ]
})
export class FavoritesComponent implements OnInit {
  @ViewChild('favoriteFoodModalRef') favoriteFoodModalRef: ElementRef;
  @ViewChild('favoritesModalRef') favoritesModalRef: ElementRef;

  @IsLoggedIn() isLoggedIn: boolean;

  restaurantsThatHaveFavorites: Array<RestaurantSimple> = [];
  currentUser: User;
  currentOpenRestaurant = new RestaurantSimple();
  size = Size;
  favoriteFoodModal: Modal;
  paths = Paths;
  foods: Array<Food>;
  defaultImage: string;
  favoritesModal: Modal;
  favoritesBackgroundImage: string;

  constructor(
    private favoritesService: FavoritesService,
    private modalService: ModalService,
    private navigatorService: NavigatorService,
    private logger: NGXLogger
  ) {
    this.defaultImage = environment.defaultImage;
    this.favoritesBackgroundImage = environment.favoritesBackgroundImage;
  }

  ngOnInit() {
    if (!this.isLoggedIn) {
      this.navigatorService.goToHome();
    } else {
      this.favoritesService
        .getRestaurantsByUserFavorites()
        .subscribe({
          next: restaurantsThatHaveFavorites => {
            this.restaurantsThatHaveFavorites = restaurantsThatHaveFavorites;
          }, error: error => {
            this.logger.error('On getting user favorites', error);
          }
        });
    }
  }

  onFavoriteClick(food: Food) {
    this.navigatorService.goToRestaurantFood(this.currentOpenRestaurant, food);
    this.favoriteFoodModal.close();
  }

  onFavoriteRestaurantClick(restaurant: RestaurantSimple) {
    this.currentOpenRestaurant = restaurant;
    this.foods = [];

    this.favoritesService
      .getFavoriteFoodsByRestaurant(this.currentOpenRestaurant.id)
      .subscribe({
        next: foods => {
          this.foods = foods;
        }, error: error => {
          this.logger.error('On getting favorite foods', error);
        }
      });

    this.favoriteFoodModal = this.modalService.open(this.favoriteFoodModalRef, ModalType.TOP_ROUNDED);
  }

  @Auth()
  openFavoritesModal() {
    this.favoritesModal = this.modalService.open(this.favoritesModalRef, ModalType.FULL_TRANSPARENT);
  }

  onHeaderClick() {
    this.navigatorService.goToRestaurantWithObject(this.currentOpenRestaurant);
    this.favoriteFoodModal.close();
  }
}
