import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Period } from '../../../domains/period';

@Component({
  selector: 'app-navbar-top-special-period-event',
  templateUrl: './navbar-top-period-event-details.component.html',
  styleUrls: ['../navbar.shared.scss']
})
export class NavbarTopPeriodEventDetailsComponent {
  @Input() title: string;
  @Input() period: Period;

  @Output() closeModal = new EventEmitter<boolean>();
  @Output() savePeriod = new EventEmitter<boolean>();

  addEvent() {
    this.savePeriod.emit(true);
  }

  goToBack() {
    this.closeModal.emit(true);
  }
}
