<app-navbar-top-profile *appIfIsMobile [title]="'Marketing'"></app-navbar-top-profile>

<div appAddClassIfMobile="has-navbar-top" class="container px-0 position-relative">
  <h1 appAddClassIfDesktop="text-white" class="text-center font-weight-bolder pt-5 mb-3">
    House plans
  </h1>

  <div
    appAddClassIfMobile="text-muted"
    appAddClassIfDesktop="text-white"
    class="text-center text-large font-weight-light"
  >
    Coming soon
  </div>
</div>
