import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Food } from '../domains/food';
import { Section } from '../domains/section';

@Injectable({providedIn: 'root'})
export class SectionService {
  private readonly sectionUrl: string;

  constructor(private http: HttpClient) {
    this.sectionUrl = `${environment.apiEndpoint}/api/sections`;
  }

  public addSection(section: Section, food: Food) {
    return this.http
      .post<Section>(`${this.sectionUrl}/${food.id}`, section)
      .pipe(map(sectionI => plainToClass(Section, sectionI)));
  }

  public editSection(section: Section) {
    return this.http.put(`${this.sectionUrl}`, section);
  }
}
