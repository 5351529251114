import { plainToClass } from 'class-transformer';
import { Subject } from 'rxjs';
import { Restaurant } from '../../domains/restaurant';
import { Listener } from '../listener';

export class RestaurantListener extends Listener {
  subject = new Subject<Restaurant>();

  constructor(restaurant: Restaurant) {
    super();
    this.topic = `/push/restaurant/${restaurant.hostname}/${restaurant.locationIndex}`;
  }

  transform(frame: any): Restaurant {
    return plainToClass(Restaurant, JSON.parse(frame.body));
  }
}
