export enum PeriodType {
  BUSINESS_HOURS = 'BUSINESS_HOURS',
  BREAKFAST = 'BREAKFAST',
  LUNCH = 'LUNCH',
  DINNER = 'DINNER',
  HAPPY_HOUR = 'HAPPY_HOUR',
  BRUNCH = 'BRUNCH',
  LATE_NIGHT = 'LATE_NIGHT',
  EVENT = 'EVENT',
  CLOSED = 'CLOSED',
  SPECIAL = 'SPECIAL'
}
