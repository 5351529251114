<div *ngIf="user" class="min-h-100vh d-flex flex-column" (swiperight)="onSwipeRight()">
  <ng-container *ngIf="!subscription">
    <div class="membership-header d-flex">
      <div class="membership-user-avatar">
        <app-user-avatar [user]="user" [widthAndHeight]="100"></app-user-avatar>
      </div>

      <div class="membership-user-name text-overflow-crop mx-2">
        <span>{{ user.firstName }} {{ user.lastName }}</span><br>
        <span>@{{ user.username }}</span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="subscription">
    <div class="d-flex p-3">
      <div class="card-block w-100">
        <app-membership-card
          [user]="user"
          [subscription]="subscription"
        ></app-membership-card>
      </div>
    </div>
  </ng-container>
</div>
