<div class="navbar-container max-width fixed-bottom d-flex safe-area-bottom">
  <nav class="navbar navbar-bottom bg-white flex-fill pr-0">
    <app-navbar-bottom-icon-feed></app-navbar-bottom-icon-feed>

    <app-navbar-bottom-icon-explore></app-navbar-bottom-icon-explore>

    <div class="visibility-hidden"></div>
  </nav>

  <app-navbar-bottom-semicircle-notch (menuClick)="onMainMenuClick()">
    <app-image-picker
      *ngIf="food"
      [imageSelectModalTitle]="food.title"
      [itemGallery]="itemGallery"
      (save)="onImageUploaded($event);"
    ></app-image-picker>

    <i *ngIf="!food" class="icon main-grey"></i>
  </app-navbar-bottom-semicircle-notch>

  <nav class="navbar navbar-bottom bg-white flex-fill pl-0">
    <div class="visibility-hidden"></div>

    <app-navbar-bottom-icon-recent></app-navbar-bottom-icon-recent>

    <div *ngIf="isOwner">
      <app-image-picker [enableImageSelectModal]="true" (save)="onImageUploaded($event)"></app-image-picker>
    </div>

    <app-navbar-bottom-icon-user *ngIf="!isOwner"></app-navbar-bottom-icon-user>
  </nav>
</div>
