import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SegmentedButton } from '../models/segmented-button';

@Component({
  selector: 'app-segmented-button',
  templateUrl: './segmented-button.component.html',
  styleUrls: ['./segmented-button.component.scss']
})
export class SegmentedButtonComponent implements OnInit {
  @Input() buttons: [SegmentedButton];
  @Input() activeIndex = 0;

  @Output() change = new EventEmitter<SegmentedButton>();

  holdIndex: number | null = null;

  ngOnInit(): void {
  }

  get highlightTransform(): string {
    return `translateX(${this.activeIndex * 100}%)`;
  }

  setActive(index: number, selectedButton: SegmentedButton): void {
    this.activeIndex = index;
    this.holdIndex = null;

    this.change.emit(selectedButton);
  }

  setHold(index: number): void {
    this.holdIndex = index;
  }

  clearHold(): void {
    this.holdIndex = null;
  }
}
