import { RestaurantExplore } from '../domains/restaurant-explore';
import { SearchResultSlideType } from './enums/search-result-slide-type';
import { TypeCategorySection } from './type-category-section';

export class SearchResultSlide {
  loaded = false;
  type: SearchResultSlideType;
  restaurantId: number;
  restaurant: RestaurantExplore;
  keysString: string;
  typeCategorySection: TypeCategorySection;
}
