<div class="modal-body">
  <div class="form-row mt-2rem">
    <div class="form-group col-6">
      <label for="rest-name" class="font-weight-500">Primary restaurant name</label>
      <input
        [(ngModel)]="restaurant.name"
        type="text"
        id="rest-name-full"
        class="form-control"
      />
    </div>

    <div class="form-group col-6">
      <label for="rest-name" class="font-weight-500">Secondary name</label>
      <input
        [(ngModel)]="restaurant.secondName"
        type="text"
        id="rest-name"
        class="form-control"
      />
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-6">
      <label for="rest-address" class="font-weight-500">Street address</label>
      <input
        [(ngModel)]="restaurant.address"
        type="text"
        id="rest-address"
        class="form-control"
      />
    </div>

    <div class="form-group col-6">
      <label for="rest-zip" class="font-weight-500">Zip</label>
      <input
        [(ngModel)]="restaurant.zipCode"
        type="text"
        id="rest-zip"
        class="form-control"
      />
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-6">
      <label for="rest-email" class="font-weight-500">Location Email</label>
      <input
        [(ngModel)]="restaurant.email"
        type="email"
        id="rest-email"
        class="form-control"
      />
    </div>

    <div class="form-group col-6">
      <label for="rest-phone" class="font-weight-500">Location Phone</label>
      <input
        [(ngModel)]="phone"
        type="tel"
        id="rest-phone"
        class="form-control"
        mask="(000) 000-0000"
        placeholder="(___) ___-____"
      />
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-6">
      <label for="rest-email" class="font-weight-500">Website</label>
      <input
        [(ngModel)]="restaurant.website"
        type="url"
        id="websiste"
        class="form-control"
      />
    </div>

    <div class="form-group col-6">
      <label for="rest-name" class="font-weight-500">Category</label>
      <select
        [(ngModel)]="typeCategorySelected"
        class="form-control custom-select"
      >
        <option *ngFor="let typeCategory of typeCategories" [ngValue]="typeCategory">
          {{ typeCategory.title | andToAmpersand }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-6">
      <label for="rest-email" class="font-weight-500">MmMm</label>
      <input
        [(ngModel)]="restaurant.mmmm"
        type="text"
        id="mmmm"
        class="form-control"
      />
    </div>

    <div class="form-group col-6">
      <label for="rest-phone" class="font-weight-500">Instagram</label>
      <input
        [(ngModel)]="restaurant.instagram"
        type="text"
        id="instagram"
        class="form-control"
      />
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-6">
      <label for="rest-email" class="font-weight-500">Facebook</label>
      <input
        [(ngModel)]="restaurant.facebook"
        type="text"
        id="facebook"
        class="form-control"
      />
    </div>

    <div class="form-group col-6">
      <label for="rest-phone" class="font-weight-500">X/Twitter</label>
      <input
        [(ngModel)]="restaurant.twitter"
        type="text"
        id="twitter"
        class="form-control"
      />
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-6">
      <label for="rest-email" class="font-weight-500">Tiktok</label>
      <input
        [(ngModel)]="restaurant.tiktok"
        type="text"
        id="tiktok"
        class="form-control"
      />
    </div>

    <div class="form-group col-6">
      <div style="margin-top: 1.775rem;" class="text-right">
        <button class="btn btn-link mt-1 col-5" (click)="closeModal()">Cancel</button>

        <button class="btn btn-primary col-6" (click)="onRestaurantChange(); closeModal();">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
