import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { plainToClass } from 'class-transformer';
import { Restaurant } from '../../../../domains/restaurant';
import { RestaurantLocation } from '../../../../models/restaurant-location';

@Component({
  selector: 'app-location-add-modal',
  templateUrl: './location-add-modal.component.html',
  styles: []
})
export class LocationAddModalComponent implements OnInit {
  @Input() restaurant: Restaurant;

  @Output() locationAdd = new EventEmitter<RestaurantLocation>();

  location = new RestaurantLocation();

  locationForm = new UntypedFormGroup({
    zipCode: new UntypedFormControl('', [Validators.required]),
    address: new UntypedFormControl('', [Validators.required]),
    phone: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.email])
  });

  submitClicked = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  get zipCode() {
    return this.locationForm.get('zipCode');
  }

  get address() {
    return this.locationForm.get('address');
  }

  get phone() {
    return this.locationForm.get('phone');
  }

  get email() {
    return this.locationForm.get('email');
  }

  onSubmit() {
    this.submitClicked = true;

    if (this.locationForm.valid) {
      this.location = plainToClass(RestaurantLocation, this.locationForm.value);
      this.locationAdd.emit(this.location);
    }
  }
}
