<div *ngIf="isMobile" class="modal-top-standard bg-dark">
  <input ngbAutofocus type="hidden"/>

  <div class="modal-top-standard-header mx-3">
    <div
      class="flex-content-vc-hc pt-3rem font-weight-600 font-size-1-85rem"
    >
      {{ name.value || this.namePlaceholder }}
    </div>

    <div class="flex-content-vc-hc pb-3-rem font-size-1rem">{{ secondNamePreview }}</div>
  </div>

  <form [formGroup]="restaurantNewForm" (ngSubmit)="onSubmit()">
    <div class="modal-top-standard-body">
      <div class="modal-top-standard-content p-3">
        <div class="mt-0">
          <div class="form-row">
            <div class="form-group col-7">
              <label class="form-label" for="restaurant-name">Primary name</label>

              <input
                appAutocompleteOff
                [ngClass]="{'is-invalid': submitClicked && !name.valid}"
                type="text"
                id="restaurant-name"
                class="form-control form-control-inverted text-muted"
                formControlName="name"
                [placeholder]="namePlaceholder"
                (focus)="hidePlaceholder()"
              />

              <small class="invalid-feedback">
                Required
              </small>
            </div>

            <div class="form-group col-5">
              <label class="form-label" for="restaurant-second-name">
                Secondary
                <span class="text-muted small">optional</span>
              </label>

              <input
                appAutocompleteOff
                type="text"
                id="restaurant-second-name"
                class="form-control form-control-inverted text-muted"
                formControlName="secondName"
                [placeholder]="secondNamePlaceholder"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-7">
              <label class="form-label" for="location-address">Street address</label>

              <input
                appAutocompleteOff
                [ngClass]="{'is-invalid': submitClicked && !address.valid}"
                type="text"
                id="location-address"
                class="form-control form-control-inverted"
                formControlName="address"
              />

              <small class="invalid-feedback">
                Required
              </small>
            </div>

            <div class="form-group col-5">
              <label class="form-label" for="location-zip">Zip</label>

              <input
                ngbAutofocus
                [ngClass]="{inputClass: true, 'is-invalid': submitClicked && !zipCode.valid}"
                type="text"
                id="location-zip"
                class="form-control form-control-inverted"
                formControlName="zipCode"
              >

              <small class="invalid-feedback">
                Required
              </small>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-7">
              <label class="form-label" for="location-email">Restaurant email</label>

              <input
                appAutocompleteOff
                [ngClass]="{'is-invalid': submitClicked && !email.valid}"
                type="email"
                id="location-email"
                class="form-control form-control-inverted"
                formControlName="email"
              />

              <small class="invalid-feedback">
                Invalid email address
              </small>
            </div>

            <div class="form-group col-5">
              <label for="location" class="form-label">Location phone</label>

              <input
                appAutocompleteOff
                type="tel"
                id="location"
                class="form-control form-control-inverted text-muted"
                formControlName="phone"
                mask="(000) 000-0000"
                placeholder="(___) ___-____"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-7">
              <label class="form-label" for="location-email">Website</label>

              <input
                appAutocompleteOff
                [ngClass]="{'is-invalid': submitClicked && !website.valid}"
                type="text"
                id="website"
                class="form-control form-control-inverted"
                formControlName="website"
              />

              <small class="invalid-feedback">
                Invalid
              </small>
            </div>

            <div class="form-group col-5">
              <label class="form-label" for="instagram">Instagram</label>

              <input
                appAutocompleteOff
                [ngClass]="{'is-invalid': submitClicked && !instagram.valid}"
                type="text"
                id="instagram"
                class="form-control form-control-inverted"
                formControlName="instagram"
              />

              <small class="invalid-feedback">
                Invalid
              </small>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-7">
              <table class="w-100">
                <tr>
                  Gain owner access
                </tr>

                <tr class="h-36px">
                  <td
                    class="cursor-pointer text-white"
                  >
                    Email me instructions
                  </td>

                  <td>
                    <label class="custom-control custom-checkbox custom-checkbox-secondary mb-0">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        formControlName="accessEmail"
                      />

                      <span class="custom-control-label cursor-pointer"></span>
                    </label>
                  </td>
                </tr>
              </table>
            </div>

            <div class="form-group col-5">
              <label for="category" class="form-label">Category</label>

              <select
                [ngClass]="{'is-invalid': submitClicked && !category.valid}"
                id="category"
                class="custom-select text-white"
                formControlName="category"
              >
                <option value="" hidden selected>Select</option>

                <option *ngFor="let typeCategory of typeCategories" [ngValue]="typeCategory.id">
                  {{ typeCategory.title | andToAmpersand }}
                </option>
              </select>

              <small class="invalid-feedback">
                Required
              </small>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-top-standard-footer bg-white p-3">
        <div class="modal-top-standard-footer-content">
          <input
            type="submit"
            class="btn btn-white btn-block text-uppercase"
            value="Add Restaurant"
          />
        </div>
      </div>
    </div>
  </form>
</div>

<div
  *ngIf="!isMobile"
  appModalCloseOnClick
  class="h-100 w-100 modal-body p-0 flex-content-vc-hc flex-column"
>
  <div class="w-100 h-100">
    <div class="min-h-100vh flex-content-vc-hc flex-column">
      <div appModalCloseOnClickPrevent class="modal-box">
        <div class="user-authentication text-center">
          <div class="min-h-110px">
            <input ngbAutofocus type="hidden"/>

            <div class="flex-content-vc-hc px-3 pt-2rem font-weight-600 font-size-1-85rem w-100">
              {{ name.value || this.namePlaceholder }}
            </div>

            <div class="flex-content-vc-hc px-3 font-size-1rem w-100" [ngClass]="{'h-23-5px': !secondName.value}">
              {{ secondName.value || this.secondNamePlaceholder }}
            </div>
          </div>

          <form [formGroup]="restaurantNewForm" (ngSubmit)="onSubmit()">
            <div class="modal-standard-body">
              <div class="p-3">
                <div class="mt-2rem">
                  <div class="form-row">
                    <div class="form-group col-7">
                      <label class="form-label d-flex align-items-start" for="restaurant-name">Primary name</label>

                      <input
                        appAutocompleteOff
                        [ngClass]="{'is-invalid': submitClicked && !name.valid}"
                        type="text"
                        id="restaurant-name-desktop"
                        class="form-control form-control-inverted text-muted"
                        formControlName="name"
                        [placeholder]="namePlaceholder"
                        (focus)="hidePlaceholder()"
                      />

                      <small class="invalid-feedback">
                        Required
                      </small>
                    </div>

                    <div class="form-group col-5">
                      <label class="form-label d-flex align-items-start" for="restaurant-second-name">
                        Secondary
                        &nbsp;<span class="text-muted small">optional</span>
                      </label>

                      <input
                        appAutocompleteOff
                        type="text"
                        id="restaurant-second-name-desktop"
                        class="form-control form-control-inverted text-muted"
                        formControlName="secondName"
                        [placeholder]="secondNamePlaceholder"
                      />
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group col-7">
                      <label class="form-label d-flex align-items-start" for="location-address">Street address</label>

                      <input
                        appAutocompleteOff
                        [ngClass]="{'is-invalid': submitClicked && !address.valid}"
                        type="text"
                        id="location-address-desktop"
                        class="form-control form-control-inverted"
                        formControlName="address"
                      />

                      <small class="invalid-feedback">
                        Required
                      </small>
                    </div>

                    <div class="form-group col-5">
                      <label class="form-label d-flex align-items-start" for="location-zip">Zip</label>

                      <input
                        ngbAutofocus
                        [ngClass]="{inputClass: true, 'is-invalid': submitClicked && !zipCode.valid}"
                        type="text"
                        id="location-zip-desktop"
                        class="form-control form-control-inverted"
                        formControlName="zipCode"
                      >

                      <small class="invalid-feedback">
                        Required
                      </small>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group col-7">
                      <label class="form-label d-flex align-items-start" for="location-email">Restaurant email</label>

                      <input
                        appAutocompleteOff
                        [ngClass]="{'is-invalid': submitClicked && !email.valid}"
                        type="email"
                        id="location-email-desktop"
                        class="form-control form-control-inverted"
                        formControlName="email"
                      />

                      <small class="invalid-feedback">
                        Invalid email address
                      </small>
                    </div>

                    <div class="form-group col-5">
                      <label for="location" class="form-label d-flex align-items-start">Location phone</label>

                      <input
                        appAutocompleteOff
                        type="tel"
                        id="location-desktop"
                        class="form-control form-control-inverted text-muted"
                        formControlName="phone"
                        mask="(000) 000-0000"
                        placeholder="(___) ___-____"
                      />
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group col-7">
                      <label class="form-label d-flex align-items-start" for="location-email">Website</label>

                      <input
                        appAutocompleteOff
                        [ngClass]="{'is-invalid': submitClicked && !website.valid}"
                        type="text"
                        id="website-desktop"
                        class="form-control form-control-inverted"
                        formControlName="website"
                      />

                      <small class="invalid-feedback">
                        Invalid
                      </small>
                    </div>

                    <div class="form-group col-5">
                      <label class="form-label d-flex align-items-start" for="instagram">Instagram</label>

                      <input
                        appAutocompleteOff
                        [ngClass]="{'is-invalid': submitClicked && !instagram.valid}"
                        type="text"
                        id="instagram-desktop"
                        class="form-control form-control-inverted"
                        formControlName="instagram"
                      />

                      <small class="invalid-feedback">
                        Invalid
                      </small>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group col-7">
                      <table class="w-100">
                        <tr class="d-flex align-items-start">
                          Gain owner access
                        </tr>

                        <tr class="h-36px">
                          <td
                            class="cursor-pointer text-white d-flex align-items-start pt-2"
                          >
                            Email me instructions
                          </td>

                          <td>
                            <label class="custom-control custom-checkbox custom-checkbox-secondary mb-0">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                formControlName="accessEmail"
                              />

                              <span class="custom-control-label cursor-pointer"></span>
                            </label>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div class="form-group col-5">
                      <label for="category" class="form-label d-flex align-items-start">Category</label>

                      <div
                        appNgbDropdownOnHover
                        ngbDropdown
                        [placement]="'top-left'"
                        class="d-flex align-items-start cursor-pointer pt-2"
                      >
                        <a
                          appHrefNone
                          ngbDropdownToggle
                          class="d-flex text-align-start text-white hide-arrow dropdown-toggle outline-none"
                        >
                          {{ restaurantTypeCategory ? (restaurantTypeCategory.title | andToAmpersand) : 'Select' }}
                        </a>

                        <div
                          ngbDropdownMenu
                          class="bg-dark backdrop-filter-brightness-0-5"
                        >
                          <a
                            *ngFor="let typeCategory of typeCategories"
                            appHrefNone
                            class="dropdown-item d-inline-flex"
                            (click)="onTypeCategoryChange(typeCategory)"
                          >
                            {{ typeCategory.title | andToAmpersand }}
                          </a>
                        </div>
                      </div>

                      <small class="invalid-feedback">
                        Required
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-center mt-2rem px-3 mb-2rem">
                <input
                  type="submit"
                  class="btn btn-white btn-block text-uppercase"
                  value="Add restaurant"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
