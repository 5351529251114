<div class="modal-header flex-content-vc-hc">
  <div class="title-standard image-select-title text-center w-100">
    <span>{{ title ? title : 'Journal the moment' }}</span>
  </div>
</div>

<div class="modal-body p-0">
  <div
    class="food-image-select"
    [appBackgroundUrl]="instagramClicked || !backgroundImage
      ? null
      : backgroundImage.url + '&w=' + windowWidth"
    (click)="onBgImageClick()"
  >
    <div class="row mx-0">
      <ng-container *ngFor="let img of images">
        <div class="col-3 px-0">
          <app-div-rectangle>
            <div class="food-image cursor-pointer" (click)="onImageClick(img)">
              <ng-container *ngIf="!img.isMediaLoaded">
                <div class="image-loader-wrapper position-absolute"></div>
                <img
                  [src]="img.path"
                  [hidden]="true"
                  alt=""
                  (load)="img.isMediaLoaded = true"
                />
              </ng-container>

              <img *ngIf="img.isMediaLoaded" [src]="img.path" class="image-cover" alt=""/>

              <div
                *ngIf="isSameImage(img)"
                class="food-image-selected flex-content-hc"
                (click)="onImageClick(img)"
              >
                <div class="align-self-center text-primary">
                  <i class="ion d-block ion-ios-checkmark-circle-outline"></i>
                </div>
              </div>
            </div>
          </app-div-rectangle>
        </div>
      </ng-container>
    </div>

    <div id="bottom-element" #bottomElement></div>
  </div>

  <div class="btn-group d-flex p-3">
    <button type="button" class="btn btn-white" (click)="library()">Camera</button>

    <button
      *ngIf="!firstLoaded || !igAccessToken || imagesNextUrl || isInstagram"
      type="button"
      class="btn btn-white"
      (click)="instagramButtonClick()"
    >
      {{ !igAccessToken || !firstLoaded ? 'Instagram' : 'Load More' }}
    </button>
  </div>
</div>

<input
  #fileInputCapture
  [hidden]="true"
  type="file"
  accept="image/gif, image/jpeg, image/png"
  capture="environment"
  (change)="onImageUploaded(fileInputCapture.files)"
/>

<input
  #fileInput
  [hidden]="true"
  type="file"
  accept="image/gif, image/jpeg, image/png"
  (change)="onImageUploaded(fileInput.files)"
/>
