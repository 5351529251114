import { Expose, Type } from 'class-transformer';
import { OrderFood } from './order-food';

export class OrderBase {
  @Expose() subtotal = 0;
  @Expose() tipPercentage: number;
  @Expose() tip: number;
  @Expose() salesTax: number;
  @Expose() fee: number;
  @Expose() totalWithoutFee: number;
  @Expose() total: number;
  @Expose() asap: boolean;
  @Expose() feeEnabled = true;
  @Expose() phone: string;

  @Type(() => Date)
  @Expose() requestTime: Date;

  @Type(() => OrderFood)
  @Expose() foods: OrderFood[] = [];
}
