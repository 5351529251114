<ng-container *appIfIsMobile>
  <router-outlet></router-outlet>
</ng-container>

<ng-container *appIfIsDesktop>
  <div class="layout-wrapper layout-2">
    <div class="layout-inner">
      <app-layout-sidenav-left
        [admin]="true"
        [hasReferrer]="hasReferrer"
      ></app-layout-sidenav-left>

      <div appAddClassIfDesktop="layout-container-desktop" class="layout-container">
        <div class="layout-content">
          <div class="container-fluid">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
