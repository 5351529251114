import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IsDevice } from '../../decorators/is-device.decorator';
import { ModalClose } from '../../decorators/modal-close.decorator';
import { ConfirmEmailToken } from '../../domains/confirm-email-token';
import { User } from '../../domains/user';
import { LocalStorage } from '../../local-storage/local-storage';
import { Role } from '../../models';
import { DeviceType } from '../../models/enums/device-type';
import { InstagramState } from '../../models/enums/instagram-state';
import { LocalStorageKey } from '../../models/enums/local-storage-key';
import { ModalType } from '../../models/enums/modal-type';
import { SignupStep } from '../../models/enums/signup-step';
import { Modal } from '../../models/modal';
import { AuthenticationService } from '../../services/authentication.service';
import { InstagramService } from '../../services/instagram.service';
import { ModalService } from '../../services/modal.service';
import { NavigatorService } from '../../services/navigator.service';
import { NotificationService } from '../../services/notification.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-authorization',
  templateUrl: './user-authorization.component.html',
  styleUrls: ['./user-authorization.component.scss']
})
export class UserAuthorizationComponent implements OnInit, OnDestroy {
  @Input() user: User;

  @ViewChild('usernameChoiceModalRef') usernameChoiceModalRef: ElementRef;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  loginBackground: string;
  isLogin = false;
  isSignup = false;
  userSignupStep = SignupStep.REGISTER;
  loginTries = 0;
  returnUrl: string;
  registerForm: UntypedFormGroup;
  usernameForm: UntypedFormGroup;
  loginForm: UntypedFormGroup;
  finishForm: UntypedFormGroup;
  submittedLogin = false;
  submittedSignUp = false;
  usernameChoiceModal: Modal;
  newUser = new User();
  mmmmLogo: string;
  submitted = false;
  usernameChecked = false;
  finishSignUpSubmitted = false;
  isOpenedInModal = true;
  isConfirm = false;
  confirmCheckSubscription: Subscription;
  signupStep = SignupStep;
  isDataReady = false;
  tokenId = 0;

  constructor(
    private socialAuthService: SocialAuthService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private navigatorService: NavigatorService,
    private userService: UserService,
    private instagramService: InstagramService,
    private formBuilder: UntypedFormBuilder,
    private logger: NGXLogger,
    private modalService: ModalService,
    private router: Router
  ) {
    this.newUser.role = Role.USER;
    this.mmmmLogo = environment.mmmmLogo;
    this.loginBackground = environment.loginBackgroundImage;

    this.confirmCheckSubscription = this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd && event.snapshot?.component === UserAuthorizationComponent) {
        this.isConfirm = event.snapshot.data?.type === 'confirm';

        if (this.isConfirm) {
          const queryParams = this.activatedRoute.snapshot.queryParams;

          const id = queryParams['id'];
          const token = queryParams['token'];
          const email = queryParams['e'];
          const role = queryParams['r'];

          this.userService.confirmEmail(new ConfirmEmailToken(id, token, email)).subscribe(data => {
            if (typeof data === 'object') {
              this.authenticationService.saveUserDataToCookie(data);

              this.notificationService.emailConfirmed();

              this.navigatorService.goToUrl('/').then();
            } else if (data) {
              this.userSignupStep = this.signupStep.CREATE_USERNAME;
              this.signupEnable();

              this.newUser.email = email;
              this.newUser.confirmed = true;
              this.newUser.role = role;
            } else {
              this.userSignupStep = this.signupStep.REGISTER;

              // TODO Display notification for invalid confirm url
              this.notificationService.invalidConfirmLink();
            }

            this.isDataReady = true;
          });
        } else {
          this.isDataReady = true;
        }
      }
    });

    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.finishForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

    this.usernameForm = this.formBuilder.group({
      username: ['',
        {
          validators: [Validators.required, Validators.pattern(/^[a-zA-Z0-9._]+$/)],
          // asyncValidators: [this.usernameValidator()],
          // updateOn: 'blur'
        }
      ]
    });

    this.finishForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  ngOnInit(): void {
    this.isOpenedInModal = document.getElementsByTagName('ngb-modal-window').length > 0;

    if (this.isOpenedInModal) {
      this.isDataReady = true;
    }

    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';
  }

  ngOnDestroy() {
    this.confirmCheckSubscription.unsubscribe();
  }

  get fr() {
    return this.registerForm.controls;
  }

  get finishFormControls() {
    return this.finishForm.controls;
  }

  get usernameControl() {
    return this.usernameForm.controls.username;
  }

  get fl() {
    return this.loginForm.controls;
  }

  onLoginClick(type: string) {
    let providerId;
    let isSocial = false;

    switch (type) {
      case 'google':
        isSocial = true;
        providerId = GoogleLoginProvider.PROVIDER_ID;
        break;
      case 'facebook':
        isSocial = true;
        providerId = FacebookLoginProvider.PROVIDER_ID;
        break;
      case 'ig':
        this.navigatorService.goToExternal(this.instagramService.getCodeUrl(InstagramState.LOGIN));
    }

    if (isSocial) {
      this.socialAuthService
        .signIn(providerId)
        .then((user: SocialUser) => {
          this.modalClose();

          this.userService.checkSocialUserToExists(user).subscribe(exists => {
            if (exists) {
              this.authenticationService.socialLogin(user)
                .subscribe({
                  next: () => {
                    if (!this.isOpenedInModal) {
                      this.navigatorService.goToUrl(this.returnUrl).then();
                    }
                  }, error: error => {
                    this.logger.error('On signing in', error);
                  }
                });
            } else {
              this.newUser.socialId = user.id;
              this.newUser.email = user.email;
              this.newUser.socialToken = user.authToken;
              this.newUser.firstName = user.firstName;
              this.newUser.lastName = user.lastName;
              this.newUser.confirmed = true;
              this.newUser.socialUser = user;

              this.usernameChoiceModal = this.modalService.open(
                this.usernameChoiceModalRef,
                ModalType.LOGIN_HELPER
              );
            }
          });
        });
    }
  }

  loginAndSignupSwitch() {
    if (this.isSignup) {
      this.loginEnable();
    } else {
      this.signupEnable();
    }
  }

  loginEnable() {
    this.isSignup = false;
    this.isLogin = true;
  }

  signupEnable() {
    this.isLogin = false;
    this.isSignup = true;
  }

  onDefaultLoginClick() {
    if (this.isLogin) {
      this.submittedLogin = true;

      if (this.loginForm.invalid) {
        return;
      }

      this.authenticationService
        .login(this.fl.email.value, this.fl.password.value)
        .subscribe({
          next: () => {
            if (this.isOpenedInModal) {
              this.modalClose();
            } else {
              this.navigatorService.goToUrl(this.returnUrl).then();
            }
          }, error: error => {
            this.logger.error('On user login', error);
            this.loginTries++;

            if (this.loginTries >= 3) {
              this.userService
                .resetPasswordSendingEmail(this.fl.email.value, Role.USER)
                .subscribe({
                  next: () => {
                    this.notificationService.passwordResetLinkSent(this.fl.email.value);
                  }, error: err => {
                    this.notificationService.loginError();
                    this.logger.error('On resetting email', err);
                  }
                });
            } else {
              this.notificationService.loginError();
            }
          }
        });
    } else {
      this.submittedSignUp = true;

      if (this.registerForm.invalid) {
        return;
      }

      this.userService.checkEmailToExists(this.fr.email.value)
        .subscribe((tokenId) => {
          if (tokenId === 0) {
            this.fr.email.setErrors({exists: true});
          } else {
            this.tokenId = tokenId;
            this.newUser.email = this.fr.email.value;
            this.newUser.confirmed = false;

            this.userSignupStep = this.signupStep.CHECK_EMAIL;
          }
        });
    }
  }

  onUsernameSelected(username: string) {
    this.usernameChoiceModal.close();
    this.usernameChoiceModal = null;

    this.newUser.username = username;

    this.signUp(this.newUser);
  }

  private signUp(user: User) {
    this.authenticationService.signUp(user)
      .subscribe({
        next: () => {
          this.modalClose();

          this.notificationService.welcome(this.newUser.firstName, this.newUser.lastName);

          this.newUser = new User();

          this.navigatorService.goToUrl('/').then();
        }, error: error => {
          this.logger.error('On user signup', error);
        }
      });
  }

  onNextClick() {
    this.submitted = true;

    if (this.usernameForm.invalid) {
      return;
    }

    this.userService.checkUsernameToExists(this.usernameControl.value)
      .subscribe(usernameCheck => {
        this.usernameChecked = true;

        if (usernameCheck.user || usernameCheck.instagram || usernameCheck.restaurant) {
          this.usernameControl.setErrors({exists: true});
        } else {
          this.newUser.username = this.usernameControl.value;

          this.userSignupStep = this.signupStep.FINISH;
        }
      });
  }

  changeEmail() {
    this.userSignupStep = this.signupStep.REGISTER;
  }

  onResendClicked() {
    this.userService.resendConfirmationEmail(this.tokenId, this.newUser.email)
      .subscribe({
        next: () => {
          this.notificationService.confirmationEmailSent(this.newUser.email);
        }, error: () => {
          this.notificationService.makeSelection();
        }
      });
  }

  onFinishClick() {
    this.finishSignUpSubmitted = true;

    if (this.finishForm.invalid) {
      return;
    }

    this.newUser.firstName = this.finishFormControls.firstName.value;
    this.newUser.lastName = this.finishFormControls.lastName.value;
    this.newUser.password = this.finishFormControls.password.value;

    this.signUp(this.newUser);

    this.modalClose();
  }

  @ModalClose({all: true})
  private modalClose() {
  }

  instagramClick() {
    const tempUserLocalStorage = new LocalStorage(User, LocalStorageKey.TEMP_USER);
    tempUserLocalStorage.setItem(this.user);

    this.navigatorService.goToExternal(this.instagramService.getCodeUrl(InstagramState.USERNAME));
  }
}
