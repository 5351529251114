<div class="modal-body ui-mh-100vh flex-content-hc flex-column" (swipe)="modalClose()">
  <div class="icon-container flex-content-hc">
    <div *ngIf="path === paths.GEMS" class="icon-v2 diamond-blue"></div>
    <div *ngIf="path === paths.FAVORITES" class="icon main-circle-white"></div>
    <div *ngIf="path === paths.MESSAGE" class="icon-v2 chat-white"></div>
    <div *ngIf="path === paths.COMMENT" class="icon-v2 chat-filled"></div>
  </div>

  <div *ngIf="path !== paths.USER" class="text-box text-center">
    <p>
      <ng-container *ngIf="path === paths.GEMS">Your feed is coming!</ng-container>
      <ng-container *ngIf="path === paths.FAVORITES">Mber favorites are coming!</ng-container>
      <ng-container *ngIf="path === paths.MESSAGE">Messaging is coming!</ng-container>
      <ng-container *ngIf="path === paths.COMMENT">Comments are coming!</ng-container>
      <br>
      We'll email you when it's ready
    </p>
  </div>

  <div *ngIf="path === paths.USER" class="text-box text-center">
    <p>Follows are coming soon!</p>
  </div>

  <div
    *ngIf="!isSubscribed"
    class="flex-content-hc btn-box mber-button"
  >
    <button
      class="btn rounded-pill btn-outline-white text-transform-none"
      (click)="onSubscribeClick();"
    >
      Become a Mber
    </button>
  </div>

  <div class="mt-auto"></div>
</div>
