import { ImageUtils } from '../utils/image-utils';
import { BaseImage } from './base-image';

export class Image extends BaseImage {
  id: number;
  igId: number;
  orient: number;

  isMediaLoaded = false;

  get isDataOrURL() {
    return ImageUtils.isDataOrURL(this.path);
  }

  get ratio() {
    return this.width >= this.height ? this.width / this.height : this.height / this.width;
  }

  get isPost() {
    return this.path.startsWith('/post');
  }
}
