import { Injectable } from '@angular/core';
import Hashids from 'hashids';
import { nanoid } from 'nanoid';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../environments/environment';
import { Food } from '../domains/food';
import { Post } from '../domains/post';
import { Restaurant } from '../domains/restaurant';
import { RestaurantSimple } from '../domains/restaurant-simple';
import { User } from '../domains/user';
import { LoaderService } from './loader.service';
import { NavigatorShareService } from './navigator-share.service';
import { TypeCategoryService } from './type-category.service';

@Injectable({providedIn: 'root'})
export class ShareService {
  constructor(
    private navigatorShareService: NavigatorShareService,
    private logger: NGXLogger,
    private typeCategoryService: TypeCategoryService,
    private loaderService: LoaderService
  ) {
  }

  private share(title: string, text: string, url: string) {
    this.loaderService.showLoader();

    this.navigatorShareService.share({
      title,
      text,
      url
    }).then(response => {
      this.logger.debug('Shared:', response);
    }).catch(error => {
      this.logger.error('On share', error, {title, text, url});
    }).finally(() => {
      this.loaderService.hideLoader();
    });
  }

  private windowOpen(url: string) {
    const linkElement: HTMLAnchorElement = document.createElement('a');
    linkElement.setAttribute('target', '_blank');

    linkElement.setAttribute('rel', 'noopener noreferrer');
    linkElement.href = url;
    linkElement.click();
    linkElement.remove();
  }

  private shareOnTwitter(url: string, text: string, hashtags = []) {
    this.loaderService.showLoader();

    let twitterUrl = `https://twitter.com/share?text=${encodeURIComponent(text)}&url=${url}`;

    if (hashtags.length > 0) {
      twitterUrl += `&hashtags=${hashtags.join(',')}`;
    }

    this.windowOpen(twitterUrl);

    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 500);
  }

  private shareOnFacebook(url: string, text: string, hashtag: string = null) {
    this.loaderService.showLoader();

    const fbUrl = `https://www.facebook.com/sharer.php?u=${url}&display=page&ref=plugin&src=share_button`;

    // const fbUrl = `https://www.facebook.com/dialog/share?`
    //         + `app_id=${environment.facebookAppId}`
    //         + `&display=page`
    //         + `&href=${url}`
    //         + `&quote=${encodeURIComponent(text)}`
    //         + `&hashtag=${hashtag ? hashtag : '#mmmm'}`
    //         + `&redirect_uri=${url}`;

    this.windowOpen(fbUrl);

    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 500);
  }

  shareMMMM(social: string = null) {
    this.loaderService.showLoader();

    const url = `${environment.domain}`;
    const text = 'A directory of restaurant Instagrams & videos by creators';

    switch (social) {
      case 'twitter':
        this.shareOnTwitter(url, text, ['MmMm']);

        break;
      case 'facebook':
        this.shareOnFacebook(url, text);

        break;
      default:
        this.share('', text, url);

        break;
    }

    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 500);
  }

  shareFood(restaurant: Restaurant, food: Food, social: string = null) {
    this.loaderService.showLoader();

    const url = `https://${restaurant.hostname}.${environment.domain}${food.profileUrl}`;
    const title = `${food.title.trim()} • ${restaurant.nameView.trim()} • ${restaurant.cityAndState}`;

    switch (social) {
      case 'twitter':
        this.shareOnTwitter(url, title, ['MmMm']);

        break;
      case 'facebook':
        this.shareOnFacebook(url, title);

        break;
      default:
        this.share(title, '', url);

        break;
    }

    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 500);
  }

  shareCity(restaurant, social: string = null) {
    this.loaderService.showLoader();

    const url = `${location.href}`;
    const text = `A directory of every restaurant’s Instagram in ${restaurant.cityAndState}

Your neighborhood’s menu highlighted by YouTube | IG | TikTok creators`;

    switch (social) {
      case 'twitter':
        this.shareOnTwitter(url, text, ['MmMm']);

        break;
      case 'facebook':
        this.shareOnFacebook(url, text);

        break;
      default:
        this.share('', text, url);

        break;
    }

    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 500);
  }

  shareGlobal() {
    this.loaderService.showLoader();

    this.share('Kill PDFs', '', `https://${environment.domain}`);

    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 500);
  }

  shareUrl(url) {
    this.loaderService.showLoader();

    this.share('', '', url);

    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 500);
  }

  shareRestaurant(restaurant: Restaurant | RestaurantSimple, social: string = null) {
    this.loaderService.showLoader();

    const url = `https://${restaurant.hostname}.${environment.domain}`;

    let title = restaurant.name;

    if (restaurant.secondName) {
      title += ` • ${restaurant.secondName}`;
    }

    title += ` • ${restaurant.cityAndState}`;

    switch (social) {
      case 'twitter':
        this.shareOnTwitter(url, title, ['MmMm']);

        break;
      case 'facebook':
        this.shareOnFacebook(url, title);

        break;
      default:
        this.share(title, '', url);

        break;
    }

    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 5000);
  }

  notifyOwner(restaurant: Restaurant | RestaurantSimple) {
    this.share(
      'Welcome to mmmm.menu a community of dining journals.',
      `Welcome to mmmm.menu a community of dining journals.\n\n` +
      `Verify ownership of "${restaurant.nameView}"` +
      'to gain admin privileges and mmmm power customers to promote you\n\n',
      `https://${restaurant.hostname}.${environment.domain}`
    );
  }

  sharePost(post: Post, user: User) {
    this.loaderService.showLoader();

    const hashIds = new Hashids('', 8);
    const uniqueToken = nanoid(8) + hashIds.encode(user.id);

    this.share(
      `${post.user.firstName} ${post.user.lastName}`,
      post.caption ? post.caption : '',
      `https://${environment.domain}/${post.user.username}/post/${post.hash}?uid=${uniqueToken}`
    );

    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 500);
  }

  sharePosOnSocial(post: Post, user: User, social: string = null) {
    this.loaderService.showLoader();

    const hashIds = new Hashids('', 8);
    const uniqueToken = nanoid(8) + hashIds.encode(user.id);

    const title = `${post.user.firstName} ${post.user.lastName}`;

    const url = `https://${environment.domain}/${post.user.username}/post/${post.hash}?uid=${uniqueToken}`;

    switch (social) {
      case 'twitter':
        this.shareOnTwitter(url, title, ['MmMm']);

        break;
      case 'facebook':
        this.shareOnFacebook(url, title);

        break;
      default:
        this.share(title, '', url);

        break;
    }

    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 5000);
  }
}
