import { Component, Input, OnInit } from '@angular/core';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { DeviceType } from '../../../models/enums/device-type';

@Component({
  selector: 'app-user-loader',
  templateUrl: './user-loader.component.html',
  styleUrls: ['./../user.component.scss']
})
export class UserLoaderComponent implements OnInit {
  @Input() isSearching = false;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  ngOnInit(): void {
  }
}
