import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { DeviceType } from '../../../models/enums/device-type';
import { ModalType } from '../../../models/enums/modal-type';
import { Modal } from '../../../models/modal';
import { Place } from '../../../models/place';
import { SearchParams } from '../../../models/search-params';
import { SearchResult } from '../../../models/search-result';
import { ModalService } from '../../../services/modal.service';
import { SearchCityService } from '../../../services/search-city.service';
import { SearchParamsService } from '../../../services/search-params.service';

@Component({
  selector: 'app-search-city',
  templateUrl: './search-city.component.html',
  styles: []
})
export class SearchCityComponent implements OnInit, OnDestroy {
  @Input() searchResult: SearchResult;

  @Output() localitySelected = new EventEmitter<SearchParams>();
  @Output() findMe = new EventEmitter<boolean>();
  @Output() modalClosed = new EventEmitter<boolean>();

  @ViewChild('searchCityModalRef') searchCityModalRef: ElementRef;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  searchCityModal: Modal;
  cityModalSubjectSubscription: Subscription;

  constructor(
    private modalService: ModalService,
    private searchCityService: SearchCityService,
    private searchParamsService: SearchParamsService
  ) {
    this.cityModalSubjectSubscription = this.searchCityService.openSearchCityModalSubject.subscribe(() => {
      this.openSearchCityModal();
    });
  }

  ngOnInit(): void {
    if (this.cityModalSubjectSubscription === undefined) {
      this.cityModalSubjectSubscription = this.searchCityService.openSearchCityModalSubject.subscribe(value => {
        this.openSearchCityModal();
      });
    }
  }

  ngOnDestroy(): void {
    this.cityModalSubjectSubscription?.unsubscribe();
  }

  openSearchCityModal() {
    this.searchCityModal = this.modalService.open(
      this.searchCityModalRef,
      this.isMobile ? ModalType.TOP_SEARCH : ModalType.SEARCH_SQUARE
    );

    this.searchCityModal.onClose().then(() => {
      this.modalClosed.emit(true);
    });
  }

  onSelectPlace(place: Place) {
    this.searchCityModal.close();

    this.searchParamsService.getByPlaceId(place.placeId).subscribe(params => {
      this.localitySelected.emit(params);
    });
  }

  onFindMe() {
    this.searchCityModal.close();
    this.modalClosed.emit(true);
    this.findMe.emit(true);
  }
}
