<ng-container *ngIf="currentUser?.id == post.user.id">
  <div *ngIf="isDesktop" ngbDropdown appNgbDropdownOnHover [placement]="'bottom-right'" class="about-restaurant-menu">
    <div
      ngbDropdownToggle
      class="hide-arrow cursor-pointer"
    >
      <span [ngClass]="{'dots-v': !postDesktop, 'dots-h-white': postDesktop}" class="icon-v2 cursor-pointer"></span>
    </div>

    <div
      ngbDropdownMenu
      class="bg-dark backdrop-filter-blur-5"
    >
      <a appHrefNone class="dropdown-item d-inline-flex text-white" (click)="editPost()">
        Edit
      </a>

      <a appHrefNone class="dropdown-item d-inline-flex text-white" (click)="openPostModal()">
        Delete
      </a>
    </div>
  </div>

  <app-picker
    *ngIf="!isDesktop"
    [picker]="userPicker"
    [classes]="'icon ml-3 ' + (isDark ? 'ellipsis mt-n15px opacity-50' : 'dots-v-white')"
    [title]="postsMobile ? (currentUser?.firstName + ' ' + currentUser?.lastName) : post.food.title"
    (selectedValue)="onUserOptionsSelected($event)"
  ></app-picker>
</ng-container>

<ng-container *ngIf="currentUser?.id !== post.user.id">
  <div *ngIf="isDesktop" ngbDropdown appNgbDropdownOnHover [placement]="'bottom-right'" class="about-restaurant-menu">
    <div
      ngbDropdownToggle
      class="hide-arrow"
    >
      <span [ngClass]="{'dots-v': !postDesktop, 'dots-h-white': postDesktop}" class="icon-v2"></span>
    </div>

    <div
      ngbDropdownMenu
      class="availability-dropdown dropdown-menu-left bg-dark"
    >
      <a
        appHrefNone
        class="dropdown-item d-inline-flex text-white"
        (click)="onReportOptionsSelected(postReportType.SPAM)"
      >
        Spam
      </a>

      <a
        appHrefNone
        class="dropdown-item d-inline-flex text-white"
        (click)="openPostModal()"
      >
        Inappropriate
      </a>

      <a
        appHrefNone
        class="dropdown-item d-inline-flex text-white"
        (click)="onReportOptionsSelected(postReportType.ILLEGAL)"
      >
        Illegal
      </a>
    </div>
  </div>

  <app-picker
    *ngIf="!isDesktop"
    [classes]="'icon ml-3 ' + (isDark ? 'ellipsis mt-n15px opacity-50' : 'dots-v-white')"
    [titleWidth]="'3.9rem'"
    [picker]="reportPicker"
    (selectedValue)="onReportOptionsSelected($event)"
  ></app-picker>
</ng-container>

<ng-template #postModalRef>
  <app-report-video
    [currentUser]="currentUser"
    [post]="post"
    [isPost]="true"
    (postDeleted)="confirmPostDelete()"
  ></app-report-video>
</ng-template>
