<div class="additions-cs-header modal-blur-dark-fixed flex-content-vc fixed-top px-3">
  <div>
    <p class="font-weight-500 cursor-pointer mb-0 text-default">
      {{ food.title }}
    </p>
  </div>

  <div *ngIf="isAdmin" class="ml-auto">
    <div class="cursor-pointer" style="width: 32px;">
      <app-div-rectangle>
        <app-image-picker [enableImageSelectModal]="true" (save)="updateFoodImage($event)">
          <app-food-img
            *ngIf="food.images && food.images.length > 0"
            [images]="food.images"
            [width]="100"
            [height]="100"
            [fromAdmin]="true"
            [alt]="food.title"
          ></app-food-img>
        </app-image-picker>
      </app-div-rectangle>
    </div>
  </div>
</div>

<div
  *ngIf="delayed"
  [ngClass]="{'additions-cs-content-admin': isAdmin}"
  class="additions-cs-content modal-blur-dark-fixed d-flex flex-column"
>
  <input ngbAutofocus type="hidden"/>

  <div *ngIf="food" class="p-3">
    <textarea
      [(ngModel)]="food.description"
      appTextareaAutosize
      rows="4"
      class="textarea-transparent p-0"
      placeholder="Description"
      (change)="onSave()"
    ></textarea>
  </div>

  <div *ngIf="isAdmin">
    <div ngbAccordion class="additions-cs-accordion" [closeOthers]="true">
      <div ngbAccordionItem id="accordion-sizes" [disabled]="!food.id">
        <div ngbAccordionHeader>
          <button ngbAccordionButton class="btn btn-link w-100 d-flex justify-content-between">
            <span class="text-body">Sizes | Options</span>
            <div class="collapse-icon collapse-icon-inverted"></div>
          </button>
        </div>

        <div ngbAccordionCollapse>
          <app-options-cs
            [food]="food"
            [section]="size"
            [type]="sectionType.SIZE"
          ></app-options-cs>
        </div>
      </div>

      <ng-container *ngFor="let i of [0, 1, 2]">
        <div
          ngbAccordionItem
          id="accordion-options-{{i}}"
          [disabled]="!food.id || (i != 0 && options[i - 1] == undefined)"
        >
          <div ngbAccordionHeader>
            <button ngbAccordionButton class="btn btn-link w-100 d-flex justify-content-between">
              <span class="text-body">Combos | Extras</span>
              <div class="collapse-icon collapse-icon-inverted"></div>
            </button>
          </div>

          <div ngbAccordionCollapse>
            <app-options-cs
              [food]="food"
              [section]="options[i]"
              [type]="sectionType.OPTION"
            ></app-options-cs>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="p-3">
      <app-tags-edit
        [food]="food"
        [tags]="tags"
        [showWarnings]="showWarnings"
        [isCs]="true"
        (save)="onSave()"
      ></app-tags-edit>
    </div>
  </div>

  <div *ngIf="!isAdmin">
    <div class="p-3">Sizes | Options</div>

    <div class="p-3">
      <app-options-cs
        [food]="food"
        [section]="size"
        [type]="sectionType.SIZE"
      ></app-options-cs>
    </div>
  </div>
</div>
