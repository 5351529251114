import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { IDeactivateComponent } from '../interfaces/ideactivate.component';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({providedIn: 'root'})
class DeactivateChecker {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
  }

  canDeactivate(
    component: IDeactivateComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.router['lastLocationChangeInfo']?.source === 'popstate' &&
      nextState.url === `/${this.authenticationService.currentUser.username}/post`
    ) {
      return component.canDeactivate();
    }

    return true;
  }
}

export const DeactivateGuard: CanDeactivateFn<IDeactivateComponent> = (
  component: IDeactivateComponent,
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  nextState: RouterStateSnapshot
): Observable<boolean> | Promise<boolean> | boolean => {
  return inject(DeactivateChecker).canDeactivate(component, route, state, nextState);
};
