<nav class="navbar navbar-top fixed-top bg-white max-width safe-area-top">
  <div class="navbar-top-left navbar-width-30px">
    <span class="cursor-pointer text-black font-size-0-9rem" (click)="onMenusClick()">
      Menus
    </span>
  </div>

  <a *ngIf="title" appHrefNone class="navbar-brand navbar-title mr-0 text-center" (click)="goBack()">
    {{ title }}
  </a>

  <div class="navbar-top-right navbar-width-30px">
    <a appHrefNone (click)="save()" class="btn btn-primary md-btn-flat pr-0 text-capitalize">
      Save
    </a>
  </div>
</nav>

<ng-template #menuListModalRef>
  <app-menu-list [restaurant]="restaurant" (enabledMenusSave)="onEnabledMenusSave($event)"></app-menu-list>
</ng-template>
