import { Injectable } from '@angular/core';
import exifr from 'exifr';
import { Image as ImageModel } from '../models/image';
import { FileUtils } from '../utils/file-utils';

@Injectable({providedIn: 'root'})
export class ImageService {
  constructor() {
  }

  private getFileDataAsImage(file: File, fileData: string): Promise<ImageModel> {
    return new Promise(resolve => {
      const image = new ImageModel();
      image.path = fileData;

      const imageElement = new Image();
      imageElement.src = fileData;

      imageElement.onload = (_event) => {
        image.width = imageElement.width;
        image.height = imageElement.height;

        exifr.orientation(file).then(value => {
          if (!value) {
            value = 0;
          }

          image.orient = value;

          resolve(image);
        });
      };
    });
  }

  getUploadedAsImage(file: File): Promise<ImageModel> {
    return new Promise(resolve => {
      FileUtils.getFileData(file).then(fileData => {
        this.getFileDataAsImage(file, fileData).then(image => {
          resolve(image);
        });
      });
    });
  }

  getUploadedAsImageCompressed(file: File): Promise<ImageModel> {
    return new Promise(resolve => {
      this.getUploadedAsImage(file).then(image => {
        // TODO - Check image orientation and depending on that, compress by width or height
        const compressOptions = image.width >= image.height ? {maxHeight: 2160} : {maxWidth: 2160};

        FileUtils.getFileDataCompressed(file, compressOptions).then(fileData => {
          this.getFileDataAsImage(file, fileData).then(imageCompressed => {
            resolve(imageCompressed);
          });
        });
      });
    });
  }
}
