<app-food-img
  *ngIf="!food.googleImage"
  [images]="food.images"
  [width]="720"
  [height]="720"
  [alt]="food.title"
></app-food-img>

<div
  class="card position-absolute-0 bg-transparent classic-item-text"
  (mouseover)="foodHovered = true"
  (mouseleave)="foodHovered = false"
>
  <span ngPreserveWhitespaces class="text-white w-100">
    <div [ngClass]="{'bg-black-70': foodHovered}" class="restaurant-food-image-title bottom-0 text-center">
      {{ food?.title }}
    </div>

    <div *ngIf="foodHovered" class="restaurant-food-image-title top-0 text-center bg-black-70">
      {{ restaurant?.nameView }}
    </div>
 </span>
</div>

<div *ngIf="!foodHovered" ngPreserveWhitespaces class="classic-item-status">
  <span
    *ngIf="food.status.isSoldOut"
    class="badge badge-danger text-uppercase"
  >
    Sold out
  </span>

  <ng-container *ngFor="let tag of food.tags">
    <span
      *ngIf="!food.status.isSoldOut && tag.tagType == tagType.temp"
      class="badge badge-outline-white text-uppercase"
    >
      {{ tag.value }}
    </span>
  </ng-container>
</div>
