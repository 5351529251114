import { Exclude, Type } from 'class-transformer';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { CategoryStatus } from '../models/enums/category-status';
import { CategoryType } from '../models/enums/category-type';
import { DrinkType } from '../models/enums/drink-type';
import { Food } from './food';
import { Tag } from './tag';
import { User } from './user';

export class Category {
  categoryStatusChange = new BehaviorSubject<CategoryStatus>(CategoryStatus.AVAILABLE);

  id: number;
  uuid = uuidv4();
  title: string;
  description: string;
  type: CategoryType;
  itemCount: number;
  ordering: number;
  tags: Array<Tag>;
  drinkType: DrinkType;
  default: boolean;

  @Type(() => Food)
  foods: Array<Food> = new Array<Food>();

  voluntary: boolean;
  volunteer: User;
  hidden: boolean;

  isInvalid = false;

  @Exclude()
  originalFoods: Food[] = [];

  constructor() {
  }

  get isEmpty(): boolean {
    if (this.title) {
      return false;
    }

    if (this.description) {
      return false;
    }

    return this.foods.every(food => !food.title && !food.description);
  }

  get placeholder(): string {
    return this.drinkType ? this.drinkType : 'Category';
  }

  get viewTitle(): string {
    return this.title ? this.title : ' ';
  }

  set categoryStatus(categoryStatus: CategoryStatus) {
    this.categoryStatusChange.next(categoryStatus);
  }

  get categoryStatus() {
    return this.categoryStatusChange.getValue();
  }

  addNewFood(withOrder: boolean) {
    this.foods.push(new Food(withOrder));
  }

  foodPrev(food: Food) {
    const foodIndex = this.foods.indexOf(food);

    if (foodIndex > -1) {
      return this.foods[foodIndex - 1];
    } else {
      return null;
    }
  }

  foodNext(food: Food) {
    const foodIndex = this.foods.indexOf(food);

    if (foodIndex > -1 && foodIndex < (this.foods.length - 1)) {
      return this.foods[foodIndex + 1];
    } else {
      return null;
    }
  }
}
