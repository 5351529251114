<ng-container *ngIf="isImageExists()">
  <img
    *ngIf="images[0].isDataOrURL"
    [src]="images[0].path"
    class="w-100 h-100"
    alt=""
  >

  <div *ngIf="!images[0].isDataOrURL" class="w-100 h-100">
    <ng-container *ngIf="!isImageLoaded">
      <ng-container *ngTemplateOutlet="noImage"></ng-container>
    </ng-container>

    <img
      *ngIf="showImageBeforeLoad"
      appBlurImg
      [path]="images[0].path"
      [options]="{w: width, h: height, fit: 'crop'}"
      (load)="load()"
      (error)="error()"
      class="image-cover"
      [alt]="alt"
    >

    <div *ngIf="!fromAdmin" class="background-image-gradient"></div>
  </div>
</ng-container>

<ng-container *ngIf="!isImageExists()">
  <ng-container *ngTemplateOutlet="noImage"></ng-container>
</ng-container>

<ng-template #noImage>
  <div
    [appBackgroundUrl]="defaultMenuImage"
    class="bg-cover-transparent h-100 w-100 d-flex justify-content-center align-items-center"
  >
    <div
      [ngClass]="{'opacity-01-to-1-on-hover': !foodGallery, 'opacity-01': foodGallery}"
      class="icon-v2 mmmm-logo-white scale-2_5"
    ></div>
  </div>
</ng-template>



