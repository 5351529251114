<div class="modal-body ui-mh-100vh" (swipe)="onSwipe()">
  <div class="p-5 content text-center">
    <div>
      <a href="https://{{restaurant.hostname}}.{{domain}}" class="domain">
        {{ restaurant.hostname }}.{{ domain }}
      </a>
    </div>

    <div *ngIf="restaurant.qr" class="m-2">
      <div class="w-100">
        <img
          appBlurImg
          [path]="restaurant.qr.path"
          [options]="{w: restaurant.qr.width, h: restaurant.qr.height}"
          class="w-100"
          [alt]="restaurant.nameView"
        />
      </div>
    </div>
  </div>
</div>
