import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LocalStorage } from '../local-storage/local-storage';
import { LocalStorageKey } from '../models/enums/local-storage-key';
import { GeoLocation } from '../models/geo-location';
import { ExploreRouteParams } from '../models/route-params/explore-route-params';
import { SearchParams } from '../models/search-params';
import { GeoLocationService } from './geo-location.service';

@Injectable({providedIn: 'root'})
export class SearchParamsService {
  private readonly searchParamsUrl: string;

  searchParamsByGeoLocationLocalStorage = new LocalStorage(
    SearchParams,
    LocalStorageKey.SEARCH_PARAMS_BY_GEO_LOCATION
  );

  geoLocationLocalStorage = new LocalStorage(GeoLocation, LocalStorageKey.GEO_LOCATION);
  searchParamsLocalStorage = new LocalStorage(SearchParams, LocalStorageKey.SEARCH_PARAMS);

  constructor(
    private http: HttpClient,
    private geoLocationService: GeoLocationService,
    private logger: NGXLogger
  ) {
    this.searchParamsUrl = `${environment.apiEndpoint}/api/search-params`;
  }

  getByGeoLocation(geoLocation: GeoLocation, findMe: boolean) {
    return this.http
      .get(
        `${this.searchParamsUrl}/geo-location`,
        {params: {'geo-location': JSON.stringify(geoLocation), 'find-me': findMe}}
      )
      .pipe(map(value => plainToClass(SearchParams, value)));
  }

  getByExploreParams(exploreRouteParams: ExploreRouteParams) {
    return this.http
      .get(
        `${this.searchParamsUrl}/explore-params`,
        {params: {'explore-params': JSON.stringify(exploreRouteParams)}}
      )
      .pipe(map(value => plainToClass(SearchParams, value)));
  }

  getByPlaceId(placeId: string) {
    return this.http
      .get(`${this.searchParamsUrl}/place-id`, {params: {'place-id': placeId}})
      .pipe(map(value => plainToClass(SearchParams, value)));
  }

  getByMyGeoLocation(byGeoLocation = false): Promise<SearchParams> {
    return new Promise((resolve, reject) => {
      this.geoLocationService.getGeoLocation(true).then(geoLocationNew => {
        const geoLocation = this.geoLocationLocalStorage.getItem();
        const searchParamsByGeoLocation = this.searchParamsByGeoLocationLocalStorage.getItem();

        if (
          geoLocation &&
          searchParamsByGeoLocation &&
          this.geoLocationService.areSameGeoLocations(geoLocation, geoLocationNew) &&
          this.geoLocationService.areSameGeoLocations(geoLocation, searchParamsByGeoLocation.geoLocation)
        ) {
          resolve(searchParamsByGeoLocation);
        } else {
          this.geoLocationLocalStorage.setItem(geoLocationNew);

          this.getByGeoLocation(geoLocationNew, byGeoLocation)
            .subscribe({
              next: searchParamsByGeoLocationNew => {
                this.searchParamsByGeoLocationLocalStorage.setItem(searchParamsByGeoLocationNew);

                resolve(searchParamsByGeoLocationNew);
              }, error: error => {
                this.logger.error('On identifying location', error);

                reject(error);
              }
            });
        }
      });
    });
  }

  removeGeoLocationFromPath(searchParams: SearchParams) {
    const pathWithGeoLocation = searchParams.path.split('/');

    const latIndex = pathWithGeoLocation.indexOf('lat');
    const lonIndex = pathWithGeoLocation.indexOf('lon');

    if (latIndex > -1 && lonIndex > -1) {
      pathWithGeoLocation.splice(latIndex, 2);
      pathWithGeoLocation.splice(latIndex, 2);
    }

    searchParams.geoLocation = null;
    searchParams.path = pathWithGeoLocation.join('/');

    this.searchParamsLocalStorage.setItem(searchParams);

    return searchParams;
  }
}
