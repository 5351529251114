<div class="navbar-container max-width fixed-bottom d-flex safe-area-bottom">
  <nav class="navbar navbar-bottom bg-white flex-fill">
    <div class="navbar-bottom-left text-black"></div>

    <div *ngIf="menu?.expanded" class="navbar-bottom-right text-black" (click)="onSortChanged()">
      <span *ngIf="sort" class="cursor-pointer">Sort</span>
      <span *ngIf="!sort" class="cursor-pointer">Edit</span>
    </div>
  </nav>
</div>
