import { Time } from '../models/period/time';

export class TimeUtils {
  static stringToArray(timeArray: any) {
    if (!timeArray) {
      return timeArray;
    }

    if (!Array.isArray(timeArray)) {
      timeArray = timeArray.split(':');
    }

    return timeArray;
  }

  static timeToArray(hour: number, minute: number) {
    return [hour, minute];
  }

  static timeToString(hour: number, minute: number, short = false) {
    const pm = short ? 'p' : ' pm';
    const am = short ? 'a' : ' am';

    const hourInitial = hour;

    if (hour === 0) {
      hour = 12;
    }

    if (hour > 12) {
      hour -= 12;
    }

    const hourOrMinuteToString = (hourOrMinute: number) => (hourOrMinute < 10 ? `0` : '') + `${hourOrMinute}`;

    let timeString = `${hourOrMinuteToString(hour)}:${hourOrMinuteToString(minute)}`;

    timeString += hourInitial >= 12 ? pm : am;

    return timeString;
  }

  static timeFirstToString(hour: number, minute: number) {
    if (hour === 0) {
      hour = 12;
    }

    if (hour > 12) {
      hour -= 12;
    }

    const hourOrMinuteToString = (hourOrMinute: number) => (hourOrMinute < 10 ? `0` : '') + `${hourOrMinute}`;

    return `${hourOrMinuteToString(hour)}:${hourOrMinuteToString(minute)}`;
  }

  static isSame(hour1: number, minute1: number, hour2: number, minute2: number) {
    return hour1 === hour2 && minute1 === minute2;
  }

  static compare(hour1: number, minute1: number, hour2: number, minute2: number) {
    if (hour1 > hour2) {
      return 1;
    }

    if (hour1 < hour2) {
      return -1;
    }

    if (minute1 > minute2) {
      return 1;
    } else if (minute1 < minute2) {
      return -1;
    }

    return 0;
  }

  static toTime(timeInput: any): Time {
    if (!timeInput || timeInput instanceof Time) {
      return timeInput;
    }

    const timeArray = TimeUtils.stringToArray(timeInput);

    return new Time(+timeArray[0], +timeArray[1]);
  }
}
