<app-flash-receipt></app-flash-receipt>

<ng-container *ngIf="!dataLoaded">
  <app-menu-loader></app-menu-loader>
</ng-container>

<div *ngIf="dataLoaded" class="has-navbar-both">
  <ng-container *ngIf="restaurant && !isEditMode">
    <app-navbar-top-menu
      [title]="restaurant.nameView | abbreviate: false: '' | city: restaurant.cityNameToRemove"
      [listView]="listView"
      [restaurant]="restaurant"
      [isCs]="restaurant && isEditMode && ((isOwnerLess && isVolunteer) || (isOwned && isAdminUser))"
      [editMode]="isEditMode"
      (toggle)="switchToggle($event)"
    ></app-navbar-top-menu>

    <app-menu *ngIf="restaurant" [restaurant]="restaurant" [menuInput]="menu" [listView]="listView"></app-menu>
  </ng-container>

  <ng-container
    *ngIf="currentUserRestaurant && isEditMode && ((isOwnerLess && isVolunteer) || (isOwned && isAdminUser))"
  >
    <app-food-menus-cs
      [currentRestaurant]="currentUserRestaurant"
      (savedChanges)="onSaveChanges()"
    ></app-food-menus-cs>
  </ng-container>
</div>
