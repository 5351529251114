import { Type } from 'class-transformer';
import { BaseImage } from './base-image';
import { UnsplashUser } from './unsplash-user';

export class UnsplashImage extends BaseImage {
  altDescription: string;
  color: string;

  @Type(() => UnsplashUser)
  user: UnsplashUser;
}
