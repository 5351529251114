import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ResetPassword } from '../../domains/reset-password';
import { MustMatch } from '../../helpers/mustmatch.validator';
import { Role } from '../../models';
import { AuthenticationService } from '../../services/authentication.service';
import { NavigatorService } from '../../services/navigator.service';
import { NotificationService } from '../../services/notification.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: []
})
export class ResetComponent implements OnInit {
  resetForm: UntypedFormGroup;
  submitted = false;
  id: number;
  token: string;
  role: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private notificationService: NotificationService,
    private navigatorService: NavigatorService,
    private authenticationService: AuthenticationService,
    private logger: NGXLogger
  ) {
    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  get f() {
    return this.resetForm.controls;
  }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.queryParams['token'];
    this.id = this.activatedRoute.snapshot.queryParams['id'];
    this.role = this.activatedRoute.snapshot.queryParams['r'];
  }

  onSubmit() {
    this.submitted = true;

    if (this.resetForm.invalid) {
      return;
    }

    const resetPassword = new ResetPassword(this.id, this.token, this.f.password.value);

    this.userService
      .resetPassword(resetPassword)
      .subscribe({
        next: userData => {
          this.authenticationService.saveUserDataToCookie(userData);
          this.notificationService.passwordUpdatedAfterReset();

          if (this.role === Role.USER) {
            return this.navigatorService.goToHome();
          } else {
            return this.navigatorService.goToAdmin();
          }
        }, error: error => {
          this.logger.error('On resetting password', error);

          return this.navigatorService.goToHome();
        }
      });
  }
}
