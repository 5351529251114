<div [ngClass]="{'bg-white': categoriesExpanded}">
  <main
    appDynamicUrl
    [ngClass]="{'mb-6-5rem' : !categoriesExpanded || listView}"
    class="container max-width min-h-100vh px-0 bg-white"
  >
    <div
      *ngIf="!categoriesExpanded || listView || menus.length === 0"
      [appBackgroundUrl]="exploreBackgroundImage"
      class="ios-fixed-background position-fixed"
    >
      <div
        [ngClass]="{'list-backdrop' : listView && categoriesExpanded || menus.length === 0}"
        class="container-backdrop-fixed"
      ></div>
    </div>

    <div
      *ngFor="let menu of menus; let menuLast = last;"
      class="card bg-transparent text-white box-shadow-none"
      [ngClass]="{'card-menu': categoriesExpanded}"
    >
      <div
        *ngIf="menus.length > 0"
        [appDynamicUrlElement]="restaurantPath + '/' + menu.slug"
        [attr.data-slug]="menu.slug"
        id="menu-{{menu.id}}"
        class="menu"
        [ngClass]="{'bg-white': categoriesExpanded && !listView, 'pb-0': menu.default}"
      >
        <h1
          *ngIf="!hasOnlyDefaultMenu && !menu.default"
          class="menu-title text-center cursor-pointer mt-5 mb-0"
          [ngClass]="{
            'menu-text-color': categoriesExpanded && !listView,
            'text-white': !(categoriesExpanded || !listView)
          }"
          (click)="toggleMenuCategories(menu)"
        >
          {{ menu.display(restaurant) }}
        </h1>

        <div
          *ngFor="let category of menu.categories; let categoryIndex = index; let catLast = last;"
          class="category-container"
          id="category-{{ category.id }}"
        >
          <div [hidden]="!categoriesExpanded">
            <div id="phantom-category-{{category.id}}" class="phantom-category"></div>

            <div
              *ngIf="!category.default"
              [ngClass]="{
                'category-name': categoryIndex != 0,
                'category-name-first': categoryIndex == 0
              }"
              class="row"
            >
              <div class="col-12 text-center">
                <button
                  [ngClass]="{'text-white': listView}"
                  type="button"
                  class="btn-category btn btn-block btn-primary-shadow md-btn-flat text-transform-none"
                  (click)="toggleMenuCategories(menu)"
                >
                  {{ category.viewTitle }}
                </button>
              </div>
            </div>

            <div
              *ngIf="category.description"
              [ngClass]="{'bg-grey text-white': listView}"
              class="category-note border-light p-3"
            >
              <div class="media text-center">
                <span class="category-note-content">
                  {{ category.description }}
                </span>
              </div>
            </div>

            <div [ngClass]="{'category-content': !hasOnlyDefaultMenu, 'mt-0': menu.default}">
              <div *ngIf="menu.view.isList" class="list">
                <div class="list-group">
                  <ng-container *ngFor="let food of category.foods; let isFirst = first; let isLast = last;">
                    <app-phantom [view]="food.view" [viewType]="viewType.LIST"></app-phantom>

                    <div
                      *ngIf="food.view.isList"
                      [ngClass]="{'text-danger': food.status.isSoldOut}"
                      class="list-group-item"
                      (click)="toView(menu, food, viewType.CLASSIC)"
                    >
                      <div class="row">
                        <ng-container *ngIf="!(catLast && menuLast)">
                          <div class="col-9 text-left">
                            <span>{{ food.title }}</span>

                            <i *ngIf="food.isFeatured" class="ion ion-ios-star ml-1"></i>
                          </div>

                          <div class="col-3 text-right">
                            <span>{{ food.priceView }}</span>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="catLast && menuLast">
                          <div class="col-9 text-left">
                            <span>{{ food.title }}</span>

                            <i *ngIf="food.isFeatured" class="ion ion-ios-star ml-1"></i>
                          </div>
                        </ng-container>
                      </div>
                    </div>

                    <ng-container *ngIf="food.view.isClassic && !isFirst && category.foodPrev(food).view.isList">
                      <div class="food-prev-list"></div>
                    </ng-container>

                    <div *ngIf="food.view.isClassic" class="list-classic">
                      <div class="row">
                        <div class="col-12 bg-white">
                          <app-classic-food
                            [house]="catLast && menuLast"
                            [restaurant]="restaurant"
                            [food]="food"
                            [isLast]="isLast && catLast && menuLast"
                            [srcView]="viewType.LIST"
                            (view)="toView(menu, food, viewType.LIST)"
                            (basketAdd)="addToBasket($event, category.id)"
                          ></app-classic-food>
                        </div>
                      </div>
                    </div>

                    <ng-container *ngIf="food.view.isClassic && !isLast && category.foodNext(food).view.isList">
                      <div class="food-next-list"></div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>

              <div *ngIf="menu.view.isColumns" class="classic p-0">
                <div class="row row-foods">
                  <ng-container *ngFor="let food of category.foods; let isLast = last;">
                    <div
                      *ngIf="food.view.isColumns"
                      class="col-foods col-6 mb-1"
                      (click)="toView(menu, food, viewType.CLASSIC)"
                    >
                      <app-phantom [view]="food.view" [viewType]="viewType.COLUMNS"></app-phantom>

                      <app-div-rectangle>
                        <div class="card card-item-image">
                          <div class="food-status flex-content-vc-hc">
                            <span *ngIf="food.status.isSoldOut" class="badge badge-danger text-uppercase">
                              Sold out
                            </span>

                            <ng-container *ngFor="let tag of food.tags">
                              <span
                                *ngIf="!food.status.isSoldOut && tag.tagType == tagType.temp"
                                class="badge badge-outline-white text-uppercase"
                              >
                                {{ tag.value }}
                              </span>
                            </ng-container>
                          </div>

                          <app-food-img
                            *ngIf="!food.googleImage"
                            [images]="food.images"
                            [width]="720"
                            [height]="720"
                            [alt]="food.title"
                          ></app-food-img>

                          <div
                            *ngIf="food.googleImage"
                            [appBackgroundUrl]="food.images[0].path | googleImage: 360"
                            class="w-100 h-100 background-center-cover-no-repeat"
                          ></div>

                          <div class="card-item-image-title text-center">
                            {{ food.title }}
                          </div>
                        </div>
                      </app-div-rectangle>
                    </div>

                    <ng-container *ngIf="food.view.isClassic">
                      <section class="col-food col-12 bg-white">
                        <app-classic-food
                          [house]="catLast && menuLast"
                          [restaurant]="restaurant"
                          [food]="food"
                          [isLast]="isLast && catLast && menuLast"
                          [srcView]="viewType.COLUMNS"
                          (view)="toView(menu, food, viewType.COLUMNS)"
                          (basketAdd)="addToBasket($event, category.id)"
                        ></app-classic-food>

                        <div *ngIf="listView" class="mt-3-5rem"></div>
                      </section>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>

<app-basket [restaurant]="restaurant"></app-basket>

<app-navbar-bottom-menus
  [restaurant]="restaurant"
  [rotateMainLogo]="true"
  [listView]="listView"
  (viewChanged)="switchView($event)"
  (mainMenuClick)="openMainMenuModal()"
></app-navbar-bottom-menus>

<ng-template #previewModalRef let-c="close" let-d="dismiss">
  <div>
    <swiper
      [config]="previewConfig"
      (slideChange)="onSlideChange($event)"
    >
      <ng-template swiperSlide></ng-template>

      <ng-template swiperSlide>
        <div class="w-100 h-100">
          <app-preview-modal
            [restaurantExplore]="restaurant"
            [title]="searchParams.locationName"
            [isHoursEnabled]="isHoursEnabled"
            (hoursToggle)="isHoursEnabled = $event"
          ></app-preview-modal>
        </div>
      </ng-template>

      <ng-template swiperSlide></ng-template>
    </swiper>
  </div>
</ng-template>
