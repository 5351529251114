<div
  [ngClass]="{'container-mobile p-0_8rem': isMobile, 'p-1-2rem': isDesktop}"
  class="container font-size-1rem safe-area-top"
  (swipeup)="modalClose()"
>
  <div class="mt-1 mx-1">
    <app-membership-card [edit]="true" [user]="currentUser" [price]="selectedPrice"></app-membership-card>
  </div>

  <input ngbAutofocus type="hidden"/>

  <ng-container *ngIf="!editMode">
    <div [ngClass]="{'mt-4 mb-2rem': isDesktop, 'my-3': !isDesktop}" class="row text-center mx-4">
      Mbership M powers restaurants with
      apps designed to lighten their load
    </div>

    <div [ngClass]="{'mt-3 mb-2rem': isDesktop, 'my-3': !isDesktop}" class="row text-center mx-4">
      Mbers also look forward to a future suite of
      premium features & exclusive benefits
    </div>
  </ng-container>

  <ng-container *ngIf="editMode">
    <div [ngClass]="{'mt-4 mb-3': isDesktop, 'my-3': !isDesktop}" class="row mx-4 justify-content-center">
      Supporting folks who feed
    </div>
  </ng-container>

  <div [ngClass]="{'mt-2': isDesktop}" class="row price flex-content-vc-hc">
    <div class="pl-4">
      <div class="input-group">
        <div class="input-group-prepend" (click)="focusInput()">
          <div class="input-group-text border-0">$</div>
        </div>

        <input
          [(ngModel)]="selectedPrice"
          [min]="5"
          (ngModelChange)="onInputChange()"
          id="custom-input"
          class="custom-input form-control border-0 mt-1"
          type="number"
        >

        <div class="font-size-1rem d-flex align-items-end mb-1 font-weight-500" (click)="focusInput()">/mo</div>
      </div>
    </div>
  </div>

  <div class="row mt-0 mx-4 mb-2">
    <nouislider
      [(ngModel)]="selectedPrice"
      [config]="sliderOptions"
      (slide)="onSliderChange($event)"
      class="w-100 slider-touch-area"
    ></nouislider>
  </div>

  <div *ngIf="showMinPlaceHolder && selectedPrice !== null" class="row mt-n3 flex-content-hc mx-0">
    <small>Min $5</small>
  </div>

  <div
    [ngClass]="showMinPlaceHolder && selectedPrice !== null ? 'mt-3' : 'mt-3'"
    class="row flex-content-hc mx-0"
  >
    <small>Adjust anytime • ${{ selectedPrice * 12 }} once a year</small>
  </div>
</div>

<div class="modal-top-standard-footer bg-transparent border-bottom-radius-3 p-3">
  <button
    type="submit"
    class="btn btn-gold btn-block text-uppercase"
    (click)="onClickSubscribe()"
  >
    {{ editMode ? 'Save' : 'WELCOME TO THE CLUB' }}
  </button>
</div>
