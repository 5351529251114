import { RestaurantsPickerPosition } from './restaurants-picker-position';

export class RestaurantsPickerPositionWrapper {
  list = RestaurantsPickerPosition;
  value: RestaurantsPickerPosition;

  constructor(position: string = null) {
    if (position) {
      this.value = RestaurantsPickerPosition[position];
    }
  }

  get isSearchModal(): boolean {
    return this.value === this.list.SEARCH_MODAL;
  }

  get isDistanceSlider(): boolean {
    return this.value === this.list.DISTANCE_SLIDER;
  }
}
