import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Food } from '../../../../../domains/food';
import { Tag } from '../../../../../domains/tag';
import { TagType } from '../../../../../models/enums/tag-type';

@Component({
  selector: 'app-tags-edit',
  templateUrl: './tags-edit.component.html',
  styleUrls: ['./tag-edit.component.scss']
})
export class TagsEditComponent implements OnInit {
  @Input() food: Food;
  @Input() tags: Array<Tag> = [];
  @Input() showWarnings: boolean;
  @Input() isCs = false;

  @Output() save = new EventEmitter<boolean>();

  warningTags: Array<Tag> = [];
  tempTags: Array<Tag> = [];
  regularTags: Array<Tag> = [];

  allergensTag: Tag;

  tagForm: UntypedFormGroup;

  regularTagsForm: UntypedFormArray;
  tempTagsForm: UntypedFormArray;
  warningTagsForm: UntypedFormArray;

  allergensTagForm: AbstractControl;

  spicyCheckBox = false;
  spicyDegree = 1;

  constructor() {
  }

  ngOnInit(): void {
    if (this.tags !== undefined) {
      this.tags.forEach(tag => {
        tag.checked = false;

        if (this.food.tags) {
          const foodTag = this.food.tags.find(tagI => tagI.id === tag.id);

          if (foodTag !== undefined) {
            // TODO create new object instead of adding property to model
            tag.checked = true;

            if (tag.value === 'spicy') {
              this.spicyCheckBox = true;
              this.spicyDegree = tag.degree;
            }
          }
        }

        switch (tag.tagType) {
          case TagType.regular:
            if (tag.value !== 'featured') {
              this.regularTags.push(tag);
            }

            break;
          case TagType.temp:
            this.tempTags.push(tag);

            break;
          case TagType.warning:
            this.warningTags.push(tag);

            break;
        }
      });

      this.regularTagsForm = this.createControls(this.regularTags);
      this.tempTagsForm = this.createControls(this.tempTags);
      this.warningTagsForm = this.createControls(this.warningTags);

      const allergensTagIndex = this.warningTags.findIndex(tag => tag.value === 'allergens');
      this.allergensTag = this.warningTags[allergensTagIndex];
      this.allergensTagForm = this.warningTagsForm.controls[allergensTagIndex];

      this.tagForm = new UntypedFormGroup({
        regularTagsControl: this.regularTagsForm,
        tempTagsControl: this.tempTagsForm,
        warningTagsControl: this.warningTagsForm
      });
    }
  }

  private createControls(tags: Array<Tag>) {
    const arr = tags.map(tag => {
      return new UntypedFormControl(tag.checked || false);
    });

    return new UntypedFormArray(arr);
  }

  onSubmit(tag: Tag = null) {
    if (tag && tag.value === 'allergens' && !this.allergensTagForm.value) {
      this.warningTagsForm
        .controls
        .forEach((control, index) => {
          const controlTag = this.warningTags[index];

          if (controlTag.value !== 'adv') {
            control.setValue(false);
          }
        });
    }

    this.food.tags = [];
    this.fillCheckedTags(this.tagForm.value.warningTagsControl, this.warningTags);
    this.fillCheckedTags(this.tagForm.value.tempTagsControl, this.tempTags);
    this.fillCheckedTags(this.tagForm.value.regularTagsControl, this.regularTags);
    this.save.emit(true);
  }

  setExclusiveTags(event: Event, tagForm: UntypedFormArray, tags: Tag[], tagValue: string, tag: AbstractControl) {
    const element: HTMLInputElement = (event.target || event.currentTarget) as HTMLInputElement;

    tag.setValue(element.checked);

    const tagsExclusive = tags.filter(tagsItered => tagsItered.exclusive);

    tagsExclusive.forEach(tagExclusive => {
      if (tagExclusive.value !== tagValue) {
        const tagControl = tagForm.controls.find((tagItered, index) => {
          return tags[index].value === tagExclusive.value;
        });

        if (tagControl !== undefined) {
          tagControl.setValue(false);
        }
      }
    });
    this.onSubmit();
  }

  spicyCheckBoxChanged() {
    if (this.spicyCheckBox) {
      this.spicyDegreeChanged();
    } else {
      const spicyControls = this.regularTagsForm.controls.filter((tagControl, i) => {
        return this.regularTags[i].value === 'spicy';
      });

      spicyControls.forEach(tagControl => tagControl.setValue(false));
    }
  }

  spicyDegreeChanged() {
    const spicyTagControl = this.regularTagsForm.controls.find((tagControl, i) => {
      return this.regularTags[i].value === 'spicy' && this.regularTags[i].degree === this.spicyDegree;
    });

    if (spicyTagControl !== undefined) {
      spicyTagControl.setValue(true);
    }

    const otherSpicyTagControls = this.regularTagsForm.controls.filter((tagControl, i) => {
      return this.regularTags[i].value === 'spicy' && this.regularTags[i].degree !== this.spicyDegree;
    });

    if (otherSpicyTagControls.length > 0) {
      otherSpicyTagControls.forEach(spicyTagControlItered => spicyTagControlItered.setValue(false));
    }

    this.onSubmit();
  }

  private fillCheckedTags(controlArray: [], tagArray: Array<Tag>) {
    controlArray.forEach((isChecked, index) => {
      if (isChecked) {
        this.food.tags.push(tagArray[index]);
      }
    });
  }
}
