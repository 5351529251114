export class MathUtils {
  static degreesToRadians(degrees: number) {
    return degrees * (Math.PI / 180);
  }

  static distance(
    latitudeInitial: number,
    longitudeInitial: number,
    latitudeCompared: number,
    longitudeCompared: number
  ) {
    return (
      (
        3959 *
        Math.acos(
          Math.cos(MathUtils.degreesToRadians(latitudeInitial)) *
          Math.cos(MathUtils.degreesToRadians(latitudeCompared)) *
          Math.cos(
            MathUtils.degreesToRadians(longitudeCompared) - MathUtils.degreesToRadians(longitudeInitial)
          ) +
          Math.sin(MathUtils.degreesToRadians(latitudeInitial)) *
          Math.sin(MathUtils.degreesToRadians(latitudeCompared))
        )
      ) ||
      0
    );
  }
}
