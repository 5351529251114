<ng-container *appIfIsMobile>
  <app-navbar-top-profile [title]="'Hero'"></app-navbar-top-profile>

  <div class="container has-navbar-top max-width">
    <ng-container [ngTemplateOutlet]="heroCards"></ng-container>
  </div>
</ng-container>

<ng-container *appIfIsDesktop>
  <div class="row">
    <div class="col-4">
      <ng-container [ngTemplateOutlet]="heroCards"></ng-container>
    </div>
  </div>
</ng-container>

<ng-template #heroCards>
  <ng-template
    [ngTemplateOutlet]="restaurants"
    [ngTemplateOutletContext]="{restaurants: active, title: 'Active Duty'}"
  ></ng-template>

  <ng-template
    [ngTemplateOutlet]="restaurants"
    [ngTemplateOutletContext]="{restaurants: retired, title: 'Retired'}"
  ></ng-template>
</ng-template>

<ng-template #restaurants let-restaurants="restaurants" let-title="title">
  <div
    *ngIf="restaurants.length > 0"
    appAddClassIfDesktop="mb-3"
    appAddClassIfMobile="mt-3"
    class="card active-container container-row bg-white mx-0 mb-3"
  >
    <div class="card-header text-center font-weight-bold">{{ title }}</div>

    <ul class="list-group my-2">
      <ng-container *ngFor="let restaurant of restaurants; let first = first; let last = last;">
        <li
          [ngClass]="{'mt-3': first, 'mb-3': last}"
          class="list-group-item flex-content-vc justify-content-between border-0 cursor-pointer mx-3"
          (click)="onRestaurantClick(restaurant)"
        >
          <span class="font-weight-semibold">{{ restaurant.viewHostname }}</span>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
