export class ScriptLoaderUtils {
  static loadScriptFromHtml(html, providerName) {
    const url = /<script.*?src="(.*?)"/.exec(html)[1];

    return new Promise((resolve, reject) => {
      const id = `${providerName}-script`;

      if (document.getElementById(id)) {
        resolve('loaded');
      }

      const script = document.createElement('script');

      script.async = true;
      script.src = url;
      script.setAttribute('id', id);

      script.onload = () => {
        resolve('loaded');
      };

      script.onerror = () => {
        reject('errored');
      };

      document.head.appendChild(script);
    });
  }
}
