<app-navbar-top [title]="'Reset password'"></app-navbar-top>

<div class="container has-navbar-top max-width">
  <div class="card overflow-hidden mt-3">
    <div class="card-body">
      <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <input
            [ngClass]="{ 'is-invalid': f.password.errors && submitted}"
            type="password"
            class="form-control"
            placeholder="New password"
            formControlName="password"
          />
          <small class="invalid-feedback">
            {{ f.password.errors && f.password.errors.required ? 'Required.' : 'Needs to be at least 6 characters' }}
          </small>
        </div>

        <div class="form-group">
          <input
            [ngClass]="{ 'is-invalid': f.confirmPassword.errors && submitted}"
            type="password"
            class="form-control"
            placeholder="Confirm password"
            formControlName="confirmPassword"
          />
          <small class="invalid-feedback"> New passwords must match. </small>
        </div>

        <button type="submit" class="btn btn-primary float-right">Reset</button>
      </form>
    </div>
  </div>
</div>

<app-navbar-bottom></app-navbar-bottom>
