import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Order } from '../../../domains/order/order';
import { ModalType } from '../../../models/enums/modal-type';
import { Picker } from '../../../models/picker';
import { ModalService } from '../../../services/modal.service';
import { OrderService } from '../../../services/order.service';
import { WebSocketService } from '../../../services/web-socket.service';
import { AdminOrderListener } from '../../../websocket/listeners/admin-order-listener';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.shared.scss']
})
export class OrdersComponent implements OnInit {
  @ViewChild('orderModalRef') orderModalRef: ElementRef;

  orders: Order[] = [];

  orderAccepted: Order;

  picker = new Picker();

  constructor(
    private modalService: ModalService,
    private orderService: OrderService,
    private webSocketService: WebSocketService,
    private activatedRoute: ActivatedRoute,
    private logger: NGXLogger
  ) {
    orderService
      .getOrdersForAdmin()
      .subscribe({
        next: orders => {
          this.orders = orders;
          this.sort();
        }, error: error => {
          this.logger.error('On getting orders for admin', error);
        }
      });

    [10, 15, 20, 30, 45, 60].forEach(minutes => {
      this.picker.addDefaultOption(`${minutes}`, `${minutes} min`);
    });
  }

  ngOnInit() {
    this.activatedRoute.parent.params.subscribe(params => {
      this.webSocketService
        .registerListener(new AdminOrderListener(params['hostname']))
        .subject
        .subscribe(order => {
          if (order) {
            const orderExist = this.orders.find(orderI => orderI.id === order.id);

            if (orderExist === undefined) {
              this.orders.push(order);
              this.sort();
            }
          }
        });
    });
  }

  openOrdersModal(order: Order) {
    this.orderAccepted = order;

    this.modalService.open(this.orderModalRef, ModalType.TOP_SHADOW_NONE);
  }

  accept(order: Order, minutes: string) {
    this.orderService
      .process(order, true, minutes)
      .subscribe({
        next: orderSaved => {
          order.readyTime = orderSaved.readyTime;
          order.accepted = true;
          this.sort();
        }, error: error => {
          this.logger.error('On processing order', error);
        }
      });
  }

  decline(order: Order) {
    this.orderService
      .process(order, false)
      .subscribe({
        next: () => {
          order.declined = true;
          this.sort();
        }, error: error => {
          this.logger.error('On processing order', error);
        }
      });
  }

  private sort() {
    this.orders.sort((order1, order2) => {
      if ((order1.accepted || order1.declined) && (!order2.accepted && !order2.declined)) {
        return 1;
      } else if ((!order1.accepted && !order1.declined) && (order2.accepted || order2.declined)) {
        return -1;
      } else {
        return (order1.createdAt < order2.createdAt) ? 1 : -1;
      }
    });
  }
}
