import { CategoryType } from '../models/enums/category-type';
import { TagType } from '../models/enums/tag-type';

export class Tag {
  id: number;
  value: any;
  display: any;
  checked = false;
  tagType: TagType;
  categoryType: CategoryType;
  ordering: number;
  exclusive: boolean;
  degree: number;
  viewOrdering: number;

  constructor(value: any, label: any) {
    this.value = value;
    this.display = label;
  }
}
