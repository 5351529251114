import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NavigatorService } from '../../../services/navigator.service';

@Component({
  selector: 'app-navbar-top-post',
  templateUrl: './navbar-top-post.component.html',
  styleUrls: ['../navbar.shared.scss']
})
export class NavbarTopPostComponent implements OnInit, OnChanges {
  @Input() editMode: boolean;
  @Input() restaurantPath: string;
  @Input() restaurantTitle: string;
  @Input() username: string;

  @Output() cancelClick = new EventEmitter<boolean>();
  @Output() rightButtonClick = new EventEmitter<boolean>();

  constructor(private navigatorService: NavigatorService) {
  }

  ngOnInit() {
  }

  cancelEdited() {
    this.cancelClick.emit(true);
  }

  rightButtonClicked() {
    this.rightButtonClick.emit(true);
  }

  goToRestaurant() {
    this.navigatorService.goToUrl(this.restaurantPath).then();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('restaurantPath')) {
      this.restaurantPath = changes.restaurantPath.currentValue;
    }

    if (changes.hasOwnProperty('restaurantTitle')) {
      this.restaurantTitle = changes.restaurantTitle.currentValue;
    }
  }
}
