import { FoodStatus } from './enums/food-status';
import { WrapperInterface } from './wrappers/wrapper.interface';

export class FoodStatusWrapper implements WrapperInterface {
  list = FoodStatus;
  value: FoodStatus;

  constructor(type: string = null) {
    if (type) {
      this.value = this.list[type];
    } else {
      this.value = this.list.AVAILABLE;
    }
  }

  set newValue(status: FoodStatus) {
    this.value = status;
  }

  get newValue() {
    return this.value;
  }

  get pickerClass() {
    switch (this.value) {
      case this.list.AVAILABLE:
        return 'eye';
      case this.list.SOLD_OUT:
        return 'sold-out';
      case this.list.HIDDEN:
        return 'eye-hidden';
      default:
        return '';
    }
  }

  get isAvailable() {
    return this.value === this.list.AVAILABLE;
  }

  get isSoldOut() {
    return this.value === this.list.SOLD_OUT;
  }

  get isHidden() {
    return this.value === this.list.HIDDEN;
  }
}
