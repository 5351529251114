import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'abbreviate'})
export class AbbreviatePipe implements PipeTransform {
  abbreviates = {
    Barbeque: 'BBQ',
    Barbecue: 'BBQ',
    'Bar-B-Q': 'BBQ',
    'Bar-B-Que': 'BBQ',
    'B.B.Q': 'BBQ',
    Bbq: 'BBQ',
    ' and ': ' & ',
    ' And ': ' & ',
    Delicatessen: 'Deli',
    Middle: 'Mid'
  };

  abbreviatesForPreview = {
    'Bar & Grill': 'B&G',
    'Bar and Grill': 'B&G',
    'bar and grill': 'B&G',
    'bar & grill': 'B&G'
  };

  abbreviatesWithCity = {};

  toRemoveInSearch = [];

  toRemoveEnd = [];

  toRemoveEndInSearch = [];

  transform(value: string, preview: boolean, cityName: string): any {
    if (!value) {
      return '';
    }

    const titles = value.toLowerCase().split(' ');

    value = '';

    for (const title of titles) {
      value += title.charAt(0).toUpperCase() + title.slice(1) + ' ';
    }

    value = value.trim();

    if (value) {
      for (const key in this.abbreviates) {
        value = value.replace(key, this.abbreviates[key]);
      }

      this.toRemoveEnd.forEach(word => {
        if (value.endsWith(word) && value.length > word.length) {
          value = value.replace(word, '');
        }
      });

      if (!preview) {
        if (value.startsWith(cityName)) {
          for (const key in this.abbreviatesWithCity) {
            value = value.replace(key, this.abbreviatesWithCity[key]);
          }
        }

        this.toRemoveInSearch.forEach(word => {
          value = value.replace(word, '');
        });

        this.toRemoveEndInSearch.forEach(word => {
          if (value.endsWith(word) && value.length > word.length) {
            value = value.replace(word, '');
          }
        });
      } else {
        for (const key in this.abbreviatesForPreview) {
          value = value.replace(key, this.abbreviatesForPreview[key]);
        }
      }
    }

    return value;
  }
}
