<app-navbar-top-profile *appIfIsMobile [title]="'My restaurant'"></app-navbar-top-profile>

<div appAddClassIfMobile="has-navbar-top" class="container-fluid mt-4">
  <div class="row">
    <div *ngIf="restaurant" class="col-md-6 col-sm-6 col-xl-4">
      <div class="card mb-3">
        <div class="card-body">
          <div ngbDropdown [placement]="'bottom-right'" class="about-restaurant-menu">
            <a
              appHrefNone
              ngbDropdownToggle
              class="hide-arrow"
            >
              <span class="icon-v2 dots-h"></span>
            </a>

            <div
              ngbDropdownMenu
              class="availability-dropdown dropdown-menu-left"
            >
              <a appHrefNone class="dropdown-item d-inline-flex" (click)="openEditRestaurantModal()">
                Edit
              </a>

              <a appHrefNone class="dropdown-item d-inline-flex" (click)="openLocationAddModal()">
                Add Location
              </a>

              <a appHrefNone class="dropdown-item d-inline-flex" (click)="openLocationRemoveModal()">
                Close Location
              </a>
            </div>
          </div>

          <ul class="about-restaurant pl-0 mb-0 list-unstyled">
            <li>
              <span class="font-weight-semibold">Primary</span>
              <span>{{ restaurant.name }}</span>
            </li>

            <li>
              <span class="font-weight-semibold">Secondary</span>
              <span>{{ restaurant.secondName }}</span>
            </li>

            <li>
              <span class="font-weight-semibold">Category</span>
              <span>{{ restaurant.typeCategory.display }}</span>
            </li>

            <li>
              <span>{{ restaurant.address }}</span>
            </li>

            <li>
              <span>{{ restaurant.nationalPhoneNumber | mask: '(000) 000-0000' }}</span>
            </li>

            <li>
              <span>{{ restaurant.email }}</span>
            </li>

            <li>
              <span>{{ restaurant.website }}</span>
            </li>

            <li>
              <div class="flex-content-vc">
                <span class="icon-v2 mmmm-social"></span>

                <div class="ml-2">
                  <span>{{ restaurant.mmmm }}</span>
                </div>
              </div>
            </li>

            <li>
              <div class="flex-content-vc">
                <span class="icon-v2 instagram"></span>

                <div class="ml-2">
                  <span>{{ restaurant.instagram }}</span>
                </div>
              </div>
            </li>

            <li>
              <div class="flex-content-vc">
                <span class="icon-v2 facebook"></span>

                <div class="ml-2">
                  <span>{{ restaurant.facebook }}</span>
                </div>
              </div>
            </li>

            <li>
              <div class="flex-content-vc">
                <span class="icon-v2 twitter"></span>

                <div class="ml-2">
                  <span>{{ restaurant.twitter }}</span>
                </div>
              </div>
            </li>

            <li>
              <div class="flex-content-vc">
                <span class="icon-v2 tiktok"></span>

                <div class="ml-2">
                  <span>{{ restaurant.tiktok }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #restaurantEditModalRef>
  <app-restaurant-edit-modal [restaurant]="restaurant"></app-restaurant-edit-modal>
</ng-template>

<ng-template #locationAddModalRef>
  <app-location-add-modal [restaurant]="restaurant" (locationAdd)="cloneRestaurant($event)"></app-location-add-modal>
</ng-template>

<ng-template #locationRemoveModalRef>
  <app-location-delete-modal [restaurant]="restaurant" (removeClick)="removeRestaurant()"></app-location-delete-modal>
</ng-template>
