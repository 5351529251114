<div class="layout-wrapper layout-2">
  <div class="layout-inner">
    <app-layout-sidenav-left
      *appIfIsDesktop
      [admin]="true"
      [hasReferrer]="hasReferrer"
    ></app-layout-sidenav-left>

    <div class="layout-container">
      <div class="layout-content">
        <div class="router-transitions flex-grow-1">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>

    <app-layout-sidenav-right *appIfIsDesktop></app-layout-sidenav-right>
  </div>
</div>

<div class="layout-overlay" (click)="closeSidenav()"></div>
