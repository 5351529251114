<div appModalCloseOnClick class="modal-body min-h-100vh p-0 flex-content-vc-hc flex-column">

  <ng-container *ngIf="showIgLoginButton">
    <p> This username can be claimed by logging in via </p>

    <button
      class="btn rounded-pill btn-outline-white text-transform-none"
      (click)="instagramClick()"
    >
      Instagram
    </button>
  </ng-container>

  <div *ngIf="!isMobile" appModalCloseOnClickPrevent class="modal-box">
    <div class="user-authentication w-100 h-100">
      <div class="w-100 padding-0-4rem">
        <input ngbAutofocus type="hidden"/>

        <div class="flex-content-hc">
          <div class="flex-content-vc-hc mmmm-icon-rounded">
            <span appHrefNone class="icon-v2 mmmm-2x-red"></span>
          </div>
        </div>

        <form [formGroup]="usernameForm" class="mt-4 pt-4">
          <div class="form-group">
            <div class="row">
              <div class="col-12">
                <label
                  for="username-des"
                  class="d-flex justify-content-start text-white"
                >
                  Create username
                </label>

                <input
                  [ngClass]="{ 'is-invalid': usernameControl.errors && (submitted || usernameChecked)}"
                  class="form-control form-border-white btn-outline-white pl-12px"
                  type="text"
                  id="username-des"
                  formControlName="username"
                >

                <small class="invalid-feedback text-danger text-align-start">
                  {{
                    usernameControl.errors?.required ? 'Required' :
                      usernameControl.errors?.pattern ? 'Letters, numbers, underscores and periods only' :
                        'Username already exists'
                  }}
                </small>

                <small *ngIf="!submitted" class="form-text text-white-50">
                  Letters, numbers, underscores and periods only
                </small>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12">
              <button class="col-12 btn btn-white text-black btn-sign-in">
                Next
              </button>
            </div>
          </div>
        </form>

        <div class="w-100 text-center text-white mt-4">or</div>

        <button
          class="btn rounded-pill btn-outline-white btn-block text-transform-none mt-3"
          (click)="instagramClick()"
        >
          Claim my Instagram username
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="isMobile" class="border-bottom-radius-3 modal-box-mobile">
    <div class="user-authentication-mobile w-100 h-100">
      <div appModalCloseOnClickPrevent class="w-100 p-4">
        <input ngbAutofocus type="hidden"/>

        <div class="flex-content-hc">
          <div class="flex-content-vc-hc mmmm-icon-rounded-small">
            <span appHrefNone class="icon-v2 mmmm-2x-red"></span>
          </div>
        </div>

        <form [formGroup]="usernameForm" class="mt-4 pt-4">
          <div class="form-group">
            <div class="row">
              <div class="col-12">
                <label
                  for="username-mobile"
                  class="d-flex justify-content-start text-white"
                >
                  Create username
                </label>

                <input
                  [ngClass]="{ 'is-invalid': usernameControl.errors && (submitted || usernameChecked)}"
                  class="form-control form-border-white btn-outline-white pl-12px"
                  type="text"
                  id="username-mobile"
                  formControlName="username"
                >

                <small class="invalid-feedback text-danger text-align-start">
                  {{
                    usernameControl.errors?.required ? 'Required' :
                      usernameControl.errors?.pattern ? 'Letters, numbers, underscores and periods only' :
                        'Username already exists'
                  }}
                </small>

                <small *ngIf="!submitted" class="form-text text-white-50">
                  Letters, numbers, underscores and periods only
                </small>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12">
              <button class="col-12 btn btn-white text-black btn-sign-in">
                Next
              </button>
            </div>
          </div>
        </form>

        <div class="w-100 text-center text-white mt-4">or</div>

        <button
          class="btn rounded-pill btn-outline-white btn-block text-transform-none mt-3"
          (click)="instagramClick()"
        >
          Claim my Instagram username
        </button>
      </div>
    </div>
  </div>

  <div class="m-auto"></div>
</div>
