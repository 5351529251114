import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RestaurantExplore } from '../../../domains/restaurant-explore';
import { RestaurantsPickerPosition } from '../../../models/restaurants-picker-position';
import { RestaurantsPickerPositionWrapper } from '../../../models/restaurants-picker-position-wrapper';
import { SearchResult } from '../../../models/search-result';

@Component({
  selector: 'app-restaurants-picker',
  templateUrl: './restaurants-picker.component.html',
  styles: []
})
export class RestaurantsPickerComponent implements OnInit {
  @Input() position: RestaurantsPickerPosition;
  @Input() searchResult: SearchResult;
  @Input() title: string;
  @Input() isDisabled = false;

  @Output() restaurantClicked = new EventEmitter<RestaurantExplore>();

  positionWrapper: RestaurantsPickerPositionWrapper;

  constructor() {
  }

  ngOnInit(): void {
    this.positionWrapper = new RestaurantsPickerPositionWrapper(this.position);
  }

  onPickerSelected(hostname: string) {
    const restaurant = this.searchResult.restaurants.find(restaurantI =>
      restaurantI.hostname === hostname
    );

    this.restaurantClicked.emit(restaurant);
  }
}
