<div class="modal-header justify-content-center align-items-center" (click)="onHeaderClick()">
  <span class="title">{{ title }}</span>
</div>

<div class="classic mt-1" [ngClass]="{'scaleY--1': scale}">
  <ng-content></ng-content>
</div>

<div
  class="sw-content"
  (panend)="onPanEnd($event)"
  (panstart)="onPanStart($event)"
  (pan)="onPan($event)"
  (swipeup)="onSwipeUp($event)"
>
  <div class="sw">
    <div class="sw-element"></div>
  </div>
</div>
