import { Component, Input, OnInit } from '@angular/core';
import { Auth } from '../../../../../decorators/auth.decorator';
import { IsDevice } from '../../../../../decorators/is-device.decorator';
import { ModalClose } from '../../../../../decorators/modal-close.decorator';
import { Subscribe } from '../../../../../decorators/subscribe.decorator';
import { Food } from '../../../../../domains/food';
import { RestaurantSimple } from '../../../../../domains/restaurant-simple';
import { TopFood } from '../../../../../domains/top-foods';
import { DeviceType } from '../../../../../models/enums/device-type';
import { UnsplashImage } from '../../../../../models/unsplash-image';
import { NavigatorService } from '../../../../../services/navigator.service';
import { NotificationService } from '../../../../../services/notification.service';

@Component({
  selector: 'app-preview-modal-slide',
  templateUrl: './preview-modal-slide.component.html',
  styleUrls: ['./preview-modal-slide.component.scss']
})
export class PreviewModalSlideComponent implements OnInit {
  @Input() restaurant: RestaurantSimple;
  @Input() topFood: TopFood;
  @Input() backgroundImage: string | UnsplashImage;
  @Input() noGradient: boolean;
  @Input() noFooter = false;
  @Input() hoursEnabled = true;

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  pictureHover: boolean;

  constructor(
    private navigatorService: NavigatorService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
  }

  titleClicked() {
    this.notificationService.titleToastr();
  }

  openGoogleMap() {
    this.navigatorService.goToMapsRestaurant(this.restaurant);
  }

  @ModalClose()
  @Auth()
  @Subscribe()
  openMembershipModal() {
  }

  goToFood(food: Food) {
    this.closeRestModal();

    this.navigatorService.goToUrl('/' + this.restaurant.hostname + food.profileUrl).then();
  }

  @ModalClose()
  closeRestModal() {
  }

  statsClicked() {
    this.notificationService.statsToastr();
  }
}
