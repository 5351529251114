<div appModalCloseOnClickPrevent class="w-100 h-100">
  <div class="w-100 h-100 d-flex flex-column">
    <div class="d-flex justify-content-center mt-2-5rem">
      <span class="name-container rounded-circle">
        <app-user-avatar
          [user]="currentUser"
          [widthAndHeight]="400"
          (imageClick)="goToUser()"
          class="w-100 h-100"
        ></app-user-avatar>
      </span>
    </div>

    <div class="d-flex justify-content-center font-weight-500 text-overflow-crop mt-3">
      <span class="cursor-pointer" (click)="goToUser()">
        {{ currentUser.username }}
      </span>
    </div>

    <div
      *ngIf="postVoted"
      [ngClass]="{'animate__animated animate__fadeInUp': showIcon}"
      class="d-flex justify-content-center mt-4rem"
    >
      <span class="icon-v2 best-picture-gold scale-2_5"></span>
    </div>

    <div class="m-auto"></div>

    <div class="d-flex justify-content-between mb-3">
      <span class="d-flex justify-content-start ml-3"></span>

      <span class="d-flex justify-content-center">
        <button
          class="btn rounded-pill btn-block text-transform-none w-6rem bg-light-gray"
          (click)="togglePostVote($event)"
        >
          <span *ngIf="post?.votesCount == 0" class="text-black">vote</span>

          <span *ngIf="post?.votesCount == 1" class="text-black">{{ post?.votesCount }} vote</span>

          <span *ngIf="post?.votesCount > 1" class="text-black">{{ post?.votesCount }} votes</span>
        </button>
      </span>

      <span class="d-flex justify-content-end mr-3 mt-11px">
         <app-post-options
           [post]="post"
           [isDark]="true"
           (postDeleted)="postDeleted(post)"
         ></app-post-options>
      </span>
    </div>
  </div>
</div>
