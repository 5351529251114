import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Auth } from '../../decorators/auth.decorator';
import { IsDevice } from '../../decorators/is-device.decorator';
import { LocationEmbed } from '../../domains/location-embed';
import { Restaurant } from '../../domains/restaurant';
import { RestaurantEmbed } from '../../domains/restaurant-embed';
import { RestaurantSimple } from '../../domains/restaurant-simple';
import { DeviceType } from '../../models/enums/device-type';
import { ModalType } from '../../models/enums/modal-type';
import { ReportSubjectType } from '../../models/enums/report-subject-type';
import { ReportType } from '../../models/enums/report-type';
import { ReviewStatus } from '../../models/enums/review-status';
import { Picker } from '../../models/picker';
import { PickerConfig } from '../../models/picker-config';
import { EmbedService } from '../../services/embed.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-embed-video',
  templateUrl: 'embed-video.component.html',
  styleUrls: ['embed-video.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmbedVideoComponent implements OnInit {
  @Input() embed: RestaurantEmbed | LocationEmbed;
  @Input() restaurant: RestaurantSimple | Restaurant;

  @Output() deleteEmbed = new EventEmitter<RestaurantEmbed | LocationEmbed>();

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  @ViewChild('reportVideoModalRef') reportVideoModalRef: ElementRef;

  picker: Picker;
  reviewStatus = ReviewStatus;
  currentReportType: ReportType;
  currentObjectId: number;
  reportType = ReportType;
  reportSubjectType: ReportSubjectType;
  subjectId: number;

  constructor(
    private embedService: EmbedService,
    private modalService: ModalService
  ) {
    this.picker = new Picker(
      plainToClass(PickerConfig, {
        options: [{
          value: ReportType.BROKEN_LINK,
          label: 'Broken link'
        }, {
          value: ReportType.UNRELATED_VIDEO,
          label: 'Unrelated'
        }, {
          value: ReportType.SPAM,
          label: 'Spam'
        }]
      })
    );
  }

  ngOnInit(): void {
    if (this.embed instanceof RestaurantEmbed) {
      this.reportSubjectType = ReportSubjectType.RESTAURANT;
      this.subjectId = this.restaurant.id;
    } else if (this.embed.districtId) {
      this.reportSubjectType = ReportSubjectType.DISTRICT;
      this.subjectId = this.embed.districtId;
    } else {
      this.reportSubjectType = ReportSubjectType.CITY;
      this.subjectId = this.embed.cityId;
    }
  }

  cancelVideo(embed: RestaurantEmbed | LocationEmbed) {
    this.embedService.delete(embed).subscribe(() => {
      this.deleteEmbed.emit(embed);
    });
  }

  @Auth()
  openPostModal(reportType: ReportType) {
    this.currentReportType = reportType;

    this.modalService.open(this.reportVideoModalRef, this.isMobile ? ModalType.LOGIN_MOBILE : ModalType.LOGIN);
  }
}
