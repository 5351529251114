import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UnsplashImage } from '../models/unsplash-image';
import { BackgroundImageService } from '../services/background-image.service';
import { ImageUtils } from '../utils/image-utils';

@Directive({
  selector: '[appBackgroundUrl]'
})
export class BackgroundUrlDirective implements OnInit {
  private _backgroundUrl = new BehaviorSubject<string | UnsplashImage>('');

  @Input('appBackgroundUrl') set backgroundUrl(value: string | UnsplashImage) {
    this._backgroundUrl.next(value);
  }

  element: HTMLElement;

  constructor(
    element: ElementRef,
    private renderer: Renderer2,
    private backgroundImageService: BackgroundImageService
  ) {
    this.element = element.nativeElement;
    this.element.classList.add('app-background-url');
    this.element.classList.add('background-center-cover-no-repeat');

    if (!this.element.classList.contains('position-fixed')) {
      this.element.classList.add('position-relative');
    }
  }

  ngOnInit(): void {
    this._backgroundUrl.subscribe(image => {
      setTimeout(() => {
        this.setBackgroundImage(image);
      });
    });
  }

  private setBackgroundImage(image: string | UnsplashImage) {
    if (image) {
      if (image instanceof UnsplashImage) {
        const url = image.url + `&w=${window.innerWidth}&auto=format&auto=compress`;

        this.backgroundImageService.setBlurHash(image.blurHash, this.element);

        ImageUtils.downloadImage(url).then(() => {
          this.backgroundImageService.removeBlurHash(this.element);

          this.element.style.backgroundImage = `url(${url})`;
        });
      } else {
        this.element.style.backgroundImage = `url(${image})`;
      }
    } else {
      this.element.style.backgroundImage = 'none';
    }
  }
}
