import { Image } from './image';

export class ImageList {
  images: Image[];
  nextUrl: string;

  constructor(images: Image[], nextUrl: string) {
    this.images = images;
    this.nextUrl = nextUrl;
  }
}
