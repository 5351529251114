import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appDynamicUrlElement]'
})
export class DynamicUrlElementDirective implements OnInit {
  @Input() appDynamicUrlElement = '';

  element: HTMLElement;

  constructor(element: ElementRef) {
    this.element = element.nativeElement;
  }

  ngOnInit(): void {
    this.element.classList.add('app-dynamic-url-element');
    this.element.dataset.url = this.appDynamicUrlElement;
  }
}
