import { Component, OnInit } from '@angular/core';
import { CurrentRestaurant } from '../../../../../decorators/current-restaurant.decorator';
import { Restaurant } from '../../../../../domains/restaurant';

@Component({
  selector: 'app-menu-loader',
  templateUrl: './menu-loader.component.html',
  styleUrls: ['./menu-loader.component.scss']
})
export class MenuLoaderComponent implements OnInit {
  @CurrentRestaurant() currentRestaurant: Restaurant;

  ngOnInit(): void {
  }
}
