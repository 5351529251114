import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { Restaurant } from '../../../domains/restaurant';
import { User } from '../../../domains/user';
import { BooleanLocalStorage } from '../../../local-storage/boolean-local-storage';
import { LocalStorageKey } from '../../../models/enums/local-storage-key';
import { MenuType } from '../../../models/enums/menu-type';
import { ModalType } from '../../../models/enums/modal-type';
import { ModalService } from '../../../services/modal.service';
import { NavigatorService } from '../../../services/navigator.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-navbar-top-menu-cs',
  templateUrl: './navbar-top-menu-cs.component.html',
  styleUrls: ['../navbar.shared.scss']
})
export class NavbarTopMenuCsComponent implements OnInit {
  @Input() title: string;
  @Input() restaurant: Restaurant;

  @Output() saveChanges = new EventEmitter<boolean>();
  @Output() enabledMenuTypesSave = new EventEmitter<MenuType[]>();

  @ViewChild('menuListModalRef') menuListModalRef: ElementRef;

  @CurrentUser() currentUser: User;

  constructor(
    public navigatorService: NavigatorService,
    private notificationService: NotificationService,
    private modalService: ModalService,
  ) {
  }

  ngOnInit() {
    const isEditNoteShowedLocalStorage = new BooleanLocalStorage(
      LocalStorageKey.IS_EDIT_NOTE_SHOWED,
      {hostname: this.restaurant.hostname, locationId: this.restaurant.locationIndex}
    );

    if (!isEditNoteShowedLocalStorage.getItem()) {
      isEditNoteShowedLocalStorage.setItem(true);

      if (this.restaurant.isScraped) {
        this.notificationService.editMode();
      } else {
        this.notificationService.restaurantCrated();
      }
    }
  }

  goBack() {
    this.navigatorService.goToRestaurantFromSubPages(false, 200);
  }

  save() {
    this.saveChanges.emit(true);
  }

  get isAdmin(): boolean {
    return this.currentUser?.isAdmin(this.restaurant);
  }

  onMenusClick() {
    this.modalService.open(this.menuListModalRef, ModalType.CS_MENU_LIST);
  }

  onEnabledMenusSave(enabledMenus: MenuType[]) {
    this.enabledMenuTypesSave.emit(enabledMenus);
  }
}
