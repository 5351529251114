<div *ngIf="images?.length == 0" class="text-center">
  <img
    [src]="defaultRestaurantImage"
    [style.width.px]="elementWidth"
    [style.height.px]="elementHeight"
    [ngClass]="{'w-100': !elementWidth}"
    class="object-fit-cover"
    alt=""
  />
</div>

<ng-container *ngIf="images?.length > 0">
  <img
    *ngIf="images[0].isDataOrURL"
    [style.width.px]="elementWidth"
    [style.height.px]="elementHeight"
    [src]="images[0].path"
    [ngClass]="{'w-100': !elementWidth}"
    class="object-fit-cover"
    alt=""
  />

  <ng-container *ngIf="!images[0].isDataOrURL">
    <div *ngIf="!isImageLoaded" class="image-loader-wrapper w-100"></div>

    <img
      appBlurImg
      [style.width.px]="elementWidth"
      [style.height.px]="elementHeight"
      [path]="images[0].path"
      [alt]="alt"
      [ngClass]="{'w-100': !elementWidth}"
      class="ng-star-inserted border-radius-0-125rem object-fit-cover"
      (load)="isImageLoaded = true"
    />
  </ng-container>
</ng-container>
