import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutRestaurantComponent } from './components/admin/about-restaurant/about-restaurant.component';
import { AdminComponent } from './components/admin/admin.component';
import { DashboardComponent } from './components/admin/dashboard/dashboard.component';
import { MenuTableComponent } from './components/admin/menu-table/menu-table.component';
import { OrdersComponent } from './components/admin/orders/orders.component';
import { PlansComponent } from './components/admin/plans/plans.component';
import { RestaurantEditComponent } from './components/admin/restaurant-edit/restaurant-edit.component';
import { BrowserSupportComponent } from './components/browser-support/browser-support.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ExploreComponent } from './components/explore/explore.component';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { HomeComponent } from './components/home/home.component';
import { InstagramComponent } from './components/instagram/instagram.component';
import { LayoutAdminLocationComponent } from './components/layout/layout-main/layout-admin/layout-admin-location/layout-admin-location.component';
import { LayoutAdminWithoutLocationComponent } from './components/layout/layout-main/layout-admin/layout-admin-without-location/layout-admin-without-location.component';
import { LayoutAdminComponent } from './components/layout/layout-main/layout-admin/layout-admin.component';
import { LayoutEntityComponent } from './components/layout/layout-main/layout-front/layout-entity/layout-entity.component';
import { LayoutFoodComponent } from './components/layout/layout-main/layout-front/layout-entity/layout-restaurant/layout-menu/layout-food/layout-food.component';
import { LayoutMenuComponent } from './components/layout/layout-main/layout-front/layout-entity/layout-restaurant/layout-menu/layout-menu.component';
import { LayoutRestaurantLocationComponent } from './components/layout/layout-main/layout-front/layout-entity/layout-restaurant/layout-restaurant-location/layout-restaurant-location.component';
import { LayoutRestaurantNoLocationComponent } from './components/layout/layout-main/layout-front/layout-entity/layout-restaurant/layout-restaurant-no-location/layout-restaurant-no-location.component';
import { LayoutRestaurantComponent } from './components/layout/layout-main/layout-front/layout-entity/layout-restaurant/layout-restaurant.component';
import { LayoutUserComponent } from './components/layout/layout-main/layout-front/layout-entity/layout-user/layout-user.component';
import { LayoutFrontComponent } from './components/layout/layout-main/layout-front/layout-front.component';
import { LayoutMainComponent } from './components/layout/layout-main/layout-main.component';
import { LayoutSettingsComponent } from './components/layout/layout-main/layout-settings/layout-settings.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PostNewComponent } from './components/post-shared/post-new/post-new.component';
import { PostsComponent } from './components/post-shared/posts/posts.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { ResetComponent } from './components/reset/reset.component';
import { FoodGalleryComponent } from './components/restaurant/menu-router/menu/food-gallery/food-gallery.component';
import { RestaurantComponent } from './components/restaurant/restaurant.component';
import { HeroComponent } from './components/settings/hero/hero.component';
import { PasswordComponent } from './components/settings/password/password.component';
import { ProfileComponent } from './components/settings/profile/profile.component';
import { SettingsComponent } from './components/settings/settings/settings.component';
import { SubscriptionComponent } from './components/settings/subscription/subscription.component';
import { TermsComponent } from './components/terms/terms.component';
import { UserAuthorizationComponent } from './components/user-authorization/user-authorization.component';
import { UserComponent } from './components/user/user.component';
import { VerifyOwnershipComponent } from './components/verify-ownership/verify-ownership.component';
import { AdminAuthGuard, AuthGuard } from './guard/auth-checker';
import { BrowserSupportGuard } from './guard/browser-checker';
import { DeactivateGuard } from './guard/deactivate-checker';
import { EntityCheckGuard } from './guard/entity-checker';
import { LocationCheckGuard } from './guard/location-checker';
import { SearchParamsGuard, SearchParamsInitialGuard } from './guard/search-params-checker';
import { Role } from './models';
import { PostPageType } from './models/types/post-page-type';
import { PostType } from './models/types/post-type';

const adminChildren: Routes = [
  {path: '', component: AdminComponent},
  {path: 'orders', component: OrdersComponent},
  {path: 'locations', component: RestaurantEditComponent},
  {path: 'menu-table', component: MenuTableComponent},
  {path: 'upgrade', component: PlansComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'about', component: AboutRestaurantComponent}
];

const restaurantChildren: Routes = [
  {path: 'verify-ownership', component: VerifyOwnershipComponent},
  {
    path: 'food-new',
    component: PostNewComponent,
    data: {postPageType: PostPageType.RESTAURANT, postType: PostType.PLATE}
  },
  {path: '', component: RestaurantComponent},
  {
    path: ':menu', component: LayoutMenuComponent, children: [
      {path: '', component: RestaurantComponent},
      {
        path: ':food', component: LayoutFoodComponent, children: [
          {path: '', component: RestaurantComponent},
          {
            path: 'photos',
            canDeactivate: [DeactivateGuard],
            component: FoodGalleryComponent,
            data: {isFoodType: true}
          },
          {
            path: 'photos/new',
            component: PostNewComponent,
            data: {defaultSeo: true, postPageType: PostPageType.GALLERY}
          },
          {path: 'photos/:postHash', component: PostsComponent, data: {isFanFoodImage: true}},
        ]
      },
    ]
  },
];

/**
 * @warning Look to https://github.com/rykersoft/angular-seo if routes are changed
 */
const routes: Routes = [
  {path: 'browser-support', component: BrowserSupportComponent},
  {
    path: '', component: LayoutMainComponent, canActivate: [BrowserSupportGuard, SearchParamsInitialGuard], children: [
      {path: 'login', component: UserAuthorizationComponent, data: {defaultSeo: true}},
      {path: 'reset', component: ResetComponent, data: {defaultSeo: true}},
      {path: 'confirm', component: UserAuthorizationComponent, data: {defaultSeo: true, type: 'confirm'}},
      {
        path: 'settings',
        component: LayoutSettingsComponent,
        canActivate: [AuthGuard],
        children: [
          {path: '', component: SettingsComponent, data: {defaultSeo: true}},
          {path: 'profile', component: ProfileComponent, data: {defaultSeo: true}},
          {path: 'password', component: PasswordComponent, data: {defaultSeo: true}},
          {path: 'hero', component: HeroComponent, data: {defaultSeo: true}},
          {path: 'subscription', component: SubscriptionComponent, data: {defaultSeo: true}},
        ]
      },
      {
        path: 'admin/:hostname',
        component: LayoutAdminComponent,
        canActivate: [AdminAuthGuard],
        data: {roles: [Role.ADMIN]},
        children: [
          {path: '', component: LayoutAdminWithoutLocationComponent, children: adminChildren},
          {path: ':locationId', component: LayoutAdminLocationComponent, children: adminChildren}
        ]
      },
      {
        path: '',
        component: LayoutFrontComponent,
        children: [
          {path: '', component: ExploreComponent, canActivate: [SearchParamsGuard]},
          {path: 'restaurants', component: ExploreComponent, canActivate: [SearchParamsGuard]},
          {
            path: ':stateKey/restaurants',
            component: ExploreComponent,
            canActivate: [SearchParamsGuard]
          },
          {
            path: ':stateKey/:countySlug/restaurants',
            component: ExploreComponent,
            canActivate: [SearchParamsGuard]
          },
          {
            path: ':stateKey/:countySlug/:citySlug/restaurants',
            component: ExploreComponent,
            canActivate: [SearchParamsGuard]
          },
          {
            path: ':stateKey/:countySlug/:citySlug/:districtSlug/restaurants',
            component: ExploreComponent,
            canActivate: [SearchParamsGuard]
          },
          {
            path: ':stateKey/:countySlug/r/:regionSlug/restaurants',
            component: ExploreComponent,
            canActivate: [SearchParamsGuard]
          },
          {
            path: ':stateKey/:countySlug/r/:regionSlug/:citySlug/restaurants',
            component: ExploreComponent,
            canActivate: [SearchParamsGuard]
          },
          {
            path: ':stateKey/:countySlug/r/:regionSlug/:citySlug/:districtSlug/restaurants',
            component: ExploreComponent,
            canActivate: [SearchParamsGuard]
          },
          {
            path: ':stateKey/:countySlug/:citySlug/lat/:lat/lon/:lon/restaurants',
            component: ExploreComponent,
            canActivate: [SearchParamsGuard]
          },
          {
            path: ':stateKey/:countySlug/:citySlug/:districtSlug/lat/:lat/lon/:lon/restaurants',
            component: ExploreComponent,
            canActivate: [SearchParamsGuard]
          },
          {
            path: ':stateKey/:countySlug/r/:regionSlug/:citySlug/lat/:lat/lon/:lon/restaurants',
            component: ExploreComponent,
            canActivate: [SearchParamsGuard]
          },
          {
            path: ':stateKey/:countySlug/r/:regionSlug/:citySlug/:districtSlug/lat/:lat/lon/:lon/restaurants',
            component: ExploreComponent,
            canActivate: [SearchParamsGuard]
          },
          {path: 'privacy', component: PrivacyComponent, data: {defaultSeo: true}},
          {path: 'terms', component: TermsComponent, data: {defaultSeo: true}},
          {path: 'gems', component: HomeComponent, data: {defaultSeo: true}},
          {path: 'instagram', component: InstagramComponent, data: {defaultSeo: true}},
          {path: 'checkout/:action', component: CheckoutComponent, data: {defaultSeo: true}},
          {
            path: 'favorites',
            component: FavoritesComponent,
            canActivate: [AuthGuard],
            data: {defaultSeo: true}
          },
          {
            path: '',
            component: LayoutEntityComponent,
            canActivateChild: [EntityCheckGuard],
            children: [
              {
                path: ':hostname',
                component: LayoutRestaurantComponent,
                canActivate: [LocationCheckGuard],
                children: [
                  {
                    path: '',
                    component: LayoutRestaurantNoLocationComponent,
                    children: restaurantChildren
                  },
                  {
                    path: ':locationId',
                    component: LayoutRestaurantLocationComponent,
                    children: restaurantChildren
                  },
                ]
              },
              {
                path: ':username', component: LayoutUserComponent, children: [
                  {path: '', component: UserComponent, canDeactivate: [DeactivateGuard]},
                  {
                    path: 'post',
                    component: PostNewComponent,
                    data: {
                      defaultSeo: true,
                      postPageType: PostPageType.USER,
                      postType: PostType.USER
                    }
                  },
                  {path: 'post/:postHash', component: PostsComponent},
                ]
              },
            ]
          },
          {
            path: '**',
            pathMatch: 'full',
            component: PageNotFoundComponent,
            data: {defaultSeo: true}
          }
        ]
      }
    ]
  },
];
// *******************************************************************************
//
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
