import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Restaurant } from '../../../../domains/restaurant';
import { Basket } from '../../../../models/basket/basket';
import { DateUtils } from '../../../../utils/date-utils';

@Component({
  selector: 'app-pick-up-modal',
  templateUrl: './pick-up-modal.component.html',
  styleUrls: []
})
export class PickUpModalComponent implements OnInit {
  @Input() restaurant: Restaurant;
  @Input() basket: Basket;

  @Output() set = new EventEmitter<Date>();

  dateToday: Date;
  dateDays: Date[] = [];
  dateDaySelected: Date;
  dateTimeNow: Date;
  dateTimeList: Date[] = [];
  dateTimeSelected: Date;

  constructor() {
    this.dateToday = new Date();
    this.dateToday.setHours(0);
    this.dateToday.setMinutes(0);
    this.dateToday.setSeconds(0);
    this.dateToday.setMilliseconds(0);

    this.dateDays.push(this.dateToday);

    for (let i = 1; i <= 6; i++) {
      const dateDay = new Date(this.dateToday.getTime() + (i * 24 * 60 * 60 * 1000));
      this.dateDays.push(dateDay);
    }
  }

  ngOnInit() {
    if (!this.basket.requestTime || this.basket.asap) {
      this.dateDaySelected = this.dateDays[0];
    } else {
      this.dateDays.forEach(dateDay => {
        if (DateUtils.isSameDay(this.basket.requestTime, dateDay)) {
          this.dateDaySelected = dateDay;
        }
      });
    }

    this.setDateTimeList(this.restaurant, this.dateDaySelected, true);
  }

  setDateTimeList(restaurant: Restaurant, dateDay: Date, initial = false) {
    this.dateTimeList = [];
    this.dateTimeNow = new Date();

    if (dateDay === this.dateToday) {
      this.dateTimeList.push(this.dateTimeNow);
    }

    for (let hour = 0; hour <= 23; hour++) {
      [0, 15, 30, 45].forEach(minute => {
        const dateTime = new Date(
          this.dateTimeNow.getFullYear(),
          this.dateTimeNow.getMonth(),
          this.dateTimeNow.getDate(),
          hour,
          minute
        );

        if (dateDay !== this.dateToday || dateTime > this.dateTimeNow) {
          this.dateTimeList.push(dateTime);
        }
      });
    }

    if (!initial || !this.basket.requestTime || this.basket.asap) {
      this.dateTimeSelected = this.dateTimeList[0];
    } else {
      this.dateTimeList.forEach(dateTime => {
        if (DateUtils.isSameTime(this.basket.requestTime, dateTime)) {
          this.dateTimeSelected = dateTime;
        }
      });
    }
  }

  setPickUpTime() {
    if (this.dateDaySelected === this.dateToday && this.dateTimeSelected === this.dateTimeNow) {
      this.set.emit(null);
    } else {
      const date = new Date(
        this.dateDaySelected.getFullYear(),
        this.dateDaySelected.getMonth(),
        this.dateDaySelected.getDate(),
        this.dateTimeSelected.getHours(),
        this.dateTimeSelected.getMinutes(),
        this.dateTimeSelected.getSeconds(),
        this.dateTimeSelected.getMilliseconds()
      );

      this.set.emit(date);
    }
  }

  setDateDay(dateDay: Date) {
    this.setDateTimeList(this.restaurant, dateDay);
  }
}
