import { Component, Input, OnInit } from '@angular/core';
import { Food } from '../../../../../../domains/food';
import { Restaurant } from '../../../../../../domains/restaurant';
import { Tag } from '../../../../../../domains/tag';

@Component({
  selector: 'app-allergens-modal',
  templateUrl: './allergens-modal.component.html',
  styleUrls: []
})
export class AllergensModalComponent implements OnInit {
  @Input() food: Food;
  @Input() allergens: Tag[];
  @Input() restaurant: Restaurant;

  constructor() {
  }

  ngOnInit() {
  }
}
