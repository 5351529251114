<app-picker
  *ngIf="positionWrapper.isSearchModal"
  [classes]="'icon rest-selector'"
  [picker]="searchResult.restaurantsPicker"
  [title]="title"
  [disabled]="isDisabled"
  (selectedValue)="onPickerSelected($event)"
></app-picker>

<app-picker
  *ngIf="positionWrapper.isDistanceSlider"
  [picker]="searchResult.restaurantsPicker"
  [title]="(searchResult.distanceFilterMobile || '1') + ' mi (' + searchResult.restaurantsLength.toString() + ')'"
  [widthByText]="true"
  classes="text-default background-none"
  (selectedValue)="onPickerSelected($event)"
></app-picker>
