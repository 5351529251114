import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigatorService } from '../../../services/navigator.service';

@Component({
  selector: 'app-navbar-bottom',
  templateUrl: './navbar-bottom.component.html',
  styleUrls: [
    '../navbar.shared.scss'
  ]
})
export class NavbarBottomComponent implements OnInit {
  @Output() mainMenuClick = new EventEmitter<boolean>();

  constructor(
    public navigatorService: NavigatorService
  ) {
  }

  ngOnInit() {
  }

  onMainMenuClick() {
    if (this.mainMenuClick.observers.length > 0) {
      this.mainMenuClick.emit(true);
    } else {
      this.goToExplore();
    }
  }

  goToExplore() {
    this.navigatorService.goToUrl('/').then();
  }
}
