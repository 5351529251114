import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, isDevMode, OnInit, ViewChild } from '@angular/core';
import { ActivationEnd, Event as RouterEvent, NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppService } from './app.service';
import { LayoutFrontComponent } from './components/layout/layout-main/layout-front/layout-front.component';
import { DeviceInfo } from './decorators/device-info.decorator';
import { IsDevice } from './decorators/is-device.decorator';
import { IsSidenavMouseOver } from './decorators/is-sidenav-mouse-over.decorator';
import { Device } from './models/device';
import { DeviceType } from './models/enums/device-type';
import { ModalType } from './models/enums/modal-type';
import { SidenavType } from './models/enums/sidenav-type';
import { DecoratorService } from './services/decorator/decorator.service';
import { LayoutService } from './services/layout.service';
import { LoaderService } from './services/loader.service';
import { ModalService } from './services/modal.service';
import { SeoService } from './services/seo.service';

declare var gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: [':host { display: block;}']
})
export class AppComponent implements OnInit {
  @ViewChild('loginModalRef') loginModalRef: ElementRef;
  @ViewChild('subscribeModalRef') subscribeModalRef: ElementRef;
  @ViewChild('subscribeDesktopModal') subscribeDesktopModal: ElementRef;

  @IsSidenavMouseOver([SidenavType.LEFT, SidenavType.RIGHT]) isSidenavLeftOrRightMouseOver: boolean;

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;
  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  @DeviceInfo() deviceInfo: Device;

  constructor(
    private router: Router,
    private appService: AppService,
    private layoutService: LayoutService,
    private decoratorService: DecoratorService,
    private loaderService: LoaderService,
    private deviceDetectorService: DeviceDetectorService,
    private modalService: ModalService,
    private seoService: SeoService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    decoratorService.setAppComponent(this);

    // Subscribe to router events to handle page transition
    this.router.events.subscribe(this.navigationInterceptor.bind(this));

    // Disable animations and transitions in IE10 to increase performance
    if (typeof document['documentMode'] === 'number' && document['documentMode'] < 11) {
      const style = document.createElement('style');

      style.textContent = `
      * {
          -ms-animation: none !important;
          animation: none !important;
          -ms-transition: none !important;
          transition: none !important;
      }`;

      document.head.appendChild(style);
    }
  }

  ngOnInit() {
    this.loaderService.hideSplash();

    this._document.body.classList.add(
      this.deviceInfo.os.toLowerCase() +
      '-' +
      this.deviceInfo.osVersion.toLowerCase().replace('.', '-')
    );

    this.setCssProperties();

    this.router.events.subscribe(event => {
      if (
        event instanceof ActivationEnd &&
        event.snapshot.component
      ) {
        if (!this.isDesktop) {
          if (event.snapshot.component === LayoutFrontComponent) {
            this._document.body.classList.add('bg-white');
          } else {
            this._document.body.classList.remove('bg-white');
          }
        }

        if (event.snapshot.data?.defaultSeo) {
          this.seoService.setDefaultMetaTags();
        }
      }
    });
  }

  @HostListener('window:resize')
  onResize() {
    if (isDevMode()) {
      this.deviceDetectorService.setDeviceInfo(window.navigator.userAgent);
    }

    this.setCssProperties();
  }

  @HostListener('window:scroll')
  onScroll() {
    this.setCssProperties();
  }

  @HostListener('window:popstate')
  onBackButtonClicked() {
    if (this.modalService.hasOpenModal()) {
      this.modalService.closeAll();
    }
  }

  @HostListener('window:orientationchange')
  onOrientationChange() {
    this.modalService.closeByType(ModalType.PREVIEW_SWIPER);
  }

  private setCssProperties() {
    const container: HTMLElement = document.querySelector(':root');
    container.style.setProperty('--vh', `${window.innerHeight / 100}px`);
    container.style.setProperty('--inaw', `${this.isMobile ? 0 : 1}rem`);
  }

  private navigationInterceptor(e: RouterEvent) {
    if (e instanceof NavigationEnd) {
      // Scroll to top of the page
      this.appService.scrollTop(0, 0);

      gtag('config', 'UA-148827121-1', {
        page_path: e.urlAfterRedirects
      });
    }

    if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
      if (this.layoutService.isSmallScreen() && !this.layoutService.isCollapsed()) {
        setTimeout(() => this.layoutService.setCollapsed(true, true), 10);
      }
    }
  }
}
