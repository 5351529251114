import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { User } from '../../domains/user';
import { LocalStorage } from '../../local-storage/local-storage';
import { CookieKey } from '../../models/enums/cookie-key';
import { InstagramState } from '../../models/enums/instagram-state';
import { LocalStorageKey } from '../../models/enums/local-storage-key';
import { AuthenticationService } from '../../services/authentication.service';
import { InstagramService } from '../../services/instagram.service';
import { LoaderService } from '../../services/loader.service';
import { NavigatorService } from '../../services/navigator.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-instagram',
  template: ''
})
export class InstagramComponent implements OnInit {
  submitted = false;
  redirectUrl: string;
  currentUser: User;
  code: string;
  backgroundImage: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private instagramService: InstagramService,
    private cookieService: CookieService,
    private navigatorService: NavigatorService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private loaderService: LoaderService
  ) {
    this.backgroundImage = environment.loginBackgroundImage;
  }

  ngOnInit() {
    this.loaderService.showLoader();

    this.redirectUrl = this.cookieService.get(CookieKey.REDIRECT_URL) || '/';

    this.activatedRoute.queryParams.subscribe(params => {
      this.code = params['code'];
      const state = params['state'];

      if (this.code) {
        if (state !== InstagramState.USERNAME) {
          this.currentUser = this.authenticationService.currentUser;

          this.instagramService
            .connect(this.code)
            .subscribe({
              next: userData => {
                this.loaderService.hideLoader();

                if (state === InstagramState.LOGIN) {
                  this.authenticationService.saveUserDataToCookie(userData);
                } else {
                  this.authenticationService.currentUser = userData.user;
                }

                if (userData.user.email.startsWith('ig_')) {
                  this.navigatorService.goToExploreWithQueryParams({'show-email': true});
                } else {
                  setTimeout(() => {
                    this.navigatorService.goToUrl(this.redirectUrl).then();
                  }, 100);
                }
              }, error: () => {
                this.navigatorService.goToUrl(this.redirectUrl).then();
              }
            });
        } else {
          const tempUserLocalStorage = new LocalStorage(User, LocalStorageKey.TEMP_USER);
          const user = tempUserLocalStorage.getItem();
          tempUserLocalStorage.removeItem();

          this.instagramService.addInstagramToUser(this.code, user).subscribe((userData) => {
            const newUser = plainToClass(User, userData['user']);

            this.notificationService.welcome(newUser.firstName, newUser.lastName);

            this.navigatorService.goToHome();
          });
        }
      } else {
        this.navigatorService.goToUrl(this.redirectUrl).then();
      }
    });
  }
}
