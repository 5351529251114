import { Component } from '@angular/core';
import { RestaurantSimple } from '../../../domains/restaurant-simple';
import { NavigatorService } from '../../../services/navigator.service';
import { RestaurantService } from '../../../services/restaurant.service';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent {
  active: Array<RestaurantSimple> = [];
  retired: Array<RestaurantSimple> = [];

  constructor(
    private restaurantService: RestaurantService,
    private navigatorService: NavigatorService
  ) {
    this.restaurantService
      .getCsRestaurants()
      .subscribe(restaurants => {
        restaurants.forEach(restaurant => {
          if (restaurant.isOwned) {
            this.retired.push(restaurant);
          } else {
            this.active.push(restaurant);
          }
        });
      });
  }

  onRestaurantClick(restaurant: RestaurantSimple) {
    this.navigatorService.goToRestaurantWithObject(restaurant);
  }
}
