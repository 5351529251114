export class DateUtils {
  static WEEK_DAYS = {
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
    7: 'Sun'
  };

  static isSameDay(d1: Date, d2: Date) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  static isSameTime(d1: Date, d2: Date) {
    return d1.getHours() === d2.getHours() &&
      d1.getMinutes() === d2.getMinutes() &&
      d1.getSeconds() === d2.getSeconds() &&
      d1.getMilliseconds() === d2.getMilliseconds();
  }

  static isSameMonth(date1: Date, date2: Date) {
    return date1.getMonth() === date2.getMonth();
  }

  static stringToDate(dateString: string) {
    const dateSplit = dateString.split('-');

    return new Date(+dateSplit[0], +dateSplit[1] - 1, +dateSplit[2]);
  }

  static dateToString(date: Date = null) {
    if (!date) {
      date = new Date();
    }

    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${date.getFullYear()}-${month > 9 ? month : '0' + month}-${day > 9 ? day : '0' + day}`;
  }

  static toDate(dateInput: any) {
    if (!dateInput || dateInput instanceof Date) {
      return dateInput;
    }

    if (!Array.isArray(dateInput)) {
      dateInput = dateInput.split('-');
    }

    return dateInput.length === 3 ? new Date(+dateInput[0], +dateInput[1] - 1, +dateInput[2]) : dateInput;
  }

  static dateToArray(date: Date) {
    return date ? [date.getFullYear(), date.getMonth() + 1, date.getDate()] : [];
  }

  static getWeekDay(date: Date) {
    return this.WEEK_DAYS[date.getUTCDay() + 1];
  }

  static daysToString(days: number[]) {
    days = days.sort();

    const periodSymbol = ' - ';
    const separatorSymbol = ' • ';
    let title: string;

    if (days.length > 0) {
      if (days.toString() === [1, 2, 3, 4, 5, 6, 7].toString()) {
        title = `7 Days`;
      } else if (days.length === 2) {
        if (days[0] === 1 && days[1] === 7) {
          return this.WEEK_DAYS[days[1]] + separatorSymbol + this.WEEK_DAYS[days[0]];
        }

        return this.WEEK_DAYS[days[0]] + separatorSymbol + this.WEEK_DAYS[days[1]];
      } else {
        days.forEach((day, index) => {
          if (!title) {
            title = this.WEEK_DAYS[day];
          } else if (!days[index - 1]) {
            title += separatorSymbol + this.WEEK_DAYS[day];
          } else if (index === (days.length - 1) || (index < (days.length - 1) && !days[index + 1])) {
            title += periodSymbol + this.WEEK_DAYS[day];
          }
        });
      }

      return title;
    } else {
      return null;
    }
  }
}
