import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-restaurant-loader',
  templateUrl: './restaurant-loader.component.html',
  styleUrls: ['./../restaurant-desktop.component.scss']
})
export class RestaurantLoaderComponent implements OnInit {
  exploreBackgroundImage: string;

  ngOnInit(): void {
    this.exploreBackgroundImage = environment.favoritesBackgroundImage;
  }
}
