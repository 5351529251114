<nav class="navbar navbar-top nav-fill fixed-top bg-white max-width safe-area-top">
  <div class="navbar-top-left navbar-width-30px">
    <a appHrefNone class="navbar-top-icon-back" (click)="goBack()">
      <span class="icon-v2 arrow-back scale-82"></span>
    </a>
  </div>

  <div class="nav-item">
    <span
      *ngIf="title"
      class="navbar-brand fans-title"
    >
      {{ title }}
    </span>
  </div>

  <div class="navbar-top-right navbar-width-30px">
    <app-picker
      [picker]="picker"
      [value]="sort"
      [classes]="'icon dots-v ml-3 pl-6px scale-82'"
      (selectedValue)="onOptionsSelected($event)"
    ></app-picker>
  </div>
</nav>

