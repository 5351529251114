import { Subscription } from 'rxjs';
import { BaseLocalStorage } from '../local-storage/base-local-storage';
import { BooleanLocalStorage } from '../local-storage/boolean-local-storage';
import { LocalStorage } from '../local-storage/local-storage';
import { NumberLocalStorage } from '../local-storage/number-local-storage';
import { StringLocalStorage } from '../local-storage/string-local-storage';
import { LocalStorageKey } from '../models/enums/local-storage-key';
import { LocalStorageOptions } from '../models/local-storage-options';
import { LocalStorageValueOptions } from '../models/local-storage-value-options';
import { DecoratorInjectorService } from '../services/decorator/decorator-injector.service';
import { DecoratorService } from '../services/decorator/decorator.service';

export function LocalStorageValue<LocalStorageClassType>(
  localStorageClassType: new (...args: any[]) => LocalStorageClassType,
  localStorageKey: LocalStorageKey,
  options: LocalStorageValueOptions = {}
): PropertyDecorator {
  return (target: any, propertyKey: string) => {
    let service: DecoratorService;
    let subscription: Subscription;
    let localStorage: LocalStorageClassType | BaseLocalStorage;
    let localStorageOptions: LocalStorageOptions = {};

    const setLocalStorage = () => {
      localStorageOptions = {};

      if (options.restaurant) {
        localStorageOptions.hostname = service.routerService.restaurantHostname;
        localStorageOptions.locationId = service.routerService.restaurantLocationId;
      }

      const currentUser = service.authenticationService.currentUser;

      if (options.user && currentUser) {
        localStorageOptions.userId = currentUser.id;
      }

      const localStorageClasses = [BooleanLocalStorage.name, StringLocalStorage.name, NumberLocalStorage.name];

      if (localStorageClasses.includes(localStorageClassType.name)) {
        localStorage = new localStorageClassType(localStorageKey, localStorageOptions);
      } else {
        localStorage = new LocalStorage(localStorageClassType, localStorageKey, localStorageOptions);
      }
    };

    const getter = () => {
      if (service === undefined) {
        service = DecoratorInjectorService.getService();
      }

      if (localStorage === undefined) {
        setLocalStorage();
      }

      if (subscription === undefined) {
        subscription = service.routerService.restaurantSubject.subscribe(restaurant => {
          if (restaurant) {
            setLocalStorage();
          }
        });
      }

      return localStorage;
    };

    const setter = () => {
    };

    Object.defineProperty(target, propertyKey, {
      get: getter,
      set: setter
    });
  };
}
