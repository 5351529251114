<ng-container *appIfIsMobile>
  <app-navbar-top-profile [title]="'Password'" [edited]="edited" (saveClick)="onSubmit()"></app-navbar-top-profile>

  <div class="container max-width has-navbar-top">
    <ng-container [ngTemplateOutlet]="passwordCard"></ng-container>
  </div>
</ng-container>

<ng-container *appIfIsDesktop>
  <ng-container [ngTemplateOutlet]="passwordCard"></ng-container>
</ng-container>

<ng-template #passwordCard>
  <div class="card" appAddClassIfMobile="password-mobile my-3" appAddClassIfDesktop="password-desktop">
    <div class="card-body">
      <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label class="form-label">Current password</label>
          <input
            [ngClass]="{ 'is-invalid': controls.current.errors && submitted}"
            formControlName="current"
            type="password"
            class="form-control"
            (ngModelChange)="checkChange()"
          />
          <small class="invalid-feedback">
            Incorrect
          </small>
        </div>

        <div class="form-group">
          <label class="form-label">New password</label>
          <input
            [ngClass]="{ 'is-invalid': controls.new.errors && submitted}"
            formControlName="new"
            type="password"
            class="form-control"
            (ngModelChange)="checkChange()"
          />

          <small class="invalid-feedback">
            {{ controls.new.errors?.required ? '' : 'Min 6 characters' }}
          </small>
        </div>

        <div class="form-group">
          <label class="form-label">Repeat new password</label>
          <input
            [ngClass]="{ 'is-invalid': controls.repeat.errors && submitted}"
            formControlName="repeat"
            type="password"
            class="form-control"
            (ngModelChange)="checkChange()"
          />
          <small class="invalid-feedback">
            Does not match
          </small>
        </div>

        <div *appIfIsDesktop class="mt-3 flex-content-hr">
          <button
            type="button"
            class="btn-forgot btn btn-link text-capitalize text-default"
            (click)="forgotPassword()"
          >
            Forgot Password
          </button>

          <button class="btn-change btn btn-primary ml-1 text-uppercase" (click)="onSubmit()">Update</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
