<div *appIfIsDesktop class="user-subscribe-desktop-container min-h-100vh">
  <div [appBackgroundUrl]="membershipBackground" class="user-subscribe-desktop d-flex">
    <div class="user-subscribe-image position-relative">
      <div class="user-subscribe-image-background"></div>

      <div class="d-flex h-100 p-4">
        <div class="user-subscribe-image-text mt-auto text-white">
          <h1 class="flex-content-vc">
            <i class="icon main-circle-white"></i>
            <span class="font-weight-600 mb-n2-rem">power</span>
          </h1>

          <h3>
            <div class="pl-8-4rem">folks who feed</div>
          </h3>
        </div>
      </div>
    </div>

    <app-subscribe-modal class="subscribe-modal-backdrop"></app-subscribe-modal>

  </div>
</div>
