import { DecoratorInjectorService } from '../services/decorator/decorator-injector.service';
import { DecoratorService } from '../services/decorator/decorator.service';

export function Subscribe(): MethodDecorator {
  return (target: object, key: string, descriptor: any) => {
    const originalMethod = descriptor.value;

    descriptor.value = (...args: any[]) => {
      const service: DecoratorService = DecoratorInjectorService.getService();

      service.subscribeModal().then(() => {
        return originalMethod.apply(this, args);
      });
    };

    return descriptor;
  };
}
