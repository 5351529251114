<div class="restaurant-desktop-container position-relative w-100">
  <div class="restaurant-desktop d-flex">
    <div class="position-absolute-0 gray-scale-1 z-0"></div>

    <div class="restaurant-desktop-main h-100vh flex-fill overflow-hidden">
      <div class="restaurant-desktop-content d-flex w-100 h-100">
        <div class="flex-content-hr flex-fill">
          <div class="restaurant-desktop-menus">
            <div class="restaurant-desktop-menu"></div>

            <div class="restaurant-desktop-category">
              <div class="row">
                <ng-container *ngFor="let number of [0,1,2]">
                  <div class="col-4">
                    <app-div-rectangle>
                      <ngx-skeleton-loader
                        count="3"
                        animation="progress-dark"
                        [theme]="{
                        'width': '99.5%',
                        'height': '99.5%',
                        'border-radius': '0',
                        'background-color': '#ffffff20',
                        'padding-right': '-0.85rem',
                        'padding-left': '-0.85rem',
                        'margin-bottom': '1.75rem',
                        'backdrop-filter': 'blur(10px)'
                      }"
                      ></ngx-skeleton-loader>
                    </app-div-rectangle>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="restaurant-desktop-sidebar restaurant-desktop-sidebar-active blur-10">
      <div class="d-flex flex-column h-100">
        <div class="restaurant-desktop-logo">
          <a appHrefNone class="icon-v2 mmmm-2x-red"></a>
        </div>

        <div class="restaurant-desktop-controls-loader">
          <div class="restaurant-desktop-controls-menu mt-3">
            <ngx-skeleton-loader
              count="3"
              animation="progress-dark"
              [theme]="{
                'display': 'block',
                'height': '24px',
                'width': '73px',
                'border-radius': '1rem',
                'margin-bottom': '1rem',
                'background-color': '#ffffff20'
              }"
            ></ngx-skeleton-loader>
          </div>

          <div class="flex-fill"></div>
        </div>
      </div>
    </div>
  </div>
</div>
