import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RestaurantSimple } from '../../../domains/restaurant-simple';
import { ModalType } from '../../../models/enums/modal-type';
import { Modal } from '../../../models/modal';
import { RestaurantLocation } from '../../../models/restaurant-location';
import { LoaderService } from '../../../services/loader.service';
import { ModalService } from '../../../services/modal.service';
import { NavigatorService } from '../../../services/navigator.service';
import { RestaurantService } from '../../../services/restaurant.service';

@Component({
  selector: 'app-about-restaurant',
  templateUrl: './about-restaurant.component.html',
  styleUrls: ['./about-restaurant.component.scss']
})
export class AboutRestaurantComponent implements OnInit {
  @ViewChild('restaurantEditModalRef') restaurantEditModalRef: ElementRef;
  @ViewChild('locationAddModalRef') locationAddModalRef: ElementRef;
  @ViewChild('locationRemoveModalRef') locationRemoveModalRef: ElementRef;

  restaurant: RestaurantSimple;
  edit = false;
  locationAddModal: Modal;
  locationRemoveModal: Modal;

  constructor(
    private restaurantService: RestaurantService,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private navigatorService: NavigatorService
  ) {
  }

  ngOnInit(): void {
    this.restaurantService.getUserTopRestaurantSimple().subscribe((restaurant: RestaurantSimple) => {
      this.restaurant = restaurant;
    });
  }

  openEditRestaurantModal() {
    this.modalService.open(this.restaurantEditModalRef, ModalType.BASE);
  }

  openLocationAddModal() {
    this.locationAddModal = this.modalService.open(this.locationAddModalRef, ModalType.LOCATION_ADD_REMOVE);
  }

  openLocationRemoveModal() {
    this.locationRemoveModal = this.modalService.open(this.locationRemoveModalRef, ModalType.LOCATION_ADD_REMOVE);
  }

  cloneRestaurant(location: RestaurantLocation) {
    this.locationAddModal.close();
    this.loaderService.showLoader();

    this.restaurantService.clone(location).subscribe(restaurantSimple => {
      this.loaderService.hideLoader();
      this.goToAdmin(restaurantSimple.locationIndex);
    });
  }

  removeRestaurant() {
    this.locationRemoveModal.close();
    this.loaderService.showLoader();

    this.restaurantService.remove().subscribe(() => {
      this.loaderService.hideLoader();
      this.goToAdmin(1);
    });
  }

  goToAdmin(locationIndex: number) {
    this.navigatorService.goToAdminSettings(locationIndex);
  }
}
