<div class="like text-center">
  <div class="post-icon">
    <span
      #mmmmButtonRef
      id="mmmm-button-ref"
      [ngClass]="{
        'mmmm-white opacity-07-to-1-on-hover': !isMobile && !object.currentUserLiked,
        'mmmm-red': object.currentUserLiked,
        'mmmm-white': !object.currentUserLiked
      }"
      class="icon-v2"
      (click)="onLikeClicked()"
    ></span>
  </div>

  <div
    [innerHTML]="object?.likeCount"
    [ngClass]="{
      'opacity-0': object?.likeCount <= 0,
      'text-white font-weight-400 font-size-0-96rem': !isMobile,
      'font-weight-600': isMobile
    }"
    class="like-count"
  ></div>
</div>
