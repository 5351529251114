<app-navbar-top-explore
  [findMeLoader]="findMeLoader"
  [menuHours]="true"
  [country]="country"
  [searchParams]="searchParams"
  (editRestaurantMenus)="onEditRestaurantMenus($event)"
  (videoModalClicked)="openSearchVideoModal()"
  (openSearchModal)="openSearchCityModal()"
  (onSearchClicked)="onSearchClicked()"
  class="bg-white"
></app-navbar-top-explore>

<app-distance-filter
  *ngIf="searchParams.geoLocation && !findMeLoader"
  (restaurantClick)="onRestaurantClick($event)"
  [position]="distanceSliderPosition.NAV_BAR"
  [searchParams]="searchParams"
  [searchResult]="searchResult"
></app-distance-filter>

<div
  [appBackgroundUrl]="backgroundImage"
  id="container-search"
  class="container container-search has-navbar-both max-width px-0 ios-fixed-background safe-area-top"
>
  <div [ngClass]="{expanded: expanded}" class="container-backdrop-fixed safe-area-top"></div>

  <app-search-mobile-loader [isSearching]="isSearching"></app-search-mobile-loader>

  <div *ngIf="!isSearching">
    <ng-container *ngFor="let group of searchResult.groupsFiltered, let first = first">
      <div *ngIf="group?.hasRestaurants || group?.hasChains" class="card-section">
        <div [ngClass]="{'mt-31px': (searchParams.geoLocation || !findMeLoader) && first}" class="p-0 mt-31px">
          <ng-container *ngFor="let typeCategorySection of group.sections; let i = index;">
            <div
              *ngIf="typeCategorySection.hasRestaurants || typeCategorySection.hasChain"
              [ngClass]="{'section-expanded': expanded}"
              class="section text-center position-relative"
            >
              <div
                *ngIf="expanded"
                id="header-{{typeCategorySection.keysString}}"
                class="phantom-header"
              ></div>

              <button
                *ngIf="expanded"
                class="btn btn-block btn-dark btn-primary-shadow md-btn-flat text-transform-none section-header pb-2-5rem"
                (click)="toggleHeader()"
              >
                {{ typeCategorySection.title }}
              </button>

              <button
                *ngIf="!expanded"
                class="btn btn-block btn-dark btn-primary-shadow md-btn-flat text-transform-none section-header m-0"
                (click)="toggleHeader(typeCategorySection.keysString)"
              >
                {{ typeCategorySection.title }}
              </button>

              <div *ngIf="expanded" class="restaurant-list">
                <app-search-restaurant-list
                  [cityName]="searchParams.locationName"
                  [typeCategorySection]="typeCategorySection"
                  (restaurantClick)="onRestaurantClick($event)"
                  (dividerClick)="onDividerClick($event)"
                  (goToRestaurantToEditMode)="goToRestaurantToEditMode($event)"
                ></app-search-restaurant-list>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<app-navbar-bottom-explore
  [rotateMainLogo]="rotate"
  [expanded]="expanded"
  (toggleButtonClick)="toggleHeader()"
  (mainMenuClick)="openSearchFiltersModal()"
  (favoritesClick)="openFavoriteModal()"
  (videoClick)="openVideoModal()"
></app-navbar-bottom-explore>

<ng-template #searchFiltersModalRef>
  <div class="safe-area-padding-top">
    <app-search-modal
      [country]="country"
      [searchParams]="searchParams"
      (countySelectedChange)="countySelectedChange($event)"
      (regionSelectedChange)="regionSelectedChange($event)"
      (citySelectedChange)="citySelectedChange($event)"
      (districtSelectedChange)="districtSelectedChange($event)"
      (localitySelectedChange)="onLocalitySelectedChange($event)"
    ></app-search-modal>

    <app-search-modal-footer
      [title]="searchParams.locationName"
      [searchModalState]="searchModalStateWrapper.list.INITIAL"
      [searchResult]="searchResult"
      [searchParams]="searchParams"
      (restaurantClicked)="onRestaurantClick($event)"
      (openSearchModal)="openSearchCityModal()"
    ></app-search-modal-footer>
  </div>
</ng-template>

<ng-template #searchVideoModalRef>
  <div class="safe-area-padding-top">
    <app-search-video-modal
      [searchParams]="searchParams"
      class="bg-dark"
    ></app-search-video-modal>

    <app-search-modal-footer
      [searchResult]="searchResult"
      [searchModalState]="searchModalStateWrapper.list.VIDEO"
    ></app-search-modal-footer>
  </div>
</ng-template>

<ng-template #favoritesModalRef>
  <div>
    <swiper [config]="searchFiltersConfig" (swiper)="onSwiper($event)">
      <ng-template swiperSlide>
        <div class="w-100 h-100 bg-white border-bottom-radius-3">
          <app-search-modal-favorites-slide
            *ngIf="searchModalStateWrapper.isFavorite()"
            [searchParams]="searchParams"
            [searchResult]="searchResult"
            [locationFavorites]="locationFavorites"
            (setPreviewSlideRestaurant)="onSetPreviewSlideRestaurant($event)"
            (restaurantNameClick)="slideToPreview()"
            (favoriteStatusChanged)="onFavoriteStatusChange()"
          ></app-search-modal-favorites-slide>

          <app-search-video-modal
            *ngIf="searchModalStateWrapper.isVideo()"
            [searchParams]="searchParams"
            class="bg-dark"
          ></app-search-video-modal>

          <app-targets
            *ngIf="searchModalStateWrapper.isTargets()"
            [searchParams]="searchParams"
          ></app-targets>

          <app-search-modal-footer
            [title]="searchParams.locationName"
            [searchModalState]="searchModalStateWrapper.value"
            [searchResult]="searchResult"
            [searchParams]="searchParams"
            (onStatusChange)="searchModalStateWrapper.value = $event"
            (restaurantClicked)="onRestaurantClick($event)"
          ></app-search-modal-footer>
        </div>
      </ng-template>

      <ng-template swiperSlide *ngIf="currentRestaurant">
        <div class="w-100 h-100">
          <app-preview-modal
            [restaurantExplore]="currentRestaurant"
            [title]="searchParams.locationName"
            [searchParams]="searchParams"
            [isHoursEnabled]="getHoursEnabled()"
            (hoursToggle)="isHoursEnabled = $event"
          ></app-preview-modal>
        </div>
      </ng-template>
    </swiper>
  </div>
</ng-template>

<ng-template #previewAndChainsAndDividerModalsRef let-c="close" let-d="dismiss">
  <div>
    <swiper
      [config]="previewAndChainsConfig"
    >
      <ng-template swiperSlide *ngFor="let slide of searchResult.slides">
        <div class="w-100 h-100">
          <app-divider-mobile-modal
            *ngIf="slide.type == searchResultSlideType.DIVIDER"
            [typeCategorySection]="slide.typeCategorySection"
            [cityName]="searchParams.locationName"
          ></app-divider-mobile-modal>

          <app-preview-modal
            *ngIf="slide.type == searchResultSlideType.RESTAURANT"
            [searchParams]="searchParams"
            [restaurantExplore]="slide.restaurant"
            [title]="searchParams.locationName"
            [isHoursEnabled]="isHoursEnabled"
            (hoursToggle)="isHoursEnabled = $event"
          ></app-preview-modal>
        </div>
      </ng-template>
    </swiper>
  </div>
</ng-template>

<app-search-city
  [searchResult]="searchResult"
  (localitySelected)="onSearchCityLocalitySelected($event)"
  (findMe)="findMe()"
></app-search-city>
