<div
  class="section-item text-white"
>
  <div *ngIf="section.type === sectionType.OPTION" class="form-row m-0">
    <div class="form-group col-12">
      <input
        [(ngModel)]="section.title"
        type="text"
        class="form-control form-control-inverted"
        placeholder="Section name"
        (change)="sectionEdit()"
      />
    </div>
  </div>
  <ng-container *ngFor="let extra of section.extras; let extraIndex = index; let first = first">
    <div class="form-row m-0">
      <div class="form-group form-group-col flex-fill">
        <input
          [(ngModel)]="extra.name"
          type="text"
          class="form-control form-control-inverted"
          (change)="onExtraChange(extra)"
        />
      </div>
      <div class="form-group form-group-col">
        <div class="form-row">
          <div class="col-12 input-group-prepend">
            <span class="input-group-text input-group-text-inverted pr-2 text-white">$</span>
            <input
              [ngModel]="extra.price | number: '.2-2'"
              [min]="0"
              [ngClass]="{'is-invalid': extra.name && extra.hasRequiredError}"
              type="number"
              inputmode="numeric"
              pattern="[0-9]*"
              class="form-control form-control-inverted form-control-3-digit pl-0"
              (change)="onPriceChange($event, extra)"
            />
            <small class="invalid-feedback">Required</small>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="section.type === sectionType.OPTION">
  <div class="w-100 form-row py-3">
    <div class="col-6 form-inline">
      <select
        [(ngModel)]="section.min"
        id="require"
        class="w-100 form-control form-control-inverted mr-2"
        (ngModelChange)="sectionEdit()"
      >
        <option *ngFor="let i of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" [ngValue]="i">Require {{ i }}</option>
      </select>
    </div>
    <div class="col-6 form-inline" *ngIf="section.min == 0">
      <select
        [(ngModel)]="section.max"
        id="max"
        class="w-100 form-control form-control-inverted ml-2"
        (ngModelChange)="sectionEdit()"
      >
        <option *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" [ngValue]="i">Max {{ i }}</option>
      </select>
    </div>
  </div>
</ng-container>
<div class="form-row py-3">
  <a class="px-2" (click)="onAddItem()">MORE</a>
</div>
