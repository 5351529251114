<div class="restaurant-mmmm-footer bg-white border-bottom-radius-3">
  <div *ngIf="searchModalStateWrapper.isInitial()" class="d-flex flex-content-vc-hc h-100">
    <div class="footer-icon flex-content-vc-hc">
      <i class="icon-v2 share-dark" (click)="onClickShare()"></i>
    </div>

    <div class="footer-icon flex-content-vc-hc">
      <app-picker
        [classes]="'icon rest-selector'"
        [picker]="restaurantsPicker"
        [title]="searchParams.locationName"
        (selectedValue)="onRestaurantsPickerSelected($event)"
      ></app-picker>
    </div>

    <div class="footer-icon flex-content-vc-hc">
      <i class="icon-v2 filter" (click)="onFilterClick()"></i>
    </div>

    <div class="footer-icon flex-content-vc-hc">
      <i class="icon-v2 search-black-new" (click)="openCitySearchModal()"></i>
    </div>
  </div>

  <div *ngIf="!searchModalStateWrapper.isInitial() && isLoggedIn" class="d-flex flex-content-vc-hc h-100">
    <app-segmented-button
      [buttons]="segments"
      [activeIndex]="activeSegmentedButton"
      (change)="statusChange($event)"
    ></app-segmented-button>
  </div>

  <div *ngIf="searchModalStateWrapper.isVideo()" class="d-flex flex-content-vc-hc h-100"></div>
</div>
