import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { IsDevice } from '../decorators/is-device.decorator';
import { DeviceType } from '../models/enums/device-type';

@Directive({
  selector: '[appAddClassIfDesktop]'
})
export class AddClassIfDesktopDirective implements OnInit {
  @Input() appAddClassIfDesktop: string;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  nativeElement: HTMLElement;

  constructor(
    element: ElementRef
  ) {
    this.nativeElement = element.nativeElement;
  }

  ngOnInit() {
    if (
      !this.isMobile &&
      this.appAddClassIfDesktop &&
      !this.nativeElement.classList.contains(this.appAddClassIfDesktop)
    ) {
      this.nativeElement.classList.add(this.appAddClassIfDesktop);
    }
  }
}
