import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'andToAmpersand'})
export class AndToAmpersandPipe implements PipeTransform {
  abbreviates = {
    ' and ': ' & ',
    ' And ': ' & '
  };

  transform(value: string): any {
    for (const key in this.abbreviates) {
      value = value.replace(key, this.abbreviates[key]);
    }

    return value;
  }
}
