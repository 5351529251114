import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CookieKey } from '../models/enums/cookie-key';
import { RouterService } from '../services/router.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private routerService: RouterService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf(environment.apiEndpoint) !== -1) {
      const headers = {};

      const token = this.cookieService.get(CookieKey.USER);

      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }

      const currentRestaurantHostName = this.routerService.restaurantHostname;

      if (currentRestaurantHostName) {
        headers['Restaurant'] = currentRestaurantHostName;
      }

      const currentLocationId = this.routerService.restaurantLocationId;

      if (currentLocationId) {
        headers['LocationId'] = `${currentLocationId}`;
      }

      if (headers) {
        request = request.clone({
          setHeaders: headers
        });
      }
    }

    return next.handle(request);
  }
}
