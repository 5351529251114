<div *appIfIsMobile class="basket-mobile">
  <div
    appAnimate
    [ngClass]="{'visibility-hidden': basket.isEmpty() || !basketService.needShow}"
    class="basket-short max-width"
    (click)="openBasketModal()"
  >
    <div class="row">
      <div class="col-12 text-center">
        <span class="mr-0 d-block">{{ basket.quantity }} (${{ basket.subtotal | precision }})</span>
      </div>
    </div>
  </div>

  <ng-template #basketModalRef let-c="close">
    <app-basket-modal
      *ngIf="restaurant"
      [basket]="basket"
      [modal]="basketModal"
    ></app-basket-modal>
  </ng-template>
</div>

<div *appIfIsDesktop>
  <a
    appHrefNone
    appAnimate
    [ngClass]="{'visibility-hidden': basket.isEmpty() || !basketService.needShow}"
    appOpacityFullOnHover
    class="basket-short mt-3 opacity-30"
  >
    <span
      (click)="onTitleClick()"
      class="text-uppercase text-white font-weight-500 font-size-1-3rem"
    >
      {{ basket.quantity }} (${{ basket.subtotal | precision }})
    </span>
  </a>
</div>
