import { Component, Input, OnInit } from '@angular/core';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { DeviceType } from '../../../models/enums/device-type';
import { ViewType } from '../../../models/enums/view-type';
import { View } from '../../../models/view';

@Component({
  selector: 'app-phantom',
  templateUrl: './phantom.component.html',
  styles: []
})
export class PhantomComponent implements OnInit {
  @Input() view: View;
  @Input() viewType: ViewType;
  @Input() hasPaddingTop = true;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }
}
