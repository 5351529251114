import { Injectable } from '@angular/core';
import { NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { Time } from '../models/period/time';

@Injectable()
export class NgbTimeModelAdapter extends NgbTimeAdapter<Time> {
  fromModel(time: Time): NgbTimeStruct {
    if (!time) {
      return null;
    }

    return {
      hour: time.hour,
      minute: time.minute,
      second: 0
    };
  }

  toModel(time: NgbTimeStruct): Time {
    if (!time) {
      return null;
    }

    const timeModel = new Time();
    timeModel.hour = time.hour;
    timeModel.minute = time.minute;

    return timeModel;
  }
}
