import { Token } from './token';

export class ConfirmEmailToken extends Token {
  email: string;

  constructor(id: number, token: string, email: string) {
    super(id, token);

    this.email = email;
  }
}
