import { Component, Input, OnInit } from '@angular/core';
import { IsLoggedIn } from '../../../../decorators/is-logged-in.decorator';
import { RestaurantSimple } from '../../../../domains/restaurant-simple';
import { NavigatorService } from '../../../../services/navigator.service';

@Component({
  selector: 'app-navbar-bottom-icon-explore',
  templateUrl: './navbar-bottom-icon-explore.component.html',
  styles: [':host { display: contents; }']
})
export class NavbarBottomIconExploreComponent implements OnInit {
  @Input() restaurant: RestaurantSimple;
  @Input() isRestaurant = false;

  @IsLoggedIn() isLoggedIn: boolean;

  constructor(private navigatorService: NavigatorService) {
  }

  ngOnInit(): void {
  }

  goToExplore() {
    this.navigatorService.goToUrl('/').then();
  }
}
