import { Component, Input, OnInit } from '@angular/core';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { DeviceType } from '../../../models/enums/device-type';

@Component({
  selector: 'app-search-mobile-loader',
  templateUrl: './search-mobile-loader.component.html',
  styleUrls: ['./search-mobile-loader.component.scss']
})
export class SearchMobileLoaderComponent implements OnInit {
  @Input() isSearching = false;

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  ngOnInit(): void {
  }
}
