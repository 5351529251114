import { Exclude, Transform, Type } from 'class-transformer';
import { PeriodTypeWrapper } from '../models/period/period-type-wrapper';
import { Time } from '../models/period/time';
import { Wrapper } from '../models/wrappers/wrapper';
import { DateUtils } from '../utils/date-utils';
import { TimeUtils } from '../utils/time-utils';

export class Period {
  id: number;

  @Type(() => PeriodTypeWrapper)
  @Transform(value => Wrapper.toClass(value, PeriodTypeWrapper), {toClassOnly: true})
  @Transform(value => Wrapper.toPlain(value, PeriodTypeWrapper), {toPlainOnly: true})
  type: PeriodTypeWrapper;

  @Transform(dateInput => DateUtils.toDate(dateInput), {toClassOnly: true})
  @Transform(date => DateUtils.dateToArray(date), {toPlainOnly: true})
  date: Date;

  @Type(() => Time)
  @Transform(timeInput => TimeUtils.toTime(timeInput), {toClassOnly: true})
  @Transform(time => time ? TimeUtils.timeToArray(time.hour, time.minute) : time, {toPlainOnly: true})
  timeStart: Time;

  @Type(() => Time)
  @Transform(timeInput => TimeUtils.toTime(timeInput), {toClassOnly: true})
  @Transform(time => time ? TimeUtils.timeToArray(time.hour, time.minute) : time, {toPlainOnly: true})
  timeEnd: Time;

  weekDayStart: number;
  weekDayEnd: number;

  @Transform(dateInput => DateUtils.toDate(dateInput), {toClassOnly: true})
  @Transform(date => DateUtils.dateToArray(date), {toPlainOnly: true})
  closedFrom: Date;

  @Transform(dateInput => DateUtils.toDate(dateInput), {toClassOnly: true})
  @Transform(date => DateUtils.dateToArray(date), {toPlainOnly: true})
  closedUntil: Date;

  eventTitle: string;
  eventDetails: string;

  @Exclude()
  ordering: number;

  @Exclude()
  removed = false;
  closed: boolean;

  weekDaysToString() {
    if (this.weekDayStart === this.weekDayEnd) {
      return DateUtils.WEEK_DAYS[this.weekDayStart];
    } else if (this.weekDayStart === 1 && this.weekDayEnd === 7) {
      return '7 Days';
    } else {
      const separator = Math.abs(this.weekDayStart - this.weekDayEnd) === 1 ? ' • ' : ' - ';
      return `${DateUtils.WEEK_DAYS[this.weekDayStart]}${separator}${DateUtils.WEEK_DAYS[this.weekDayEnd]}`;
    }
  }

  get startDate(): Date {
    return this.type.isClosed ? this.closedFrom : this.date;
  }

  timeToString(short = false) {
    if (!this.timeStart && !this.timeEnd) {
      return '';
    }

    if (
      this.timeStart.hour === 0 && this.timeStart.minute === 0 &&
      this.timeEnd.hour === 23 && this.timeEnd.minute === 59
    ) {
      return '24 Hours';
    }

    if (this.timeStart && this.timeEnd) {
      return `${this.timeStart.toString(short)} - ${this.timeEnd.toString(short)}`;
    }
  }

  /*
    Index for sorting the periods in the week days order.
    Mon - {Day} = 1X
    Tue - {Day} = 2X
    etc
   */
  get weekStartEndIndex(): number {
    const sevenDays = 17; // Mon - Sun = 7 Days should be after all days
    const afterSevenDays = 88; // Sun can be 70 or 77, so 88 is after all days

    const index = this.weekDayStart * 10 + this.weekDayEnd;

    return index === sevenDays ? afterSevenDays : index;
  }

  get sectionGroupOrdering(): number {
    if (this.type.isBusinessHours) {
      return this.weekStartEndIndex;
    } else if (this.type.isNormalHours) {
      let value = 0;

      if (this.timeStart) {
        value += this.timeStart.hour * 100 + this.timeStart.minute;

        value *= 10000;

        if (this.timeEnd) {
          value += this.timeEnd.hour * 100 + this.timeEnd.minute;
        }
      }

      return value;
    } else {
      let index = 0;

      index += this.startDate.getDate() * 100;

      if (this.closedUntil && !DateUtils.isSameDay(this.startDate, this.closedUntil)) {
        index += this.closedUntil.getDate();
      }

      return index;
    }
  }

  get sectionGroupIndex(): string {
    return this.sectionGroupOrdering.toString();
  }

  get sectionOrdering(): number {
    if (this.startDate) {
      let index = 0;

      if (this.closedUntil && !DateUtils.isSameMonth(this.closedFrom, this.closedUntil)) {
        index += this.closedUntil.getMonth() * 100 + this.startDate.getMonth();
      } else {
        index += this.startDate.getMonth() * 100;
      }

      return index;
    } else if (this.type.isNormalHours && !this.type.isBusinessHours) {
      return this.weekStartEndIndex;
    }

    return 0;
  }

  get sectionIndex(): string {
    return this.sectionOrdering === 0 ? this.type.value : this.sectionOrdering.toString();
  }
}
