import { Component, Input, OnInit } from '@angular/core';
import { CurrentRestaurant } from '../../decorators/current-restaurant.decorator';
import { Restaurant } from '../../domains/restaurant';
import { ContactService } from '../../services/contact.service';
import { NavigatorService } from '../../services/navigator.service';

@Component({
  selector: 'app-mini-contact',
  templateUrl: './mini.contact.component.html',
  styleUrls: ['./mini.contact.component.scss']
})
export class MiniContactComponent implements OnInit {
  @Input() classes: string;
  @Input() from = 'restaurant';

  @CurrentRestaurant() currentRestaurant: Restaurant;

  constructor(
    private contactService: ContactService,
    private navigatorService: NavigatorService
  ) {
  }

  ngOnInit(): void {
  }

  onCallClick() {
    this.contactService.call(this.currentRestaurant);
  }

  onEmailClick() {
    this.contactService.mail(this.currentRestaurant);
  }

  openGoogleMaps() {
    this.navigatorService.goToMapsRestaurant(this.currentRestaurant);
  }

  onShareRestClick() {
    this.contactService.share(this.currentRestaurant);
  }

  onFeedback() {
    this.contactService.feedback();
  }
}
