import { Type } from 'class-transformer';
import { GeoLocation } from './geo-location';
import { GlobalBaseLocation } from './global-base-location';

export class BaseLocation extends GlobalBaseLocation {
  slug: string;

  @Type(() => GeoLocation)
  geoLocation: GeoLocation;
}
