export class StripeSubscription {
  id: string;
  customerId: string;
  created: number;
  startDate: number;
  currentPeriodEnd: number;
  currentPeriodStart: number;
  status: string;
  price: number;
  cancelAtPeriodEnd: boolean;
}
