import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CurrentUser } from '../decorators/current-user.decorator';
import { Food } from '../domains/food';
import { RestaurantSimple } from '../domains/restaurant-simple';
import { User } from '../domains/user';
import { FavoriteStatus } from '../models/enums/favorite-status';
import { LocationType } from '../models/enums/location-type';
import { LocationFavorites } from '../models/location-favorites';
import { SearchParams } from '../models/search-params';

@Injectable({providedIn: 'root'})
export class FavoritesService {
  private readonly favoriteUrl: string;
  favoritesTimeout: ReturnType<typeof setTimeout>;
  favoriteClickSubject: BehaviorSubject<boolean>;

  @CurrentUser() currentUser: User;

  constructor(private http: HttpClient) {
    this.favoriteUrl = `${environment.apiEndpoint}/api/favorites`;
    this.favoriteClickSubject = new BehaviorSubject<boolean>(false);
  }

  public setFavoriteStatus(foodId: number, favoriteStatus: FavoriteStatus) {
    return this.http.post<FavoriteStatus>(`${this.favoriteUrl}/${favoriteStatus}`, foodId);
  }

  public getFavoriteStatus(foodId: number) {
    return this.http
      .get<FavoriteStatus>(`${this.favoriteUrl}/food/${foodId}`);
  }

  public getRestaurantsByUserFavorites() {
    return this.http.get<Array<RestaurantSimple>>(`${this.favoriteUrl}/restaurants`)
      .pipe(map(restaurant => plainToClass(RestaurantSimple, restaurant)));
  }

  public getFavoriteFoodsByRestaurant(restaurantId: number) {
    return this.http.get<Array<Food>>(`${this.favoriteUrl}/restaurant/${restaurantId}`)
      .pipe(map(food => plainToClass(Food, food)));
  }

  public getUserFavoritesByLocationId(locationType: LocationType, locationId: number) {
    return this.http.get<LocationFavorites>(`${this.favoriteUrl}/location/${locationType}/${locationId}`)
      .pipe(map(value => plainToClass(LocationFavorites, value)));
  }

  public getUsersLocationFavorites(locationType: LocationType, locationId: number) {
    return new Promise((resolve, reject) => {
      if (!this.currentUser) {
        return;
      }

      if (this.favoritesTimeout !== undefined) {
        clearTimeout(this.favoritesTimeout);
      }

      this.favoritesTimeout = setTimeout(() => {
        this.getUserFavoritesByLocationId(locationType, locationId)
          .subscribe(locationFavorites => {
            resolve(locationFavorites);
          });
      }, 100);
    });
  }

  private getFavoritesByLocationId(locationType: LocationType, locationId: number) {
    return this.getUsersLocationFavorites(locationType, locationId);
  }

  public getFavoritesBySearchParams(searchParams: SearchParams) {
    return this.getLocationFavorites(searchParams?.cityId, searchParams?.districtId);
  }

  public getLocationFavorites(cityId, districtId) {
    if (districtId) {
      return this.getFavoritesByLocationId(LocationType.DISTRICT, districtId);
    }

    if (cityId) {
      return this.getFavoritesByLocationId(LocationType.CITY, cityId);
    }

    return new Promise((resolve, reject) => {
      resolve(null);
    });
  }

  public favoriteStatusChange(searchParams) {
    return new Promise((resolve, reject) => {
      if (searchParams.districtId) {
        this.getUsersLocationFavorites(LocationType.DISTRICT, searchParams.districtId)
          .then((locationFavorites: LocationFavorites) => {
            resolve(locationFavorites);
          });
      } else {
        this.getUsersLocationFavorites(LocationType.CITY, searchParams.cityId)
          .then((locationFavorites: LocationFavorites) => {
            resolve(locationFavorites);
          });
      }
    });
  }
}
