import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { classToPlain } from 'class-transformer';
import { environment } from '../../environments/environment';
import { Period } from '../domains/period';

@Injectable({providedIn: 'root'})
export class PeriodService {
  private readonly periodsUrl: string;

  constructor(private http: HttpClient) {
    this.periodsUrl = `${environment.apiEndpoint}/api/periods`;
  }

  add(period: Period) {
    if (period.type.isEvent && period.eventDetails) {
      period.eventDetails = period.eventDetails.replace(/(<([^>]+)>)/gi, '');
    }

    return this.http.post<Period>(`${this.periodsUrl}`, classToPlain(period));
  }

  delete(period: Period) {
    return this.http.post<Period>(`${this.periodsUrl}/delete`, classToPlain(period));
  }
}
