import { Subscription } from 'rxjs';
import { SidenavType } from '../models/enums/sidenav-type';
import { DecoratorInjectorService } from '../services/decorator/decorator-injector.service';
import { DecoratorService } from '../services/decorator/decorator.service';

export function IsSidenavMouseOver(sidenavTypes: SidenavType[]): PropertyDecorator {
  return (target: any, propertyKey: string) => {
    let service: DecoratorService;
    const isMouseOvers: { [sidenavType: string]: boolean } = {};
    const subscribers: { [sidenavType: string]: Subscription } = {};

    const getValue = () => {
      return Object.entries(isMouseOvers).some(key => {
        return key[1];
      });
    };

    const getter = () => {
      if (Object.keys(isMouseOvers).length > 0) {
        return getValue();
      }

      if (service === undefined) {
        service = DecoratorInjectorService.getService();
      }

      sidenavTypes.forEach(sidenavType => {
        if (subscribers[sidenavType] === undefined) {
          subscribers[sidenavType] = service.layoutSidenavService.sidenavIsMouseOverSubjects[sidenavType]
            .subscribe(isOpenValue => {
              isMouseOvers[sidenavType] = isOpenValue;
            });
        }
      });

      sidenavTypes.forEach(sidenavType => {
        isMouseOvers[sidenavType] =
          service.layoutSidenavService.sidenavIsMouseOverSubjects[sidenavType].getValue();
      });

      return getValue();
    };

    const setter = () => {
    };

    Object.defineProperty(target, propertyKey, {
      get: getter,
      set: setter
    });
  };
}
