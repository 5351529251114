import { Subscription } from 'rxjs';
import { Restaurant } from '../domains/restaurant';
import { DecoratorInjectorService } from '../services/decorator/decorator-injector.service';
import { DecoratorService } from '../services/decorator/decorator.service';

export function CurrentRestaurant(): PropertyDecorator {
  return (target: any, propertyKey: string) => {
    let service: DecoratorService;
    let currentRestaurant: Restaurant = null;
    let subscription: Subscription;

    const getter = () => {
      if (service === undefined) {
        service = DecoratorInjectorService.getService();
      }

      if (subscription === undefined) {
        subscription = service.routerService.restaurantSubject.subscribe(restaurant => {
          currentRestaurant = restaurant;
        });
      }

      return currentRestaurant;
    };

    const setter = () => {
    };

    Object.defineProperty(target, propertyKey, {
      get: getter,
      set: setter
    });
  };
}
