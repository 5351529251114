export class DomUtils {
  static querySelectorAsync = (selector: string, parentElement: HTMLElement = null): Promise<HTMLElement> => {
    if (!parentElement) {
      parentElement = document.querySelector('html');
    }

    return new Promise((resolve) => {
      let dynamicElement = parentElement.querySelector(selector);

      if (dynamicElement) {
        resolve(dynamicElement as HTMLElement);
      } else {
        const observer = new MutationObserver((mutations, self) => {
          dynamicElement = parentElement.querySelector(selector);

          if (dynamicElement) {
            self.disconnect();
            resolve(dynamicElement as HTMLElement);
          }
        });

        const config = {childList: true, subtree: true};

        observer.observe(parentElement, config);
      }
    });
  }
}
