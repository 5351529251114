import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDisableUncheck]'
})
export class DisableUncheckDirective implements AfterViewInit {
  nativeElement: HTMLInputElement;

  constructor(element: ElementRef) {
    this.nativeElement = element.nativeElement;
  }

  @HostListener('click', ['$event']) onClick($event) {
    if (this.nativeElement.type === 'checkbox') {
      this.nativeElement.checked = true;
    }
  }

  ngAfterViewInit(): void {
    this.nativeElement.checked = true;
  }
}
