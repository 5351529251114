import { Subscription } from 'rxjs';
import { RouteParamsType } from '../models/enums/route-params-type';
import { RouteParams } from '../models/route-params/route-params';
import { DecoratorInjectorService } from '../services/decorator/decorator-injector.service';
import { DecoratorService } from '../services/decorator/decorator.service';

export function RouteParamsValue(routeParamsType: RouteParamsType): PropertyDecorator {
  return (target: any, propertyKey: string) => {
    let service: DecoratorService;
    let routeParams: RouteParams;
    let subscription: Subscription;

    const getter = () => {
      if (service === undefined) {
        service = DecoratorInjectorService.getService();
      }

      if (subscription === undefined) {
        switch (routeParamsType) {
          case RouteParamsType.EXPLORE:
            subscription = service.routerService.exploreRouteParamsSubject.subscribe(value => {
              routeParams = value;
            });

            break;
          case RouteParamsType.RESTAURANT:
            subscription = service.routerService.restaurantRouteParamsSubject.subscribe(value => {
              routeParams = value;
            });

            break;
          case RouteParamsType.USER:
            subscription = service.routerService.userRouteParamsSubject.subscribe(value => {
              routeParams = value;
            });

            break;
          default:
            routeParams = null;
        }
      }

      return routeParams;
    };

    const setter = (newValue) => {
      routeParams = newValue;
    };

    Object.defineProperty(target, propertyKey, {
      get: getter,
      set: setter
    });
  };
}
