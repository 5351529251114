import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ExploreComponent } from '../components/explore/explore.component';
import { RouteHelper } from '../helpers/route-helper';
import { LocalStorage } from '../local-storage/local-storage';
import { LocalStorageKey } from '../models/enums/local-storage-key';
import { ExploreRouteParams } from '../models/route-params/explore-route-params';
import { SearchParams } from '../models/search-params';
import { NavigatorService } from '../services/navigator.service';
import { SearchParamsService } from '../services/search-params.service';

@Injectable({providedIn: 'root'})
class SearchParamsChecker {
  searchParamsLocalStorage = new LocalStorage(SearchParams, LocalStorageKey.SEARCH_PARAMS);

  constructor(
    private navigatorService: NavigatorService,
    private searchParamsService: SearchParamsService,
    private logger: NGXLogger
  ) {
  }

  async canActivateInitial(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const searchParams = this.searchParamsLocalStorage.getItem();

    if (!searchParams && RouteHelper.getRouteSnapshotLastChild(route).component !== ExploreComponent) {
      await this.getSearchParamsByGeoLocation();
    }

    return true;
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const exploreRouteParams = new ExploreRouteParams(route);

    let searchParams = this.searchParamsLocalStorage.getItem();

    if (exploreRouteParams.isEmpty) {
      if (!searchParams) {
        searchParams = await this.getSearchParamsByGeoLocation();
      }

      this.navigatorService.goToUrl(searchParams.path).then();

      return false;
    }

    if (exploreRouteParams.path !== searchParams?.path) {
      await this.getSearchParamsByExploreParams(exploreRouteParams);
    }

    return true;
  }

  private getSearchParamsByGeoLocation(): Promise<SearchParams> {
    return new Promise((resolve, reject) => {
      this.searchParamsService.getByMyGeoLocation().then(searchParamsByGeoLocation => {
        this.searchParamsLocalStorage.setItem(searchParamsByGeoLocation);

        resolve(searchParamsByGeoLocation);
      });
    });
  }

  private getSearchParamsByExploreParams(exploreRouteParams: ExploreRouteParams): Promise<void> {
    return new Promise((resolve, reject) => {
      this.searchParamsService
        .getByExploreParams(exploreRouteParams)
        .subscribe({
          next: searchParamsByExploreParams => {
            this.searchParamsLocalStorage.setItem(searchParamsByExploreParams);

            resolve();
          }, error: error => {
            this.logger.error('On identifying location', error);
          }
        });
    });
  }
}

export const SearchParamsInitialGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Promise<boolean> | boolean => {
  return inject(SearchParamsChecker).canActivateInitial(route, state);
};

export const SearchParamsGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Promise<boolean> | boolean => {
  return inject(SearchParamsChecker).canActivate(route, state);
};
