import md5 from 'crypto-js/md5';

export class HashUtils {
  static getCodePositionUniqueHash() {
    const err = new Error();

    return md5(err.stack.toString()).toString();
  }

  static getHashFromObject(obj: any) {
    return md5(JSON.stringify(obj)).toString();
  }

  static getHashFromString(str: string) {
    return md5(str).toString();
  }
}

