import { Subject } from 'rxjs';
import { Listener } from '../listener';

export class VolunteerIdListener extends Listener {
  subject = new Subject<number>();

  constructor(hostname: string) {
    super();
    this.topic = `/push/volunteer-published/${hostname}`;
  }

  transform(frame: any): number {
    return JSON.parse(frame.body);
  }
}
