import { DeviceType } from '../models/enums/device-type';
import { DecoratorInjectorService } from '../services/decorator/decorator-injector.service';
import { DecoratorService } from '../services/decorator/decorator.service';

export function IsDevice(deviceType: DeviceType): PropertyDecorator {
  return (target: any, propertyKey: string) => {
    let service: DecoratorService;
    let isDevice: boolean;

    const getter = () => {
      if (isDevice !== undefined) {
        return isDevice;
      }

      if (service === undefined) {
        service = DecoratorInjectorService.getService();
      }

      isDevice =
        deviceType === DeviceType.MOBILE && service.deviceDetectorService.isMobile() ||
        deviceType === DeviceType.DESKTOP && service.deviceDetectorService.isDesktop() ||
        deviceType === DeviceType.TABLET && service.deviceDetectorService.isTablet();

      return isDevice;
    };

    const setter = (newValue) => {
      isDevice = newValue;
    };

    Object.defineProperty(target, propertyKey, {
      get: getter,
      set: setter
    });
  };
}
