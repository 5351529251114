import { ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalType } from './enums/modal-type';

export class Modal {
  type: ModalType;
  id: string;
  element: NgbModalRef;
  window: Element;
  backdrop: Element;

  constructor(modalElement: NgbModalRef) {
    this.element = modalElement;
  }

  close(reason = '') {
    this.element.close(reason);
  }

  dismiss(reason = '') {
    this.element.dismiss(reason);
  }

  onClose(): Promise<ModalDismissReasons | string> {
    return new Promise((resolve, reject) => {
      this.element.result.then((result) => {
        resolve(result);
      }, (reason) => {
        resolve(reason);
      }).catch(e => {
        reject(e);
      });
    });
  }
}
