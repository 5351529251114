import { SocialUser } from '@abacritt/angularx-social-login';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorage } from '../local-storage/local-storage';
import { User } from '../models';
import { CookieKey } from '../models/enums/cookie-key';
import { DateTransform } from '../utils/date-transform';
import { RestaurantService } from './restaurant.service';
import { UserService } from './user.service';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  private userLocalStorage = new LocalStorage(User, CookieKey.USER);

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private restaurantService: RestaurantService,
    private userService: UserService,
  ) {
    const currentUser = this.userLocalStorage.getItem() || null;
    this.currentUserSubject = new BehaviorSubject<User>(currentUser);

    if (!currentUser && this.cookieService.get(CookieKey.USER)) {
      userService.getCurrent()
        .subscribe(user => {
          this.userLocalStorage.setItem(user);
          this.currentUserSubject = new BehaviorSubject<User>(user);
        });
    }
  }

  set currentUser(user: User) {
    this.userLocalStorage.setItem(user);
    this.currentUserSubject.next(user);
  }

  get currentUser(): User {
    if (!this.cookieService.get(CookieKey.USER)) {
      this.logout();
    }

    return this.currentUserSubject.getValue();
  }

  isLoggedIn() {
    return !!this.currentUser;
  }

  socialLogin(socialUser: SocialUser) {
    return this.http.post<any>(`${this.userService.userUrl}/social-login`, socialUser)
      .pipe(map(userData => {
        this.saveUserDataToCookie(userData);

        return userData;
      }));
  }

  signUp(user: User) {
    return this.http.post<any>(`${this.userService.userUrl}/signup`, user)
      .pipe(map(userData => {
        this.saveUserDataToCookie(userData);

        return userData;
      }));
  }

  login(email: string, password: string) {
    return this.http.post<any>(`${this.userService.userUrl}/login`, {email, password})
      .pipe(map(userData => {
        this.saveUserDataToCookie(userData);

        return userData;
      }));
  }

  saveUserDataToCookie(userData) {
    if (userData && userData.token) {
      const user = plainToClass(User, userData.user);

      this.cookieService.set(
        CookieKey.USER,
        userData.token,
        DateTransform.plus(366),
        '/',
        null,
        null,
        'Lax'
      );

      this.deleteOldVersions();
      this.currentUser = user;

      return userData;
    }
  }

  private deleteOldVersions() {
    const startOfCookieKey = 'MMMM_U_365_V';
    const keyVersion = parseInt(CookieKey.USER.replace(startOfCookieKey, ''), 10);

    for (let i = 0; i < keyVersion; i++) {
      const key = startOfCookieKey + i;

      this.cookieService.delete(key, '/');
      localStorage.removeItem(key);
    }
  }

  logout() {
    this.currentUserSubject.next(null);
    this.cookieService.delete(CookieKey.USER, '/');
    this.userLocalStorage.removeItem();
    this.restaurantService.clearAllCaches();
  }
}
