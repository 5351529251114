import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { createElement } from 'react';
import { render } from 'react-dom';
import ImageCropper from '../../../../../react/components/ImageCropper';
import { CroppedImageDimensions } from '../../../models/cropped-image-dimensions';
import { Image } from '../../../models/image';
import { ImageUtils } from '../../../utils/image-utils';

@Component({
  selector: 'app-image-cropper',
  template: '<div #react></div>',
  styles: []
})
export class ImageCropperComponent implements OnInit, OnChanges {
  @Input() image: Image;
  @Input() imageData: string;
  @Input() rotation: number;
  @Input() showGrid: boolean;

  @Output() imageCropped = new EventEmitter<string>();

  @ViewChild('react', {read: ElementRef, static: true}) ref: ElementRef;

  width: number;

  constructor() {
  }

  ngOnInit(): void {
    this.width = this.ref.nativeElement.offsetWidth;
    this.renderReactElement();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.renderReactElement();
  }

  onImageCropped(croppedImageDimensions: CroppedImageDimensions) {
    if (croppedImageDimensions.width > 0 && croppedImageDimensions.height > 0) {
      ImageUtils.cropImage(this.imageData, this.rotation, croppedImageDimensions).then(imageBase64 => {
        this.imageCropped.emit(imageBase64);
      });
    }
  }

  private renderReactElement() {
    const props = {
      imageData: this.imageData,
      rotation: this.rotation,
      ratio: this.image.ratio,
      width: this.width,
      showGrid: this.showGrid,
      onCropComplete: (_, event) => this.onImageCropped(plainToClass(CroppedImageDimensions, event))
    };

    const reactElement = createElement(ImageCropper, props);

    render(reactElement, this.ref.nativeElement);
  }
}
