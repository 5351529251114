import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

export class SearchFormGroup {
  searchForm: UntypedFormGroup;

  constructor(formBuilder: UntypedFormBuilder) {
    this.searchForm = formBuilder.group({
      locationName: [null],
      stateId: [null],
      countyId: [null],
      regionId: [null],
      cityId: [null],
      districtId: [null],
    });
  }

  get rawValue() {
    return this.searchForm.getRawValue();
  }

  get stateId() {
    return this.searchForm.controls['stateId'];
  }

  get countyId() {
    return this.searchForm.controls['countyId'];
  }

  get regionId() {
    return this.searchForm.controls['regionId'];
  }

  get cityId() {
    return this.searchForm.controls['cityId'];
  }

  get districtId() {
    return this.searchForm.controls['districtId'];
  }

  get locationName() {
    return this.searchForm.controls['locationName'];
  }

  set stateId(value: any) {
    this.stateId.setValue(value);
  }

  set countyId(value: any) {
    this.countyId.setValue(value);
  }

  set regionId(value: any) {
    this.regionId.setValue(value);
  }

  set cityId(value: any) {
    this.cityId.setValue(value);
  }

  set districtId(value: any) {
    this.districtId.setValue(value);
  }

  set locationName(value: any) {
    this.locationName.setValue(value);
  }

  toggle(type: string) {
    this.searchForm.controls[type].setValue(!this.searchForm.controls[type].value);
  }
}
