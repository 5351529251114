import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IsDevice } from '../../../../../decorators/is-device.decorator';
import { Menu } from '../../../../../domains/menu';
import { CategoryType } from '../../../../../models/enums/category-type';
import { DeviceType } from '../../../../../models/enums/device-type';
import { DrinkType } from '../../../../../models/enums/drink-type';
import { Types } from '../../../../../models/types';

@Component({
  selector: 'app-add-category-button',
  templateUrl: './add-category-button.component.html',
  styleUrls: [
    './add-category-button.component.scss'
  ]
})
export class AddCategoryButtonComponent implements OnInit, AfterViewInit {
  @Input() menu: Menu;
  @Input() isAdmin: boolean;

  @Output() addCategory = new EventEmitter<Types>();

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  @ViewChild('button') button: ElementRef;

  categoryType = CategoryType;

  drinkCategoryTypes = [DrinkType.Beer, DrinkType.Wine, DrinkType.Cocktails, DrinkType.Beverages];

  ngOnInit(): void {
    if (this.menu.categories.length === 0 && this.menu.title !== 'Drinks') {
      this.addCategory.emit(new Types(CategoryType.Standard, null));
    }
  }

  ngAfterViewInit() {
    if (this.menu.categories.length === 0 && this.menu.title === 'Drinks') {
      setTimeout(() => {
        this.button.nativeElement.click();
      }, 1000);
    }
  }

  addingCategory(categoryType: CategoryType, drinkType: DrinkType) {
    this.addCategory.emit(new Types(categoryType, drinkType));
  }
}
