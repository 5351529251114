import { Component, OnInit } from '@angular/core';
import { Auth } from '../../../../decorators/auth.decorator';
import { IsLoggedIn } from '../../../../decorators/is-logged-in.decorator';
import { NavigatorService } from '../../../../services/navigator.service';

@Component({
  selector: 'app-navbar-bottom-icon-user',
  templateUrl: './navbar-bottom-icon-user.component.html',
  styles: [':host { display: contents; }']
})
export class NavbarBottomIconUserComponent implements OnInit {
  @IsLoggedIn() isLoggedIn: boolean;

  constructor(private navigatorService: NavigatorService) {
  }

  ngOnInit(): void {
  }

  @Auth()
  goToUser() {
    this.navigatorService.goToUser();
  }
}
