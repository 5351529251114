import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'meridiem'})
export class MeridiemPipe implements PipeTransform {
  transform(time: string) {
    if (time) {
      return time.replace('AM', 'a').replace('PM', 'p');
    } else {
      return time;
    }
  }
}
