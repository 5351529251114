import { Component, OnInit } from '@angular/core';
import { BackgroundImageService } from '../../services/background-image.service';

@Component({
  selector: 'app-privacy-component',
  templateUrl: './privacy.component.html',
  styleUrls: []
})
export class PrivacyComponent implements OnInit {
  constructor(private backgroundImageService: BackgroundImageService) {
  }

  ngOnInit() {
    this.backgroundImageService.setCurrentBackgroundImageForDesktop();
  }
}
