import { Exclude, Transform, Type } from 'class-transformer';
import { MenuType } from '../models/enums/menu-type';
import { ViewType } from '../models/enums/view-type';
import { MenuTypeWrapper } from '../models/menu-type-wrapper';
import { View } from '../models/view';
import { Wrapper } from '../models/wrappers/wrapper';
import { Category } from './category';
import { RestaurantSimple } from './restaurant-simple';

export class Menu {
  id: number;
  title: string;
  description: string;
  ordering: number;
  slug: string;
  checkedMenu = false;
  expanded = false;

  @Type(() => Category)
  categories: Array<Category> = new Array<Category>();

  referenceId: number;
  referredId: number;
  referredTitleIsAdded = false;
  default: boolean;

  @Type(() => MenuTypeWrapper)
  @Transform(value => Wrapper.toClass(value, MenuTypeWrapper), {toClassOnly: true})
  @Transform(value => Wrapper.toPlain(value, MenuTypeWrapper), {toPlainOnly: true})
  menuType: MenuTypeWrapper;

  gallery: boolean;

  @Exclude()
  originalCategories: Category[] = [];

  view = new View();

  hasFilteredFood = false;

  constructor() {
  }

  setViewType(view: ViewType) {
    this.view.type = view;

    this.categories.forEach(category => {
      category.foods.forEach(food => {
        food.view.type = view;
      });
    });
  }

  display(restaurant: RestaurantSimple = null) {
    if (restaurant?.enabledMenuTypes.includes(MenuType.ALL_DAY) && this.menuType.isLunch()) {
      return 'All Day';
    }

    return this.title;
  }
}
