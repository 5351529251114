import { LocalStorageOptions } from '../models/local-storage-options';
import { BaseLocalStorage } from './base-local-storage';

export class NumberLocalStorage extends BaseLocalStorage {
  constructor(
    localStorageKey: string,
    options: LocalStorageOptions = {}
  ) {
    super(localStorageKey, options);
  }

  setItem(value: number) {
    super.setItem(value);
  }

  getItem(): number {
    return +super.getItem();
  }
}
