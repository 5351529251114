import { LocalStorageOptions } from '../models/local-storage-options';
import { BaseLocalStorage } from './base-local-storage';

export class BooleanLocalStorage extends BaseLocalStorage {
  constructor(
    localStorageKey: string,
    options: LocalStorageOptions = {}
  ) {
    super(localStorageKey, options);
  }

  setItem(value: boolean) {
    super.setItem(`${value}`);
  }

  getItem(): boolean {
    return super.getItem() === 'true';
  }
}
