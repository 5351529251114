import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Picker } from '../../../../models/picker';

@Component({
  selector: 'app-picker-modal',
  templateUrl: './picker-modal.component.html',
  styles: []
})
export class PickerModalComponent implements OnInit {
  @Input() picker: Picker;
  @Output() selected = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
  }
}
