import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appOpacityFullOnHover]'
})
export class OpacityFullOnHoverDirective {
  readonly className = 'opacity-full-on-hover';

  element: Element;

  constructor(element: ElementRef) {
    this.element = element.nativeElement;
  }

  @HostListener('mouseover')
  public onMouseOver(): void {
    if (!this.element.classList.contains(this.className)) {
      this.element.classList.add(this.className);
    }
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    if (this.element.classList.contains(this.className)) {
      this.element.classList.remove(this.className);
    }
  }
}
