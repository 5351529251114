<ng-container *appIfIsMobile>
  <app-navbar-top-profile [title]="'Mbership'"></app-navbar-top-profile>

  <div class="container max-width has-navbar-top p-0">
    <ng-container [ngTemplateOutlet]="subscriptionCard"></ng-container>
  </div>
</ng-container>

<ng-container *appIfIsDesktop>
  <div class="row">
    <div class="col-4">
      <ng-container [ngTemplateOutlet]="subscriptionCard"></ng-container>
    </div>
  </div>
</ng-container>

<ng-template #subscriptionCard>
  <div class="card">
    <div class="card-body">
      <ng-container *ngIf="dataLoaded">
        <div *ngIf="subscription" class="row">
          <div class="col-7">
            {{ subscription.cancelAtPeriodEnd ? 'Ends' : 'Renews' }}
            {{ endDate | date: 'MM.dd.YYYY' }}
          </div>

          <div class="col-3 text-center">
            ${{ subscription.price / 100 }}
          </div>

          <div class="col-2 text-right">
            <button
              class="btn md-btn-flat btn-secondary p-0 text-transform-none"
              (click)="onSubscribeClick()"
            >
              edit
            </button>
          </div>
        </div>

        <div *ngIf="!subscription" class="row">
          <div class="col-12">
            <button class="btn btn-primary btn-block text-transform-none" (click)="onSubscribeClick()">
              Become a Mber
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

