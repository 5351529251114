import { Component, OnInit } from '@angular/core';
import { IsLoggedIn } from '../../../../decorators/is-logged-in.decorator';
import { NotificationService } from '../../../../services/notification.service';

@Component({
  selector: 'app-navbar-bottom-icon-feed',
  templateUrl: './navbar-bottom-icon-feed.component.html',
  styles: [':host { display: contents; }']
})
export class NavbarBottomIconFeedComponent implements OnInit {
  @IsLoggedIn() isLoggedIn: boolean;

  constructor(private notificationService: NotificationService) {
  }

  ngOnInit(): void {
  }

  openHomeToastr() {
    this.notificationService.showHomeToastr();
  }
}
