import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Period } from '../../../domains/period';
import { RestaurantHoursSection } from '../../../models/period/restaurant-hours-section';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-restaurant-hours',
  templateUrl: './restaurant-hours.component.html',
  styleUrls: ['./restaurant-hours.component.scss']
})
export class RestaurantHoursComponent implements OnInit {
  @Input() restaurantHoursSections: RestaurantHoursSection[];
  @Input() isEdit = false;
  @Input() isDesktop = false;
  @Input() isModal = false;

  @Output() removePeriod = new EventEmitter<number>();

  constructor(private notificationService: NotificationService) {
  }

  ngOnInit(): void {
  }

  periodEventToast(period: Period) {
    this.notificationService.addPeriodClicked(period);

    event.stopPropagation();
  }

  removeRegularPeriodEmit(id: number) {
    this.removePeriod.emit(id);
  }
}
