import { Type } from 'class-transformer';
import { TypeCategory } from '../domains/type-category';

export class TypeCategorySection {
  keys = [];
  titles: string[] = [];
  section: number;
  hasChain = false;
  hasRestaurants = false;
  isExample = false;

  @Type(() => TypeCategory)
  typeCategories: Array<TypeCategory> = [];

  get keysString() {
    return this.keys.join('-').replace(' ', '-');
  }

  get title() {
    return this.titles.join(' | ');
  }
}
