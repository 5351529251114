import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Restaurant } from '../../../../domains/restaurant';

@Component({
  selector: 'app-location-delete-modal',
  templateUrl: './location-delete-modal.component.html',
  styles: []
})
export class LocationDeleteModalComponent implements OnInit {
  @Input() restaurant: Restaurant;

  @Output() removeClick = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onRemoveClick() {
    this.removeClick.emit(true);
  }
}
