<ng-container *ngIf="menu.title != 'Drinks'">
  <div (click)="addingCategory(categoryType.Standard, null)" class="mb-5">
    <ng-container *ngIf="isAdmin">
      <a
        #button
        appHrefNone
        class="category-add-btn nav-link text-primary text-uppercase font-weight-semibold font-size-0-85rem"
      >
        Add Category
      </a>
    </ng-container>

    <ng-container *ngIf="!isAdmin">
      <a appHrefNone class="text-white-50 mr-5">Add category</a>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="menu.title == 'Drinks'">
  <div
    ngbDropdown
    [placement]="menu.categories.length == 0 ? 'bottom-left' : 'top-left'"
    class="dropdown-toggle-hide-arrow mb-5"
  >
    <ng-container *ngIf="isAdmin && isDesktop">
      <button
        ngbDropdownToggle type="button"
        class="category-add-btn md-btn-flat btn btn-primary font-weight-semibold text-muted"
      >
        Add New Category
      </button>
    </ng-container>

    <ng-container *ngIf="!isDesktop">
      <a appHrefNone ngbDropdownToggle class="text-white-50 mr-5">Add new category</a>
    </ng-container>

    <ng-container *ngIf="!isAdmin && isDesktop">
      <button
        ngbDropdownToggle
        type="button"
        class="btn md-btn-flat btn-block font-weight-semibold text-muted"
      >
        Add New Category
      </button>
    </ng-container>

    <div ngbDropdownMenu [ngClass]="{'w-100': !isAdmin}" class="dropdown-menu-left text-center text-uppercase">
      <a
        appHrefNone
        *ngFor="let type of drinkCategoryTypes"
        class="dropdown-item"
        role="button"
        (click)="addingCategory(categoryType.Cocktail, type)"
      >
        {{ type }}
      </a>
    </div>
  </div>
</ng-container>

