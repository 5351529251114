import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { Restaurant } from '../../../../domains/restaurant';
import { Basket } from '../../../../models/basket/basket';
import { RestaurantService } from '../../../../services/restaurant.service';

@Component({
  selector: 'app-phone-modal',
  templateUrl: './phone-modal.component.html'
})
export class PhoneModalComponent implements OnInit {
  @Input() restaurant: Restaurant;
  @Input() basket: Basket;

  @Output() set = new EventEmitter<string>();

  phoneControl = new UntypedFormControl();

  phone: string;

  constructor(private restaurantService: RestaurantService, private logger: NGXLogger) {
  }

  ngOnInit() {
    if (this.restaurant.phone) {
      this.set.emit(this.basket.phone);
    }
  }

  setPhone() {
    if (this.phoneControl.valid) {
      this.restaurant.phone = this.phone;

      this.restaurantService
        .edit(this.restaurant)
        .subscribe({
          next: () => {
            this.set.emit(this.phone);
          }, error: error => {
            this.logger.error(`On editing restaurant with id: ${this.restaurant.id}`, error);
          }
        });
    }
  }
}
