import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Options } from 'sortablejs';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { Menu } from '../../../domains/menu';
import { Ordering } from '../../../domains/ordering';
import { Restaurant } from '../../../domains/restaurant';
import { RestaurantSimple } from '../../../domains/restaurant-simple';
import { Section } from '../../../domains/section';
import { CategoryTypeTags } from '../../../models/category-type-tags';
import { DeviceType } from '../../../models/enums/device-type';
import { MenuType } from '../../../models/enums/menu-type';
import { SectionType } from '../../../models/enums/section-type';
import { Types } from '../../../models/types';
import { CategoryService } from '../../../services/category.service';
import { MenuService } from '../../../services/menu.service';
import { NavigatorService } from '../../../services/navigator.service';
import { RestaurantService } from '../../../services/restaurant.service';
import { RouterService } from '../../../services/router.service';
import { TagService } from '../../../services/tag.service';

@Component({
  selector: 'app-admin-menu-table',
  templateUrl: './menu-table.component.html',
  styleUrls: [
    './menu-table.component.scss',
    '../admin.shared.scss',
  ],
  encapsulation: ViewEncapsulation.None
})
export class MenuTableComponent implements OnInit {
  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  editMode: boolean;
  activeMenu: Menu;
  menus: Array<Menu> = new Array<Menu>();
  menusAll: Menu[] = [];
  activeTab: number;
  restaurant: Restaurant;
  locationTitle: string;

  categoriesSortableOptions: Options = {
    animation: 150,
    handle: '.category-sortable',
    onUpdate: (event: any) => {
      this.categoriesOrderingChange();
    }
  };

  sameAsLunch = false;
  sizes: { [id: number]: Section } = {};
  options: { [id: number]: Section[] } = {};
  locations: RestaurantSimple[] = [];
  fragmentSubscription: Subscription;
  locationId: number;
  categoryTypeTags: CategoryTypeTags;

  constructor(
    private menuService: MenuService,
    private categoryService: CategoryService,
    private tagService: TagService,
    private activatedRoute: ActivatedRoute,
    private navigatorService: NavigatorService,
    private restaurantService: RestaurantService,
    private logger: NGXLogger,
    private routerService: RouterService
  ) {
  }

  ngOnInit(): void {
    this.locationId = this.routerService.restaurantLocationId;

    if (this.isMobile) {
      this.navigatorService.goToAdminSettings(this.locationId);
    }

    this.activatedRoute.url.subscribe(val => {
      this.tagService
        .getTags()
        .then(categoryTypeTags => {
          this.categoryTypeTags = categoryTypeTags;
          this.restaurantService.getCurrentUserRestaurant()
            .subscribe((restaurant: Restaurant) => {
              this.restaurant = restaurant;
              this.menusAll = restaurant.menus;
              this.menus = this.menuService.setFilteredMenus(this.menusAll, restaurant.enabledMenuTypes);

              this.locationTitle = restaurant.address;

              if (this.fragmentSubscription !== undefined) {
                this.fragmentSubscription.unsubscribe();
              }

              this.fragmentSubscription = this.activatedRoute.fragment.subscribe((fragment: string) => {
                let menu: Menu;

                if (fragment) {
                  const ids = fragment.split('-');

                  if (ids.length > 0) {
                    const menuId = parseInt(ids[0], 10);

                    if (menuId) {
                      menu = this.menus.find(menuI => menuI.id === menuId);

                      if (menu !== undefined) {
                        this.activeMenu = menu;
                        this.activeTab = this.activeMenu.id;

                        this.menus.forEach((menuI, index) => {
                          menuI.categories.forEach(categoryI => {
                            this.tagService.setCategoryTags(categoryI);

                            for (let i = categoryI.foods.length; i < 3; i++) {
                              categoryI.addNewFood(true);
                            }

                            categoryI.foods.forEach(foodI => {
                              const size = foodI.sections.find(sectionI => {
                                return sectionI.type === SectionType.SIZE;
                              });

                              if (size !== undefined) {
                                this.sizes[foodI.id] = size;
                              }

                              this.options[foodI.id] = [];

                              foodI
                                .sections
                                .filter(sectionI =>
                                  sectionI.type === SectionType.OPTION
                                ).forEach(sectionI => {
                                if (
                                  (
                                    !this.sizes[foodI.id] ||
                                    sectionI.id !== this.sizes[foodI.id].id
                                  ) &&
                                  this.options[foodI.id].length < 3
                                ) {
                                  this.options[foodI.id].push(sectionI);
                                }
                              });
                            });
                          });
                        });
                      }
                    }
                  }
                }

                if (this.menus.length > 0 && !menu) {
                  menu = this.menus[0];

                  this.goToMenuTable(this.restaurant.locationIndex, menu.id);
                }
              });

              this.restaurantService.getLocations().subscribe(locations => {
                this.locations = [];
                locations.forEach(location => {
                  this.locations.push(location);
                });
              });
            }, error => {
              this.activatedRoute.queryParams.subscribe(params => {
                this.navigatorService.goToAdminSettings();
              });

              return;
            });
        });
    });

    this.editMode = false;
  }

  private goToMenuTable(locationIndex: number, menuId: number = null) {
    if (locationIndex) {
      this.navigatorService.goToMenuTable(locationIndex, menuId);
    } else {
      this.navigatorService.goToMenuTable(null, menuId);
    }
  }

  onMenuTabChanged(event: NgbNavChangeEvent) {
    this.goToMenuTable(this.restaurant.locationIndex, parseInt(event.nextId, 10));
  }

  addCategory(types: Types) {
    const category = this.menuService.addCategory(this.activeMenu, types, false);
    this.tagService.setCategoryTags(category);
  }

  private categoriesOrderingChange() {
    const orderArray: Array<number> = [];

    this.activeMenu.categories.forEach(category => {
      if (category.id) {
        orderArray.push(category.ordering);
      }
    });

    orderArray.sort((a, b) => a > b ? 1 : -1);
    const categoryOrderings: Array<Ordering> = [];
    let j = 0;

    this.activeMenu.categories.forEach(category => {
      if (category.id) {
        const order = orderArray[j++];
        category.ordering = order;
        categoryOrderings.push(new Ordering(category.id, order));
      }
    });

    this.categoryService
      .changeOrdering(categoryOrderings)
      .subscribe({
        next: () => {
          this.logger.debug('Successfully updated category orderings');
        }, error: error => {
          this.logger.error('On changing category orderings', error);
        }
      });
  }

  onRestaurantHostClick() {
    this.navigatorService.goToRestaurantFromSubPages(true);
  }

  goToMenuTableFromLocation(locationIndex: number) {
    this.restaurant = null;
    this.menus = [];
    this.navigatorService.goToMenuTable(locationIndex);
  }

  onEnabledMenusSave(enabledMenuTypes: MenuType[]) {
    this.restaurant.enabledMenuTypes = enabledMenuTypes;
    this.menus = this.menuService.setFilteredMenus(this.menusAll, enabledMenuTypes);

    if (this.activeMenu && this.menus.length > 0 && !this.menus.includes(this.activeMenu)) {
      this.navigatorService.goToMenuTable(this.restaurant.locationIndex, this.menus[0].id);
    }
  }
}
