import { Extra } from '../../domains/extra';
import { Section } from '../../domains/section';
import { SectionManagerBase } from './section-manager-base';
import { SectionManagerInterface } from './section-manager-interface';

export class SectionCheckbox extends SectionManagerBase implements SectionManagerInterface {
  constructor(section: Section) {
    super(section);
  }

  getExtras(): Extra[] {
    return this.extras.filter(extraItered => this.checked[extraItered.id] === true);
  }

  getExtrasIds(): string[] {
    return this.getExtras().map(extraItered => `${extraItered.id}`);
  }

  hasSelections(): boolean {
    return true;
  }

  canAddExtra(): boolean {
    return false;
  }
}
