import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-desktop-loader',
  templateUrl: './search-desktop-loader.component.html',
  styleUrls: ['./search-desktop-loader.component.scss']
})
export class SearchDesktopLoaderComponent implements OnInit {
  @Input() isSearching = false;

  ngOnInit(): void {
  }
}
