import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Like } from '../domains/like';

@Injectable({providedIn: 'root'})
export class LikeService {
  private readonly likeUrl: string;

  constructor(private http: HttpClient) {
    this.likeUrl = `${environment.apiEndpoint}/api/likes`;
  }

  public like(like: Like, verb: string, isLiked: boolean) {
    return this.http.put(`${this.likeUrl}/like/${verb}/${String(isLiked)}`, like);
  }

  public checkUserLike(getstreamId: string, verb: string) {
    return this.http.get<boolean>(`${this.likeUrl}/${getstreamId}/${verb}`);
  }
}
