import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-browser-support',
  templateUrl: './browser-support.component.html',
  styleUrls: ['./browser-support.component.scss']
})
export class BrowserSupportComponent implements OnInit {
  constructor(private app: AppService) {
    this.app.pageTitle = 'Browser is not supported!';
  }

  ngOnInit() {
  }
}
