<div class="m-3 d-flex height">
  <ng-container *ngIf="!igUsernameSuggestion && restaurant.instagram">
    <div class="m-auto"></div>

    <div
      class="icon-v2 instagram-white"
      (click)="goToInstagram(restaurant.instagram)"
    ></div>
  </ng-container>

  <ng-container *ngIf="igUsernameSuggestion">
    <div class="z-index-1 width">
      <button class="btn btn-sm btn-dark" (click)="cancel()"> Cancel</button>
    </div>

    <div class="m-auto"></div>

    <div class="z-index-1">
      <span class="text-white">
          Pending approval
      </span>
    </div>

    <div class="m-auto"></div>

    <div class="width text-right">
      <div
        class="icon-v2 instagram-white"
        (click)="goToInstagram(igUsernameSuggestion.suggestion)"
      ></div>
    </div>
  </ng-container>
</div>
