import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { TypeCategory } from '../domains/type-category';
import { TypeCategorySimple } from '../domains/type-category-simple';

@Injectable({providedIn: 'root'})
export class TypeCategoryService {
  private readonly categoryUrl: string;

  constructor(private http: HttpClient) {
    this.categoryUrl = `${environment.apiEndpoint}/api/type-categories`;
  }

  public getAll(): Observable<Array<TypeCategorySimple>> {
    return this.http.get<Array<TypeCategorySimple>>(`${this.categoryUrl}`);
  }

  public getFromRedis() {
    return this.http.get<number>(`${this.categoryUrl}/redis`);
  }

  public getById(id: number) {
    return this.http.get<TypeCategory>(`${this.categoryUrl}/${id}`);
  }

  public getByRestaurantId(id: number): Observable<TypeCategorySimple> {
    return this.http.get<TypeCategorySimple>(`${this.categoryUrl}/restaurant/${id}`);
  }
}
