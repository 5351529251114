import { Directive, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ImgixService } from '../services/imgix.service';
import { ImageUtils } from '../utils/image-utils';

@Directive({
  selector: '[appBlurImg]'
})
export class BlurImgDirective implements OnInit, OnChanges {
  @Input() path: string;
  @Input() options: {};

  isOriginalImageLoaded = false;
  blurOptions = {
    blur: 1000
  };

  isImageDataOrInstagram: boolean;

  constructor(
    readonly element: ElementRef,
    private imgixService: ImgixService
  ) {
  }

  private setImage() {
    this.isImageDataOrInstagram = ImageUtils.isDataOrURL(this.path);

    this.element.nativeElement.src = this.isImageDataOrInstagram ?
      this.path :
      this.imgixService.getUrl(this.path, {...this.options, ...this.blurOptions});
  }

  ngOnInit(): void {
    this.setImage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty('path') &&
      changes.path.previousValue !== undefined &&
      changes.path.previousValue !== changes.path.currentValue
    ) {
      this.setImage();
    }
  }

  @HostListener('load')
  onLoad() {
    if (!this.isImageDataOrInstagram && !this.isOriginalImageLoaded) {
      this.isOriginalImageLoaded = true;
      this.element.nativeElement.src = this.imgixService.getUrl(this.path, this.options);
    }
  }
}
