<div class="d-flex flex-column">
  <nav class="navbar navbar-top navbar-top-px-0 bg-white fixed-top max-width safe-area-top flex-content-hc h-44px">
    <div class="custom-select-navbar-title">{{ searchParams.locationName }}</div>
  </nav>

  <div
    [appBackgroundUrl]="backgroundImage ? backgroundImage: ''"
    class="target-background"
  >
    <div
      class="position-absolute-0 bottom-footer-height backdrop-filter-brightness-0-85"
      (click)="targetClicked()"
    ></div>
  </div>
</div>
