<div class="modal-body">
  <form [formGroup]="locationForm" (ngSubmit)="onSubmit()">
    <div class="form-row">
      <div class="form-group col-5">
        <label class="form-label" for="location-zip">Zip</label>
        <input
          [ngClass]="{'is-invalid': submitClicked && !zipCode.valid}"
          type="text"
          id="location-zip"
          class="form-control"
          formControlName="zipCode"
        />
        <small class="invalid-feedback">Required</small>
      </div>
      <div class="form-group col-7">
        <label class="form-label" for="location-address">Street address</label>
        <input
          [ngClass]="{'is-invalid': submitClicked && !address.valid}"
          type="text"
          id="location-address"
          class="form-control"
          formControlName="address"
        />
        <small class="invalid-feedback">Required</small>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-5">
        <label for="location-phone" class="form-label">Location phone</label>
        <input
          [ngClass]="{'is-invalid': submitClicked && !phone.valid}"
          type="tel"
          id="location-phone"
          class="form-control"
          formControlName="phone"
          mask="(000) 000-0000"
          placeholder="(___) ___-____"
        />
        <small class="invalid-feedback">Required</small>
      </div>
      <div class="form-group col-7">
        <label class="form-label" for="location-email">Location email</label>
        <input
          [ngClass]="{'is-invalid': submitClicked && !email.valid}"
          type="email"
          id="location-email"
          class="form-control"
          formControlName="email"
        />
        <small *ngIf="email.errors?.required" class="invalid-feedback">Required</small>
        <small *ngIf="email.errors?.email" class="invalid-feedback">Invalid email address</small>
      </div>
    </div>
    <div class="d-flex">
      <input
        type="submit"
        class="btn btn-primary ml-auto"
        value="Add Location"
      />
    </div>
  </form>
</div>
