import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'shortNumberFormat'})
export class ShortNumberFormatPipe implements PipeTransform {
  transform(value: number): any {
    if (value < 1000) {
      return value;
    }

    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const suffixNum = Math.floor(('' + value).length / 3);
    const shortValueNumber = parseFloat(
      (suffixNum > 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(2)
    );
    let shortValue: string;

    if (shortValueNumber % 1 !== 0) {
      shortValue = shortValueNumber.toFixed(1);
    } else {
      shortValue = shortValueNumber.toString();
    }

    return shortValue + suffixes[suffixNum];
  }
}
