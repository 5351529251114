import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { BooleanLocalStorage } from '../../../local-storage/boolean-local-storage';
import { NumberLocalStorage } from '../../../local-storage/number-local-storage';
import { DistanceSliderPosition } from '../../../models/distance-slider-position';
import { DistanceSliderPositionWrapper } from '../../../models/distance-slider-position-wrapper';
import { DeviceType } from '../../../models/enums/device-type';
import { LocalStorageKey } from '../../../models/enums/local-storage-key';
import { RestaurantsPickerPosition } from '../../../models/restaurants-picker-position';
import { SearchParams } from '../../../models/search-params';
import { SearchResult } from '../../../models/search-result';
import { NavigatorService } from '../../../services/navigator.service';
import { SearchParamsService } from '../../../services/search-params.service';

@Component({
  selector: 'app-distance-filter',
  templateUrl: './distance-filter.component.html',
  styleUrls: [
    './distance-filter.component.scss'
  ]
})
export class DistanceFilterComponent implements OnInit, OnChanges {
  @Input() position: DistanceSliderPosition;
  @Input() searchParams: SearchParams;
  @Input() searchResult: SearchResult;

  @Output() restaurantClick = new EventEmitter<any>();

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  searchModalCloseDisableLocalStorage = new BooleanLocalStorage(LocalStorageKey.SEARCH_MODAL_CLOSE_DISABLE);

  distanceFilterMobileLocalStorage = new NumberLocalStorage(LocalStorageKey.DISTANCE_FILTER_MILES_MOBILE);
  distanceFilterDesktopLocalStorage = new NumberLocalStorage(LocalStorageKey.DISTANCE_FILTER_MILES_DESKTOP);

  restaurantsPickerPosition = RestaurantsPickerPosition;

  rangeConfig = {
    step: .1,
    direction: 'ltr',
  };

  positionWrapper: DistanceSliderPositionWrapper;

  desktopDistances = [
    {value: 5, label: '5.0'},
    {value: 2, label: '2.0'},
    {value: 1, label: '1.0'},
    {value: 0.3, label: '0.3'},
  ];

  constructor(
    private navigatorService: NavigatorService,
    private searchParamsService: SearchParamsService
  ) {
  }

  ngOnInit(): void {
    this.positionWrapper = new DistanceSliderPositionWrapper(this.position);

    if (this.distanceFilterMobileLocalStorage.getItem()) {
      this.searchResult.distanceFilterMobile = this.distanceFilterMobileLocalStorage.getItem();
    }

    this.searchResult.distanceFilterDesktop = this.distanceFilterDesktopLocalStorage.getItem() || 5;

    this.distanceChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchResult && this.searchResult.isReady) {
      this.distanceChange();
    }
  }

  onRestaurantSelected($event: any) {
    this.restaurantClick.emit($event);
  }

  desktopDistanceChange(distance: number) {
    this.searchResult.distanceFilterDesktop = distance;

    this.distanceChange();
  }

  distanceChange() {
    if (this.isMobile && this.searchResult.distanceFilterMobile > 0) {
      this.distanceFilterMobileLocalStorage.setItem(this.searchResult.distanceFilterMobile);
      this.searchResult.filterGroupsAndSetRestaurantsAndSlidesAndPicker(
        this.distanceFilterMobileLocalStorage.getItem()
      );
    }

    if (!this.isMobile && this.searchResult.distanceFilterDesktop > 0) {
      this.distanceFilterDesktopLocalStorage.setItem(this.searchResult.distanceFilterDesktop);
      this.searchResult.filterGroupsAndSetRestaurantsAndSlidesAndPicker(
        this.distanceFilterDesktopLocalStorage.getItem()
      );
    }
  }

  goToCityRestaurants() {
    this.searchParams = this.searchParamsService.removeGeoLocationFromPath(this.searchParams);

    setTimeout(() => {
      this.searchModalCloseDisableLocalStorage.setItem(true);

      this.navigatorService.goToUrl(this.searchParams.path).then(() => {
        this.searchModalCloseDisableLocalStorage.setItem(false);
      });
    }, 100);
  }
}
