import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentNavigationExtra } from '../../../../../decorators/current-navigation-extra.decorator';
import { CurrentRestaurant } from '../../../../../decorators/current-restaurant.decorator';
import { CurrentUser } from '../../../../../decorators/current-user.decorator';
import { IsDevice } from '../../../../../decorators/is-device.decorator';
import { RouteParamsValue } from '../../../../../decorators/route-params-value.decorator';
import { WaitForRestaurant } from '../../../../../decorators/wait-for-restaurant.decorator';
import { Food } from '../../../../../domains/food';
import { Post } from '../../../../../domains/post';
import { PostPage } from '../../../../../domains/post-page';
import { Restaurant } from '../../../../../domains/restaurant';
import { User } from '../../../../../domains/user';
import { IDeactivateComponent } from '../../../../../interfaces/ideactivate.component';
import { DeviceType } from '../../../../../models/enums/device-type';
import { PostsSort } from '../../../../../models/enums/posts-sort';
import { RouteParamsType } from '../../../../../models/enums/route-params-type';
import { ViewType } from '../../../../../models/enums/view-type';
import { Image } from '../../../../../models/image';
import { RestaurantRouteParams } from '../../../../../models/route-params/restaurant-route-params';
import { PostTypeWrapper } from '../../../../../models/types/post-type-wrapper';
import { UnsplashImage } from '../../../../../models/unsplash-image';
import { FoodService } from '../../../../../services/food.service';
import { GalleryService } from '../../../../../services/gallery.service';
import { LoaderService } from '../../../../../services/loader.service';
import { NavigatorService } from '../../../../../services/navigator.service';
import { PostVoteService } from '../../../../../services/post-vote.service';
import { PostService } from '../../../../../services/post.service';
import { RestaurantService } from '../../../../../services/restaurant.service';
import { SeoService } from '../../../../../services/seo.service';
import { UnsplashService } from '../../../../../services/unsplash.service';
import { ViewService } from '../../../../../services/view.service';
import { WebSocketService } from '../../../../../services/web-socket.service';
import { FoodPostListener } from '../../../../../websocket/listeners/food-post-listener';

@Component({
  selector: 'app-food-gallery',
  templateUrl: './food-gallery.component.html',
  styleUrls: ['./food-gallery.component.scss']
})
export class FoodGalleryComponent implements OnInit, IDeactivateComponent {
  @CurrentRestaurant() currentRestaurant: Restaurant;
  @CurrentUser() currentUser: User;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  @RouteParamsValue(RouteParamsType.RESTAURANT) restaurantRouteParams: RestaurantRouteParams;

  private loaderWasHide = false;
  private pages = 0;
  private hasNext = true;
  private requestSent = false;

  isFoodType: boolean;
  foodId: number;
  url: string;
  postsSortedByMMMM: Post[] = [];
  posts: Post[] = [];
  sort = PostsSort.BEST_PICTURE;
  viewType = ViewType;
  postVoted = false;
  backgroundImage: UnsplashImage;
  dataLoaded = false;
  postType: PostTypeWrapper;
  food: Food;
  newPost: Post;
  showIcon = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private foodService: FoodService,
    private postService: PostService,
    private navigatorService: NavigatorService,
    private webSocketService: WebSocketService,
    private loaderService: LoaderService,
    private restaurantService: RestaurantService,
    private seoService: SeoService,
    @Inject(DOCUMENT) private _document: Document,
    private viewService: ViewService,
    private postVoteService: PostVoteService,
    private unsplashService: UnsplashService,
    private galleryService: GalleryService,
  ) {
    this.url = this.router.url.split('?')[0];
    this.isFoodType = activatedRoute.snapshot.data['isFoodType'];

    this.newPost = this.galleryService.post;
  }

  @WaitForRestaurant()
  ngOnInit(): void {
    if (this.restaurantRouteParams.foodIsGallery) {
      this.postType = new PostTypeWrapper(
        this.restaurantService.foodGalleryTypes[this.restaurantRouteParams.foodWithIdSlug]
      );
    }

    this.food = this.galleryService.food;

    this.unsplashService.randomPhoto(true).subscribe(value => {
      this.backgroundImage = value;
    });

    setTimeout(() => {
      if (!this.loaderWasHide) {
        this.loaderService.showLoader();
      }
    }, 100);

    if (!this.restaurantRouteParams.foodIsGallery) {
      this.foodService.getFood(this.restaurantRouteParams.foodId).subscribe(food => {
        this.food = food;

        this.activatedRoute.queryParams.subscribe(queryParams => {
          this.seoService.setFoodMetaTags(this.currentRestaurant, this.food);
          this.foodId = this.food.id;

          if (this.hasNext) {
            this.getPosts();
          }
        });

        this.webSocketService.registerListener(new FoodPostListener(this.foodId))
          .subject
          .subscribe(post => {
            if (!(this.food.images?.length)) {
              this.food.images = post.images;
            }

            const index = this.postsSortedByMMMM.findIndex(iPost => iPost.hash === post.hash);

            if (index === -1) {
              this.postsSortedByMMMM.push(post);

              this.posts.unshift(post);
            } else {
              this.postsSortedByMMMM[index].id = post.id;
              this.postsSortedByMMMM[index].getstreamId = post.getstreamId;
            }
          });
      });
    } else {
      if (!this.food) {
        this.currentRestaurant.menus.forEach(menu => {
          menu.categories.forEach(category => {
            category.foods.forEach(food => {
              if (this.restaurantRouteParams.foodWithIdSlug === food.slug) {
                this.food = food;
              }
            });
          });
        });
      }

      this.getPostsByRestaurant();
    }
  }

  fetchFoodIfNull(foodId: number): Promise<void> {
    return new Promise(((resolve, reject) => {
      if (this.food) {
        resolve();
      } else {
        this.foodService.getFood(foodId)
          .subscribe({
            next: food => {
              this.food = food;

              resolve();
            }, error: error => {
              reject(error);
            }
          });
      }
    }));
  }

  private getPosts() {
    this.requestSent = true;

    this.postService.getPostsByFoodId(this.food.id, this.pages).subscribe((postPage) => {
      this.initPosts(postPage);
    });
  }

  private getPostsByRestaurant() {
    this.requestSent = true;

    this.postService.getPostsByType(this.postType, this.pages).subscribe((postPage) => {
      this.initPosts(postPage);
    });
  }

  private initPosts(postPage: PostPage) {
    this.hasNext = postPage.hasNext;
    this.pages++;

    const posts = postPage.posts;

    this.postsSortedByMMMM = this.postsSortedByMMMM.concat(posts);

    if (this.newPost) {
      this.sort = PostsSort.RECENT;
    }

    this.sortAndFilterPosts(this.sort);

    this.loaderWasHide = true;
    this.loaderService.hideLoader();

    setTimeout(() => {
      this.requestSent = false;
    }, 100);

    this.dataLoaded = true;
  }

  private sortAndFilterPosts(sort: PostsSort) {
    this.posts = this.postsSortedByMMMM.slice(0);

    switch (sort) {
      case PostsSort.RECENT:
        this.posts.sort((post1, post2) => post1.id < post2.id ? 1 : -1);
        break;
      case PostsSort.OLDEST:
        this.posts.sort((post1, post2) => post1.id > post2.id ? 1 : -1);
        break;
      case PostsSort.BEST_PICTURE:
        this.posts.sort((post1, post2) => post1.votesCount < post2.votesCount ? 1 : -1);
        break;
    }
  }

  goToFoodGalleryNew(image: Image) {
    this.navigatorService.goToFoodGalleryNew(this.url, image, this.food, 100);
  }

  sortChange(sort: PostsSort) {
    this.sortAndFilterPosts(sort);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.newPost) {
      setTimeout(() => {
        this._document.defaultView.location.reload();
      }, 10);
    }

    return !this.newPost;
  }

  onPostClick(post: Post, newViewType: ViewType) {
    this.viewService.setViewTypeAndScrollToPhantomView(post.view, newViewType);

    if (newViewType === ViewType.CLASSIC && this.currentUser) {
      this.postVoteService.getPostVote(post.id).subscribe(postVote => {
        this.postVoted = !!postVote;
      });
    }
  }

  goToUser(user: User) {
    this.navigatorService.goToUser(user.username);
  }

  postDeleted(post: Post) {
    const index = this.posts.indexOf(post);

    if (index > -1) {
      this.posts.splice(index, 1);
    }
  }

  togglePostVote(post: Post) {
    this.postVoteService.togglePostVote(post.id, !this.postVoted).subscribe((postVotesCount: number) => {
      this.postVoted = !this.postVoted;

      if (this.postVoted) {
        this.showIcon = true;
      }

      post.votesCount = postVotesCount;
    });
  }
}
