<div
  class="owner-content d-flex flex-column safe-area-top"
>
  <div class="card owner-body border-radius-none mt-0 d-flex h-100">
    <div
      [appBackgroundUrl]="backgroundImage ? backgroundImage: ''"
      class="h-100 w-100"
    ></div>

    <div class="position-absolute-0 backdrop-filter-brightness-0-35"></div>

    <div class="position-absolute-0 font-size-1rem">
      <div class="icon-v2 mmmm-2x-red scale-2 mt-5rem w-100 d-flex justify-content-center"></div>
      <div class="text-white w-100 d-flex justify-content-center mt-5rem">Enjoy the experience of an app</div>
      <div class="text-white w-100 d-flex justify-content-center mt-1">without installing one</div>

      <div class="w-100 d-flex justify-content-center mt-2-5rem icon-v2 add-to-home-screen"></div>
      <div class="text-white w-100 d-flex justify-content-center mt-2">Tap share icon</div>
      <div class="text-white w-100 d-flex justify-content-center mt-1">(Bottom of Safari)</div>

      <div class="w-100 d-flex justify-content-center mt-2-5rem icon-v2 slide-up"></div>
      <div class="text-white w-100 d-flex justify-content-center mt-2">Add to home screen</div>
      <div class="text-white w-100 d-flex justify-content-center mt-1">(Slide up to access)</div>
    </div>
  </div>

  <div class="modal-top-standard-footer border-bottom-radius-3 bg-white p-3">
    <div class="modal-top-standard-footer-content">
      <input
        type="submit"
        class="btn btn-white btn-block text-uppercase"
        value="Learn about Mbership"
        (click)="openMembershipModal()"
      />
    </div>
  </div>
</div>

<ng-template #membership let-c="close">
  <app-membership-modal
    [user]="currentUser"
  ></app-membership-modal>
</ng-template>
