<div class="receipt-modal max-width {{ !detailsView ? 'hash' : '' }}">
  <div class="receipt-modal-body">
    <ng-container *ngIf="!detailsView">
      <input ngbAutofocus type="hidden"/>

      <div class="receipt h-100 p-3">
        <div
          appFitText
          [ngStyle]="{visibility: showHash ? 'visible' : 'hidden'}"
          class="code animate_animated animate__fadeIn"
        >
          {{ order.hashId }}
        </div>

        <div class="options">
          <div class="logo float-right p-4">
            <img [src]="emailLogo" class="w-100" alt="MMMM Menu"/>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="detailsView">
      <div class="details">
        <div class="px-4 pt-4 pb-3">
          <table class="w-100">
            <ng-container *ngFor="let food of order.foods">
              <tr>
                <td class="order-item-quantity">
                  <span>{{ food.quantity }}</span>
                </td>
                <td>
                  <span>{{ food.title }}</span>
                </td>
                <td class="order-item-price text-right">
                  <span *ngIf="food.priceBaseTotal > 0">{{ food.priceBaseTotal | precision }}</span>
                </td>
              </tr>

              <ng-container *ngFor="let section of food.sections">
                <ng-container *ngIf="section.extras.length > 0">
                  <tr *ngFor="let extra of section.extras">
                    <td></td>
                    <td>
                      <span class="order-item-quantity d-inline-block">
                          <span *ngIf="extra.quantity > 1">{{ extra.quantity }}</span>
                      </span>
                      <span>{{ extra.name }}</span>
                    </td>
                    <td class="order-item-price text-right">
                      <span *ngIf="extra.showPrice && extra.total > 0">
                          {{ extra.total | precision }}
                      </span>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>

              <tr *ngIf="food.instruction">
                <td class="instruction" colspan="3">
                  {{ food.instruction }}
                </td>
              </tr>
            </ng-container>
          </table>
        </div>

        <hr class="m-0">

        <div class="px-4 py-3">
          <div class="row">
            <div class="col-6">
              Subtotal<br>
              Tax {{ order.feeEnabled ? '& fee' : '' }} <br>
              Tip {{ order.tipPercentage }}%<br>
              Total
            </div>

            <div class="col-6 text-right">
              {{ order.subtotal | precision }}<br>
              {{ (order.salesTax + (order.feeEnabled ? order.fee : 0)) | precision }}<br>
              {{ order.tip | precision }}<br>
              ${{ order.total | precision }}
            </div>
          </div>
        </div>

        <hr class="m-0">

        <div class="px-4 py-3 mb-5">
          <div class="row">
            <div class="col-6">
              <span>{{ order.hashIdLong }}</span>
            </div>

            <div class="col-6 text-right">
              <span *ngIf="order.accepted">
                  {{ order.readyTime | date:'E M/dd' }}
                @ {{ (order.readyTime | date:'h:mm a') | lowercase }}
              </span>
              <span *ngIf="order.declined">
                Order Declined
              </span>
              <span *ngIf="!order.accepted && !order.declined">
                Order Pending
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div #bottomElement></div>
  </div>

  <div class="receipt-modal-footer">
    <div class="clearfix">
      <div *ngIf="order.accepted" class="switcher-box">
        <label class="switcher switcher-secondary">
          <input
            [(ngModel)]="detailsView"
            type="checkbox"
            class="switcher-input"
            (change)="onSwitcherChange()"
          />

          <span class="switcher-indicator"></span>
        </label>
      </div>
    </div>

    <ng-container *ngIf="detailsView">
      <div ngbDropdown [autoClose]="'outside'" class="btn-group inline-flex w-100">
        <button ngbDropdownToggle type="button" class="btn btn-default btn-block dropdown-toggle">
          Select
        </button>

        <div ngbDropdownMenu class="w-100 text-center dropdown-menu-right" x-placement="bottom-right">
          <app-mini-contact classes="dropdown-item" from="receipt"></app-mini-contact>
        </div>
      </div>
    </ng-container>
  </div>
</div>
