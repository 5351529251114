import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NGXLogger } from 'ngx-logger';
import { Restaurant } from '../domains/restaurant';
import { RestaurantSimple } from '../domains/restaurant-simple';
import { LoaderService } from './loader.service';
import { NotificationService } from './notification.service';
import { ShareService } from './share.service';

@Injectable({providedIn: 'root'})
export class ContactService {
  private isDesktop: boolean;

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    public shareService: ShareService,
    private notificationService: NotificationService,
    private logger: NGXLogger,
    private loaderService: LoaderService
  ) {
    this.isDesktop = deviceDetectorService.isDesktop();
  }

  call(restaurant: Restaurant | RestaurantSimple) {
    this.loaderService.showLoader();

    if (restaurant.phone) {
      if (this.isDesktop) {
        window.open(`tel: ${restaurant.phone}`, '_blank');
      } else {
        window.location.href = `tel: ${restaurant.phone}`;
      }
    } else {
      this.notificationService.phoneNotProvided();
    }

    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 500);
  }

  mail(restaurant: Restaurant | RestaurantSimple) {
    if (restaurant.email) {
      if (this.isDesktop) {
        window.open(`mailto: ${restaurant.email}`, '_blank');
      } else {
        window.location.href = `mailto: ${restaurant.email}`;
      }
    } else {
      this.notificationService.emailNotProvided();
    }
  }

  share(restaurant: Restaurant | RestaurantSimple) {
    this.shareService.shareRestaurant(restaurant);
  }

  feedback() {
    this.logger.warn('Will be ready in future story');
  }
}
