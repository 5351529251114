import { PickerOption } from './picker-option';

export class PickerGroup {
  isDefault = false;
  label: string = null;
  options: PickerOption[] = [];

  constructor(label: string = null) {
    this.label = label;
  }

  addOption(value: string, label: string, returnObject: any = null) {
    this.options.push(new PickerOption(value, label, returnObject));
  }
}
