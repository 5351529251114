import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class LoggerService {
  readonly loggerUrl: string;

  constructor(private http: HttpClient, private logger: NGXLogger) {
    this.loggerUrl = `${environment.apiEndpoint}/api/logs`;
  }

  errorLog(error: any, userErrorMessage: string = null) {
    let errorMessage = '';
    let errorMessageBody: string;

    if (userErrorMessage) {
      errorMessage += userErrorMessage + '\n';
    }

    if (error.message) {
      errorMessage += error.message;
    }

    if (error instanceof HttpErrorResponse) {
      errorMessageBody = errorMessage + '\n' + JSON.stringify(error.error);
    } else if (error instanceof GeolocationPositionError) {
      errorMessageBody = errorMessage + '\ncode: ' + error.code;
    } else {
      errorMessageBody = errorMessage + '\n' + error.stack;
    }

    this.http.post<any>(`${this.loggerUrl}/error`, errorMessageBody).subscribe(() => {
      this.logger.error(errorMessage, error);
    });
  }

  newErrorLog(message: string) {
    const error = new Error(message);

    this.errorLog(error);
  }
}
