<h6 class="extra-mb">
  {{ section.title || (section.min === 0 ? 'Add Ons' : '') }}
</h6>

<div *ngFor="let extra of section.extras; let i = index" class="flex-content-vc extra-mb">
  <div class="flex-fill">
    <label class="custom-control custom-checkbox custom-checkbox-secondary mb-0">
      <input
        [(ngModel)]="section.checked[extra.id]"
        type="checkbox"
        name="extra-{{section.categoryId}}-{{section.foodId}}-{{section.id}}"
        value="{{extra.id}}"
        class="custom-control-input"
      >

      &nbsp;<span class="custom-control-label cursor-pointer ml-0-4rem">{{ extra.name }}</span>
    </label>
  </div>

  <div class="d-inline-flex">
    <p class="mb-0 text-right">
      <span *ngIf="section.isPriceVisible(extra)">{{ extra.price }}</span>
    </p>
  </div>
</div>
