import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { RestaurantDetailsImageType } from '../models/enums/restaurant-details-image-type';
import { Image } from '../models/image';

@Injectable({providedIn: 'root'})
export class RestaurantDetailsService {
  private readonly restaurantDetailsUrl: string;

  constructor(
    private http: HttpClient
  ) {
    this.restaurantDetailsUrl = `${environment.apiEndpoint}/api/restaurant-details`;
  }

  uploadImage(image: Image, imageType: RestaurantDetailsImageType) {
    return this.http
      .put<Image>(`${this.restaurantDetailsUrl}/image/${imageType}`, image)
      .pipe(map(value => plainToClass(Image, value)));
  }

  deleteImage(imageType: RestaurantDetailsImageType) {
    return this.http.delete(`${this.restaurantDetailsUrl}/image/${imageType}`);
  }

  public uploadLogo(image: Image) {
    return this.uploadImage(image, RestaurantDetailsImageType.LOGO);
  }

  public deleteLogo() {
    return this.deleteImage(RestaurantDetailsImageType.LOGO);
  }

  public uploadAvatar(image: Image) {
    return this.uploadImage(image, RestaurantDetailsImageType.AVATAR);
  }

  public deleteAvatar() {
    return this.deleteImage(RestaurantDetailsImageType.AVATAR);
  }

  public uploadLogoDark(image: Image) {
    return this.uploadImage(image, RestaurantDetailsImageType.LOGO_DARK);
  }

  public deleteLogoDark() {
    return this.deleteImage(RestaurantDetailsImageType.LOGO_DARK);
  }
}
