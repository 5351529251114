import { Transform, Type } from 'class-transformer';
import { nanoid } from 'nanoid';
import { v4 as uuidv4 } from 'uuid';
import { SectionType } from '../models/enums/section-type';
import { TagType } from '../models/enums/tag-type';
import { FoodStatusWrapper } from '../models/food-status-wrapper';
import { Image } from '../models/image';
import { View } from '../models/view';
import { FavoriteStatusWrapper } from '../models/wrappers/favorite-status.wrapper';
import { Wrapper } from '../models/wrappers/wrapper';
import { Section } from './section';
import { Tag } from './tag';

export class Food {
  id: number;
  uuid = uuidv4();
  title: string;
  description: string;
  price: number;
  ordering: number;
  warning: boolean;
  slug: string;
  profileUrl: string;
  isInvalid = false;
  getstreamToken: string;
  imagesFromFan = false;
  gallery: boolean;
  googleImage = false;

  @Type(() => Section)
  sections: Array<Section> = [];

  @Type(() => Tag)
  tags: Array<Tag> = [];

  @Type(() => Image)
  images: Array<Image> = [];

  @Type(() => FoodStatusWrapper)
  @Transform(value => Wrapper.toClass(value, FoodStatusWrapper), {toClassOnly: true})
  @Transform(value => Wrapper.toPlain(value, FoodStatusWrapper), {toPlainOnly: true})
  status: FoodStatusWrapper = new FoodStatusWrapper();

  view = new View();
  sectionsOpen = false;
  hash: string;

  @Type(() => FavoriteStatusWrapper)
  @Transform(value => Wrapper.toClass(value, FavoriteStatusWrapper), {toClassOnly: true})
  @Transform(value => Wrapper.toPlain(value, FavoriteStatusWrapper), {toPlainOnly: true})
  favoriteStatus = new FavoriteStatusWrapper();

  constructor(withOrder = false) {
    this.warning = false;

    if (withOrder) {
      this.ordering = 100000000;
    }

    this.hash = nanoid(10);
  }

  sectionAdd(sectionType: SectionType) {
    const section = new Section(sectionType);

    if (!this.sections) {
      this.sections = [];
    }

    let maxOrder;

    if (sectionType === SectionType.SIZE) {
      maxOrder = 0;
    } else {
      maxOrder = Math.max.apply(Math, [1].concat(this.sections.map(sectionI => sectionI.ordering)));
    }

    section.ordering = maxOrder + 1;
    this.sections.push(section);

    this.sectionsSortAndReorder();

    return section;
  }

  sectionsSortAndReorder() {
    this.sections = this.sections.sort((section1, section2) => {
      if (section1.ordering === section2.ordering) {
        return 0;
      }

      return (section1.ordering > section2.ordering ? 1 : -1);
    });
  }

  get hasAllergens(): boolean {
    const tag = this.tags.find(tagI => tagI.value === 'allergens');

    return tag !== undefined;
  }

  get isFeatured(): boolean {
    const tag = this.tags.find(tagI => tagI.tagType === TagType.temp);

    return tag !== undefined;
  }

  get isAdvisory(): boolean {
    const tag = this.tags.find(tagI => tagI.tagType === TagType.warning && tagI.value === 'adv');

    return tag !== undefined;
  }

  get hasSections(): boolean {
    return this.sections.length > 0;
  }

  get hasSelections(): boolean {
    if (this.hasSections) {
      return this.sections.every(sectionItered => sectionItered.manager.hasSelections());
    }

    return true;
  }

  get priceView() {
    if (this.hasSections) {
      let prices = [];

      const section = this.sections[0];

      if (section && section.type === SectionType.SIZE) {
        const selectedExtra = section.extras.filter((extra) => extra.id === +section.selected[0]);

        if (selectedExtra.length > 0) {
          if (selectedExtra[0].price === 0) {
            return this.price;
          }

          return selectedExtra[0].price;
        }

        prices = section.extras.map(extra => {
          return extra.price;
        });

        if (prices.length === 0) {
          return 0;
        }

        prices = prices.sort((n1, n2) => n1 - n2);

        if (prices.length === 1) {
          if (prices[0] === 0) {
            return this.price;
          }

          return prices[0];
        } else if (prices.length === 2) {
          if (prices[0] === 0 && prices[1] === 0) {
            return this.price;
          }

          return `${prices[0]} | ${prices[1]}`;
        } else {
          if (prices[0] === 0 && prices[prices.length - 1] === 0) {
            return this.price;
          }

          return `${prices[0]} - ${prices[prices.length - 1]}`;
        }
      }
    }

    return `${this.price}`;
  }
}
