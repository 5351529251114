import { Platform } from '@angular/cdk/platform';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Auth } from '../../../../decorators/auth.decorator';
import { Restaurant } from '../../../../domains/restaurant';
import { RestaurantExplore } from '../../../../domains/restaurant-explore';
import { RestaurantSimple } from '../../../../domains/restaurant-simple';
import { ModalType } from '../../../../models/enums/modal-type';
import { TileType } from '../../../../models/enums/tile-type';
import { Modal } from '../../../../models/modal';
import { TypeCategorySection } from '../../../../models/type-category-section';
import { ModalService } from '../../../../services/modal.service';
import { PwaService } from '../../../../services/pwa.service';

@Component({
  selector: 'app-search-restaurant-list',
  templateUrl: './search-restaurant-list.component.html',
  styleUrls: ['./search-restaurant-list.component.scss']
})
export class SearchRestaurantListComponent implements OnInit {
  @Input() cityName: string;
  @Input() typeCategorySection: TypeCategorySection;

  @Output() restaurantClick = new EventEmitter<RestaurantExplore>();
  @Output() dividerClick = new EventEmitter<TypeCategorySection>();
  @Output() goToRestaurantToEditMode = new EventEmitter<RestaurantSimple>();

  @ViewChild('restaurantAddModalRef') restaurantAddModalRef: ElementRef;
  @ViewChild('addToHomeScreenModalRef') addToHomeScreenModalRef: ElementRef;

  restaurantsCount: number;
  restaurantsRemainderCount: number;
  restaurantsGroupedCount: number;
  restaurantAddModal: Modal;
  hasRestaurants: boolean;
  tileTypeList = TileType;

  constructor(
    private platform: Platform,
    private modalService: ModalService,
    public pwaService: PwaService
  ) {
  }

  ngOnInit() {
    this.restaurantsCount = this.typeCategorySection
      .typeCategories
      .reduce((sum, typeCategory) => sum + typeCategory.restaurants.length, 0);

    this.restaurantsRemainderCount = this.restaurantsCount % 3;
    this.restaurantsGroupedCount = this.restaurantsCount - this.restaurantsRemainderCount;

    this.hasRestaurants = this.typeCategorySection.hasRestaurants;
  }

  isChain(typeCategoryIndex: number, typeCategoryRestaurantIndex: number): boolean {
    return (
        this.restaurantsRemainderCount > 0 &&
        this.getRestaurantIndex(typeCategoryIndex, typeCategoryRestaurantIndex) === this.restaurantsGroupedCount + 1
      ) ||
      this.isChainSingle(typeCategoryIndex, typeCategoryRestaurantIndex);
  }

  isChainSingle(typeCategoryIndex: number, typeCategoryRestaurantIndex: number) {
    return this.restaurantsRemainderCount === 0 &&
      this.getRestaurantIndex(typeCategoryIndex, typeCategoryRestaurantIndex) === this.restaurantsCount;
  }

  private getRestaurantIndex(typeCategoryIndex: number, typeCategoryRestaurantIndex: number) {
    let restaurantIndex = 0;

    for (let i = 0; i < typeCategoryIndex; i++) {
      restaurantIndex += this.typeCategorySection.typeCategories[i].restaurants.length;
    }

    restaurantIndex += (typeCategoryRestaurantIndex + 1);

    return restaurantIndex;
  }

  openDividerModalFromExample() {
    const typeCategorySection = new TypeCategorySection();

    typeCategorySection.isExample = true;
    typeCategorySection.titles.push('M Powered');

    this.openDividerModal(typeCategorySection);
  }

  openDividerModal(typeCategorySection: TypeCategorySection) {
    this.dividerClick.emit(typeCategorySection);
  }

  @Auth()
  onClickAddRestaurant() {
    this.restaurantAddModal = this.modalService.open(this.restaurantAddModalRef, ModalType.RESTAURANT_ADD);
  }

  onClickRestaurant(restaurant: RestaurantExplore) {
    this.restaurantClick.emit(restaurant);
  }

  restaurantCreated(restaurant: Restaurant) {
    if (restaurant) {
      this.restaurantAddModal.close();

      this.goToRestaurantToEditMode.emit(restaurant);
    }
  }

  installPwa(): void {
    if (this.platform.ANDROID) {
      this.pwaService.promptEvent.prompt();
    } else if (this.platform.IOS) {
      this.modalService.open(this.addToHomeScreenModalRef, ModalType.PREVIEW);
    }
  }
}
