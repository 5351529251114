export enum RestaurantPickerOption {
  IMAGE_SEARCH = 'image_search',
  MAP_PHOTO = 'map_photo',
  CALL = 'call',
  QR = 'qr',
  VIDEO = 'video',
  INSTAGRAM = 'instagram',
  POST_INSTAGRAM = 'post_instagram',
  OWNER = 'owner',
  MENU = 'menu',
  LINK_VIDEO = 'link_video',
  PICTURES = 'pictures',
  VIBE = 'vibe',
  PEOPLE = 'people'
}
