<div
  appModalCloseOnClick
  [ngClass]="{'modal-blur-box border-bottom-radius-3' : isMobile, 'h-100': !isOpenedInModal}"
  class="modal-body p-0 flex-content-vc-hc flex-column"
>
  <div
    [ngClass]="{
      'w-100': isMobile,
      'modal-content': isMobile && isOpenedInModal,
      'min-h-100vh flex-content-vc-hc flex-column': !isMobile || !isOpenedInModal,
      'modal': isOpenedInModal,
      'mobile-page': isMobile && !isOpenedInModal
    }">
    <div appModalCloseOnClickPrevent [ngClass]="{'modal-box': !isMobile, 'w-100': isMobile}">
      <div
        [ngClass]="{'user-authentication': !isMobile, 'user-authentication-mobile': isMobile}"
        class="text-center padding-0-4rem"
      >
        <input ngbAutofocus type="hidden"/>

        <div class="flex-content-hc">
          <div class="flex-content-vc-hc mmmm-icon-rounded">
            <span appHrefNone class="icon-v2 mmmm-2x-red"></span>
          </div>
        </div>

        <ng-container *ngIf="!isPost">
          <div *ngIf="reportType === 'BROKEN_LINK'" class="mt-4 pt-4 font-weight-normal text-white">
            <span>Broken link</span>
          </div>

          <div *ngIf="reportType === 'UNRELATED_VIDEO'" class="mt-4 pt-4 font-weight-normal text-white">
            <span>Unrelated</span>
          </div>

          <div *ngIf="reportType === 'SPAM'" class="mt-4 pt-4 font-weight-normal text-white">
            <span>Other</span>
          </div>

          <div class="flex-content-hc">
            <div class="user-authentication-buttons mt-4">
              <button
                appModalCloseOnClickPrevent
                class="btn rounded-pill btn-outline-dark btn-outline-red btn-block text-transform-none"
                (click)="report()"
              >
                Report video
              </button>

              <button
                class="btn rounded-pill btn-outline-dark btn-outline-red btn-block text-transform-none mt-3"
                (click)="closeModal()"
              >
                Cancel
              </button>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="isPost">
          <div class="mt-4 pt-4 font-weight-normal text-white">
            <span *ngIf="!(currentUser?.id !== post?.user.id)">Remove post permanently?</span>

            <span *ngIf="currentUser?.id !== post?.user.id">Inappropriate post?</span>
          </div>

          <div class="flex-content-hc">
            <div class="user-authentication-buttons mt-4">
              <button
                *ngIf="!(currentUser?.id !== post?.user.id)"
                appModalCloseOnClickPrevent
                class="btn rounded-pill btn-outline-dark btn-outline-red btn-block text-transform-none"
                (click)="postDelete()"
              >
                Delete
              </button>

              <button
                class="btn rounded-pill btn-outline-dark btn-outline-red btn-block text-transform-none mt-3"
                (click)="closeModal()"
              >
                Cancel
              </button>

              <button
                *ngIf="currentUser?.id !== post?.user.id"
                appModalCloseOnClickPrevent
                class="btn rounded-pill btn-outline-dark btn-outline-red btn-block text-transform-none mt-3"
                (click)="postDelete()"
              >
                Report
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
