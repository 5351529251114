<div
  [ngClass]="{'bg-dark': hasDiningEmbeds || hasWalkingEmbeds, 'bg-f3': !hasDiningEmbeds && !hasWalkingEmbeds}"
  class="modal-body overflow-y d-flex p-0"
>
  <div class="d-flex flex-column w-100">
    <div class="min-h-100vh d-flex justify-content-center w-100">
      <div class="d-flex flex-column w-65rem z-index-1 h-100">
        <h3 class="text-center my-2rem"> {{ searchParams.locationName }} </h3>

        <div
          *ngIf="!hasDiningEmbeds && !hasWalkingEmbeds"
          [appBackgroundUrl]="backgroundImage ? backgroundImage: ''"
          class="position-absolute-0 backdrop-filter-brightness-0-85 cursor-pointer"
        >
          <div class="position-fixed-0 favorites-video-gradient"></div>
        </div>

        <h3 class="text-center text-white my-2rem position-absolute left-0 right-0">
          {{ searchParams.locationName }}
        </h3>

        <div class="target-line cursor-pointer z-index-3">
          <div  #videoList class="row mb-3">
            <div class="col-12 text-center text-white" (click)="openVideoModal()">
              Link video
            </div>

            <div class="col-6 text-center text-white">
              <ng-container *ngIf="hasDiningEmbeds">
                <div *ngFor="let embed of locationEmbeds;" class="mt-3">
                  <app-embed-video
                    *ngIf="embed.tourType == TourType.DINING"
                    [embed]="embed"
                    (deleteEmbed)="onDeleteDiningEmbed(embed)"
                  ></app-embed-video>
                </div>
              </ng-container>
            </div>

            <div *ngIf="hasWalkingEmbeds" class="col-6 text-center text-white">
              <ng-container *ngIf="hasWalkingEmbeds">
                <div *ngFor="let embed of locationEmbeds;" class="mt-3">
                  <app-embed-video
                    *ngIf="embed.tourType == TourType.WALKING"
                    [embed]="embed"
                    (deleteEmbed)="onDeleteWalkingEmbed(embed)"
                  ></app-embed-video>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #videoModalRef let-c="close">
  <app-video-modal
    [type]="type"
    [searchParams]="searchParams"
    (videoAdded)="onVideoAdded($event)"
  ></app-video-modal>
</ng-template>
