import { Device } from '../models/device';
import { DecoratorInjectorService } from '../services/decorator/decorator-injector.service';
import { DecoratorService } from '../services/decorator/decorator.service';

export function DeviceInfo(): PropertyDecorator {
  return (target: any, propertyKey: string) => {
    let service: DecoratorService;
    let deviceInfo: Device;

    const getter = () => {
      if (service === undefined) {
        service = DecoratorInjectorService.getService();
      }

      if (deviceInfo === undefined) {
        const ngxDeviceInfo = service.deviceDetectorService.getDeviceInfo();

        deviceInfo = new Device();
        deviceInfo.userAgent = ngxDeviceInfo.userAgent;
        deviceInfo.os = ngxDeviceInfo.os;
        deviceInfo.osVersion = ngxDeviceInfo.os_version;

        if (deviceInfo.os === 'Android') {
          deviceInfo.osVersion = deviceInfo.userAgent
            .split('Android')[1]
            .split(';')[0].trim()
            .split(' ')[0].trim();
        }

        if (deviceInfo.os === 'iOS') {
          deviceInfo.osVersion = deviceInfo.userAgent
            .split('iPhone OS')[1]
            .split(';')[0].trim()
            .split(' ')[0].trim()
            .split('_').join('.');
        }
      }

      return deviceInfo;
    };

    const setter = () => {
    };

    Object.defineProperty(target, propertyKey, {
      get: getter,
      set: setter
    });
  };
}
