import { Extra } from '../../domains/extra';
import { Section } from '../../domains/section';
import { SectionManagerBase } from './section-manager-base';
import { SectionManagerInterface } from './section-manager-interface';

export class SectionButtons extends SectionManagerBase implements SectionManagerInterface {
  constructor(section: Section) {
    super(section);
  }

  getExtras(): Extra[] {
    return this.extras.filter(extraItered => extraItered.quantity > 0);
  }

  getExtrasIds(): string[] {
    return this.getExtras().map(extraItered => `${extraItered.id}`);
  }

  hasSelections(): boolean {
    if (this.min === 0) {
      return true;
    } else if (this.min > 1) {
      return this.extrasQuantity() >= this.min;
    }
  }

  canAddExtra(): boolean {
    if (this.min === 0) {
      return this.extrasQuantity() < this.max;
    } else if (this.min > 1) {
      return this.extrasQuantity() < this.min;
    }
  }
}
