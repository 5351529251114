<nav class="navbar navbar-top bg-white fixed-top max-width safe-area-top">
  <div class="navbar-top-left"></div>

  <ng-container *ngIf="title">
    <div
      class="navbar-brand mr-0 custom-select-navbar-title"
      (click)="titleClick.emit(true)"
    >
      <div class="d-flex">
        <span>{{ title }}</span>
      </div>
    </div>
  </ng-container>

  <div class="navbar-top-right"></div>
</nav>
