import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Auth } from '../../../../decorators/auth.decorator';
import { Subscribe } from '../../../../decorators/subscribe.decorator';
import { RestaurantSimple } from '../../../../domains/restaurant-simple';
import { User } from '../../../../domains/user';
import { UnsplashImage } from '../../../../models/unsplash-image';
import { NotificationService } from '../../../../services/notification.service';
import { RestaurantService } from '../../../../services/restaurant.service';
import { ShareService } from '../../../../services/share.service';
import { UnsplashService } from '../../../../services/unsplash.service';

@Component({
  selector: 'app-owner-verification',
  templateUrl: './owner-verification.component.html',
  styleUrls: [
    './owner-verification.component.scss',
  ]
})
export class OwnerVerificationComponent implements OnInit {
  @Input() restaurant: RestaurantSimple;
  @Input() user: User;

  @ViewChild('membership') membershipModalRef: ElementRef;

  backgroundImage: UnsplashImage;

  constructor(
    private unsplashService: UnsplashService,
    private restaurantService: RestaurantService,
    private notificationService: NotificationService,
    private shareService: ShareService
  ) {
  }

  ngOnInit(): void {
    this.unsplashService.randomPhoto(true).subscribe(image => {
      this.backgroundImage = image;
    });
  }

  onOwnVerificationClicked() {
    setTimeout(() => {
      this.restaurantService.verifyOwnershipEmail(this.restaurant.hostname).subscribe(value => {
        this.notificationService.claimOwnerMessage();
      });
    }, 200);
  }

  @Auth()
  @Subscribe()
  openMembershipModal() {
  }

  onClickShare() {
    this.shareService.shareUrl(location.href);
  }
}
