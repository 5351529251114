import { plainToClass } from 'class-transformer';
import { Subject } from 'rxjs';
import { Post } from '../../domains/post';
import { Listener } from '../listener';

export class PostListener extends Listener {
  subject = new Subject<Post>();

  constructor(username: string) {
    super();
    this.topic = `/push/post/${username}`;
  }

  transform(frame: any): Post {
    return plainToClass(Post, JSON.parse(frame.body));
  }
}
