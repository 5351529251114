import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { IgAccessToken } from '../../../decorators/ig-access-token.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { IsLoggedIn } from '../../../decorators/is-logged-in.decorator';
import { User } from '../../../domains/user';
import { LocalStorage } from '../../../local-storage/local-storage';
import { Entity } from '../../../models/entity';
import { DeviceType } from '../../../models/enums/device-type';
import { EntityType } from '../../../models/enums/entity-type';
import { ErrorResponseType } from '../../../models/enums/error-response-type';
import { InstagramState } from '../../../models/enums/instagram-state';
import { LocalStorageKey } from '../../../models/enums/local-storage-key';
import { ModalType } from '../../../models/enums/modal-type';
import { Paths } from '../../../models/enums/paths';
import { UserGender } from '../../../models/enums/user-gender';
import { AuthenticationService } from '../../../services/authentication.service';
import { InstagramService } from '../../../services/instagram.service';
import { ModalService } from '../../../services/modal.service';
import { NavigatorService } from '../../../services/navigator.service';
import { NotificationService } from '../../../services/notification.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: 'profile.component.html',
  styleUrls: [
    '../../../../vendor/libs/ng-select/ng-select.scss',
    'profile.component.scss'
  ]
})
export class ProfileComponent implements OnInit, OnDestroy {
  @ViewChild('profile') profile: ElementRef;
  @ViewChild('passwordModalRef') passwordModalRef: ElementRef;

  @IsLoggedIn() isLoggedIn: boolean;
  @CurrentUser() currentUser: User;

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  private entitiesLocalStorage = new LocalStorage(Entity, LocalStorageKey.ENTITIES);

  userForm: UntypedFormGroup;
  user: User;
  paths = Paths;

  selectOptions = [
    {value: UserGender.MALE, label: 'Male'},
    {value: UserGender.FEMALE, label: 'Female'},
    {value: UserGender.PREFER_NOT_TO_SAY, label: 'Prefer not to say'}
  ];

  edited = false;
  email: string;
  submitted = false;
  usernameChecked = false;
  disableSubmittedButton = true;
  isPasswordModalOpen = false;

  constructor(
    public authenticationService: AuthenticationService,
    private navigatorService: NavigatorService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private logger: NGXLogger,
    public instagramService: InstagramService,
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalService,
  ) {
  }

  ngOnInit(): void {
    if (!this.isLoggedIn) {
      this.navigatorService.goToHome();
    } else {
      this.user = Object.assign({}, this.currentUser);

      if (this.isTempEmail()) {
        this.submitted = true;
        this.email = this.user.unverifiedEmail || '';
      } else {
        this.email = this.user.email;
      }
    }

    this.activatedRoute.fragment.subscribe(fragment => {
      if (fragment && fragment === 'settings' && this.profile) {
        this.profile.nativeElement.classList.add('animate__animated', 'animate__slideInRight', 'faster');

        this.profile.nativeElement.addEventListener('animationend', () => {
          this.navigatorService.changeLocationTo(
            `${this.currentUser.username}/profile`
          );
        });
      }
    });

    this.userForm = this.formBuilder.group({
      firstname: [this.user.firstName, Validators.required],
      lastname: [this.user.lastName, Validators.required],
      bio: [this.user.bio],
      phone: [this.user.phone],
      email: [this.email, [Validators.required, Validators.email]],
      gender: [this.user.gender],
      birthday: [this.getFormattedBirthday(this.user.birthday)],
      username: [
        this.user.username,
        {
          validators: [Validators.required, Validators.pattern(/^[a-zA-Z0-9._]+$/)],
          asyncValidators: [this.usernameValidator()],
          updateOn: 'blur'
        }
      ]
    }, {updateOn: 'blur'});
  }

  ngOnDestroy(): void {
    if (this.isDesktop && this.edited) {
      this.saveChanges();
    }
  }

  get f() {
    return this.userForm.controls;
  }

  saveChanges() {
    this.submitted = true;

    if (this.userForm.invalid) {
      return;
    }

    this.edited = false;

    this.user.firstName = this.f.firstname.value;
    this.user.lastName = this.f.lastname.value;
    this.user.bio = this.f.bio.value;
    this.user.phone = this.f.phone.value;
    this.user.email = this.f.email.value;
    this.user.gender = this.f.gender.value;
    this.user.birthday = this.getDateFromFormattedBirthday(this.f.birthday.value);
    this.user.username = this.f.username.value.toLowerCase().trim();

    this.userService
      .save(this.user)
      .subscribe({
        next: userData => {
          if (this.currentUser.email !== this.user.email) {
            this.notificationService.confirmationEmailWhenUserUpdate();
          }

          this.submitted = false;

          this.disableSubmittedButton = true;

          this.updateEntityLocalStorage();
          this.authenticationService.saveUserDataToCookie(userData);
          this.user = this.currentUser;
        }, error: error => {
          this.user = Object.assign({}, this.currentUser);

          this.logger.error('On updating user', error);

          if (error.error?.type === ErrorResponseType.UserAlreadyExistsException) {
            this.notificationService.emailExists();
          }
        }
      });
  }

  checkEdited() {
    this.edited = this.userForm.dirty;

    this.disableSubmittedButton = !(this.edited && !this.userForm.invalid);
  }

  @IgAccessToken(InstagramState.CONNECT_USER, false)
  connectToInstagram() {
  }

  @IgAccessToken(InstagramState.CONNECT_USER, false, true)
  reconnectToInstagram() {
  }

  sendConfirmation() {
    this.userService.sendConfirmationEmail()
      .subscribe(value => {
        this.notificationService.confirmationEmailSent(this.user.unverifiedEmail);
      });
  }

  private usernameValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (this.user.username === control.value) {
        return of(null);
      }

      return this.userService.checkUsernameToExists(control.value)
        .pipe(map(value => {
          this.usernameChecked = true;

          return value ? {exists: true} : null;
        }));
    };
  }

  private updateEntityLocalStorage() {
    const entities = this.entitiesLocalStorage.getItems();

    const entityIndex = entities.findIndex(entityI =>
      entityI.name === this.currentUser.username
    );

    if (entityIndex > -1) {
      entities.splice(entityIndex, 1);
    }

    entities.push(plainToClass(Entity, {name: this.user.username, type: EntityType.USER}));

    this.entitiesLocalStorage.setItems(entities);
  }

  private getFormattedBirthday(timestamp: number) {
    if (!timestamp) {
      return null;
    }

    const date = new Date(timestamp);

    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();

    let dateString = '';

    if (mm < 10) {
      dateString += '0';
    }

    dateString += mm;

    if (dd < 10) {
      dateString += '0';
    }

    dateString += dd;

    return dateString + yyyy;
  }

  private getDateFromFormattedBirthday(birthday: string) {
    if (!birthday) {
      return null;
    }

    const date = new Date();

    date.setMonth(parseInt(birthday[0] + birthday[1], 10) - 1);
    date.setDate(parseInt(birthday[2] + birthday[3], 10));
    date.setFullYear(parseInt(birthday[4] + birthday[5] + birthday[6] + birthday[7], 10));

    return date.getTime();
  }

  public isTempEmail() {
    return this.user.email.indexOf('ig_') !== -1;
  }

  openPasswords() {
    this.isPasswordModalOpen = true;
    const sideNavElement = document.getElementsByTagName('app-layout-sidenav-left')[0] as HTMLElement;

    sideNavElement.style.opacity = '0';

    this.modalService.open(this.passwordModalRef, ModalType.RESTAURANT).onClose().then(() => {
      this.isPasswordModalOpen = false;
      sideNavElement.style.opacity = '1';
    });
  }
}
