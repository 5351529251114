<ng-container *ngIf="isMobile">
  <div class="mt-5">
    <div
      *ngFor="let enabledMenu of enabledMenusList; let i = index"
      appHrefNone
      class="dropdown-item text-white d-inline-flex"
    >
      <label class="switcher switcher-sm switcher-secondary mr-3">
        <input
          [checked]="enabledMenu.checked"
          [value]="enabledMenu.key"
          type="checkbox"
          class="switcher-input"
          (change)="checkedMenuChanged(enabledMenu)"
        />
        <span class="switcher-indicator"></span>
        &nbsp;&nbsp;
        <span class="mt-n3px switcher-label text-white font-size-0-96rem">
          {{ enabledMenu.title }}
        </span>
      </label>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!isMobile">
  <a
    *ngFor="let enabledMenu of enabledMenusList; let i = index"
    appHrefNone
    class="dropdown-item text-white d-inline-flex"
  >
    <label class="custom-control custom-checkbox custom-checkbox-secondary mb-0">
      <input
        [checked]="enabledMenu.checked"
        [value]="enabledMenu.key"
        type="checkbox"
        class="custom-control-input"
        (change)="checkedMenuChanged(enabledMenu)"
      />
      <span class="custom-control-label cursor-pointer"></span>
      &nbsp;&nbsp;
      <span class="switcher-label text-white font-size-0-96rem">
        {{ enabledMenu.title }}
      </span>
    </label>
  </a>
</ng-container>
