<div>
  <div class="form-row">
    <div class="form-group col">
      <select [(ngModel)]="dateDaySelected" class="custom-select" (ngModelChange)="setDateDay(dateDaySelected)">
        <option *ngFor="let dateDay of dateDays" [ngValue]="dateDay">
          <ng-container *ngIf="dateDay === dateToday">Today</ng-container>
          <ng-container *ngIf="dateDay !== dateToday">{{ dateDay | date:"E dd" }}</ng-container>
        </option>
      </select>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <select [(ngModel)]="dateTimeSelected" class="custom-select">
        <option *ngFor="let dateTime of dateTimeList" [ngValue]="dateTime">
          <ng-container *ngIf="dateTime === dateTimeNow">ASAP</ng-container>
          <ng-container *ngIf="dateTime !== dateTimeNow">
            {{ (dateTime | date:'h:mm a' | lowercase) }}
          </ng-container>
        </option>
      </select>
    </div>
  </div>
  <button type="submit" class="btn btn-primary btn-block" (click)="setPickUpTime()">
    Set Pickup Time
  </button>
</div>
