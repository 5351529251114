import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppComponent } from '../../app.component';
import { IsDevice } from '../../decorators/is-device.decorator';
import { DeviceType } from '../../models/enums/device-type';
import { ModalType } from '../../models/enums/modal-type';
import { SidenavType } from '../../models/enums/sidenav-type';
import { Modal } from '../../models/modal';
import { AuthenticationService } from '../authentication.service';
import { InstagramService } from '../instagram.service';
import { LayoutSidenavService } from '../layout-sidenav.service';
import { ModalService } from '../modal.service';
import { NavigatorService } from '../navigator.service';
import { RestaurantService } from '../restaurant.service';
import { RouterService } from '../router.service';

@Injectable({
  providedIn: 'root',
})
export class DecoratorService {
  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  private app: AppComponent;

  constructor(
    public authenticationService: AuthenticationService,
    public modalService: ModalService,
    public instagramService: InstagramService,
    public navigatorService: NavigatorService,
    public deviceDetectorService: DeviceDetectorService,
    public layoutSidenavService: LayoutSidenavService,
    public routerService: RouterService,
    public restaurantService: RestaurantService
  ) {
  }

  setAppComponent(app: AppComponent) {
    this.app = app;
  }

  authModal() {
    this.layoutSidenavService.sidenavIsMouseOverSubjects[SidenavType.RIGHT].next(false);

    return new Promise<void>(resolve => {
      this.modalService.open(
        this.app.loginModalRef,
        !this.isMobile ? ModalType.LOGIN : ModalType.LOGIN_MOBILE
      ).onClose().then(() => {
        if (this.authenticationService.currentUser) {
          resolve();
        }
      });
    });
  }

  subscribeModal() {
    return new Promise<void>(resolve => {
      let modal: Modal;

      if (!this.deviceDetectorService.isDesktop()) {
        modal = this.modalService.open(this.app.subscribeModalRef, ModalType.SUBSCRIBE);
      } else {
        modal = this.modalService.open(this.app.subscribeDesktopModal, ModalType.SUBSCRIBE_DESKTOP);
      }

      modal.onClose().then(() => {
        resolve();
      });
    });
  }
}
