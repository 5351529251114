import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { NGXLogger } from 'ngx-logger';
import { ModalClose } from '../../../../../../decorators/modal-close.decorator';
import { Instruction } from '../../../../../../domains/instruction';
import { InstructionService } from '../../../../../../services/instruction.service';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss']
})
export class InstructionsComponent implements OnInit {
  @Input() instruction: Instruction;

  @Output() changeInstruction = new EventEmitter<Instruction>();

  constructor(
    private instructionService: InstructionService,
    private logger: NGXLogger
  ) {
  }

  ngOnInit(): void {
  }

  onSaveClick() {
    if (!this.instruction.id) {
      this.instructionService
        .add(this.instruction)
        .subscribe({
          next: value => {
            this.instruction = plainToClass(Instruction, value);
            this.changeInstruction.emit(this.instruction);
            this.modalClose();
          }, error: error => {
            this.logger.error('On saving instruction', error);
          }
        });
    } else {
      this.instructionService
        .edit(this.instruction)
        .subscribe({
          next: () => {
            this.changeInstruction.emit(this.instruction);
            this.modalClose();
          }, error: error => {
            this.logger.error('On editing instruction', error);
          }
        });
    }
  }

  onDeleteClick() {
    this.instructionService
      .delete(this.instruction)
      .subscribe({
        next: () => {
          this.changeInstruction.emit(new Instruction(this.instruction.foodId));
          this.modalClose();
        }, error: error => {
          this.logger.error('On deleting instruction', error);
        }
      });
  }

  @ModalClose()
  private modalClose() {
  }
}
