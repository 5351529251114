import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ErrorResponseType } from '../models/enums/error-response-type';
import { AuthenticationService } from '../services/authentication.service';
import { NavigatorService } from '../services/navigator.service';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private navigatorService: NavigatorService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error.error.type === ErrorResponseType.UserNotFoundException) {
          this.authenticationService.logout();
          this.navigatorService.goToHome();
        }

        return throwError(error);
      })
    );
  }
}
