<div class="modal-body p-0">
  <input ngbAutofocus type="hidden"/>

  <ng-container *ngFor="let group of picker.optionGroups">
    <ng-container *ngFor="let option of group.options">
      <a
        appHrefNone
        class="btn btn-default md-btn-flat btn-block"
        (click)="selected.emit(option.value)"
      >
        {{ option.label }}
      </a>
    </ng-container>
  </ng-container>
</div>
