import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IsDevice } from '../../../../../../decorators/is-device.decorator';
import { Post } from '../../../../../../domains/post';
import { DeviceType } from '../../../../../../models/enums/device-type';

@Component({
  selector: 'app-classic-post-image',
  templateUrl: './classic-post-image.component.html',
  styleUrls: ['./classic-post-image.component.scss']
})
export class ClassicPostImageComponent implements OnInit {
  @Input() post: Post;
  @Input() postVoted: boolean;
  @Input() showIcon: boolean;

  @Output() viewClick = new EventEmitter<boolean>();

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

  toView() {
    this.viewClick.emit(true);
  }
}
