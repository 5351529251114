<div class="restaurant-divider-content d-flex flex-column">
  <div class="restaurant-divider-hours card border-radius-none mt-0 d-flex h-100">
    <div
      [appBackgroundUrl]="backgroundImage ? backgroundImage: ''"
      class="restaurant-divider-hours-background"
    >
      <div *ngIf="delayed" class="divider-modal-backdrop position-relative w-100 h-100 d-flex flex-column">
        <div class="container overflow-x text-center text-white opacity-1">
          <div class="mt-4rem section-title"> {{ typeCategorySection.title }} </div>

          <div class="mb-4rem mt-2 font-size-0.9rem font-weight-400"> {{ cityName }} </div>
        </div>

        <div class="m-auto"></div>

        <div class="container px-0 overflow-x bg-transparent">
          <div [ngClass]="{'flex-wrap-reverse': chains.length < 9}" class="row chain-row d-flex">
            <div *ngFor="let chain of chains" class="chain-box col-4">
              <app-div-rectangle>
                <div class="bg-chain chain-element flex-content-vc-hc cursor-pointer" (click)="onChainClick(chain)">
                  <button
                    type="button"
                    class="btn md-btn-flat text-white btn-block text-transform-none font-size-1rem"
                  >
                    {{ chain.shortTitle || chain.title }}
                  </button>
                </div>
              </app-div-rectangle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="divider-footer bg-white border-bottom-radius-3 p-3">
</div>
