import { RouteParams } from './route-params';

export class RestaurantRouteParams extends RouteParams {
  hostname: string = null;
  locationId = null;
  menuSlug: string = null;
  foodWithIdSlug: string = null;
  foodId: number = null;
  foodIsGallery = false;

  restaurantPath: string;
  foodPath: string = null;

  constructor() {
    super();
  }

  setFood(foodParam: string) {
    if (!this.menuSlug) {
      return this.restaurantPath;
    }

    this.foodWithIdSlug = foodParam;
    const foodParamSplit = foodParam.split('_');

    if (foodParamSplit.length > 1) {
      this.foodId = +foodParamSplit[1];
    }

    if (this.foodWithIdSlug === 'inside' || this.foodWithIdSlug === 'outside') {
      this.foodIsGallery = true;
    }

    this.foodPath = this.restaurantPath + '/' + this.menuSlug + '/' + this.foodWithIdSlug;
  }

  removeFood() {
    this.foodWithIdSlug = null;
    this.foodId = null;
    this.foodPath = null;
  }

  get hasMenu(): boolean {
    return !!this.menuSlug;
  }

  get hasFood(): boolean {
    return !!this.foodWithIdSlug && !!this.foodId;
  }
}
