import { plainToClass } from 'class-transformer';
import { Subject } from 'rxjs';
import { StateWithChild } from '../../domains/state-with-child';
import { Listener } from '../listener';

export class StateListener extends Listener {
  subject = new Subject<StateWithChild>();

  constructor() {
    super();
    this.topic = `/push/state`;
  }

  transform(frame: any): StateWithChild {
    return plainToClass(StateWithChild, JSON.parse(frame.body));
  }
}
