import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Extra } from '../domains/extra';
import { Ordering } from '../domains/ordering';
import { Section } from '../domains/section';

@Injectable({providedIn: 'root'})
export class ExtraService {
  private readonly extrasUrl: string;

  constructor(private http: HttpClient) {
    this.extrasUrl = `${environment.apiEndpoint}/api/extras`;
  }

  public addExtra(extra: Extra, section: Section) {
    return this.http.post<Extra>(`${this.extrasUrl}/${section.id}`, extra);
  }

  public editExtra(extra: Extra) {
    return this.http.put(`${this.extrasUrl}`, extra);
  }

  public deleteExtra(extra: Extra) {
    return this.http.delete(`${this.extrasUrl}/${extra.id}`);
  }

  public changeOrdering(extraOrderings: Array<Ordering>) {
    return this.http.put(`${this.extrasUrl}/change-ordering`, extraOrderings);
  }
}
