<div
  appAddClassIfMobile="classic-image-mobile"
  class="classic-image cursor-pointer"
  (click)="toView()"
>
  <app-div-rectangle *ngIf="!house">
    <div class="classic-image-container bg-cover-black">
      <div *ngIf="!favModal" ngPreserveWhitespaces class="classic-item-status z-index-3">
        <span *ngIf="food.status.isSoldOut" class="badge badge-danger text-uppercase">
            Sold out
        </span>

        <ng-container *ngFor="let tag of food.tags">
          <span
            *ngIf="!food.status.isSoldOut && tag.tagType == tagType.temp"
            class="badge badge-outline-white text-uppercase"
          >
            {{ tag.value }}
          </span>
        </ng-container>
      </div>

      <app-food-img
        *ngIf="!food.googleImage"
        [images]="food.images"
        [width]="720"
        [height]="720"
        [alt]="food.title"
        [foodGallery]="true"
      ></app-food-img>

      <div
        *ngIf="food.googleImage"
        [appBackgroundUrl]="food.images[0].path | googleImage: 1220"
        class="w-100 h-100 background-center-cover-no-repeat"
      ></div>

      <div *ngIf="!favModal" class="classic-item-text py-3 px-4">
        <div class="title-block">
          <span ngPreserveWhitespaces class="text-white">
            <ng-container *ngFor="let tag of food.tags">
              <ng-container *ngIf="tag.tagType == tagType.regular">
                <span
                  *ngIf="tag.value != 'spicy'"
                  class="badge badge-outline-white text-uppercase"
                >
                  {{ tag.value }}
                </span>

                <span *ngIf="tag.value == 'spicy'" class="badge badge-danger text-uppercase">
                  {{ tag.display }}
                </span>
              </ng-container>
            </ng-container>

            <div class="mr-2 float-left font-size-1-05rem">{{ food.title }}</div>
          </span>
        </div>

        <div class="classic-item-right">
          <span *ngIf="!food.gallery">{{ food.priceView }}</span>
        </div>
      </div>
    </div>
  </app-div-rectangle>

  <app-div-rectangle ratio="1.53" *ngIf="house">
    <div class="classic-image-container bg-cover-black">
      <app-food-img
        *ngIf="!food.googleImage"
        [images]="food.images"
        [width]="720"
        [height]="720"
        [alt]="food.title"
        [foodGallery]="true"
      ></app-food-img>

      <div
        *ngIf="food.googleImage"
        [appBackgroundUrl]="food.images[0].path | googleImage: 1220"
        class="w-100 h-100 background-center-cover-no-repeat"
      ></div>

      <div *ngIf="!favModal" class="classic-item-text py-3 px-4 d-flex">
        <div class="text-white float-left mr-2 font-size-1-05rem justify-content-start">
          {{ restaurant.nameView }}
        </div>

        <div
         *ngIf="!food.googleImage" class="icon-v2 picture-white ml-n0-3rem justify-content-end"
          (click)="galleryClicked(); $event.stopPropagation()"
        ></div>
      </div>
    </div>
  </app-div-rectangle>
</div>
