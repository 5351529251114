import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { classToPlain, plainToClass } from 'class-transformer';
import { of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Category } from '../domains/category';
import { Food } from '../domains/food';
import { Ordering } from '../domains/ordering';
import { Image } from '../models/image';

@Injectable({providedIn: 'root'})
export class FoodService {
  private readonly foodUrl: string;

  private foodsCache = new Map<number, Food>();

  constructor(private http: HttpClient) {
    this.foodUrl = `${environment.apiEndpoint}/api/foods`;
  }

  public getFood(foodId: number) {
    const cachedFood = this.foodsCache.get(foodId);

    if (cachedFood) {
      return of(cachedFood);
    }

    return this.http.get<Food>(`${this.foodUrl}/${foodId}`)
      .pipe(
        switchMap((food: Food) => {
          const processedFood = plainToClass(Food, food);

          this.foodsCache.set(foodId, processedFood);

          return of(processedFood);
        }),
        shareReplay(1)
      );
  }

  public addFood(food: Food, category: Category) {
    return this.http.post<Food>(`${this.foodUrl}/${category.id}`, classToPlain(food));
  }

  public editFood(food: Food) {
    this.clearFoodCache(food.id);

    return this.http.put(`${this.foodUrl}`, classToPlain(food));
  }

  public deleteFood(food: Food) {
    this.clearFoodCache(food.id);

    return this.http.delete(`${this.foodUrl}/${food.id}`);
  }

  public changeOrdering(foodOrderings: Array<Ordering>) {
    foodOrderings.forEach(foodOrdering => this.clearFoodCache(foodOrdering.id));

    return this.http.put(`${this.foodUrl}/change-ordering`, foodOrderings);
  }

  public updateFoodImage(food: Food) {
    this.clearFoodCache(food.id);

    return this.http
      .post<Image>(`${this.foodUrl}/${food.id}/image`, food.images[0])
      .pipe(map(value => plainToClass(Image, value)));
  }

  public deleteFoodImage(food: Food) {
    this.clearFoodCache(food.id);

    return this.http.delete(`${this.foodUrl}/${food.id}/image`);
  }

  getByQuery(query: string) {
    if (query === '') {
      return of([]);
    }

    return this.http.get<Food[]>(`${this.foodUrl}/autocomplete/${query}`)
      .pipe(map(foods => plainToClass(Food, foods)));
  }

  public setFoodIntoCache(food: Food): void {
    this.foodsCache.set(food.id, food);
  }

  public clearFoodCache(id: number) {
    this.foodsCache.delete(id);
  }
}
