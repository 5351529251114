import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { CurrentUser } from '../../../../decorators/current-user.decorator';
import { IgAccessToken } from '../../../../decorators/ig-access-token.decorator';
import { IsLoggedIn } from '../../../../decorators/is-logged-in.decorator';
import { User } from '../../../../domains/user';
import { LocalStorage } from '../../../../local-storage/local-storage';
import { ErrorResponseType } from '../../../../models/enums/error-response-type';
import { InstagramState } from '../../../../models/enums/instagram-state';
import { LocalStorageKey } from '../../../../models/enums/local-storage-key';
import { Image as ImageModel } from '../../../../models/image';
import { UnsplashImage } from '../../../../models/unsplash-image';
import { ImageService } from '../../../../services/image.service';
import { InstagramService } from '../../../../services/instagram.service';
import { LoaderService } from '../../../../services/loader.service';
import { UnsplashService } from '../../../../services/unsplash.service';

@Component({
  selector: 'app-image-select-modal',
  templateUrl: './image-select-modal.component.html',
  styleUrls: ['./image-select-modal.component.scss']
})
export class ImageSelectModalComponent implements OnInit {
  @Input() image: ImageModel;
  @Input() title: string;
  @Input() isInstagram = false;

  @Output() save = new EventEmitter<ImageModel>();

  @ViewChild('bottomElement') bottomElement: ElementRef;
  @ViewChild('fileInputCapture') fileInputCapture: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;

  @IsLoggedIn() isLoggedIn: boolean;
  @CurrentUser() currentUser: User;

  images: ImageModel[] = [];
  imagesNextUrl = '';
  imagesLoadError = false;
  igAccessToken: string;
  firstLoaded = false;
  loadClicked = false;
  instagramClicked = false;
  selectImageLocalStorage = new LocalStorage(UnsplashImage, LocalStorageKey.SELECT_IMAGE);
  backgroundImage: UnsplashImage;

  constructor(
    private instagramService: InstagramService,
    private logger: NGXLogger,
    private loaderService: LoaderService,
    private unsplashService: UnsplashService,
    private imageService: ImageService
  ) {
  }

  ngOnInit() {
    if (this.isLoggedIn) {
      this.igAccessToken = this.currentUser.igAccessToken;
    }

    this.backgroundImage = this.selectImageLocalStorage.getItem();

    if (!this.backgroundImage) {
      this.getNewImageSelectAndSaveToLocalStorage();
    }

    if (this.isInstagram) {
      this.instagramButtonClick();
    }
  }

  loadImages() {
    this.instagramService
      .loadImages(this.igAccessToken)
      .subscribe({
        next: imageList => {
          this.firstLoaded = true;

          imageList.images.forEach(image => this.images.push(image));
          this.imagesNextUrl = imageList.nextUrl;
        }, error: error => {
          if (error.error.error?.type === ErrorResponseType.OAuthException) {
            this.instagramService.disconnect().subscribe({
              error: err => {
                this.logger.error('On disconnecting from IG', err);
              }
            });
          } else {
            this.imagesLoadError = true;
          }

          this.logger.error('On getting instagram media', error);
        }
      });
  }

  loadImagesNext() {
    this.instagramService
      .loadImagesNext(this.imagesNextUrl)
      .subscribe(imageList => {
        imageList.images.forEach(image => this.images.push(image));
        this.imagesNextUrl = imageList.nextUrl;

        this.bottomElement.nativeElement.scrollIntoView({block: 'start', inline: 'nearest'});
      });
  }

  isSameImage(image: ImageModel) {
    if (!this.image) {
      return false;
    }

    return this.image.igId === image.igId;
  }

  onImageClick(image: ImageModel) {
    this.getNewImageSelectAndSaveToLocalStorage();

    if (this.isSameImage(image)) {
      this.save.emit(null);
    } else {
      this.image = image;
      this.save.emit(this.image);
    }
  }

  onBgImageClick() {
    if (!this.instagramClicked && this.backgroundImage) {
      this.camera();
    }
  }

  camera() {
    this.fileInputCapture.nativeElement.click();
  }

  library() {
    this.fileInput.nativeElement.click();
  }

  @IgAccessToken(InstagramState.CONNECT_ADMIN, true)
  instagramConnect() {
  }

  onImageUploaded(files: FileList) {
    this.loaderService.showLoader();
    this.getNewImageSelectAndSaveToLocalStorage();

    if (files.length === 0) {
      return false;
    }

    this.imageService.getUploadedAsImageCompressed(files[0]).then(image => {
      this.loaderService.hideLoader();
      this.save.emit(image);
    });
  }

  instagramButtonClick() {
    this.instagramClicked = true;

    if (this.igAccessToken) {
      if (this.firstLoaded) {
        this.loadImagesNext();
      } else {
        if (!this.loadClicked) {
          this.loadClicked = true;
          this.loadImages();
        }
      }
    } else {
      this.instagramConnect();
    }
  }

  private getNewImageSelectAndSaveToLocalStorage() {
    this.unsplashService.randomPhoto(true).subscribe(image => {
      this.backgroundImage = image;

      this.selectImageLocalStorage.setItem(this.backgroundImage);
    });
  }

  get windowWidth() {
    return window.innerWidth;
  }
}
