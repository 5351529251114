<ng-container *ngIf="isDesktop">
  <div class="input-group">
    <input
      #videoInput
      [ngClass]="{'video-input': submitClicked}"
      [placeholder]="inputPlaceholder"
      class="video-input form-control top-input-modal-input input-text-color input-text-color border-bottom-white"
      type="url"
      (input)="validateUrl($event)"
    >
  </div>

  <div class="text-right">
    <div class="card-body p-0 my-2 d-flex justify-content-between">
      <div class="text-white d-flex align-items-center">
        {{ textInput }}
      </div>

      <div>
        <button
          [ngClass]="{'gray-btn': !isUrlValid}"
          type="button"
          class="btn btn-primary md-btn-flat text-transform-none"
          (click)="onSubmitClick(videoInput.value)"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!isDesktop">
  <div class="card video-card">
    <div
      [ngClass]="{'my-2 align-items-center': restaurant, 'mt-3 justify-content-between': !restaurant}"
      class="card-body p-0 d-flex"
    >
      <div class="input-group">
        <input
          #videoInput
          [ngClass]="{'video-input': submitClicked}"
          class="video-input form-control top-input-modal-input"
          type="url"
          [placeholder]="
            !submitClicked ?
            (restaurant ? 'Paste a link from YouTube • TikTok • Rumble • etc' :
            'Link a video of a ' + (type == TourType.DINING ? 'dining' : 'walking') + ' tour')
             : 'URL required'"
          (input)="validateUrl($event)"
        >
      </div>

      <div *ngIf="!restaurant" class="pl-1_25rem text-white d-flex align-items-center" (click)="onSwitcherChange()">
        <label class="switcher switcher-secondary mb-5px">
          <input
            [checked]="type === TourType.WALKING"
            type="checkbox"
            class="switcher-input"
            (change)="onSwitcherChange()"
          />
          <span class="switcher-indicator"></span>
        </label>
      </div>
    </div>
  </div>

  <div class="card video-card bg-black border-radius-none">
    <div class="card-body p-0 my-2 d-flex justify-content-between">
      <div class="pl-1_25rem text-white d-flex align-items-center">
        {{
          restaurant ?
            'Featuring ' + restaurant.firstNameView :
            ('For ' + searchParams.locationName + ' ' + searchParams.stateKey.toUpperCase())
        }}
      </div>

      <div>
        <button
          [ngClass]="{'gray-btn': !isUrlValid}"
          type="button"
          class="btn btn-primary md-btn-flat text-transform-none"
          (click)="onSubmitClick(videoInput.value)"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</ng-container>
