<nav class="navbar navbar-top bg-white fixed-top max-width safe-area-top">
  <div class="navbar-top-left navbar-width-30px">
    <a
      *ngIf="!isEditMode && isLoggedIn && menuHours"
      appHrefNone
      class="btn btn-primary md-btn-flat pl-0 text-capitalize"
    >
      Menu
    </a>
  </div>

  <ng-container *ngIf="title">
    <span
      class="navbar-brand mr-0 navbar-title"
      (click)="titleClick.emit(true)"
    >
      {{ title }}
    </span>
  </ng-container>

  <div class="navbar-top-right navbar-width-30px">
    <a
      *ngIf="!isEditMode && isLoggedIn && menuHours"
      appHrefNone
      class="btn btn-primary md-btn-flat pl-0 text-capitalize"
    >
      Hours
    </a>
  </div>
</nav>
