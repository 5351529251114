import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Directive({
  selector: '[appRadioGroupNotRequired]'
})
export class RadioGroupNotRequiredDirective implements AfterViewInit {
  @Input() formGroup: UntypedFormGroup = null;

  @Output() checkedClick = new EventEmitter<boolean>();

  attributeName = 'data-was-checked';
  nativeElement: HTMLInputElement;

  constructor(element: ElementRef) {
    this.nativeElement = element.nativeElement;
  }

  ngAfterViewInit() {
    if (this.nativeElement.type === 'radio' && !this.formGroup) {
      if (this.nativeElement.checked) {
        this.nativeElement.setAttribute(this.attributeName, 'true');
      } else {
        this.nativeElement.setAttribute(this.attributeName, 'false');
      }
    }
  }

  @HostListener('click', ['$event'])
  onClick($event) {
    if (this.nativeElement.type === 'radio') {
      if (!this.formGroup) {
        if (this.nativeElement.getAttribute(this.attributeName) === 'true') {
          this.nativeElement.checked = false;
          this.nativeElement.setAttribute(this.attributeName, 'false');
        } else {
          this.nativeElement.setAttribute(this.attributeName, 'true');
        }

        const siblings = this.nativeElement.form.querySelectorAll(
          `input[type="radio"][name="${this.nativeElement.name}"]`
        );

        siblings.forEach(sibling => {
          if (sibling !== this.nativeElement) {
            sibling.setAttribute(this.attributeName, 'false');
          }
        });
      } else {
        const target = $event.target || $event.currentTarget;
        const formControlName = this.nativeElement.getAttribute('formControlName');
        const formControl = this.formGroup.controls[formControlName];

        if (formControl.value === target.value) {
          formControl.reset();

          this.checkedClick.emit(true);
        }
      }
    }
  }
}
