import { plainToClass } from 'class-transformer';
import { Subject } from 'rxjs';
import { Post } from '../../domains/post';
import { Listener } from '../listener';

export class FoodPostListener extends Listener {
  subject = new Subject<Post>();

  constructor(foodId: number) {
    super();
    this.topic = `/push/food-post/${foodId}`;
  }

  transform(frame: any): Post {
    return plainToClass(Post, JSON.parse(frame.body));
  }
}
