import { Component, Input, OnInit } from '@angular/core';
import { CurrentUser } from '../../../../../decorators/current-user.decorator';
import { IgUsernameSuggestion } from '../../../../../domains/ig-username-suggestion';
import { RestaurantSimple } from '../../../../../domains/restaurant-simple';
import { User } from '../../../../../domains/user';
import { IgUsernameSuggestionService } from '../../../../../services/ig-username-suggestion.service';
import { NavigatorService } from '../../../../../services/navigator.service';

@Component({
  selector: 'app-instagram-helper',
  templateUrl: 'instagram-helper.component.html',
  styleUrls: ['instagram-helper.component.scss']
})
export class InstagramHelperComponent implements OnInit {
  @Input() restaurant: RestaurantSimple;
  @Input() igUsernameSuggestion: IgUsernameSuggestion;

  @CurrentUser() currentUser: User;

  constructor(
    private navigatorService: NavigatorService,
    private igUsernameSuggestionService: IgUsernameSuggestionService
  ) {
  }

  ngOnInit(): void {
  }

  goToInstagram(username: string) {
    this.navigatorService.openInNewWindowInstagram(username);
  }

  cancel() {
    this.igUsernameSuggestionService.delete(this.igUsernameSuggestion)
      .subscribe(() => {
        this.igUsernameSuggestion = null;
      });
  }
}
