import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { UnsplashImage } from '../models/unsplash-image';
import { ImageUtils } from '../utils/image-utils';

@Injectable({providedIn: 'root'})
export class UnsplashService {
  private randomImages: Array<UnsplashImage> = [];
  private unsplashUrl: string;
  private calls = 1;

  constructor(private http: HttpClient) {
    this.unsplashUrl = `${environment.apiEndpoint}/api/unsplash`;
  }

  randomPhoto(isMobile = false): Observable<UnsplashImage> {
    if (this.randomImages.length > 0) {
      return of(this.randomImages[this.calls++ % this.randomImages.length]);
    } else {
      return this.http.get<UnsplashImage[]>(`${this.unsplashUrl}/random?desktop=${!isMobile}`).pipe(
        map(images => plainToClass(UnsplashImage, images)),
        tap(images => {
          this.randomImages = images;
          this.downloadImages().then();
        }),
        map(images => images[0])
      );
    }
  }

  private async downloadImages(): Promise<void> {
    for (const image of this.randomImages) {
      await ImageUtils.downloadImage(image.url + '&w=' + window.innerWidth + '&auto=format&auto=compress');
    }
  }
}
