import { Extra } from '../domains/extra';

export class Transformer {
  static reorderExtras(extras: Extra[]) {
    return extras.sort((extra1, extra2) => {
      if (extra1.ordering === extra2.ordering) {
        return 0;
      } else {
        return (extra1.ordering > extra2.ordering) ? 1 : -1;
      }
    });
  }
}
