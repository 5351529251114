import { Directive, ElementRef, HostListener } from '@angular/core';
import { ModalService } from '../services/modal.service';

@Directive({
  selector: '[appModalCloseOnClick]'
})
export class ModalCloseOnClickDirective {
  nativeElement: HTMLElement;

  constructor(element: ElementRef, private modalService: ModalService) {
    this.nativeElement = element.nativeElement;
  }

  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    const clickedElement = event.target;
    const modalClosePrevent = clickedElement.dataset.modalClosePrevent === 'true';
    const modalClosePreventParent = clickedElement.closest('[data-modal-close-prevent="true"]');
    const dropdownMenu = this.nativeElement.querySelector('.dropdown-menu.show');

    if (!modalClosePrevent && !modalClosePreventParent && !dropdownMenu) {
      const modal = this.modalService.getModalByElement(clickedElement.closest('ngb-modal-window'));

      if (modal) {
        modal.close();
      }
    }
  }
}
