import { Type } from 'class-transformer';
import { Favorite } from './favorite';
import { RestaurantSimple } from './restaurant-simple';

export class RestaurantFavorites {
  @Type(() => RestaurantSimple)
  restaurant: RestaurantSimple;

  @Type(() => Favorite)
  favorites: Favorite[];
}
