import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { classToPlain, plainToClass } from 'class-transformer';
import { NGXLogger } from 'ngx-logger';
import { Options } from 'sortablejs';
import { Category } from '../../../../domains/category';
import { Food } from '../../../../domains/food';
import { Menu } from '../../../../domains/menu';
import { Ordering } from '../../../../domains/ordering';
import { Section } from '../../../../domains/section';
import { DrinkType } from '../../../../models/enums/drink-type';
import { FoodStatus } from '../../../../models/enums/food-status';
import { ModalType } from '../../../../models/enums/modal-type';
import { SectionType } from '../../../../models/enums/section-type';
import { Image as ImageModel } from '../../../../models/image';
import { CategoryService } from '../../../../services/category.service';
import { FoodService } from '../../../../services/food.service';
import { ModalService } from '../../../../services/modal.service';

@Component({
  selector: 'app-category-edit',
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.scss']
})
export class CategoryEditComponent implements OnInit {
  @Input() activeMenu: Menu;
  @Input() category: Category;
  @Input() sizes: { [id: number]: Section } = {};
  @Input() options: { [id: number]: Section[] } = {};

  @ViewChild('additionsEditModalRef') additionsEditModalRef: ElementRef;

  categoryCurrent = new Category();
  foodCurrent = new Food();
  sectionCurrent: Section;
  sizeCurrent: Section;
  optionsCurrent: Section[];
  showWarningTags: boolean;
  foodStatus = FoodStatus;

  foodsSortableOptions: Options = {
    animation: 150,
    onUpdate: (event: any) => {
      this.foodsOrderingChange();
    }
  };

  constructor(
    private categoryService: CategoryService,
    private foodService: FoodService,
    private modalService: ModalService,
    private logger: NGXLogger,
  ) {
  }

  ngOnInit(): void {
  }

  onCategoryAvailabilityClick(category: Category, hidden: boolean) {
    category.hidden = hidden;
    this.onCategoryChange(category);
  }

  onCategoryChange(category: Category, food: Food = null) {
    if (category.id) {
      this.categoryService
        .editCategory(category)
        .subscribe({
          error: error => {
            this.logger.error('On editing category', error);
          }
        });
    } else {
      const categoryCloned = plainToClass(Category, classToPlain(category));
      categoryCloned.foods = categoryCloned.foods.filter(foodI => foodI.title);

      this.categoryService
        .addCategory(categoryCloned, this.activeMenu)
        .subscribe({
          next: addedCategory => {
            category.id = addedCategory.id;
            category.isInvalid = false;

            if (food) {
              const tempFood = addedCategory.foods.find(foodI => foodI.title === food.title);
              food.id = tempFood.id;
            }
          }, error: error => {
            this.logger.error('On adding category', error);
          }
        });
    }
  }

  deleteCategory(category: Category) {
    const categoryIndex = this.activeMenu.categories.findIndex(categoryItered =>
      categoryItered.id === category.id
    );

    if (categoryIndex > -1) {
      if (category.id) {
        this.categoryService
          .deleteCategory(category)
          .subscribe({
            next: () => {
              this.activeMenu.categories.splice(categoryIndex, 1);
            }, error: error => {
              this.logger.error('On deleting category', error);
            }
          });
      } else {
        this.activeMenu.categories.splice(categoryIndex, 1);
      }
    }
  }

  addFood(category: Category) {
    for (let i = 0; i < 3; i++) {
      category.addNewFood(true);
    }
  }

  onFoodAvailabilityClick(food: Food, foodStatus: FoodStatus, category: Category) {
    food.status.newValue = foodStatus;
    this.onFoodChange(food, category);
  }

  onFoodChange(food: Food, category: Category) {
    if (!category.id) {
      if (category.title) {
        this.onCategoryChange(category, food);
      } else {
        category.isInvalid = true;
      }
    } else {
      if (food.title) {
        if (food.id) {
          this.foodService
            .editFood(food)
            .subscribe({
              next: () => {
                food.isInvalid = false;
              }, error: error => {
                food.isInvalid = true;
                this.logger.error('On editing food', error);
              }
            });
        } else {
          food.ordering = null;

          this.foodService
            .addFood(food, category)
            .subscribe({
              next: addedFood => {
                this.options[addedFood.id] = [];
                food.id = addedFood.id;
                food.ordering = addedFood.ordering;
                food.isInvalid = false;
              }, error: error => {
                food.isInvalid = true;
                this.logger.error('On adding new food', error);
              }
            });
        }
      }
    }
  }

  deleteFood(food: Food, category: Category) {
    const foodIndex = category.foods.findIndex(foodI => foodI.id === food.id);

    if (foodIndex > -1) {
      if (food.id === undefined) {
        this.categoryService
          .editCategory(category)
          .subscribe({
            next: value => {
              category.foods.splice(foodIndex, 1);
            }, error: error => {
              this.logger.error('On adding category', error);
            }
          });
      } else {
        this.foodService
          .deleteFood(food)
          .subscribe({
            next: () => {
              category.foods.splice(foodIndex, 1);
            }, error: error => {
              this.logger.error('On deleting food', error);
            }
          });
      }
    }
  }

  private foodsOrderingChange() {
    const category = this.category;
    const orderArray: Array<number> = [];

    category.foods.forEach(food => {
      if (food.title) {
        orderArray.push(food.ordering);
      }
    });

    orderArray.sort((a, b) => a > b ? 1 : -1);
    const foodOrderings: Array<Ordering> = [];
    let j = 0;

    category.foods.forEach(food => {
      if (food.title) {
        const order = orderArray[j++];
        food.ordering = order;
        foodOrderings.push(new Ordering(food.id, order));
      }
    });

    this.foodService
      .changeOrdering(foodOrderings)
      .subscribe({
        next: () => {
          this.logger.debug('Successfully updated food orderings');
        }, error: error => {
          this.logger.error('On changing food orderings', error);
        }
      });
  }

  updateFoodImage(food: Food, image: ImageModel) {
    if (image != null) {
      if (food.images?.length === 0) {

        food.images.push(image);
      } else {
        food.images[0] = image;
      }

      this.foodService
        .updateFoodImage(food)
        .subscribe(value => {
          food.images[0] = value;
        });
    } else {
      this.foodService
        .deleteFoodImage(food)
        .subscribe(_ => {
          food.images = new Array<ImageModel>();
        });
    }
  }

  onPriceChange(event, food: Food, category: Category) {
    food.price = event.target.value;
    this.onFoodChange(food, category);
  }

  openOptionsModal(category: Category, food: Food) {
    this.foodCurrent = food;
    this.categoryCurrent = category;
    this.showWarningTags = category.drinkType !== DrinkType.Cocktails;

    if (this.sizes[food.id] === undefined) {
      this.sizes[food.id] = food.sectionAdd(SectionType.SIZE);
    }

    for (let i = 0; i < 3; i++) {
      if (this.options[food.id][i] === undefined) {
        this.options[food.id][i] = food.sectionAdd(SectionType.OPTION);
      }
    }

    this.sizeCurrent = this.sizes[food.id];
    this.optionsCurrent = this.options[food.id];

    this.modalService.open(this.additionsEditModalRef, ModalType.ADDITIONS_EDIT);
  }

  onTagSave() {
    this.onFoodChange(this.foodCurrent, this.categoryCurrent);
  }

  drag(event: any) {
    const tooltip = event.target.querySelector('ngb-tooltip-window');

    if (tooltip && !tooltip.classList.contains('d-none')) {
      tooltip.classList.add('d-none');
    }
  }

  hasAllergens(food) {
    return food.tags.find(tag => tag.value === 'allergens') !== undefined;
  }

  isImageExists(food): boolean {
    return food.images && food.images.length > 0 && !!food.images[0].path && !food.images[0].isPost;
  }

  deleteImg(food) {
    this.foodService.deleteFoodImage(food)
      .subscribe({
        next: () => {
          food.images = [];
        }, error: error => {
          this.logger.error('On deleting image', error);
        }
      });
  }
}
