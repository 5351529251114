import { Component, OnInit } from '@angular/core';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { IsLoggedIn } from '../../../decorators/is-logged-in.decorator';
import { Restaurant } from '../../../domains/restaurant';
import { DeviceType } from '../../../models/enums/device-type';
import { Picker } from '../../../models/picker';
import { RestaurantService } from '../../../services/restaurant.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: []
})
export class DashboardComponent implements OnInit {
  @IsDevice(DeviceType.MOBILE) isMobile: boolean;
  @IsLoggedIn() isLoggedIn: boolean;

  userRestaurant: Restaurant;
  userOptionsPicker: Picker;
  restaurantAdminPath: string;
  isReady = false;

  constructor(
    private restaurantService: RestaurantService,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.isReady = true;
    });

    if (this.isLoggedIn) {
      this.restaurantService.getUserTopRestaurant().subscribe(restaurant => {
        restaurant.periodsGroupAndSort();

        this.userRestaurant = restaurant;
        this.userOptionsPicker = this.userService.getPickerOptions(!!restaurant);

        if (restaurant) {
          this.restaurantAdminPath = `/admin/${restaurant.hostname}`;
        }
      });
    }
  }
}
