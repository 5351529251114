<table class="main-table table table-borderless card-table">
  <thead>
    <tr>
      <th class="cell-availability text-center">
        <div class="cell-height w-100 flex-content-vc-hc">
          <div
            ngbDropdown
            appNgbDropdownOnHover
            [placement]="'bottom-left'"
            class="d-inline-flex cursor-pointer"
          >
            <div
              ngbDropdownToggle
              class="availability-dropdown-toggle flex-content-vc-hc hide-arrow"
            >
              <span *ngIf="!category.hidden" class="icon-v2 eye"></span>
              <span *ngIf="category.hidden" class="icon-v2 eye-hidden"></span>
            </div>

            <div
              ngbDropdownMenu
              class="availability-dropdown dropdown-menu-left"
            >
              <a
                appHrefNone
                class="dropdown-item d-inline-flex"
                (click)="onCategoryAvailabilityClick(category, false)"
              >
                <span class="icon-v2 eye"></span>
                <span class="mx-2">Available</span>
                <span *ngIf="!category.hidden" class="icon-v2 checkmark"></span>
              </a>
              <a
                appHrefNone
                class="dropdown-item d-inline-flex"
                (click)="onCategoryAvailabilityClick(category, true)"
              >
                <span class="icon-v2 eye-hidden"></span>
                <span class="mx-2">Hidden</span>
                <span *ngIf="category.hidden" class="icon-v2 checkmark"></span>
              </a>

              <ng-container *ngIf="category.foods | foodsEmpty">
                <div class="dropdown-divider"></div>
                <a
                  appHrefNone
                  class="dropdown-item d-inline-flex text-danger"
                  (click)="deleteCategory(category)"
                >
                  <span class="empty-icon"></span>
                  <span class="mx-2">Delete</span>
                </a>
              </ng-container>
            </div>
          </div>
        </div>
      </th>

      <th class="cell-image drag-me">
        <div class="cell-height w-100 flex-content-vc-hc">
          <a
            ngbTooltip="Click & Drag"
            placement="left"
            class="icon-v2 arrange category-sortable"
          ></a>
        </div>
      </th>

      <th class="cell-price">
        <div class="cell-height w-100 d-flex align-items-center">
          <span class="font-weight-normal">Category</span>
        </div>
      </th>

      <th class="cell-title">
        <input
          [(ngModel)]="category.title"
          [placeholder]="category.drinkType ? category.drinkType : 'Category name'"
          [ngClass]="{'is-invalid': category.isInvalid}"
          type="text"
          class="form-control"
          (change)="onCategoryChange(category)"
        />
        <small class="invalid-feedback">Required</small>
      </th>

      <th class="">
        <input
          [(ngModel)]="category.description"
          type="text"
          name="description"
          class="form-control"
          placeholder="Category notes"
          (change)="onCategoryChange(category)"
        />
      </th>

      <th class="cell-options">
        <div class="cell-height w-100 flex-content-vc-hc">
          <span class="font-weight-normal">Allergens</span>
        </div>
      </th>

      <th class="cell-options">
        <div class="cell-height w-100 flex-content-vc-hc">
          <span class="font-weight-normal">Options</span>
        </div>
      </th>

      <th class="cell-drag">
        <div class="cell-height w-100 flex-content-vc-hc">
          Arrange
        </div>
      </th>
    </tr>
  </thead>
  <tbody
    *ngIf="!category.hidden"
    [sortablejs]="category.foods"
    [sortablejsOptions]="foodsSortableOptions"
  >
  <ng-container *ngFor="let food of category.foods; let index = index">
    <tr [id]="food.id" (drag)="drag($event)">
      <td class="cell-availability">
        <div class="cell-height w-100 flex-content-vc-hc">
          <div
            ngbDropdown
            appNgbDropdownOnHover
            [placement]="'bottom-left'"
            class="d-inline-flex cursor-pointer"
          >
            <div
              ngbDropdownToggle
              class="availability-dropdown-toggle flex-content-vc-hc hide-arrow"
            >
              <span *ngIf="food.status.isAvailable" class="icon-v2 eye"></span>
              <span *ngIf="food.status.isSoldOut" class="icon-v2 sold-out"></span>
              <span *ngIf="food.status.isHidden" class="icon-v2 eye-hidden"></span>
            </div>

            <div
              ngbDropdownMenu
              class="availability-dropdown dropdown-menu-left"
            >
              <a
                appHrefNone
                class="dropdown-item d-inline-flex"
                (click)="onFoodAvailabilityClick(food, foodStatus.AVAILABLE, category)"
              >
                <span class="icon-v2 eye"></span>
                <span class="mx-2">Available</span>
                <span *ngIf="food.status.isAvailable" class="icon-v2 checkmark"></span>
              </a>
              <a
                appHrefNone
                class="dropdown-item d-inline-flex"
                (click)="onFoodAvailabilityClick(food, foodStatus.SOLD_OUT, category)"
              >
                <span class="icon-v2 sold-out"></span>
                <span class="mx-2">Sold Out</span>
                <span *ngIf="food.status.isSoldOut" class="icon-v2 checkmark"></span>
              </a>
              <a
                appHrefNone
                class="dropdown-item d-inline-flex"
                (click)="onFoodAvailabilityClick(food, foodStatus.HIDDEN, category)"
              >
                <span class="icon-v2 eye-hidden"></span>
                <span class="mx-2">Hidden</span>
                <span *ngIf="food.status.isHidden" class="icon-v2 checkmark"></span>
              </a>
              <div class="dropdown-divider"></div>
              <a
                appHrefNone
                class="dropdown-item d-inline-flex text-danger"
                (click)="deleteFood(food, category)"
              >
                <span class="empty-icon"></span>
                <span class="mx-2">Delete</span>
              </a>
            </div>
          </div>
        </div>
      </td>
      <td class="cell-image">
        <div class="food-image-content cursor-pointer">
          <app-div-rectangle>
            <app-image-picker
              [isAdmin]="true"
              [enableImageSelectModal]="true"
              [imageSelectModalTitle]="food.title"
              [existImage]="food.images[0]"
              [showDeleteOption]="isImageExists(food)"
              [deleteEmitObject]="food"
              accept="gif, jpeg, png"
              (save)="updateFoodImage(food, $event)"
              (delete)="deleteImg($event)"
            >
              <div *ngIf="isImageExists(food)" class="food-img">
                <app-food-img
                  [images]="food.images"
                  [width]="36"
                  [height]="36"
                  [fromAdmin]="true"
                  [alt]="food.title"
                ></app-food-img>
              </div>
            </app-image-picker>
          </app-div-rectangle>
        </div>
      </td>

      <td class="cell-price">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>

          <input
            [ngModel]="food.price | number: '.2-2'"
            [min]="0"
            type="number"
            class="form-control"
            inputmode="numeric"
            pattern="[0-9]*"
            (change)="onPriceChange($event, food, category)"
          />
        </div>
      </td>

      <td class="cell-title">
        <input
          [(ngModel)]="food.title"
          [ngClass]="{'is-invalid': food.isInvalid}"
          type="text"
          class="form-control"
          placeholder="Item name"
          (change)="onFoodChange(food, category)"
        />
        <small class="invalid-feedback">Duplicate item</small>
      </td>

      <td>
        <input
          [(ngModel)]="food.description"
          type="text"
          name="description"
          class="form-control"
          placeholder="Description"
          (change)="onFoodChange(food, category)"
        />
      </td>

      <td class="cell-options">
        <div class="cell-height w-100 flex-content-vc-hc">
          <button
            *ngIf="food.title"
            [disabled]="!food.id"
            class="btn-options btn btn-dark md-btn-flat"
            (click)="openOptionsModal(category, food)"
          >
            <div
              class="icon-v2"
              [ngClass]="{
                'checkmark-black': hasAllergens(food),
                'checkmark-red' : !hasAllergens(food)
              }"
            ></div>
          </button>
        </div>
      </td>

      <td class="cell-options">
        <div class="cell-height w-100 flex-content-vc-hc">
          <button
            *ngIf="food.title"
            [disabled]="!food.id"
            class="btn-options btn btn-dark md-btn-flat"
            (click)="openOptionsModal(category, food)"
          >
            <span class="icon-v2 dots-h"></span>
          </button>
        </div>
      </td>

      <td class="cell-drag drag-me">
        <div class="cell-height w-100 flex-content-vc-hc">
          <a
            ngbTooltip="Click & Drag"
            placement="left"
            class="icon-v2 arrange"
          ></a>
        </div>
      </td>
    </tr>
  </ng-container>
  </tbody>
</table>

<table class="main-table table table-borderless card-table">
  <tbody>
  <tr>
    <td colspan="2">
      <button
        *ngIf="!category.hidden"
        type="button"
        class="btn-more btn btn-secondary md-btn-flat"
        (click)="addFood(category)"
      >
        More items
      </button>
    </td>
    <td class="cell-image"></td>
    <td class="cell-price"></td>
    <td class="cell-title"></td>
    <td></td>
    <td class="cell-options"></td>
    <td class="cell-drag drag-me"></td>
  </tr>
  </tbody>
</table>

<ng-template #additionsEditModalRef let-c="close">
  <app-additions-edit-modal
    [food]="foodCurrent"
    [section]="sectionCurrent"
    [tags]="categoryCurrent.tags"
    [showWarnings]="showWarningTags"
    [size]="sizeCurrent"
    [options]="optionsCurrent"
    (save)="onTagSave()"
  ></app-additions-edit-modal>
</ng-template>
