import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Order } from '../../../domains/order/order';

@Component({
  selector: 'app-receipt-modal',
  templateUrl: './receipt-modal.component.html',
  styleUrls: ['./receipt-modal.component.scss']
})
export class ReceiptModalComponent implements OnInit, AfterViewInit {
  @Input() order: Order;

  @ViewChild('bottomElement') bottomElement: ElementRef;

  detailsView = false;
  showHash = false;
  emailLogo: string;

  constructor() {
    this.emailLogo = environment.emailLogo;
  }

  ngOnInit(): void {
    this.detailsView = !this.order.accepted;
    this.scrollToBottom();
  }

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));

    setTimeout(() => {
      this.showHash = true;
    }, 300);
  }

  private scrollToBottom() {
    setTimeout(() => {
      this.bottomElement.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
    }, 100);
  }

  onSwitcherChange() {
    this.scrollToBottom();
  }
}
