import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Auth } from '../../../decorators/auth.decorator';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { IsLoggedIn } from '../../../decorators/is-logged-in.decorator';
import { User } from '../../../domains/user';
import { DeviceType } from '../../../models/enums/device-type';
import { ModalType } from '../../../models/enums/modal-type';
import { Image as ImageModel } from '../../../models/image';
import { Modal } from '../../../models/modal';
import { ImageService } from '../../../services/image.service';
import { LoaderService } from '../../../services/loader.service';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styles: []
})

export class ImagePickerComponent<T> implements OnInit {
  @Input() accept = '*';
  @Input() isCompressDisabled = true;
  @Input() imageSelectModalTitle: string;
  @Input() existImage: ImageModel;
  @Input() showDeleteOption = false;
  @Input() deleteEmitObject: T;
  @Input() enableImageSelectModal = false;
  @Input() itemGallery = false;
  @Input() postImageIcon = false;
  @Input() isAdmin = false;

  @Output() save = new EventEmitter<ImageModel>();
  @Output() delete = new EventEmitter<T>();

  @ViewChild('imageSelectModalRef') imageSelectModalRef: ElementRef;
  @ViewChild('commonImageInput') commonImageInput: ElementRef;
  @ViewChild('content') content: ElementRef;
  @ViewChild('dropdown') ngbDropdown: NgbDropdown;

  @HostBinding('attr.class') cssClasses = 'flex-content-vc-hc w-100 h-100';

  @IsLoggedIn() isLoggedIn: boolean;

  @CurrentUser() currentUser: User;

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  fileAccept = 'image/*';
  igAccessToken: string;
  imageSelectModal: Modal;

  constructor(
    private modalService: ModalService,
    private loaderService: LoaderService,
    private imageService: ImageService,
  ) {
  }

  ngOnInit(): void {
    if (this.accept !== '*') {
      this.fileAccept = this.accept.split(',').map(type => 'image/' + type.trim()).join(',');
    }

    if (this.isLoggedIn) {
      this.igAccessToken = this.enableImageSelectModal && this.currentUser.igAccessToken;
    }
  }

  @Auth()
  openImageSelectModal() {
    this.imageSelectModal = this.modalService.open(this.imageSelectModalRef, ModalType.IMAGE_SELECT);
  }

  onImageUpload(files: FileList) {
    if (files.length === 0) {
      return false;
    }

    this.loaderService.showLoader();

    if (this.isCompressDisabled) {
      this.imageService.getUploadedAsImage(files[0]).then(image => {
        this.loaderService.hideLoader();
        this.save.emit(image);
      });
    } else {
      this.imageService.getUploadedAsImageCompressed(files[0]).then(image => {
        this.loaderService.hideLoader();
        this.save.emit(image);
      });
    }
  }

  @Auth()
  clickCommonImageInput() {
    this.commonImageInput.nativeElement.click();
  }

  onInstagramImageSelect($event) {
    this.imageSelectModal.close();
    this.save.emit($event);
  }

  onNgContentClicked() {
    if (this.igAccessToken) {
      this.ngbDropdown.open();
    } else {
      this.commonImageInput.nativeElement.click();
    }
  }
}
