export class PickerOption {
  value: string;
  label: string;
  returnObject: any;

  constructor(value: string, label: string, returnObject: any = null) {
    this.value = value;
    this.label = label;
    this.returnObject = returnObject;
  }
}
