<div class="classic">
  <div class="row">
    <textarea
      [(ngModel)]="instruction.text"
      class="w-100 border-0 instruction"
      placeholder="Preparation request (no mayo)"
    ></textarea>
  </div>
</div>
<div class="options d-flex">
  <div class="flex-grow-1">
    <button class="btn btn-primary cursor-pointer text-uppercase btn-block" (click)="onSaveClick()">
      Save instructions
    </button>
  </div>
  <div class="">
    <button
      *ngIf="instruction.id"
      class="delete-btn ion ion-md-trash btn btn-dark cursor-pointer"
      (click)="onDeleteClick()"
    ></button>
  </div>
</div>
