import { SocialUser } from '@abacritt/angularx-social-login';
import { Role } from '../models';
import { UserGender } from '../models/enums/user-gender';
import { Restaurant } from './restaurant';

export class User {
  id: number;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  socialId: string;
  socialToken: string;
  profileImage: string;
  igId?: string;
  igUsername?: string;
  password: string;
  role: Role;
  phone: string;
  confirmed: boolean;
  bio: string;
  gender: UserGender;
  birthday: number;
  igExpireIn: Date;

  hostnames: string[];
  igAccessToken: string;
  getstreamToken: string;
  followersCount: number;
  followingCount: number;
  likesCount: number;
  socialUser: SocialUser;
  unverifiedEmail: string;

  isAdmin(restaurant: Restaurant): boolean {
    return this.id === restaurant?.userId;
  }
}
