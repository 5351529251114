import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Food } from '../../../domains/food';
import { Restaurant } from '../../../domains/restaurant';
import { RestaurantSimple } from '../../../domains/restaurant-simple';
import { FavoriteStatus } from '../../../models/enums/favorite-status';

@Component({
  selector: 'app-favorite-food-image',
  templateUrl: './favorite-food-image.component.html',
  styleUrls: ['./favorite-food-image.component.scss']
})
export class FavoriteFoodImageComponent {
  @Input() food: Food;
  @Input() restaurant: Restaurant | RestaurantSimple;

  @Output() desktopRestaurantNameClick = new EventEmitter<boolean>();

  imageHover = false;
  grayscaleAdd = false;

  onDesktopRestaurantClick(event: any) {
    this.desktopRestaurantNameClick.emit(event);
  }

  onFavoriteStatusChanged(value: FavoriteStatus) {
    this.grayscaleAdd = FavoriteStatus.EATEN === value;
  }
}
