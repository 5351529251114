import { Expose, Transform } from 'class-transformer';

export class GeoLocation {
  @Expose()
  @Transform(value => +value, {toClassOnly: true})
  latitude: number;

  @Expose()
  @Transform(value => +value, {toClassOnly: true})
  longitude: number;

  constructor(latitude: number, longitude: number) {
    this.latitude = latitude;
    this.longitude = longitude;
  }
}
