import { Type } from 'class-transformer';
import { GlobalBaseLocation } from '../models/global-base-location';
import { CountyWithChild } from './county-with-child';

export class StateWithChild extends GlobalBaseLocation {
  readonly wikimediaUrl = 'https://upload.wikimedia.org/wikipedia/commons/thumb';

  @Type(() => CountyWithChild)
  counties: CountyWithChild[] = [];

  flag: string;
  seal: string;
  countiesLoaded: boolean;

  get flagUrl() {
    return this.flag ? `${this.wikimediaUrl}/${this.flag.replace('{width}', '500')}` : null;
  }

  get sealUrl() {
    return this.seal ? `${this.wikimediaUrl}/${this.seal.replace('{width}', '340')}` : null;
  }
}
