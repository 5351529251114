import { Component, Input, OnInit } from '@angular/core';
import { Chain } from '../../../../domains/chain';
import { TypeCategorySection } from '../../../../models/type-category-section';
import { UnsplashImage } from '../../../../models/unsplash-image';
import { ChainService } from '../../../../services/chain.service';
import { NavigatorService } from '../../../../services/navigator.service';
import { UnsplashService } from '../../../../services/unsplash.service';

@Component({
  selector: 'app-divider-mobile-modal',
  templateUrl: './divider-mobile-modal.component.html',
  styleUrls: ['./divider-mobile-modal.component.scss']
})
export class DividerMobileModalComponent implements OnInit {
  @Input() typeCategorySection: TypeCategorySection;
  @Input() cityName: string;

  chains: Chain[] = [];
  delayed = false;
  backgroundImage: UnsplashImage;

  constructor(
    private unsplashService: UnsplashService,
    private navigatorService: NavigatorService,
    private chainService: ChainService
  ) {
  }

  ngOnInit(): void {
    this.unsplashService.randomPhoto(true).subscribe(value => {
      this.backgroundImage = value;
    });

    setTimeout(() => {
      this.delayed = true;
    }, 50);

    if (!this.typeCategorySection.isExample) {
      this.chainService.getAllByTypeCategorySection(this.typeCategorySection).subscribe(chains => {
        this.chains = chains;
      });
    }
  }

  onChainClick(chain: Chain) {
    this.navigatorService.goToMaps(chain.title + ', ' + this.cityName + ', CA');
  }
}
