import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Instruction } from '../domains/instruction';

@Injectable({providedIn: 'root'})
export class InstructionService {
  private readonly instructionUrl: string;

  constructor(private http: HttpClient) {
    this.instructionUrl = `${environment.apiEndpoint}/api/instructions`;
  }

  public getByFoodIds(foodIds: number[]) {
    return this.http.get<Array<Instruction>>(`${this.instructionUrl}/foods/${foodIds.join(',')}`);
  }

  public getByFoodId(foodId: number) {
    return this.http.get<Instruction>(`${this.instructionUrl}/food/${foodId}`);
  }

  public add(instruction: Instruction) {
    return this.http.post<Instruction>(`${this.instructionUrl}`, instruction);
  }

  public edit(instruction: Instruction) {
    return this.http.put(`${this.instructionUrl}`, instruction);
  }

  public delete(instruction: Instruction) {
    return this.http.delete(`${this.instructionUrl}/${instruction.id}`);
  }
}
