import { ActivatedRouteSnapshot } from '@angular/router';

export class RouteHelper {
  static getRouteSnapshotLastChild(routeSnapshot: ActivatedRouteSnapshot) {
    if (!routeSnapshot.firstChild) {
      return routeSnapshot;
    }

    return this.getRouteSnapshotLastChild(routeSnapshot.firstChild);
  }
}
