import { Directive, HostListener } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Directive({
  selector: '[appNgSelectOnHover]'
})
export class NgSelectOnHoverDirective {
  constructor(private ngSelectComponent: NgSelectComponent) {
  }

  @HostListener('mouseover')
  public onMouseOver(): void {
    if (!this.ngSelectComponent.isOpen) {
      this.ngSelectComponent.open();
    }
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    if (this.ngSelectComponent.isOpen) {
      this.ngSelectComponent.close();
    }
  }
}
