import { Extra } from '../../domains/extra';
import { Section } from '../../domains/section';

export class SectionManagerBase {
  min: number;
  max: number;
  extras: Extra[];
  selected: string[];
  checked: {};

  constructor(section: Section) {
    this.min = section.min;
    this.max = section.max;
    this.extras = section.extras;
    this.selected = section.selected;
    this.checked = section.checked;
  }

  extrasQuantity(): number {
    return this.extras.reduce((sum, extra) => sum + extra.quantity, 0);
  }
}
