import { Injectable } from '@angular/core';
import { Food } from '../domains/food';
import { Post } from '../domains/post';
import { Image } from '../models/image';

@Injectable({providedIn: 'root'})
// TODO - Make it universal and reusable (instead of state)
export class PostDataService {
  post: Post;
  food: Food;
  image: Image;
}
