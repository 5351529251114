import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Auth } from '../../../decorators/auth.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { IsLoggedIn } from '../../../decorators/is-logged-in.decorator';
import { IsSidenavMouseOver } from '../../../decorators/is-sidenav-mouse-over.decorator';
import { Subscribe } from '../../../decorators/subscribe.decorator';
import { Restaurant } from '../../../domains/restaurant';
import { RestaurantSimple } from '../../../domains/restaurant-simple';
import { StripeSubscription } from '../../../domains/stripe-subscription';
import { User } from '../../../domains/user';
import { LocalStorage } from '../../../local-storage/local-storage';
import { DeviceType } from '../../../models/enums/device-type';
import { LocalStorageKey } from '../../../models/enums/local-storage-key';
import { ModalType } from '../../../models/enums/modal-type';
import { SidenavType } from '../../../models/enums/sidenav-type';
import { Picker } from '../../../models/picker';
import { SearchParams } from '../../../models/search-params';
import { UnsplashImage } from '../../../models/unsplash-image';
import { UnsplashUser } from '../../../models/unsplash-user';
import { AuthenticationService } from '../../../services/authentication.service';
import { LayoutSidenavService } from '../../../services/layout-sidenav.service';
import { LayoutService } from '../../../services/layout.service';
import { ModalService } from '../../../services/modal.service';
import { NavigatorService } from '../../../services/navigator.service';
import { RestaurantService } from '../../../services/restaurant.service';
import { RouterService } from '../../../services/router.service';
import { UserService } from '../../../services/user.service';
import { ExploreComponent } from '../../explore/explore.component';
import { RestaurantComponent } from '../../restaurant/restaurant.component';

@Component({
  selector: 'app-layout-sidenav-left',
  templateUrl: './layout-sidenav-left.component.html',
  styleUrls: ['./layout-sidenav-left.component.scss']
})
export class LayoutSidenavLeftComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() orientation = 'vertical';
  @Input() admin = false;
  @Input() hasReferrer = false;
  @Input() user: User;
  @Input() iconHover = false;

  @ViewChild('addRestaurantModalRef') addRestaurantModalRef: ElementRef;
  @ViewChild('hoursEditModalRef') hoursEditModalRef: ElementRef;

  @HostBinding('class.layout-sidenav') hostClassVertical = false;
  @HostBinding('class.layout-sidenav-horizontal') hostClassHorizontal = false;
  @HostBinding('class.flex-grow-0') hostClassFlex = false;

  @IsSidenavMouseOver([SidenavType.LEFT]) isSidenavRightMouseOver: boolean;

  @IsLoggedIn() isLoggedIn: boolean;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  restaurant: RestaurantSimple;
  active: Array<RestaurantSimple> = [];
  sidenavHovered = false;
  logoMouseOver = false;
  restaurantPath: string;
  restaurantAdminPath: string;
  restaurantHostname: string;
  subscription: StripeSubscription;
  backgroundImageLocalStorage: LocalStorage<UnsplashImage>;
  unsplashUser: UnsplashUser;
  searchParamsLocalStorage = new LocalStorage(SearchParams, LocalStorageKey.SEARCH_PARAMS);
  restaurantSubscription: Subscription;
  restaurantLocationSearchPath = '/';
  previousActivatedRouteSnapshot: ActivatedRouteSnapshot;
  userRestaurant: Restaurant;
  userOptionsPicker: Picker;
  isRestaurantPage: boolean;
  isUserPage: boolean;

  constructor(
    private router: Router,
    private layoutService: LayoutService,
    private layoutSidenavService: LayoutSidenavService,
    private authenticationService: AuthenticationService,
    private restaurantService: RestaurantService,
    private navigatorService: NavigatorService,
    private routerService: RouterService,
    private modalService: ModalService,
    private userService: UserService
  ) {
    // Set host classes
    this.hostClassVertical = this.orientation !== 'horizontal';
    this.hostClassHorizontal = !this.hostClassVertical;
    this.hostClassFlex = this.hostClassHorizontal;

    setTimeout(() => {
      this.backgroundImageLocalStorage = new LocalStorage(
        UnsplashImage,
        this.isMobile ?
          LocalStorageKey.BACKGROUND_IMAGE_MOBILE :
          LocalStorageKey.BACKGROUND_IMAGE
      );

      if (this.backgroundImageLocalStorage.getItem()) {
        this.unsplashUser = this.backgroundImageLocalStorage.getItem().user;
      }
    }, 200);
  }

  ngOnInit() {
    if (this.isLoggedIn) {
      this.restaurantService.getUserTopRestaurant().subscribe(restaurant => {
        restaurant.periodsGroupAndSort();

        this.userRestaurant = restaurant;
        this.userOptionsPicker = this.userService.getPickerOptions(!!restaurant);

        if (restaurant) {
          this.restaurantAdminPath = `/admin/${restaurant.hostname}`;
        }
      });
    }

    this.routerService.restaurantRouteParamsSubject.subscribe((params) => {
      this.isRestaurantPage = !!params;
    });

    this.routerService.userRouteParamsSubject.subscribe((params) => {
      this.isUserPage = !!params;
    });

    this.restaurantSubscription = this.routerService.restaurantSubject.subscribe(restaurant => {
      if (restaurant) {
        this.restaurantLocationSearchPath = restaurant.explorePath;
      }
    });

    const html = document.querySelector('html');

    if (html.classList.contains('layout-sidenav-hover')) {
      this.sidenavHovered = true;
      this.leave();
    }

    this.setRestaurants();

    this.routerService.activatedRouteSnapshotSubject.subscribe(routeSnapshot => {
      if (
        this.previousActivatedRouteSnapshot?.component === RestaurantComponent &&
        routeSnapshot.component === ExploreComponent
      ) {
        this.layoutSidenavService.sidenavIsMouseOverSubjects[SidenavType.RIGHT].next(false);
      }

      this.previousActivatedRouteSnapshot = routeSnapshot;
    });
  }

  ngOnDestroy() {
    this.restaurantSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    // Safari bugfix
    this.layoutService._redrawLayoutSidenav();
  }

  isActive(url) {
    return this.router.isActive(url, true);
  }

  isActiveAdmin(url) {
    if (url === 'menu-table') {
      return location.pathname.indexOf(url) !== -1;
    }

    return this.router.isActive(`/${this.restaurantAdminPath}/${url}`, true);
  }

  isAdmin() {
    return location.href.indexOf('admin') !== -1;
  }

  hover() {
    this.sidenavHovered = true;
    const html = document.querySelector('html');

    html.classList.add('layout-sidenav-hover');
    this.layoutSidenavService.sidenavIsMouseOverSubjects[SidenavType.RIGHT].next(true);
  }

  leave() {
    const html = document.querySelector('html');

    this.sidenavHovered = false;
    html.classList.remove('layout-sidenav-hover');
    this.layoutSidenavService.sidenavIsMouseOverSubjects[SidenavType.RIGHT].next(false);
  }

  @Auth()
  login() {
    this.isLoggedIn = true;

    this.setRestaurants();
  }

  logout() {
    this.authenticationService.logout();
    this.restaurantPath = null;
    this.restaurantAdminPath = null;
    this.navigatorService.goToHome();
  }

  private setRestaurants() {
    if (this.isLoggedIn) {
      this.restaurantHostname = this.routerService.restaurantHostname;

      this.restaurantService
        .getCurrentUserRestaurantSimples()
        .subscribe(restaurants => {
          if (restaurants.length > 0) {
            this.restaurant = restaurants[0];
            this.restaurantHostname = this.restaurant.hostname;

            if (!this.restaurantPath) {
              this.restaurantPath = `/${this.restaurant.hostname}`;
            }

            if (!this.restaurantAdminPath) {
              this.restaurantAdminPath = `/admin/${this.restaurant.hostname}`;
            }
          }
        });

      this.restaurantService
        .getCsRestaurants()
        .subscribe(restaurants => {
          this.active = restaurants.filter(restaurant => !restaurant.isOwned);
        });
    }
  }

  @Auth()
  addRestaurantDesktop() {
    this.modalService.open(this.addRestaurantModalRef, ModalType.RESTAURANT_ADD_DESKTOP);
  }

  @Auth()
  @Subscribe()
  openMbershipModal() {
  }

  @Auth()
  goToUser() {
    this.navigatorService.goToUser();
  }
}
