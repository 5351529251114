<div
  appAddClassIfMobile="classic-image-mobile"
  id="classic-post-image-{{post.id}}"
  class="classic-image cursor-pointer"
  (click)="toView()"
>
  <app-div-rectangle>
    <div class="classic-image-container bg-cover-black">
      <app-food-img
        [images]="post.images"
        [width]="720"
        [height]="720"
        [alt]="post.caption"
      ></app-food-img>

      <div *ngIf="!isDesktop && postVoted" class="classic-image-icon d-flex flex-column align-items-center">
        <div [ngClass]="{'animate__animated animate__fadeInUp': showIcon}" class="best-picture-container">
          <i class="icon-v2 best-picture scale-2_1"></i>
        </div>
      </div>
    </div>
  </app-div-rectangle>
</div>
