<form autocomplete="off">
  <div class="row m-0 p-0 d-flex justify-content-between">
    <div
      [ngClass]="{'bg-container': inputHover, 'border-bottom-19px': !leftInputHover}"
      class="search-container"
      (mouseover)="leftInputHover = true"
      (mouseleave)="leftInputHover = false"
    >
      <i
        *ngIf="!leftInputValue && inputHover"
        class="icon-v2 search-black-new ml-3 scale-0-7 position-absolute top-17px"
      ></i>

      <input
        *appIfIsDesktop
        [ngbTypeahead]="leftInputHover ? searchRest : null"
        [placement]="'bottom-left'"
        [popupClass]="'search-city-popup search-city-popup-desktop'"
        [focusFirst]="false"
        [editable]="false"
        [ngClass]="inputClass"
        type="text"
        class="form-control form-control-input input-placeholder input-placeholder-hidden"
        id="restaurant-input"
        autocomplete="new-search-city"
        placeholder="        Restaurant name"
        (focus)="onFocusInput()"
        (mouseenter)="onHoverInput()"
        (input)="onLeftInputClick($event)"
        [resultTemplate]="rt2"
      >

      <ng-template #rt2 let-r="result" let-t="term">
        <div class="d-flex align-items-center" [class.disabled]="r.disabled"></div>
      </ng-template>
    </div>

    <div
      [ngClass]="{'bg-container': inputHover, 'border-bottom-19px': !rightInputHover}"
      class="search-container"
      (mouseover)="rightInputHover = true"
      (mouseleave)="rightInputHover = false"
    >
      <i
        *ngIf="!rightInputValue && inputHover"
        class="icon-v2 search-black-new ml-3 scale-0-7 position-absolute top-17px"
      ></i>

      <input
        *appIfIsDesktop
        [ngbTypeahead]="rightInputHover ? searchCity : null"
        [placement]="'bottom-left'"
        [popupClass]="'search-city-popup search-city-popup-desktop'"
        [focusFirst]="false"
        [editable]="false"
        [inputFormatter]="googleMapsService.formatter"
        [resultFormatter]="googleMapsService.formatter"
        [ngClass]="inputClass"
        type="text"
        class="form-control form-control-input input-placeholder input-placeholder-hidden"
        id="search-input"
        autocomplete="new-search-city"
        placeholder="        City or Address"
        (selectItem)="selectItem($event)"
        (input)="onRightInputClick($event)"
        (focus)="onFocusInput()"
        [resultTemplate]="rt"
        (mouseenter)="onHoverInput()"
      >

      <ng-template #rt let-r="result" let-t="term">
        <div
          class="d-flex align-items-center"
          [class.disabled]="r.disabled"
          [ngClass]="{'find-me-item': r.description === 'Find me'}"
        >
          <i *ngIf="r.icon" [class]="r.icon"></i>

          <ngb-highlight [result]="r.description" [term]="t"></ngb-highlight>
        </div>
      </ng-template>
    </div>
  </div>

  <input
    *appIfIsMobile
    [ngbTypeahead]="searchCityMobile"
    [placement]="'bottom-left'"
    [popupClass]="'search-city-popup search-city-popup-mobile'"
    [focusFirst]="false"
    [editable]="false"
    [inputFormatter]="googleMapsService.formatter"
    [resultFormatter]="googleMapsService.formatter"
    [ngClass]="inputClass"
    type="text"
    class="form-control"
    placeholder="       City or Address"
    autocomplete="new-search-city"
    (selectItem)="selectItemMobile($event)"
  >
</form>
