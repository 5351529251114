import { DecoratorInjectorService } from '../services/decorator/decorator-injector.service';
import { DecoratorService } from '../services/decorator/decorator.service';

export function Auth(): MethodDecorator {
  return (target: object, key: string, descriptor: any) => {
    const originalMethod = descriptor.value;

    // Weird: this is undefined if I use arrow function here but in subscribe.decorator.ts it works well
    descriptor.value = function (...args: any[]) {
      const service: DecoratorService = DecoratorInjectorService.getService();

      if (service.authenticationService.isLoggedIn()) {
        return originalMethod.apply(this, args);
      } else {
        service.authModal().then(() => {
          return originalMethod.apply(this, args);
        });
      }
    };

    return descriptor;
  };
}
