import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IsDevice } from '../../../../../../decorators/is-device.decorator';
import { Restaurant } from '../../../../../../domains/restaurant';
import { DeviceType } from '../../../../../../models/enums/device-type';
import { MenuType } from '../../../../../../models/enums/menu-type';
import { RestaurantService } from '../../../../../../services/restaurant.service';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: [
    './menu-list.component.scss'
  ]
})
export class MenuListComponent implements OnInit {
  @Input() restaurant: Restaurant;

  @Output() enabledMenusSave = new EventEmitter<MenuType[]>();

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  readonly menuTypeTitles = [
    {key: MenuType.BREAKFAST, title: 'Breakfast'},
    {key: MenuType.ALL_DAY, title: 'All Day'}, // only on front
    {key: MenuType.LUNCH, title: 'Lunch'},
    {key: MenuType.HAPPY_HOUR, title: 'Happy Hour'},
    {key: MenuType.DINNER, title: 'Dinner'},
    {key: MenuType.BRUNCH, title: 'Brunch'},
    {key: MenuType.KIDS, title: 'Kids'},
    {key: MenuType.DESSERT, title: 'Dessert'},
    {key: MenuType.LATE_NIGHT, title: 'Late Night'},

    {key: MenuType.ALE, title: 'Ale'},
    {key: MenuType.LAGER, title: 'Lager'},

    {key: MenuType.RED, title: 'Red'},
    {key: MenuType.WHITE, title: 'White'},
    {key: MenuType.ROSE, title: 'Rose'},
    {key: MenuType.SPARKLING, title: 'Sparkling'},

    {key: MenuType.DONUTS, title: 'Donuts'},
    {key: MenuType.BAKERY, title: 'Bakery'},
    {key: MenuType.BAGELS, title: 'Bagels'},

    {key: MenuType.BITES, title: 'Bites'},
    {key: MenuType.DRINKS, title: 'Drinks'},
    {key: MenuType.BEER_WINE, title: 'Beer & Wine'},

    {key: MenuType.HOUSE, title: 'House'}
  ];

  enabledMenus: MenuType[] = [];
  enabledMenusList: { key: MenuType, title: string, checked: boolean }[] = [];

  constructor(private restaurantService: RestaurantService) {
  }

  ngOnInit(): void {
    this.restaurant.menuTypes.forEach(menuType => {
      const menuTypeTitle = this.menuTypeTitles.find(menu => menu.key === menuType);

      this.enabledMenusList.push({key: menuType, title: menuTypeTitle.title, checked: false});
    });

    this.enabledMenus = this.restaurant.enabledMenuTypes;

    if (this.enabledMenus.length === 0) {
      this.enabledMenusList.find(menu => menu.key === MenuType.ALL_DAY).checked = true;

      this.saveEnabledMenuTypes();
    } else {
      this.enabledMenus.forEach(menu => {
        this.enabledMenusList.find(menuList => menuList.key === menu).checked = true;
      });
    }
  }

  checkedMenuChanged(enabledMenu: { key: MenuType, title: string, checked: boolean }) {
    enabledMenu.checked = !enabledMenu.checked;

    if (enabledMenu.key === MenuType.ALL_DAY) {
      this.enabledMenusList.forEach(menu => {
        if ([MenuType.LUNCH, MenuType.DINNER].includes(menu.key)) {
          menu.checked = false;
        }
      });
    } else if ([MenuType.LUNCH, MenuType.DINNER].includes(enabledMenu.key)) {
      this.enabledMenusList.find(menu => menu.key === MenuType.ALL_DAY).checked = false;
    }

    this.saveEnabledMenuTypes();
  }

  private saveEnabledMenuTypes() {
    const enabledMenusNew = this.enabledMenusList
      .filter(menu => menu.checked)
      .map(menu => menu.key);

    this.restaurantService.setEnabledMenuTypes(enabledMenusNew)
      .subscribe(value => {
        this.enabledMenusSave.emit(enabledMenusNew);
      });
  }
}
