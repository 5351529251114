import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { CurrentNavigationExtra } from '../../../../decorators/current-navigation-extra.decorator';
import { BackgroundImageService } from '../../../../services/background-image.service';
import { LayoutService } from '../../../../services/layout.service';

@Component({
  selector: 'app-layout-admin',
  templateUrl: './layout-admin.component.html',
  styleUrls: ['./layout-admin.component.scss']
})
export class LayoutAdminComponent implements OnInit {
  // @todo ask Leo where to navigate
  @CurrentNavigationExtra('referrerUrl') referrerUrl = '/';

  hasReferrer = false;

  constructor(
    private layoutService: LayoutService,
    private router: Router,
    private backgroundImageService: BackgroundImageService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.setBackground(event.url);
      }
    });

    this.setBackground(this.router.url);
  }

  ngOnInit() {
    if (this.referrerUrl !== '/') {
      this.hasReferrer = true;
    }

    this.layoutService.removeCollapsedAddFixed();
  }

  closeSidenav() {
    this.layoutService.setCollapsed(true);
  }

  private setBackground(url: string) {
    if (url.indexOf('menu-table#') !== -1) {
      this.backgroundImageService.setAdminBackgroundImageForDesktop();
    } else {
      this.backgroundImageService.setCurrentBackgroundImageForDesktop();
    }
  }
}
