import { ElementRef, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { classToClass } from 'class-transformer';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject } from 'rxjs';
import { ModalClass } from '../models/enums/modal-class';
import { ModalCloseReasons } from '../models/enums/modal-close-reasons';
import { ModalType } from '../models/enums/modal-type';
import { Modal } from '../models/modal';
import { ModalOptions } from '../models/modal-options';
import { DomUtils } from '../utils/dom-utils';

@Injectable({providedIn: 'root'})
export class ModalService {
  activeModals: Modal[] = [];

  modalIsOpenSubjects: { [modalType: string]: BehaviorSubject<boolean> } = {};
  modalTypesSettings: { [modalType: string]: { modalClasses?: ModalClass[], modalOptions?: ModalOptions } } = {};

  private isDesktop: boolean;

  constructor(
    private ngbModal: NgbModal,
    private deviceDetectorService: DeviceDetectorService
  ) {
    this.isDesktop = this.deviceDetectorService.isDesktop();

    for (const modalType in ModalType) {
      this.modalIsOpenSubjects[modalType] = new BehaviorSubject<boolean>(false);
    }

    // Templates
    this.modalTypesSettings[ModalType.BASE] = {modalClasses: [ModalClass.RESTAURANT_EDIT]};

    this.modalTypesSettings[ModalType.BASE_ABOUT] = {
      modalClasses: [ModalClass.FULL_WIDTH_ABOUT]
    };

    this.modalTypesSettings[ModalType.TOP] = {
      modalClasses: [ModalClass.TOP, ModalClass.FULL_WIDTH]
    };

    this.modalTypesSettings[ModalType.TOP_SEARCH] = {
      modalClasses: [ModalClass.TOP_SEARCH, ModalClass.FULL_WIDTH]
    };

    this.modalTypesSettings[ModalType.TOP_ROUNDED] = {
      modalClasses: [ModalClass.TOP, ModalClass.FULL_WIDTH, ModalClass.TOP_ROUNDED]
    };

    this.modalTypesSettings[ModalType.TOP_SHADOW_NONE] = {
      modalClasses: [ModalClass.TOP, ModalClass.TOP_ROUNDED, ModalClass.FULL_WIDTH, ModalClass.SHADOW_NONE]
    };

    this.modalTypesSettings[ModalType.FULL_TRANSPARENT] = {
      modalClasses: [
        ModalClass.SLIDE_IN_BCK_CENTER,
        ModalClass.TRANSPARENT,
        ModalClass.SHADOW_NONE,
        ModalClass.FULL_WIDTH,
        ModalClass.H_100,
        ModalClass.OVERFLOW_Y_HIDDEN
      ]
    };

    this.modalTypesSettings[ModalType.GRAY_TRANSPARENT] = {
      modalClasses: [
        ModalClass.SLIDE_IN_BCK_CENTER,
        ModalClass.BLUR_LIGHT_GREY,
        ModalClass.SHADOW_NONE,
        ModalClass.FULL_WIDTH,
        ModalClass.H_100,
        ModalClass.OVERFLOW_Y_HIDDEN
      ]
    };

    this.modalTypesSettings[ModalType.BOTTOM_SWIPE] = {
      modalClasses: [
        ModalClass.BOTTOM,
        ModalClass.BOTTOM_ROUNDED,
        ModalClass.SHADOW_NONE,
        ModalClass.FULL_WIDTH,
        ModalClass.TOP_AUTO
      ]
    };

    this.modalTypesSettings[ModalType.CENTER] = {
      modalClasses: [ModalClass.CENTER]
    };

    this.modalTypesSettings[ModalType.CENTER_NARROWED] = {
      modalClasses: [ModalClass.CENTER_NARROWED]
    };

    this.modalTypesSettings[ModalType.CENTER_SQUARE] = {
      modalClasses: [ModalClass.CENTER_SQUARE]
    };

    this.modalTypesSettings[ModalType.FAVORITE_SQUARE] = {
      modalClasses: [ModalClass.FAVORITE_SQUARE],

      modalOptions: {
        windowClass: 'modal-restaurant-desktop explore',
        backdropClass: 'modal-restaurant-desktop-backdrop'
      }
    };

    this.modalTypesSettings[ModalType.VIDEO_DESKTOP] = {
      modalClasses: [ModalClass.VIDEO_DESKTOP],

      modalOptions: {
        windowClass: 'modal-restaurant-desktop explore',
        backdropClass: 'modal-restaurant-desktop-backdrop'
      }
    };

    this.modalTypesSettings[ModalType.POST_SQUARE] = {
      modalClasses: [ModalClass.POST_SQUARE],

      modalOptions: {
        backdropClass: 'modal-post'
      }
    };

    this.modalTypesSettings[ModalType.RESTAURANT_SQUARE] = {
      modalClasses: [ModalClass.RESTAURANT_SQUARE],

      modalOptions: { backdropClass: 'modal-restaurant-desktop explore modal-restaurant-desktop-backdrop' }
    };

    this.modalTypesSettings[ModalType.SEARCH_SQUARE] = {
      modalClasses: [ModalClass.SEARCH_SQUARE],

      modalOptions: {
        backdropClass: 'modal-search-desktop-backdrop'
      }
    };

    this.modalTypesSettings[ModalType.LOGIN] = {
      modalClasses: [
        ModalClass.SHADOW_NONE,
        ModalClass.FULL_WIDTH,
        ModalClass.NO_ANIMATION,
        ModalClass.BLUR_LIGHT,
        ModalClass.LOGIN
      ],
      modalOptions: {
        windowClass: 'modal-login-desktop',
      }
    };

    this.modalTypesSettings[ModalType.RESTAURANT_ADD_DESKTOP] = {
      modalClasses: [
        ModalClass.SHADOW_NONE,
        ModalClass.FULL_WIDTH,
        ModalClass.NO_ANIMATION,
        ModalClass.BLUR_LIGHT,
        ModalClass.LOGIN
      ],
      modalOptions: {
        windowClass: 'modal-login-desktop',
      }
    };

    this.modalTypesSettings[ModalType.LOGIN_MOBILE] = {
      modalClasses: [ModalClass.TOP, ModalClass.PREVIEW, ModalClass.SWIPER]
    };

    this.modalTypesSettings[ModalType.SUBSCRIBE] = {
      modalClasses: [ModalClass.TOP, ModalClass.TOP_ROUNDED, ModalClass.FULL_WIDTH, ModalClass.BLUR_DARK]
    };

    this.modalTypesSettings[ModalType.SUBSCRIBE_DESKTOP] = {
      modalClasses: [
        ModalClass.SHADOW_NONE,
        ModalClass.FULL_WIDTH,
        ModalClass.NO_ANIMATION,
        ModalClass.BLUR_LIGHT

      ],
      modalOptions: {windowClass: 'modal-subscribe-desktop'}
    };

    this.modalTypesSettings[ModalType.LOGIN_HELPER] = {
      modalClasses: [
        ModalClass.SHADOW_NONE,
        ModalClass.FULL_WIDTH,
        ModalClass.NO_ANIMATION,
        ModalClass.LOGIN
      ],
      modalOptions: {windowClass: 'login-helper'}
    };

    this.modalTypesSettings[ModalType.RESTAURANT] = {
      modalClasses: [ModalClass.SHADOW_NONE, ModalClass.FULL_WIDTH, ModalClass.NO_ANIMATION],
      modalOptions: {windowClass: 'modal-restaurant-desktop explore'}
    };

    this.modalTypesSettings[ModalType.PASSWORD_DESKTOP] = {
      modalClasses: [],
      modalOptions: {windowClass: 'modal-password-desktop'}
    };

    this.modalTypesSettings[ModalType.PREVIEW] = {
      modalClasses: [ModalClass.TOP, ModalClass.PREVIEW]
    };

    this.modalTypesSettings[ModalType.PREVIEW_SWIPER] = {
      modalClasses: [ModalClass.TOP, ModalClass.PREVIEW, ModalClass.SWIPER]
    };

    this.modalTypesSettings[ModalType.FAVORITE_SWIPER] = {
      modalClasses: [ModalClass.TOP, ModalClass.PREVIEW, ModalClass.SWIPER_FAV]
    };

    this.modalTypesSettings[ModalType.SEARCH_FILTERS] = {
      modalClasses: [ModalClass.SLIDE],
      modalOptions: {windowClass: 'search-filters-modal'}
    };

    this.modalTypesSettings[ModalType.MEMBERSHIP] = {
      modalClasses: [ModalClass.SLIDE, ModalClass.WIDTH_80, ModalClass.BLUR_DARK],
      modalOptions: {windowClass: 'modal-backdrop-light'}
    };

    this.modalTypesSettings[ModalType.CS_ADDITIONS] = {
      modalClasses: [ModalClass.SLIDE, ModalClass.WIDTH_80, ModalClass.BLUR_GREY, ModalClass.HAS_FIXED]
    };

    this.modalTypesSettings[ModalType.CS_MENU_LIST] = {
      modalClasses: [ModalClass.SLIDE, ModalClass.WIDTH_35, ModalClass.BLUR_GREY, ModalClass.HAS_FIXED]
    };

    this.modalTypesSettings[ModalType.HOURS_EDIT] = {
      modalClasses: [
        ModalClass.TOP,
        ModalClass.TOP_ROUNDED,
        ModalClass.FULL_WIDTH,
        ModalClass.SHADOW_NONE,
        ModalClass.BLUR_DARK
      ]
    };

    this.modalTypesSettings[ModalType.RESTAURANT_ADD] = {
      modalClasses: [
        ModalClass.TOP,
        ModalClass.TOP_ROUNDED,
        ModalClass.FULL_WIDTH,
        ModalClass.SHADOW_NONE,
        ModalClass.GRADIENT_BLACK_GRAY
      ]
    };

    this.modalTypesSettings[ModalType.LOCATION_ADD_REMOVE] = {
      modalClasses: [ModalClass.SM]
    };

    if (this.isDesktop) {
      this.modalTypesSettings[ModalType.IMAGE_SELECT] = {
        modalClasses: [ModalClass.SM, ModalClass.TOP_ROUNDED]
      };
    } else {
      this.modalTypesSettings[ModalType.IMAGE_SELECT] = this.modalTypesSettings[ModalType.TOP_ROUNDED];
    }

    this.modalTypesSettings[ModalType.USERNAME_EXISTS] = this.modalTypesSettings[ModalType.FULL_TRANSPARENT];

    this.modalTypesSettings[ModalType.ALLERGENS] = {
      modalClasses: [ModalClass.SLIDE, ModalClass.WIDTH_80, ModalClass.BLUR_DARK, ModalClass.DARKER]
    };

    this.modalTypesSettings[ModalType.ADDITIONS_EDIT] = {
      modalClasses: [ModalClass.LG],
      modalOptions: {windowClass: 'modal-additions'}
    };

    this.modalTypesSettings[ModalType.PICKUP] = {
      modalClasses: [ModalClass.CENTER, ModalClass.BORDER_RADIUS_0_125]
    };

    this.modalTypesSettings[ModalType.ORDER] = {
      modalClasses: [
        ModalClass.TOP,
        ModalClass.TOP_ROUNDED,
        ModalClass.FULL_WIDTH,
        ModalClass.SHADOW_NONE,
        ModalClass.TRANSPARENT
      ]
    };
  }

  open(content: ElementRef, modalType: ModalType) {
    this.modalIsOpenSubjects[modalType].next(true);

    const modalTypeSettings = this.modalTypesSettings[modalType];

    const windowClasses = modalTypeSettings.modalClasses || [];
    let options = modalTypeSettings.modalOptions || {};

    options = classToClass(options);

    let windowClassList: string[] = [];
    let backdropClassList: string[] = [];

    windowClassList = windowClassList.concat(windowClasses);

    if (options.hasOwnProperty('windowClass')) {
      const optionsWindowClasses = options.windowClass.split(' ');

      windowClassList = windowClassList.concat(optionsWindowClasses);
    }

    if (options.hasOwnProperty('backdropClass')) {
      const optionsBackdropClasses = options.backdropClass.split(' ');

      backdropClassList = backdropClassList.concat(optionsBackdropClasses);
    }

    const modalId = `${Date.now()}`;
    const modalWindowClass = `modal-window-${modalId}`;
    const modalBackdropClass = `modal-backdrop-${modalId}`;

    windowClassList.push(modalWindowClass);
    backdropClassList.push(modalBackdropClass);
    history.pushState({modalId}, null);

    if (windowClassList.length > 0) {
      options.windowClass = windowClassList.join(' ');
    }

    if (backdropClassList.length > 0) {
      options.backdropClass = backdropClassList.join(' ');
    }

    const modal = new Modal(this.ngbModal.open(content, options));

    modal.id = modalId;
    modal.type = modalType;

    const htmlElement = document.querySelector('html');
    htmlElement.classList.add('modal-open');

    DomUtils.querySelectorAsync(`.${modalWindowClass}`).then(modalWindow => {
      if (modalWindow) {
        modalWindow.id = modalWindowClass;
        modalWindow.classList.remove(modalWindowClass);
        modal.window = modalWindow;
        modal.window.setAttribute('data-type', modalType);

        if (options.hasOwnProperty('backdrop') && !options.backdrop) {
          modalWindow.addEventListener('click', event => {
            const element: HTMLInputElement = (event.target || event.currentTarget) as HTMLInputElement;

            if (element.id === modalWindow.id) {
              modal.close(ModalCloseReasons.BACKDROP);
            }
          });
        }
      }
    });

    DomUtils.querySelectorAsync(`.${modalBackdropClass}`).then(modalBackdrop => {
      if (modalBackdrop) {
        modalBackdrop.id = modalBackdropClass;
        modalBackdrop.classList.remove(modalBackdropClass);
        modal.backdrop = modalBackdrop;

        if (windowClassList.includes(ModalClass.TOP_AUTO)) {
          modal.backdrop.addEventListener('click', () => {
            modal.close();
          });
        }
      }
    });

    if (options.hasOwnProperty('timeout')) {
      const timeout = options.timeout;

      setTimeout(() => {
        modal.close(ModalCloseReasons.TIMEOUT);
      }, timeout);
    }

    this.activeModals.push(modal);

    modal.onClose().then(reason => {
      const index = this.activeModals.indexOf(modal);

      if (index > -1) {
        this.activeModals.splice(index, 1);
      }

      htmlElement.classList.remove('modal-open');

      if (reason === undefined && history.state.modalId === modal.id) {
        history.back();
      }
    });

    modal.onClose().then(() => {
      this.modalIsOpenSubjects[modalType].next(false);
    });

    return modal;
  }

  closeAll() {
    this.ngbModal.dismissAll();
  }

  closeByType(modalType: ModalType) {
    if (this.activeModals.length > 0) {
      const modals = this.activeModals.filter(modalI => modalI.type === modalType);

      modals.forEach(modal => {
        modal.close();
      });
    }
  }

  hasOpenModal(): boolean {
    return this.activeModals.length > 0;
  }

  getModalByElement(modalWindow: HTMLElement): Modal {
    if (!modalWindow) {
      return null;
    }

    const modalId = modalWindow.id.replace('modal-window-', '');

    return this.activeModals.find(modalI => modalI.id === modalId);
  }
}
