import { TimeUtils } from '../../utils/time-utils';

export class Time {
  hour = 0;
  minute = 0;

  constructor(hour = 0, minute = 0) {
    this.hour = hour;
    this.minute = minute;
  }

  clear() {
    this.hour = null;
    this.minute = null;
  }

  toString(short = false) {
    return this ? TimeUtils.timeToString(this.hour, this.minute, short) : '';
  }

  firstToString() {
    return this ? TimeUtils.timeFirstToString(this.hour, this.minute) : '';
  }
}
