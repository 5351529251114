import React, {useState} from 'react';
import Cropper from 'react-easy-crop';
import {Point} from 'react-easy-crop/types';

const ImageCropper = (props: any) => {
  const [crop, setCrop] = useState<Point>({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);

  const width = props.width;
  const outerWidth = width * props.ratio;
  const margin = -1 * (outerWidth - width) / 2;

  return (
    <div className="ImageCropperWrap" style={{width: props.width + 'px', height: props.width + 'px'}}>
      <div
        className="ImageCropper"
        style={{
          width: outerWidth + 'px',
          height: outerWidth + 'px',
          marginTop: margin + 'px',
          marginLeft: margin + 'px'
        }}
      >
        <div className="ImageCropperContainer">
          <Cropper
            image={props.imageData}
            crop={crop}
            zoom={zoom}
            rotation={props.rotation}
            aspect={1}
            showGrid={props.showGrid}
            onCropChange={setCrop}
            onCropComplete={props.onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageCropper;
