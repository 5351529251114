import { Type } from 'class-transformer';
import { GlobalBaseLocation } from '../../models/global-base-location';
import { RegionWithParent } from './region-with-parent';
import { State } from './state';

export class CountyWithParent extends GlobalBaseLocation {
  @Type(() => State)
  state: State;

  @Type(() => RegionWithParent)
  region: RegionWithParent;

  slug: string;
}
