import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: []
})
export class PlansComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
