import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appSelectWidthByText]'
})
export class SelectWidthByTextDirective implements OnInit, AfterViewInit, OnChanges {
  @Input() appSelectWidthByText: boolean;
  @Input() andTitle: string;

  element: HTMLSelectElement;
  computedStyle: CSSStyleDeclaration;
  innerTextLast: string;

  constructor(element: ElementRef) {
    this.element = element.nativeElement;
    this.computedStyle = getComputedStyle(this.element);
  }

  ngOnInit() {
    this.element.addEventListener('change', () => {
      this.setWidth();
    });
  }

  ngAfterViewInit() {
    const innerTextFirst = this.getInnerText();
    this.setWidth(innerTextFirst);

    const interval = setInterval(() => {
      const innerText = this.getInnerText();

      if (this.innerTextLast !== innerText) {
        this.setWidth(innerText);

        clearInterval(interval);
      }
    }, 10);

    setTimeout(() => {
      clearInterval(interval);
    }, 2000);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.andTitle && changes.andTitle.currentValue) {
      this.setWidth(this.andTitle);
    }
  }

  private getInnerText() {
    return this.element.options[this.element.selectedIndex].innerText.trim();
  }

  private setWidth(innerText: string = null) {
    if (!this.appSelectWidthByText) {
      return;
    }

    if (!innerText) {
      innerText = this.getInnerText();
    }

    this.innerTextLast = innerText;

    const elementTemp = document.createElement('span');
    elementTemp.innerText = innerText;
    elementTemp.style.fontSize = this.computedStyle.fontSize;
    elementTemp.style.fontFamily = this.computedStyle.fontFamily;
    elementTemp.style.fontWeight = this.computedStyle.fontWeight;
    elementTemp.style.visibility = 'hidden';

    document.querySelector('body').append(elementTemp);

    this.element.style.setProperty('width', `${elementTemp.offsetWidth}px`, 'important');
    this.element.style.setProperty('padding-left', `0`, 'important');
    this.element.style.setProperty('padding-right', `0`, 'important');

    elementTemp.remove();
  }
}
