import { CategoryType } from './enums/category-type';
import { DrinkType } from './enums/drink-type';

export class Types {
  categoryType: CategoryType;
  drinkType: DrinkType;

  constructor(categoryType: CategoryType, drinkType: DrinkType) {
    this.categoryType = categoryType;
    this.drinkType = drinkType;
  }
}
