<ng-container *appIfIsMobile>
  <app-navbar-top-profile [title]="'Account'" [edited]="edited" (saveClick)="saveChanges()"></app-navbar-top-profile>

  <div #profile class="container max-width has-navbar-top">
    <ng-container [ngTemplateOutlet]="profileCard"></ng-container>
  </div>
</ng-container>

<ng-container *appIfIsDesktop>
  <div class="row">
    <div class="col-4">
      <ng-container [ngTemplateOutlet]="profileCard"></ng-container>
    </div>
  </div>
</ng-container>

<ng-template #profileCard>
  <div class="card" appAddClassIfMobile="profile-mobile my-3" appAddClassIfDesktop="profile-desktop">
    <div *ngIf="!isPasswordModalOpen" class="card-body">
      <div class="user-profile-form">
        <form [formGroup]="userForm" (ngSubmit)="saveChanges()">
          <div class="form-group">
            <label class="form-label">Bio</label>
            <textarea
              type="text"
              class="form-control bio-input"
              maxlength="160"
              formControlName="bio"
              (ngModelChange)="checkEdited()"
            ></textarea>
          </div>

          <div class="form-group">
            <label class="form-label">First Name</label>
            <input
              [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }"
              type="text"
              class="form-control"
              formControlName="firstname"
              (ngModelChange)="checkEdited()"
            />
            <small class="invalid-feedback"> Required. </small>
          </div>

          <div class="form-group">
            <label class="form-label">Last Name</label>
            <input
              [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }"
              type="text"
              class="form-control"
              formControlName="lastname"
              (ngModelChange)="checkEdited()"
            />
            <small class="invalid-feedback"> Required. </small>
          </div>

          <div class="form-group">
            <label class="form-label">Phone</label>
            <input
              type="tel"
              class="form-control"
              mask="(000) 000-0000"
              placeholder="(___) ___-____"
              formControlName="phone"
              (ngModelChange)="checkEdited()"
            />
          </div>

          <div class="form-group">
            <label class="form-label">Email</label>
            <input
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
              type="email"
              class="form-control"
              formControlName="email"
              (ngModelChange)="checkEdited()"
            />

            <small class="invalid-feedback">
              {{ f.email.errors && f.email.errors.required ? 'Required.' : 'Email is not valid.' }}
            </small>

            <small
              *ngIf="!user.confirmed || (isTempEmail() && user.unverifiedEmail)"
              class="form-text text-muted"
            >
              <div class="row">
                <div class="col-8 text-danger">
                  {{ user.unverifiedEmail }} is unverified
                </div>

                <div class="col-4 text-right">
                  <a appHrefNone (click)="sendConfirmation()"> Resend link </a>
                </div>
              </div>
            </small>
          </div>

          <div class="form-group mb-3">
            <label class="form-label">Birthday</label>
            <input
              type="text"
              class="form-control"
              mask="00/00/0000"
              placeholder="__/__/____"
              formControlName="birthday"
              (ngModelChange)="checkEdited()"
            />
          </div>

          <div class="form-group">
            <label class="form-label">Gender</label>
            <ng-select
              [items]="selectOptions"
              [clearable]="false"
              bindLabel="label"
              bindValue="value"
              formControlName="gender"
              (ngModelChange)="checkEdited()"
            ></ng-select>
          </div>

          <div *ngIf="!user.igId" class="form-group">
            <label class="form-label">Username</label>
            <input
              [ngClass]="{ 'is-invalid': f.username.errors && (usernameChecked || submitted) }"
              type="text"
              class="form-control"
              formControlName="username"
              (ngModelChange)="checkEdited()"
            />

            <small class="invalid-feedback">
              {{
                f.username.errors?.required ? 'Required.' :
                  f.username.errors?.pattern ? 'Letters, numbers, underscores and periods only' :
                    'Username already exists'
              }}
            </small>
          </div>

          <div class="form-group">
            <div *ngIf="!user.igId" class="align-self-center">
              <button class="btn btn-primary btn-block" (click)="connectToInstagram()">
                Connect to Instagram
              </button>
            </div>

            <ng-container *ngIf="user.igId">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label class="form-label">Instagram</label>

                    <div
                      class="ig-btn pt-2 cursor-pointer text-muted small"
                      (click)="reconnectToInstagram()"
                    >
                      Change account
                    </div>

                    <div *appIfIsDesktop
                         class="ig-btn pt-2 cursor-pointer text-muted small"
                         (click)="openPasswords()"
                    >
                      Update password
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group text-right">
                    <label class="form-label">{{ user.username }}</label>
                  </div>

                  <div class="form-group text-right" *appIfIsDesktop>
                    <button
                      class="btn-change btn btn-primary text-uppercase ml-1"
                      type="submit"
                      [disabled]="disableSubmittedButton"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #passwordModalRef>
  <app-password></app-password>
</ng-template>
