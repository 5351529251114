import { Type } from 'class-transformer';
import { OrderBase } from './order-base';

export class Order extends OrderBase {
  id: number;
  hashId: string;
  hashIdLong: string;
  customerName: string;

  @Type(() => Date)
  readyTime: Date;

  accepted: boolean;
  declined: boolean;

  @Type(() => Date)
  createdAt: Date;

  get hashIdNumber() {
    return this.hashId.substring(0, 2);
  }

  get hashIdLetter() {
    return this.hashId.substring(2, 3);
  }
}
