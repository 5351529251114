import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IgUsernameSuggestion } from '../../../domains/ig-username-suggestion';
import { Restaurant } from '../../../domains/restaurant';
import { RestaurantSimple } from '../../../domains/restaurant-simple';
import { IgUsernameSuggestionService } from '../../../services/ig-username-suggestion.service';

@Component({
  selector: 'app-instagram-input-modal',
  templateUrl: 'instagram-input-modal.component.html',
  styleUrls: ['instagram-input-modal.component.scss']
})
export class InstagramInputModalComponent implements OnInit {
  @Input() restaurant: Restaurant | RestaurantSimple;
  @Input() igUsernameSuggestion: IgUsernameSuggestion;

  @Output() igUsernameSuggested = new EventEmitter<IgUsernameSuggestion>();

  isInstagramUsernameValid = false;

  constructor(private igUsernameSuggestionService: IgUsernameSuggestionService) {
  }

  ngOnInit(): void {
  }

  validateInstagramUsername(event) {
    if (!event.target.value) {
      return false;
    }

    this.isInstagramUsernameValid = event.target.checkValidity();
  }

  onSubmitClick(username: string) {
    this.igUsernameSuggestion = this.igUsernameSuggestionService
      .createNewSuggestionObject(this.restaurant.id, username);

    this.igUsernameSuggested.emit(this.igUsernameSuggestion);
  }
}
