import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Chain } from '../domains/chain';
import { LocalStorage } from '../local-storage/local-storage';
import { LocalStorageKey } from '../models/enums/local-storage-key';
import { LocationType } from '../models/enums/location-type';
import { SearchParams } from '../models/search-params';
import { TypeCategorySection } from '../models/type-category-section';

@Injectable({providedIn: 'root'})
export class ChainService {
  private readonly chainUrl: string;

  private cacheMap = new Map<string, Observable<Array<Chain>>>();
  private searchParamsLocalStorage = new LocalStorage(SearchParams, LocalStorageKey.SEARCH_PARAMS);

  constructor(private http: HttpClient) {
    this.chainUrl = `${environment.apiEndpoint}/api/chains`;
  }

  public getAllByTypeCategorySection(typeCategorySection: TypeCategorySection): Observable<Array<Chain>> {
    const typeCategoryIds = typeCategorySection.typeCategories.map(value => value.id);

    const searchParams = this.searchParamsLocalStorage.getItem();
    const geoLocation = searchParams.geoLocation;

    let type: LocationType;
    let locationId: number;

    if (searchParams.districtId) {
      locationId = searchParams.districtId;
      type = LocationType.DISTRICT;
    } else {
      locationId = searchParams.cityId;
      type = LocationType.CITY;
    }

    let key: string;

    if (!geoLocation) {
      key = `${locationId}-${type}-${typeCategoryIds.join('-')}`;

    } else {
      key = `${geoLocation.latitude}-${geoLocation.longitude}-${typeCategoryIds.join('-')}`;
    }
    const cachedData = this.cacheMap.get(key);

    if (cachedData) {
      return cachedData;
    } else {
      let newData: Observable<Array<Chain>>;

      if (!geoLocation) {
        newData = this.http.get<Array<Chain>>(
          `${this.chainUrl}/location/${locationId}/${type}/type-categories/${typeCategoryIds}`
        ).pipe(
          switchMap((data: Array<Chain>) => of(data)),
          shareReplay(1)
        );
      } else {
        newData = this.http.get<Array<Chain>>(
          `${this.chainUrl}/type-categories/${typeCategoryIds}/geo-location`,
          {params: {'geo-location': JSON.stringify(geoLocation)}}
        ).pipe(
          switchMap((data: Array<Chain>) => of(data)),
          shareReplay(1)
        );
      }

      this.cacheMap.set(key, newData);

      return newData;
    }
  }

  clearCache(key: string): void {
    this.cacheMap.delete(key);
  }

  clearAllCache(): void {
    this.cacheMap.clear();
  }
}
