<ng-container *ngIf="!restaurant.slideGalleryFoods.empty">
  <div *ngFor="let topFood of restaurant.slideGalleryFoods.topFoods; let i = index" [id]="'preview-image-' + i">
    <app-preview-modal-slide
      *ngIf="topFood"
      [restaurant]="restaurant"
      [backgroundImage]="backgroundImage"
      [topFood]="topFood"
      class="h-530px"
    ></app-preview-modal-slide>
  </div>

  <div *ngIf="restaurant.slideGalleryFoods.outside">
    <app-preview-modal-slide
      [restaurant]="restaurant"
      [backgroundImage]="restaurant.slideGalleryFoods.outside.images[0].path | imgix"
      [noFooter]="true"
      class="h-530px"
    ></app-preview-modal-slide>
  </div>

  <div *ngIf="restaurant.slideGalleryFoods.inside">
    <app-preview-modal-slide
      [restaurant]="restaurant"
      [backgroundImage]="restaurant.slideGalleryFoods.inside.images[0].path | imgix"
      [noFooter]="true"
      class="h-530px"
    ></app-preview-modal-slide>
  </div>

  <div *ngFor="let googleImage of restaurant.slideGalleryFoods.googleImages; let i = index">
    <app-preview-modal-slide
        *ngIf="i < 4"
        [restaurant]="restaurant"
        [backgroundImage]="googleImage.images[0].path | googleImage: 360"
        [noFooter]="true"
        class="h-530px"
    ></app-preview-modal-slide>
  </div>
</ng-container>
