import { Type } from 'class-transformer';
import { BaseLocation } from '../models/base-location';
import { CityWithChild } from './city-with-child';
import { RegionWithChild } from './region-with-child';

export class CountyWithChild extends BaseLocation {
  @Type(() => RegionWithChild)
  regions: RegionWithChild[] = [];

  @Type(() => CityWithChild)
  cities: CityWithChild[] = [];

  fullDisplay: string;

  regionsLoaded: boolean;
  citiesLoaded: boolean;

  get hasRegions() {
    return this.regions?.length > 0;
  }

  hasSingleCity() {
    return this.cities?.length === 1;
  }

  singleCity() {
    return  this.hasSingleCity() ? this.cities[0] : null;
  }
}
