import { Component, Input, OnInit } from '@angular/core';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { ModalClose } from '../../../decorators/modal-close.decorator';
import { Subscribe } from '../../../decorators/subscribe.decorator';
import { StripeSubscription } from '../../../domains/stripe-subscription';
import { User } from '../../../domains/user';
import { CheckoutService } from '../../../services/checkout.service';

@Component({
  selector: 'app-membership-modal',
  templateUrl: './membership-modal.component.html',
  styleUrls: ['./membership-modal.component.scss']
})
export class MembershipModalComponent implements OnInit {
  @Input() user: User;

  @CurrentUser() currentUser: User;

  isOwner: boolean;
  subscription: StripeSubscription;

  constructor(private checkoutService: CheckoutService) {
  }

  ngOnInit(): void {
    this.isOwner = this.currentUser.id === this.user.id;

    this.checkoutService.getUserSubscriptionInfo(this.user)
      .subscribe(value => {
        if (value) {
          this.subscription = value;
        }
      });
  }

  @Subscribe()
  onSubscribeClick() {
  }

  @ModalClose()
  onSwipeRight() {
  }
}
