import { plainToClass } from 'class-transformer';

export function CurrentNavigationExtra<ClassType>(
  stateKey: string,
  options?: { classType: new (...args: any[]) => ClassType }
): PropertyDecorator {
  return (target: any, propertyKey: string) => {
    let stateValue: any = null;

    const getter = () => {
      const classType = options?.classType || null;

      if (history.state?.hasOwnProperty(stateKey)) {
        const value = history.state[stateKey];

        if (classType && Array.isArray(value)) {
          stateValue = value.map(valueItem => plainToClass(classType, valueItem));
        } else if (classType && typeof value === 'object') {
          stateValue = plainToClass(classType, value);
        } else {
          stateValue = value;
        }
      }

      return stateValue;
    };

    const setter = (newStateValue) => {
      if (stateValue === null) {
        stateValue = newStateValue;
      }
    };

    Object.defineProperty(target, propertyKey, {
      get: getter,
      set: setter
    });
  };
}
