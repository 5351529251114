<div
  *ngIf="posts?.length > 0 && delayed"
  class="container px-1-15rem"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()"
>
  <div class="row mx-0">
    <div
      *ngFor="let columnIndex of columns; let first = first; let last = last;"
      [ngClass]="{'col-6' : !isDesktop, 'col-4' : isDesktop}"
      class="p-0"
    >
      <ng-container *ngFor="let post of posts; let i = index;">
        <div
          *ngIf="i % columns.length == columnIndex"
          [ngClass]="{
            'mr-0-85rem ml-0-85rem': isDesktop && (first || last),
            'mx-0-85rem': isDesktop && !first && !last,
            'mt-1-7rem': isDesktop,
            'mr-2': !isDesktop && columnIndex == 0,
            'ml-2': !isDesktop && columnIndex == 1,
            'mt-3':!isDesktop
          }"
          class="masonry-item"
          (click)="goToPost(post)"
        >
          <img
            *ngIf="post?.images?.length > 0"
            appBlurImg
            [path]="post.images[0].path"
            [options]="{w: 720}"
            class="w-100 cursor-pointer"
            [alt]="post?.caption"
          />
        </div>
      </ng-container>
    </div>
  </div>
</div>
