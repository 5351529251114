import { WrapperInterface } from '../wrappers/wrapper.interface';
import { PostType } from './post-type';

export class PostTypeWrapper implements WrapperInterface {
  list = PostType;
  value = PostType.USER;

  constructor(type: string = null) {
    if (type) {
      this.value = PostType[type];
    }
  }

  get isFood() {
    return this.value === this.list.FOOD;
  }

  get isPeople() {
    return this.value === this.list.PEOPLE;
  }

  get isPlate() {
    return this.value === this.list.PLATE;
  }

  get isPlaceInside() {
    return this.value === this.list.PLACE_INSIDE;
  }

  get isPlaceOutside() {
    return this.value === this.list.PLACE_OUTSIDE;
  }

  get isPlace() {
    return this.isPlaceInside || this.isPlaceOutside;
  }

  get placeType() {
    if (this.isPlaceInside) {
      return 'inside';
    } else if (this.isPlaceOutside) {
      return 'outside';
    } else {
      return null;
    }
  }
}
