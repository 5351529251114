<div *ngIf="isSearching && !isDesktop" class="pt-2rem section position-relative">
  <div class="justify-content-center">
    <div class="row">
      <div class="col-search col-4"></div>

      <div class="col-search col-4 px-1 mb-2rem">
        <ngx-skeleton-loader
          count="1"
          animation="progress-dark"
          [theme]="{
            'width': '99.5%',
            'height': '28.23px',
            'border-radius': '1rem',
            'background-color': '#ffffff20',
            'backdrop-filter': 'blur(10px)'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>

  <div class="row row-search">
    <ng-container *ngFor="let number of [0,1,2,3,4,5,6,7,8]">
      <div class="col-search col-4 mb-1">
        <app-div-rectangle>
          <ngx-skeleton-loader
            count="1"
            animation="progress-dark"
            [theme]="{
              'width': '99.5%',
              'height': '99.5%',
              'border-radius': '0',
              'background-color': '#ffffff20',
              'backdrop-filter': 'blur(10px)'
            }"
          ></ngx-skeleton-loader>
        </app-div-rectangle>
      </div>
    </ng-container>
  </div>

  <div class="justify-content-center pt-2-2rem">
    <div class="row">
      <div class="col-search col-4"></div>

      <div class="col-search col-4 px-1 mb-2rem">
        <ngx-skeleton-loader
          count="1"
          animation="progress-dark"
          [theme]="{
            'width': '99.5%',
            'height': '28.23px',
            'border-radius': '1rem',
            'background-color': '#ffffff20',
            'backdrop-filter': 'blur(10px)'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>

  <div class="row row-search">
    <ng-container *ngFor="let number of [0,1,2,3,4,5,6,7,8]">
      <div class="col-search col-4 mb-1">
        <app-div-rectangle>
          <ngx-skeleton-loader
            count="1"
            animation="progress-dark"
            [theme]="{
              'width': '99.5%',
              'height': '99.5%',
              'border-radius': '0',
              'background-color': '#ffffff20',
              'backdrop-filter': 'blur(10px)'
            }"
          ></ngx-skeleton-loader>
        </app-div-rectangle>
      </div>
    </ng-container>
  </div>
</div>
