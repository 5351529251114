<div class="bg-white ui-mh-100vh p-5 flex-content-vc-hc">
  <div class="flex-content-vc flex-column">
    <img [src]="mmmmLogo" alt="" class="verify-ownership-image"/>

    <div *ngIf="verificationProcess" class="mt-4 text-center font-weight-semibold">
      <p>{{ hostname }}.mmmm.menu</p>

      <ng-container *ngIf="!verificationError">
        <p>Link verification is currently processing</p>
        <p>Email will be sent upon completion</p>
      </ng-container>

      <ng-container *ngIf="verificationError">
        <p>
          Link verification failed please check your email for instructions
        </p>
      </ng-container>
    </div>
  </div>
</div>
