import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { IsLoggedIn } from '../../../decorators/is-logged-in.decorator';
import { Restaurant } from '../../../domains/restaurant';
import { RestaurantSimple } from '../../../domains/restaurant-simple';
import { User } from '../../../domains/user';
import { DeviceType } from '../../../models/enums/device-type';
import { Paths } from '../../../models/enums/paths';
import { AuthenticationService } from '../../../services/authentication.service';
import { NavigatorService } from '../../../services/navigator.service';
import { RestaurantService } from '../../../services/restaurant.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @ViewChild('settings', {static: true}) settings: ElementRef;

  @IsLoggedIn() isLoggedIn: boolean;
  @CurrentUser() currentUser: User;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  user: User;
  paths = Paths;
  restaurants: Restaurant[] = [];
  hasCsRestaurants = false;
  restaurant: RestaurantSimple;
  restaurantAdminPath: string;

  constructor(
    private authenticationService: AuthenticationService,
    private navigatorService: NavigatorService,
    private activatedRoute: ActivatedRoute,
    private restaurantService: RestaurantService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    if (!this.isMobile) {
      this.navigatorService.goToProfileSettings();
    }

    this.activatedRoute.fragment.subscribe(fragment => {
      if (fragment && fragment === 'user') {
        this.settings.nativeElement.classList.add('animate__animated', 'animate__slideInRight', 'faster');

        this.settings.nativeElement.addEventListener('animationend', () => {
          this.navigatorService.changeLocationTo('/settings');
        });
      }
    });

    if (!this.isLoggedIn) {
      this.navigatorService.goToHome();
    } else {
      this.userService
        .getById(this.currentUser.id)
        .subscribe((user: User) => {
          this.user = user;
        });

      this.restaurantService
        .hasCsRestaurants()
        .subscribe(value => {
          this.hasCsRestaurants = value;
        });

      this.restaurantService.getUserTopRestaurantSimple().subscribe(restaurant => {
        if (restaurant) {
          this.restaurant = restaurant;

          this.restaurantAdminPath = `/admin/${this.restaurant.hostname}`;
        }
      });
    }
  }

  openProfile() {
    this.navigatorService.goToProfileSettings(true);
  }

  openPasswords() {
    this.navigatorService.goToSettingsPage('password');
  }

  openSubscription() {
    this.navigatorService.goToSettingsPage('subscription');
  }

  openHero() {
    this.navigatorService.goToSettingsPage('hero');
  }

  logout() {
    this.authenticationService.logout();
    this.navigatorService.goToHome();
  }

  goToUser() {
    this.navigatorService.goToUser();
  }
}
