<div appModalCloseOnClick class="h-100">
  <div class="modal-body p-0 flex-content-vc-hc flex-column">
    <div
      appModalCloseOnClickPrevent
      [ngClass]="{'modal-box': !isMobile, 'modal-box-mobile': isMobile}"
      class="border-bottom-radius-3"
    >
      <div class="flex-content-hc">
        <div class="flex-content-vc-hc mmmm-icon-rounded-small">
          <span class="icon-v2 mmmm-2x-red"></span>
        </div>
      </div>

      <div class="w-100 form-content padding-0-4rem">
        <form [formGroup]="emailForm" (ngSubmit)="onSubmit()">
          <input ngbAutofocus type="hidden"/>

          <div class="form-group text-left">
            <label class="form-label text-white mb-2" for="email">Email</label>

            <input
              appAutocompleteOff
              [ngClass]="{'is-invalid': submitted && emailForm.invalid}"
              type="email"
              id="email"
              class="form-control form-border-white btn-outline-white pl-12px"
              formControlName="email"
            />

            <small *ngIf="emailControl.errors?.required" class="invalid-feedback">Required</small>
            <small *ngIf="emailControl.errors?.email" class="invalid-feedback">Invalid email address</small>
          </div>

          <div class="modal-top-standard-footer-content">
            <input
              type="submit"
              class="btn btn-white btn-block text-uppercase"
              value="Add to my account"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
