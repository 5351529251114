import { Directive, Input, OnChanges, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { IsDevice } from '../decorators/is-device.decorator';
import { DeviceType } from '../models/enums/device-type';

@Directive({
  selector: '[appIfIsMobile]'
})
export class IfIsMobileDirective implements OnInit, OnChanges {
  @Input() appIfIsMobile = true;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  element: HTMLElement;

  constructor(
    private view: ViewContainerRef,
    private template: TemplateRef<any>
  ) {
  }

  ngOnInit() {
    if (this.isMobile) {
      this.view.createEmbeddedView(this.template);
    } else {
      this.view.clear();
    }
  }

  ngOnChanges(): void {
  }
}
