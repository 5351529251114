import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-modal-favorites-navbar-top',
  templateUrl: './search-modal-favorites-navbar-top.component.html',
  styleUrls: ['../../../../navbar/navbar.shared.scss']
})
export class SearchModalFavoritesNavbarTopComponent implements OnInit {
  @Input() title: string;
  @Input() eatenCount: number;

  @Output() titleClick = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit() {
  }
}
