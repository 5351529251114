import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { classToPlain, plainToClass } from 'class-transformer';
import { NGXLogger } from 'ngx-logger';
import { Auth } from '../../../decorators/auth.decorator';
import { CurrentRestaurant } from '../../../decorators/current-restaurant.decorator';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { IsLoggedIn } from '../../../decorators/is-logged-in.decorator';
import { ModalClose } from '../../../decorators/modal-close.decorator';
import { Instruction } from '../../../domains/instruction';
import { Order } from '../../../domains/order/order';
import { Restaurant } from '../../../domains/restaurant';
import { RestaurantSimple } from '../../../domains/restaurant-simple';
import { User } from '../../../domains/user';
import { BooleanLocalStorage } from '../../../local-storage/boolean-local-storage';
import { Basket } from '../../../models/basket/basket';
import { BasketExtra } from '../../../models/basket/basket-extra';
import { BasketFood } from '../../../models/basket/basket-food';
import { BasketSection } from '../../../models/basket/basket-section';
import { DeviceType } from '../../../models/enums/device-type';
import { LocalStorageKey } from '../../../models/enums/local-storage-key';
import { ModalType } from '../../../models/enums/modal-type';
import { Modal } from '../../../models/modal';
import { Picker } from '../../../models/picker';
import { UnsplashImage } from '../../../models/unsplash-image';
import { BackgroundImageService } from '../../../services/background-image.service';
import { BasketService } from '../../../services/basket.service';
import { InstructionService } from '../../../services/instruction.service';
import { ModalService } from '../../../services/modal.service';
import { NavigatorService } from '../../../services/navigator.service';
import { NotificationService } from '../../../services/notification.service';
import { OrderService } from '../../../services/order.service';
import { RestaurantService } from '../../../services/restaurant.service';
import Timer = NodeJS.Timer;

@Component({
  selector: 'app-basket-modal',
  templateUrl: './basket-modal.component.html',
  styleUrls: [
    './basket-modal.component.scss'
  ]
})
export class BasketModalComponent implements OnInit, OnDestroy {
  @Input() basket: Basket;
  @Input() modal: Modal;

  @ViewChild('basketTotalEndRef') basketTotalEndRef: ElementRef;
  @ViewChild('pickUpModalRef') pickUpModalRef: ElementRef;
  @ViewChild('dineInModalRef') dineInModalRef: ElementRef;
  @ViewChild('phoneModalRef') phoneModalRef: ElementRef;
  @ViewChild('instructionsModalRef') instructionsModalRef: ElementRef;
  @ViewChild('taxFeeModalRef') taxFeeModalRef: ElementRef;

  @CurrentRestaurant() currentRestaurant: Restaurant;

  @CurrentUser() currentUser: User;

  @IsLoggedIn() isLoggedIn: boolean;

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;
  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  instructions = {};
  currentInstruction: Instruction;
  picker: Picker;
  rangeConfig = {
    step: 1,
    direction: 'ltr'
  };
  pickUpModal: Modal;
  dineInModal: Modal;
  phoneModal: Modal;
  backgroundImage: string | UnsplashImage;
  intervalHandle: Timer;
  isRestaurantReady = false;
  locations: RestaurantSimple[] = [];
  locationId: number;
  activeLocationId: number;
  orderingEnabled = false;
  isHoursEnabled = false;
  basketModalHoursEnabledLocalStorage: BooleanLocalStorage;

  constructor(
    private modalService: ModalService,
    private notificationService: NotificationService,
    private instructionService: InstructionService,
    private orderService: OrderService,
    private restaurantService: RestaurantService,
    private basketService: BasketService,
    private logger: NGXLogger,
    private navigatorService: NavigatorService,
    private backgroundImageService: BackgroundImageService,
  ) {
  }

  ngOnInit() {
    if (this.isMobile) {
      const localStorageOptions = {
        hostname: this.currentRestaurant.hostname,
        locationId: this.currentRestaurant.locationIndex,
        userId: this.currentUser?.id
      };

      this.basketModalHoursEnabledLocalStorage = new BooleanLocalStorage(
        LocalStorageKey.BASKET_MODAL_HOURS_ENABLED,
        localStorageOptions
      );

      this.isHoursEnabled = this.basketModalHoursEnabledLocalStorage.getItem();

      this.backgroundImageService.getRestaurantBgImageOrRandom(this.currentRestaurant, this.isMobile).then(image => {
        this.backgroundImage = image;
      }).catch(e => {
        this.logger.error(e);
      });
    }

    this.locationId = this.currentRestaurant?.locationIndex;
    this.activeLocationId = this.locationId;

    if (this.currentRestaurant?.phone && this.basket.phone !== this.currentRestaurant?.phone) {
      this.basket.phone = this.currentRestaurant.phone;
    }

    this.orderingEnabled = this.currentRestaurant?.orderingEnabled;
    this.isRestaurantReady = true;

    this.initPicker();

    if (this.isLoggedIn) {
      this.instructionService
        .getByFoodIds(this.basket.foods.map(item => item.id))
        .subscribe({
          next: instructions => {
            instructions.forEach(instruction => {
              this.instructions[instruction.foodId] = instruction;
            });
          }, error: error => {
            this.logger.error('On getting instructions by food ids', error);
          }
        });
    }

    if (this.basket.pickUpEnabled) {
      this.scrollToBasketTotal();
    }

    this.setTimeCheckInterval();

    this.restaurantService.getLocations().subscribe(locations => {
      locations.forEach(location => {
        if (location.id !== this.currentRestaurant?.id) {
          this.locations.push(location);
        }
      });
    });
  }

  ngOnDestroy(): void {
    if (this.intervalHandle) {
      clearInterval(this.intervalHandle);
    }
  }

  removeBasketFood(basketFood: BasketFood, section: BasketSection = null, extra: BasketExtra = null) {
    this.saveBasket();

    if (section && extra) {
      this.basket.removeExtra(basketFood, section, extra);
    } else {
      this.basket.removeFood(basketFood);
    }

    this.initPicker();

    if (this.basket.isEmpty()) {
      this.basket.pickUpEnabled = false;
      this.modal?.close();
    }

    this.basketService.basket = this.basket;
  }

  next() {
    this.notificationService.clear();

    setTimeout(() => {
      this.notificationService.orderingNotEnabled();
    }, 300);
  }

  onSelectedFoodForInstruction(foodIdString: string) {
    const foodId = +foodIdString;
    this.currentInstruction = new Instruction(foodId);

    if (this.instructions.hasOwnProperty(foodId)) {
      this.currentInstruction = this.instructions[foodId];
    }

    this.modalService.open(this.instructionsModalRef, ModalType.CENTER_NARROWED);
  }

  onChangeInstruction(instruction: Instruction) {
    if (instruction.id) {
      this.instructions[instruction.foodId] = instruction;
    } else {
      delete (this.instructions[this.currentInstruction.foodId]);
    }

    this.currentInstruction = null;
  }

  @Auth()
  loginNeed() {
  }

  private initPicker() {
    this.picker = new Picker();

    this.basket.foods.forEach(item => {
      this.picker.addDefaultOption(item.id.toString(), item.title);
    });
  }

  @Auth()
  openPickUpModal() {
    this.pickUpModal = this.modalService.open(this.pickUpModalRef, ModalType.PICKUP);
  }

  @Auth()
  openDineInModal() {
    this.dineInModal = this.modalService.open(this.dineInModalRef, ModalType.CENTER);
  }

  openPhoneModal() {
    this.phoneModal = this.modalService.open(this.phoneModalRef, ModalType.CENTER);
  }

  sliderUpdate() {
    this.basket.calculate();
  }

  openTaxFeeModal() {
    this.modalService.open(this.taxFeeModalRef, ModalType.CENTER);
  }

  sliderChange() {
    this.basketService.basket = this.basket;
  }

  @Auth()
  checkout() {
    if (this.basket.phone) {
      this.basket.foods.forEach(basketFood => {
        if (this.instructions[basketFood.id]) {
          basketFood.instruction = this.instructions[basketFood.id].text;
        }
      });

      const orderObject = classToPlain(this.basket);
      const order = plainToClass(Order, orderObject, {excludeExtraneousValues: true});

      this.orderService
        .saveOrder(order)
        .subscribe({
          next: () => {
            this.basket.clear();
            this.basketService.basket = this.basket;
            this.modal.close();
          }, error: error => {
            this.logger.error('On saving order', error);
          }
        });
    } else {
      this.openPhoneModal();
    }
  }

  setPickUpTime(time: Date) {
    this.basket.requestTime = time;
    this.basket.asap = !time;
    this.basket.pickUpEnabled = true;
    this.basketService.basket = this.basket;
    this.scrollToBasketTotal();
    this.pickUpModal.close();

    this.setTimeCheckInterval();
  }

  private scrollToBasketTotal() {
    setTimeout(() => {
      this.basketTotalEndRef.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
    }, 100);
  }

  setDineIn($event: object) {
  }

  setPhone(phone: string) {
    this.currentRestaurant.phone = phone;
    this.basket.phone = phone;
    this.basketService.basket = this.basket;
    this.phoneModal.close();
  }

  setTimeCheckInterval() {
    if (this.basket.requestTime) {
      this.intervalHandle = setInterval(() => {
        if (this.basket.requestTime < new Date()) {
          this.basket.requestTime = null;
          this.basket.asap = true;
          this.basketService.basket = this.basket;
          clearInterval(this.intervalHandle);
        }
      }, 1000);
    }
  }

  saveBasket() {
    this.basketService.basket = new Basket();
  }

  clearBasketAndClose() {
    this.saveBasket();

    setTimeout(() => {
      if (this.modal) {
        this.modal.close();
      } else {
        this.onSwipeUp();
      }

    }, 300);
  }

  @ModalClose()
  onSwipeUp() {
  }

  goToLocation(value: any) {
    const newLocationId = +value;

    this.modal.close();
    this.basket.clear();
    this.basketService.basket = this.basket;

    if (value !== this.locationId) {
      const location = this.locations.find(locationI =>
        locationI.locationIndex === newLocationId
      );
      this.navigatorService.goToRestaurantWithObject(plainToClass(RestaurantSimple, location));
    }
  }

  openGoogleMap() {
    this.navigatorService.goToMapsRestaurant(this.currentRestaurant);
  }

  setIsHoursEnabled($event: boolean) {
    this.isHoursEnabled = $event;
    this.basketModalHoursEnabledLocalStorage.setItem(this.isHoursEnabled);
  }
}
