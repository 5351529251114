import { Location } from '@angular/common';
import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { NavigatorService } from '../services/navigator.service';
import { TimeoutService } from '../services/timeout.service';

@Directive({
  selector: '[appDynamicUrl]'
})
export class DynamicUrlDirective implements OnInit {
  element: HTMLElement;

  constructor(
    element: ElementRef,
    private navigatorService: NavigatorService,
    private _location: Location,
    private timeoutService: TimeoutService
  ) {
    this.element = element.nativeElement;
  }

  ngOnInit(): void {
  }

  @HostListener('window:scroll')
  onScroll() {
    this.timeoutService.setTimeoutWithRelayRace(() => {
      const dynamicUrlElements = this.element.querySelectorAll('.app-dynamic-url-element');

      let visibleDynamicUrlElements = Array.prototype.filter.call(dynamicUrlElements, (node) => {
        const viewportOffset = node.getBoundingClientRect();

        return viewportOffset.bottom > 0 && viewportOffset.top < window.innerHeight;
      });

      if (visibleDynamicUrlElements.length > 0) {
        visibleDynamicUrlElements = visibleDynamicUrlElements.sort((
          dynamicUrlElement1: HTMLElement,
          dynamicUrlElement2: HTMLElement
        ) => {
          const dynamicUrlElement1Height = this.getElementHeight(dynamicUrlElement1);
          const dynamicUrlElement2Height = this.getElementHeight(dynamicUrlElement2);

          if (dynamicUrlElement1Height < dynamicUrlElement2Height) {
            return 1;
          } else if (dynamicUrlElement1Height > dynamicUrlElement2Height) {
            return -1;
          }

          return 0;
        });

        const url = visibleDynamicUrlElements[0].dataset.url;

        if (this._location.path().split('?')[0].indexOf(url) !== 0) {
          this.navigatorService.changeLocationTo(url);
        }
      }
    }, 250);
  }

  getElementHeight(element: HTMLElement) {
    const viewportOffset = element.getBoundingClientRect();
    const top = viewportOffset.top > 0 ? viewportOffset.top : 0;
    const bottom = viewportOffset.bottom < window.innerHeight ? viewportOffset.bottom : window.innerHeight;

    return bottom - top;
  }
}
