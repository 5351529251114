import { Component, Input, OnInit } from '@angular/core';
import { Section } from '../../../../../domains/section';

@Component({
  selector: 'app-section-checkbox',
  templateUrl: './section-checkbox.component.html',
  styleUrls: ['../section-view.component.scss']
})
export class SectionCheckboxComponent implements OnInit {
  @Input() section: Section;

  constructor() {
  }

  ngOnInit() {
  }
}
