<div
  *ngIf="!sidenavHovered"
  class="position-absolute div-rectangle" id="test"
  (mouseenter)="hover()"
></div>

<sidenav
  orientation="vertical"
  class="layout-sidenav-hover sidenav sidenav-user left-sidenav"
  (mouseleave)="leave()"
>
  <div class="sidenav-left-logo-container py-3 d-flex w-100">
    <div class="sidenav-left-logo" [ngClass]="{'layout-user-sidenav-logo': !admin}">
      <a
        [queryParams]="{restaurantId: restaurant?.id}"
        class="user-sidenav-logo flex-content-vc"
        routerLink="/"
      >
        <span
          [ngClass]="{
            'mmmm-2x-red': !hasReferrer || logoMouseOver,
            'mmmm-2x-white': isAdmin() && !logoMouseOver,
            'opacity-30': hasReferrer
          }"
          class="icon-v2"
          (mouseover)="logoMouseOver = true"
          (mouseleave)="logoMouseOver = false"
        ></span>
      </a>
    </div>
  </div>

  <div class="sidenav-inner">
    <sidenav-router-link *ngIf="!isLoggedIn" (click)="login()">
      Login
    </sidenav-router-link>

    <sidenav-router-link
      *ngIf="isLoggedIn && restaurant"
      route="/{{restaurantAdminPath}}/dashboard"
      [active]="isActiveAdmin('dashboard')"
    >
      Dashboard
    </sidenav-router-link>

    <sidenav-router-link
      *ngIf="isLoggedIn && restaurant"
      route="/{{restaurantAdminPath}}/menu-table"
      [active]="isActiveAdmin('menu-table')"
    >
      Menus
    </sidenav-router-link>

    <sidenav-router-link
      *ngIf="isLoggedIn && restaurant"
      route="/{{restaurantAdminPath}}/locations"
      [active]="isActiveAdmin('locations')"
    >
      My restaurant
    </sidenav-router-link>

    <sidenav-divider *ngIf="(sidenavHovered || admin) && (isLoggedIn && restaurant)"></sidenav-divider>

    <sidenav-router-link
      (click)="addRestaurantDesktop()"
    >
      Add restaurant
    </sidenav-router-link>

    <sidenav-router-link
      *ngIf="isLoggedIn"
      route="/settings/profile"
      [active]="isActive('/settings/profile')"
    >
      Account
    </sidenav-router-link>

    <sidenav-menu
      *ngIf="active?.length > 0 && isLoggedIn"
      [ngClass]="{'visibility-hidden': !sidenavHovered && !admin}"
      text="Hero"
    >
      <sidenav-router-link *ngFor="let restaurant of active" route="/{{restaurant.hostname}}">
        {{ restaurant.secondNameView }}
      </sidenav-router-link>
    </sidenav-menu>

    <sidenav-router-link *ngIf="isLoggedIn" [linkClass]="'sidenav-link-logout'" (click)="logout()">
      Logout
    </sidenav-router-link>
  </div>

  <a class="flex-fill" routerLink="/"></a>

  <div *ngIf="sidenavHovered || admin" class="user-sidenav-footer mt-auto w-100 p-4">
    <div class="user-sidenav-footer-links d-flex justify-content-between">
      <a
        appOpacityFullOnHover
        href="https://unsplash.com/?utm_source=mmmm&utm_medium=referral"
        target="_blank"
        class="text-white opacity-30"
      >
        Unsplash
      </a>
    </div>

    <div *ngIf="unsplashUser" class="user-sidenav-footer-links d-flex justify-content-between mt-3">
      <a
        appOpacityFullOnHover
        [href]="unsplashUser.links.html + '?utm_source=mmmm&utm_medium=referral'"
        [innerHTML]="unsplashUser.name"
        target="_blank" class="text-white opacity-30"
      ></a>
    </div>

    <div class="user-sidenav-footer-icons d-flex justify-content-between mt-3">
      <a href="https://www.instagram.com/mmmm.menu/" target="_blank" class="icon-v2 instagram-white"></a>
      <a href="https://twitter.com/mmmm_menu" target="_blank" class="icon-v2 mt-2px twitter-white"></a>
      <a href="https://www.facebook.com/mmmm.menu" target="_blank" class="icon-v2 facebook-white"></a>
    </div>

    <div class="user-sidenav-footer-links d-flex justify-content-between mt-3">
      <a appHrefNone appOpacityFullOnHover class="text-white opacity-30" routerLink="/privacy">Privacy Policy</a>
      <a appHrefNone appOpacityFullOnHover class="ml-3 text-white opacity-30" routerLink="/terms">Terms</a>
    </div>
  </div>
</sidenav>

<ng-template #addRestaurantModalRef>
  <app-restaurant-add-modal></app-restaurant-add-modal>
</ng-template>

<ng-template #hoursEditModalRef let-c="close">
  <app-hours-edit-modal
    [restaurant]="userRestaurant"
  ></app-hours-edit-modal>
</ng-template>
