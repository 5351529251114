<div class="navbar-container max-width fixed-bottom d-flex safe-area-bottom">
  <nav class="navbar navbar-bottom bg-white flex-fill pr-0">
    <app-navbar-bottom-icon-feed></app-navbar-bottom-icon-feed>

    <app-navbar-bottom-icon-explore [restaurant]="restaurant" [isRestaurant]="true"></app-navbar-bottom-icon-explore>

    <div class="visibility-hidden"></div>
  </nav>

  <app-navbar-bottom-semicircle-notch *ngIf="basket?.isEmpty()" (menuClick)="onMainMenuClick()">
    <i
      [ngClass]="{'profile-mmmm scale-1_2': rotateMainLogo, 'main-grey': !rotateMainLogo}"
      class="icon"
    ></i>
  </app-navbar-bottom-semicircle-notch>

  <nav
    *ngIf="!basket?.isEmpty()"
    class="navbar-main-menu navbar bg-white navbar-bottom px-0 flex-content-vc-hc"
  >
    <a appHrefNone (click)="onMainMenuClick()">
      <i [ngClass]="{'profile-mmmm': rotateMainLogo, 'main-grey': !rotateMainLogo}" class="icon"></i>
    </a>
  </nav>

  <nav class="navbar navbar-bottom bg-white flex-fill pl-0">
    <div class="visibility-hidden"></div>

    <a appHrefNone *ngIf="restaurant?.hasMenu && restaurant.isOwned" (click)="onViewChanged()">
      <i *ngIf="listView" class="icon list-mode"></i>
      <i *ngIf="!listView" class="icon two-two-mode"></i>
    </a>

    <app-navbar-bottom-icon-recent
      *ngIf="!restaurant?.hasMenu || !restaurant.isOwned"
      [isRestaurant]="true"
    ></app-navbar-bottom-icon-recent>

    <app-navbar-bottom-icon-user></app-navbar-bottom-icon-user>
  </nav>
</div>
