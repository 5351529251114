<div class="search-modal d-flex flex-column">
  <div class="search-modal-content card border-radius-none mt-0 d-flex flex-column h-100">
    <div class="card border-radius-none mt-0 d-flex h-100">
      <div
        [appBackgroundUrl]="backgroundImage ? backgroundImage: ''"
        class="search-modal-content-background"
        (click)="iconClick && bgClicked()"
      >
        <div
          *ngIf="iconClick"
          class="search-modal-text fixed-bottom text-white d-flex flex-column"
        >
          Every restaurant as a photo journal
        </div>
      </div>

      <div *ngIf="!iconClick" class="position-absolute-0 backdrop-filter-brightness-0-35"></div>

      <div
        *ngIf="isLocalitySelected"
        class="icon-v2 scale-07 position-absolute-mmmm-icon"
        (click)="iconClicked()"
        [ngClass]="{'mmmm-2x-gray': !iconClick, 'mmmm-2x-red': iconClick}"
      ></div>

      <ng-container *ngIf="!countySelected">
        <div
          *ngIf="stateSelected && stateSelected.sealUrl"
          class="search-modal-seal position-absolute-0"
        >
          <img
            class="w-100 scale-085"
            [src]="stateSelected.sealUrl"
            [alt]="stateSelected.display"
          >
        </div>
      </ng-container>

      <ng-container *ngIf="!isLocalitySelected && !iconClick">
        <div
          *ngIf="countySelected && stateSelected.flagUrl"
          class="search-modal-state-flag"
        >
          <img
            class="w-100"
            [src]="stateSelected.flagUrl"
            [alt]="stateSelected.display"
          >
        </div>
      </ng-container>

      <div
        class="search-modal-filters bg-transparent position-absolute-0 mb-3"
        [ngClass]="{'visibility-hidden': iconClick}"
      >
        <app-search-filters
          [position]="searchFiltersPositionMobile"
          [country]="country"
          [searchParams]="searchParams"
          (stateSelectedChange)="onStateChange($event)"
          (countySelectedChange)="onCountyChange($event)"
          (regionSelectedChange)="onRegionChange($event)"
          (citySelectedChange)="onCityChange($event)"
          (districtSelectedChange)="onDistrictChange($event)"
          (localitySelectedChange)="onLocalitySelectedChange($event)"
        ></app-search-filters>
      </div>
    </div>
  </div>
</div>
