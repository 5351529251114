import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PostsSort } from '../../../models/enums/posts-sort';
import { Picker } from '../../../models/picker';
import { NavigatorService } from '../../../services/navigator.service';

@Component({
  selector: 'app-navbar-top-food-gallery',
  templateUrl: './navbar-top-food-gallery.component.html',
  styleUrls: [
    './navbar-top-food-gallery.component.scss',
    '../navbar.shared.scss'
  ]
})
export class NavbarTopFoodGalleryComponent implements OnInit {
  @Input() title: string;
  @Input() sort: PostsSort;

  @Output() sortChange = new EventEmitter<PostsSort>();

  picker = new Picker();

  constructor(
    private navigatorService: NavigatorService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.picker.addDefaultOption(PostsSort.RECENT, 'Newest');
    this.picker.addDefaultOption(PostsSort.OLDEST, 'Oldest');
    this.picker.addDefaultOption(PostsSort.BEST_PICTURE, 'Best Picture');
  }

  goBack() {
    this.navigatorService.goToUrl(this.router.url.split('/photos')[0]).then();
  }

  onOptionsSelected(value: PostsSort) {
    this.sort = value;
    this.sortChange.emit(this.sort);
  }
}
