import { Component, Input, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ModalClose } from '../../../../decorators/modal-close.decorator';
import { Restaurant } from '../../../../domains/restaurant';
import { TypeCategorySimple } from '../../../../domains/type-category-simple';
import { RestaurantService } from '../../../../services/restaurant.service';
import { TypeCategoryService } from '../../../../services/type-category.service';

@Component({
  selector: 'app-restaurant-edit-modal',
  templateUrl: './restaurant-edit-modal.component.html',
  styles: []
})
export class RestaurantEditModalComponent implements OnInit {
  @Input() restaurant: Restaurant;

  typeCategories: TypeCategorySimple[] = [];
  typeCategorySelected: TypeCategorySimple;
  phone: string;

  constructor(
    private typeCategoryService: TypeCategoryService,
    private logger: NGXLogger,
    private restaurantService: RestaurantService
  ) {
  }

  ngOnInit(): void {
    this.phone = this.restaurant.nationalPhoneNumber;

    this.typeCategoryService
      .getAll()
      .subscribe({
        next: typeCategories => {
          this.typeCategories = typeCategories;

          this.typeCategorySelected = this.typeCategories
            .find(typeCategory => typeCategory.id === this.restaurant.typeCategory.id);
        }, error: error => {
          this.logger.error('On getting type categories', error);
        }
      });
  }

  onRestaurantChange() {
    this.restaurant.internationalPhoneNumber = this.phone;

    this.restaurantService
      .edit(this.restaurant)
      .subscribe({
        next: restaurantEdited => {
          this.restaurant.cityAndState = restaurantEdited.cityAndState;
          this.restaurant.city = restaurantEdited.city;
        }, error: error => {
          this.logger.error(`On editing restaurant with id: ${this.restaurant.id}`, error);
        }
      });
  }

  @ModalClose()
  closeModal() {
  }
}
