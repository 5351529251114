import { Type } from 'class-transformer';
import { Food } from '../domains/food';
import { TopFood } from '../domains/top-foods';

export class SlideGalleryFoods {
  @Type(() => Food)
  inside: Food;

  @Type(() => TopFood)
  topFoods: TopFood[];

  @Type(() => Food)
  outside: Food;

  @Type(() => Food)
  googleImages: Food[];

  get empty(): boolean {
    return !this.inside &&
      !this.outside &&
      (!this.topFoods || this.topFoods.length === 0) &&
      (!this.googleImages || this.googleImages.length === 0);
  }
}
