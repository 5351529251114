import { Type } from 'class-transformer';
import { Image } from '../models/image';

export class RestaurantDetails {
  id: number;

  @Type(() => Image)
  logo: Image;

  @Type(() => Image)
  avatar: Image;

  @Type(() => Image)
  backgroundImages: Array<Image>;

  @Type(() => Image)
  logoDark: Image;
}
