import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ModalClose } from '../../../decorators/modal-close.decorator';

@Component({
  selector: 'app-top-swipe-modal',
  templateUrl: './top-swipe-modal.component.html',
  styleUrls: ['./top-swipe-modal.component.scss']
})
export class TopSwipeModalComponent implements OnInit {
  @Input() title: string;
  @Input() heightInPercent = 65;
  @Input() scale = true;

  @Output() headerClick = new EventEmitter<boolean>();

  panStarted: boolean;
  modalDialog: any;
  content: any;
  height: number;
  initHeight: number;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.initPosition();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.initPosition();
  }

  onPanStart(event) {
    this.panStarted = true;
  }

  onPanEnd(event) {
    this.panStarted = false;

    if (this.initHeight / 2 >= this.initHeight + event.deltaY) {
      this.closeModal();
      return;
    }

    this.modalDialog.style.cssText = `transform: translateY(0); transition: transform 0.3s ease 0s;`;

    setTimeout(() => {
      this.content.style.cssText = `height: ${this.initHeight}px`;
    }, 300);
  }

  onPan(event) {
    if (!this.panStarted) {
      return;
    }

    this.content.style.cssText = `height: ${this.height + event.deltaY}px;`;
  }

  onSwipeUp(event) {
    this.closeModal();
  }

  onHeaderClick() {
    this.headerClick.emit(true);
  }

  @ModalClose({runMethodBefore: true, closeTimeout: 300})
  private closeModal() {
    this.modalDialog.style.cssText = `transform: translateY(-100%); transition: transform 0.3s ease 0s;`;
  }

  private initPosition() {
    this.height = window.innerHeight * this.heightInPercent / 100;
    this.initHeight = this.height;
    this.modalDialog = this.elementRef.nativeElement.parentNode.parentNode;
    this.content = this.elementRef.nativeElement.querySelector('.classic');
    this.content.style.cssText = `height: ${this.height}px`;
  }
}
