import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IsDevice } from '../../../../decorators/is-device.decorator';
import { ModalClose } from '../../../../decorators/modal-close.decorator';
import { Period } from '../../../../domains/period';
import { DeviceType } from '../../../../models/enums/device-type';
import { Time } from '../../../../models/period/time';
import { DateUtils } from '../../../../utils/date-utils';

@Component({
  selector: 'app-period-event-details-modal',
  templateUrl: './period-event-details-modal.component.html',
  styleUrls: [
    './period-event-details-modal.component.scss'
  ]
})
export class PeriodEventDetailsModalComponent implements OnInit {
  @Input() period: Period;
  @Input() dateSelected: string;
  @Input() timeList: Time[] = [];

  @Output() savePeriod = new EventEmitter<Period>();

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  date: Date;
  title: string;
  weekDay: string;
  startTime: string;
  selectedEndTime: any;
  invalid = false;

  constructor() {
  }

  ngOnInit(): void {
    this.date = DateUtils.stringToDate(this.dateSelected);

    this.title =
      `${DateUtils.getWeekDay(this.date)} ${this.date.getUTCDate()}  ${this.period.timeStart.toString(true)}`;

    this.weekDay = `${DateUtils.getWeekDay(this.date)} ${this.date.getUTCDate()}`;

    this.startTime = `${this.period.timeStart.toString(true)}`;
  }

  save() {
    if (this.period?.eventTitle) {
      this.invalid = false;

      this.savePeriod.emit(this.period);
    } else {
      this.invalid = true;
    }
  }

  @ModalClose()
  close() {
  }

  onEndTimeSelected(time: any) {
    this.selectedEndTime = time;
    this.period.timeEnd = time;
  }
}
