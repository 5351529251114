import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class LoaderService {
  constructor(@Inject(DOCUMENT) private _document: Document) {
  }

  public hideSplash() {
    const splashScreen = document.querySelector('.app-splash-screen');

    document.body.classList.remove('app-loading');

    if (splashScreen) {
      splashScreen['style'].display = 'none';
    }
  }

  public showLoader() {
    this.loaderElement.style.display = 'block';
  }

  public hideLoader() {
    this.loaderElement.style.display = 'none';
  }

  private get loaderElement() {
    return this._document.getElementById('mmmm-loader');
  }
}
