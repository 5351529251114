<div class="top-input-modal safe-area-top">
  <div class="card instagram-card">
    <div class="card-body p-0 mt-3">
      <div class="input-group">
        <input
          #instagramInput
          class="instagram-input form-control top-input-modal-input"
          type="text"
          name="search"
          [value]="igUsernameSuggestion?.suggestion"
          placeholder="Add Instagram username"
          autocomplete="off"
          min="3"
          (input)="validateInstagramUsername($event)"
        >

        <span class="input-group-append">
          <button
            type="button"
            [ngClass]="{'visibility-hidden': !isInstagramUsernameValid}"
            class="btn btn-primary md-btn-flat text-transform-none"
            (click)="onSubmitClick(instagramInput.value)"
          >
            Submit
          </button>
        </span>
      </div>
    </div>
  </div>

  <div class="card instagram-card bg-black border-radius-none">
    <div class="card-body p-0 my-3">
      <div class="mx-3 text-white">For {{ restaurant.nameView }}</div>
    </div>
  </div>
</div>
