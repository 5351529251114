import { Pipe, PipeTransform } from '@angular/core';
import { Image } from '../models/image';
import { ImgixService } from '../services/imgix.service';
import { ImageUtils } from '../utils/image-utils';

@Pipe({name: 'imgix'})
export class ImgixPipe implements PipeTransform {
  constructor(private imgixService: ImgixService) {
  }

  transform(image: Image | string, options?: {}): any {
    let path;

    if (image instanceof Image) {
      path = image.path;

      if (image.isDataOrURL) {
        return image.path;
      }
    } else {
      if (ImageUtils.isDataOrURL(image)) {
        return image;
      }

      path = image;
    }

    return this.imgixService.getUrl(path, options);
  }
}
