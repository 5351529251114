import { Injectable } from '@angular/core';
import { ViewType } from '../models/enums/view-type';
import { View } from '../models/view';
import { DomUtils } from '../utils/dom-utils';

@Injectable({providedIn: 'root'})
export class ViewService {
  constructor() {
  }

  setViewTypeAndScrollToPhantomView(view: View, newViewType: ViewType): void {
    view.type = newViewType;

    DomUtils.querySelectorAsync(`#phantom-view-${view.id}-${view.type}`).then((phantomElement) => {
      phantomElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    });
  }
}
