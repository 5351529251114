<div class="orders-modal-body order-details">
  <div class="px-4 pt-4 pb-3">
    <table>
      <ng-container *ngFor="let food of order.foods">
        <tr>
          <td class="order-item-quantity">
            <span>{{ food.quantity }}</span>
          </td>
          <td>
            <span>{{ food.title }}</span>
          </td>
          <td class="order-item-price text-right">
            <span *ngIf="food.priceBaseTotal > 0">{{ food.priceBaseTotal | precision }}</span>
          </td>
        </tr>
        <ng-container *ngFor="let section of food.sections">
          <ng-container *ngIf="section.extras.length > 0">
            <tr *ngFor="let extra of section.extras">
              <td></td>
              <td>
                <span class="order-item-quantity d-inline-block">
                    <span *ngIf="extra.quantity > 1">{{ extra.quantity }}</span>
                </span>
                <span>{{ extra.name }}</span>
              </td>
              <td class="order-item-price text-right">
                <span *ngIf="extra.showPrice && extra.total > 0">
                    {{ extra.total | precision }}
                </span>
              </td>
            </tr>
          </ng-container>
        </ng-container>
        <tr *ngIf="food.instruction">
          <td class="text-danger" colspan="3">
            {{ food.instruction }}
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
  <hr class="m-0">
  <div class="px-4 py-3">
    <div class="row">
      <div class="col-6">
        Tax<br>
        Tip {{ order.tipPercentage }}%<br>
        Total
      </div>
      <div class="col-6 text-right">
        {{ order.salesTax | precision }}<br>
        {{ order.tip | precision }}<br>
        {{ order.totalWithoutFee | precision }}
      </div>
    </div>
  </div>
  <hr class="m-0">
  <div class="px-4 pt-3 pb-4">
    <div class="row">
      <div class="col-6">
        {{ (order.createdAt | date:'LL/dd - hh:mma') | meridiem }}
        <br>
        <ng-container *ngIf="!order.asap">
          {{ (order.requestTime | date:'LL/dd - hh:mma') | meridiem }}
        </ng-container>
        <ng-container *ngIf="order.asap">
          {{ (order.readyTime | date:'LL/dd - hh:mma') | meridiem }}
        </ng-container>
        <br>
        {{ order.hashIdLong }}
      </div>
      <div class="col-6 text-right">
        {{ order.customerName }}<br>
        {{ order.phone | mask: '(000) 000-0000' }}<br>
        <span *ngIf="order.accepted">Accepted</span>
        <span *ngIf="order.declined">Declined</span>
      </div>
    </div>
  </div>
</div>
<div class="orders-modal-footer text-center">
  <div ngbDropdown [autoClose]="'outside'" [placement]="'bottom-right'" class="btn-group inline-flex w-100">
    <button ngbDropdownToggle type="button" class="btn btn-dark btn-block dropdown-toggle">Select</button>
    <div ngbDropdownMenu class="w-100 text-center dropdown-menu-right">
      <a appHrefNone class="dropdown-item">Call</a>
      <a appHrefNone class="dropdown-item">Issue Refund</a>
      <a appHrefNone class="dropdown-item">Add Charge</a>
    </div>
  </div>
</div>
