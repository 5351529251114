import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { Password } from '../../../domains/password';
import { MustMatch } from '../../../helpers/mustmatch.validator';
import { Role, User } from '../../../models';
import { ErrorResponseType } from '../../../models/enums/error-response-type';
import { NotificationService } from '../../../services/notification.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
  @CurrentUser() currentUser: User;

  passwordForm: UntypedFormGroup;
  submitted = false;
  edited = false;

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
  ) {
  }

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      current: ['', Validators.required],
      new: ['', [Validators.required, Validators.minLength(6)]],
      repeat: ['']
    }, {
      validator: MustMatch('new', 'repeat')
    });
  }

  get controls() {
    return this.passwordForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.edited = false;

    if (this.passwordForm.invalid) {
      return;
    }

    const password = new Password(this.controls['current'].value, this.controls['new'].value);

    this.userService
      .changePassword(password)
      .subscribe({
        next: () => {
          this.notificationService.passwordUpdated();
          this.submitted = false;
          this.passwordForm.reset();
        }, error: err => {
          if (err.error.type === ErrorResponseType.WrongCurrentPasswordException) {
            this.controls['current'].setErrors({incorrect: true});
          } else {
            this.logger.error('On changing password', err);
          }
        }
      });
  }

  checkChange() {
    if (this.passwordForm.valid) {
      this.edited = true;
    }
  }

  forgotPassword() {
    this.userService
      .resetPasswordSendingEmail(this.currentUser.email, Role.USER)
      .subscribe({
        next: () => {
          this.notificationService.passwordResetLinkSent(this.currentUser.email);
        }, error: err => {
          this.notificationService.loginError();
          this.logger.error('On resetting email', err);
        }
      });
  }
}
