<h6 *ngIf="section.type != sectionType.SIZE" class="extra-mb">
  {{ section.title || (section.selected.length > 0 ? 'Completed' : '') }}

  <ng-container *ngIf="section.selected.length === 0">
    (Select 1)
  </ng-container>
</h6>

<div *ngFor="let extra of section.extras" class="flex-content-vc extra-mb">
  <div class="flex-fill">
    <label class="custom-control custom-radio custom-radio-secondary mb-0">
      <input
        [(ngModel)]="section.selected[0]"
        type="radio"
        name="extra-{{section.categoryId}}-{{section.foodId}}-{{section.id}}"
        value="{{extra.id}}" class="custom-control-input"
      >

      <span class="custom-control-label cursor-pointer ml-0-4rem">{{ extra.name }}</span>
    </label>
  </div>

  <div class="d-inline-flex">
    <p class="mb-0 text-right">
      <span *ngIf="section.isPriceVisible(extra)">{{ extra.price }}</span>
    </p>
  </div>
</div>
