import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { DeviceType } from '../../../models/enums/device-type';
import { Image } from '../../../models/image';

@Component({
  selector: 'app-food-img',
  templateUrl: 'food-img.component.html',
  styleUrls: ['food-img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FoodImgComponent implements OnInit {
  @Input() images: Array<Image>;
  @Input() width: number;
  @Input() height: number;
  @Input() fromAdmin = false;
  @Input() foodGallery = false;
  @Input() alt = '';

  @Output() loaded = new EventEmitter<boolean>();
  @Output() errored = new EventEmitter<boolean>();

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  defaultMenuImage: string;
  isImageLoaded = false;
  showImageBeforeLoad = true;

  constructor() {
    this.defaultMenuImage = environment.defaultMenuImage;
  }

  ngOnInit(): void {
  }

  isImageExists(): boolean {
    return this.images && this.images.length > 0 && !!this.images[0].path;
  }

  load() {
    this.isImageLoaded = true;
    this.loaded.emit(true);
  }

  error() {
    this.isImageLoaded = false;
    this.showImageBeforeLoad = false;
    this.errored.emit(true);
  }
}
