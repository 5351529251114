<nav class="navbar navbar-top fixed-top bg-white max-width pr-0 safe-area-top">
  <div class="navbar-top-left navbar-width-30px">
    <a appHrefNone class="navbar-top-icon-back scale-82" (click)="goBack()">
      <span class="icon-v2 arrow-back"></span>
    </a>
  </div>

  <a appHrefNone class="navbar-brand navbar-title mr-0">
    {{ title }}
  </a>

  <div class="navbar-top-right navbar-width-30px">
    <button
      *ngIf="!postPageType.isRestaurant || !post.type.isPlate || postRestaurantFormGroup.title.value"
      type="button"
      class="btn btn-primary md-btn-flat pr-0 text-capitalize"
      (click)="share()"
    >
      Share
    </button>
  </div>
</nav>
