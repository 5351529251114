import { LocationType } from '../models/enums/location-type';
import { TourType } from '../models/enums/tour-type';
import { Embed } from './embed';

export class LocationEmbed extends Embed {
  locationType: LocationType;
  tourType: TourType;
  cityId: number;
  districtId: number;
}
