import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'googleImage'})
export class GoogleImagePipe implements PipeTransform {
  transform(url:string, width: number, square: boolean = false): any {
    if (url.indexOf('yelpcdn') !== -1) {
      if (square) {
        return url.replace('o.jpg', '348s.jpg');
      }

      return url;
    }

    return `${url}=w${width}-k-no`;
  }
}
