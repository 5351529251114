import { Component, Input } from '@angular/core';
import { Food } from '../../../../domains/food';
import { Restaurant } from '../../../../domains/restaurant';
import { RestaurantSimple } from '../../../../domains/restaurant-simple';
import { TagType } from '../../../../models/enums/tag-type';

@Component({
  selector: 'app-restaurant-food-image',
  templateUrl: './restaurant-food-image.component.html',
  styleUrls: ['./restaurant-food-image.component.scss']
})
export class RestaurantFoodImageComponent {
  @Input() restaurant: Restaurant | RestaurantSimple;
  @Input() food: Food;

  foodHovered = false;
  tagType = TagType;
}
