import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Auth } from '../../../../decorators/auth.decorator';
import { CityWithChild } from '../../../../domains/city-with-child';
import { CountryWithChild } from '../../../../domains/country-with-child';
import { CountyWithChild } from '../../../../domains/county-with-child';
import { District } from '../../../../domains/district';
import { RegionWithChild } from '../../../../domains/region-with-child';
import { StateWithChild } from '../../../../domains/state-with-child';
import { SearchFiltersPosition } from '../../../../models/enums/search-filters-position';
import { SearchParams } from '../../../../models/search-params';
import { UnsplashImage } from '../../../../models/unsplash-image';
import { SearchFiltersPositionWrapper } from '../../../../models/wrappers/search-filters-position.wrapper';
import { UnsplashService } from '../../../../services/unsplash.service';
import { HashUtils } from '../../../../utils/hash-utils';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: [
    './search-modal.component.scss'
  ]
})
export class SearchModalComponent implements OnInit {
  @Input() country: CountryWithChild;
  @Input() searchParams: SearchParams;

  @Output() countySelectedChange = new EventEmitter<CountyWithChild>();
  @Output() regionSelectedChange = new EventEmitter<RegionWithChild>();
  @Output() citySelectedChange = new EventEmitter<CityWithChild>();
  @Output() districtSelectedChange = new EventEmitter<District>();
  @Output() localitySelectedChange = new EventEmitter<boolean>();
  @Output() openSearchModal = new EventEmitter<boolean>();

  backgroundImage: UnsplashImage;
  hash: string;
  stateSelected: StateWithChild = null;
  countySelected: CountyWithChild;
  regionSelected: RegionWithChild;
  citySelected: CityWithChild;
  districtSelected: District;
  isLocalitySelected: boolean;
  searchFiltersPositionMobile = new SearchFiltersPositionWrapper(SearchFiltersPosition.MOBILE);
  iconClick: boolean;

  constructor(
    private unsplashService: UnsplashService
  ) {
  }

  ngOnInit(): void {
    this.unsplashService.randomPhoto(true).subscribe(value => {
      this.backgroundImage = value;
    });

    this.hash = HashUtils.getCodePositionUniqueHash();
  }

  @Auth()
  onLoginClick() {
  }

  onStateChange(state: StateWithChild) {
    this.stateSelected = state;

    this.resetCityAndDistrict();
  }

  onCountyChange(county: CountyWithChild) {
    this.countySelected = county;

    this.resetCityAndDistrict();

    this.countySelectedChange.emit(this.countySelected);
  }

  onRegionChange(region: RegionWithChild) {
    this.regionSelected = region;

    this.resetCityAndDistrict();

    this.regionSelectedChange.emit(this.regionSelected);
  }

  onCityChange(city: CityWithChild) {
    this.citySelected = city;

    this.citySelectedChange.emit(this.citySelected);
  }

  onDistrictChange(district: District) {
    this.districtSelected = district;

    this.districtSelectedChange.emit(this.districtSelected);
  }

  private resetCityAndDistrict() {
    this.citySelected = null;
    this.districtSelected = null;
  }

  iconClicked() {
    this.iconClick = !this.iconClick;
  }

  bgClicked() {
    if (this.iconClick) {
      this.iconClick = false;
    }
  }

  onLocalitySelectedChange(localitySelected: boolean) {
    setTimeout(() => {
      this.isLocalitySelected = localitySelected;
      this.localitySelectedChange.emit(localitySelected);
    });
  }

}
