<div class="sales-tax-fee">
  <input ngbAutofocus type="hidden"/>
  <div class="d-flex">
    <div class="flex-grow-1">
      Local tax {{ basket.salesTaxPercentage | precision }}%
    </div>
    <div>
      {{ basket.salesTax | precision }}
    </div>
    <div class="text-right ml-2">
      <div class="custom-control custom-checkbox custom-checkbox-dark">
        <input appDisableUncheck type="checkbox" id="sales-tax" class="custom-control-input"/>
        <label for="sales-tax" class="custom-control-label"></label>
      </div>
    </div>
  </div>
  <div class="d-flex">
    <div class="flex-grow-1">
      Round up
    </div>
    <div>
      {{ basket.fee | precision }}
    </div>
    <div class="text-right ml-2">
      <div class="custom-control custom-checkbox custom-checkbox-dark">
        <input
          [(ngModel)]="basket.feeEnabled"
          type="checkbox"
          id="fee"
          class="custom-control-input"
          (ngModelChange)="onFeeChange()"
        />
        <label for="fee" class="custom-control-label"></label>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <p>
      mmmm is free for your local<br>
      mom & pop so spread the word
    </p>
    <p class="mb-0">
      Instead there’s a voluntary<br>
      round up to keep it available
    </p>
  </div>
</div>
