<app-restaurant-loader *ngIf="isSearching"></app-restaurant-loader>

<div
  *ngIf="!isSearching"
  [ngClass]="{'opacity-0': isLoginModalOpen || isSubscribeModalOpen}"
  class="restaurant-desktop-container position-relative w-100"
>
  <div #restaurantDesktop class="restaurant-desktop d-flex">
    <div
      class="position-absolute-0 z-0"
      [ngClass]="{
        'gray-scale-0': isSidenavLeftOrRightMouseOver,
        'gray-scale-1': !isSidenavLeftOrRightMouseOver
      }"
    ></div>

    <div class="restaurant-desktop-main flex-fill">
      <div class="restaurant-desktop-header d-flex text-white">
        <div class="flex-content-hr flex-fill">
          <div
            [ngClass]="{
              'restaurant-desktop-header-no-logo': !restaurant?.details.logo,
              'restaurant-desktop-header-logo': restaurant?.details.logo,
              'align-items-center': isSidenavLeftOrRightMouseOver
            }"
            class="restaurant-desktop-header-content px-4 d-flex flex-column"
          >
            <ng-container *ngIf="restaurant">
              <ng-container *ngIf="!restaurant.details.logo">
                <div [ngClass]="{'position-fixed': isSidenavLeftOrRightMouseOver}" class="white-shadow cursor-pointer">
                  <h1
                    class="restaurant-desktop-header-name text-center"
                    (click)="openRestaurantModal()"
                  >
                    {{ restaurant.nameView | abbreviate:false: '' | city: restaurant.cityNameToRemove }}
                  </h1>

                  <div
                    *ngIf="restaurant.nameView !== restaurant.secondNameView"
                    class="text-center font-size-1-5rem rest-second-name"
                  >
                    {{ restaurant.secondNameView }}
                  </div>
                </div>
              </ng-container>

              <div
                [ngClass]="{'position-fixed': isSidenavLeftOrRightMouseOver}"
                class="restaurant-desktop-header-logo-img align-self-center mt-4 cursor-pointer white-shadow"
                (click)="openRestaurantModal()"
              >
                <img
                  *ngIf="restaurant.details.logo"
                  [src]="restaurant.details.logo.path | imgix: {w: restaurant.details.logo.width, h: restaurant.details.logo.height}"
                  [title]="restaurant.nameView"
                  [alt]="restaurant.nameView"
                />
              </div>

              <app-restaurant-hours
                *ngIf="!isSidenavLeftOrRightMouseOver"
                [ngClass]="{'mt-2rem': restaurant.details.logo, 'mt-3': !restaurant.details.logo}"
                [restaurantHoursSections]="restaurant.hoursSectionsView"
                [isDesktop]="true"
              ></app-restaurant-hours>
            </ng-container>

            <div
              [ngClass]="{'opacity-0': isSidenavLeftOrRightMouseOver}"
              class="restaurant-desktop-header-bottom mb-3 mt-auto flex-content-vc"
            >
              <app-info-bar
                *ngIf="restaurant"
                [restaurant]="restaurant"
                [restaurantPage]="true"
                class="w-100"
              ></app-info-bar>
            </div>
          </div>
        </div>
      </div>

      <div
        [ngClass]="{'opacity-0': isSidenavLeftOrRightMouseOver}"
        class="restaurant-desktop-content d-flex"
      >
        <div class="flex-content-hr flex-fill">
          <div *ngIf="restaurant" class="restaurant-desktop-menus">
            <div
              *ngFor="let menu of menus, let isLastMenu = last;"
              id="restaurant-desktop-menu-{{menu.slug}}"
              class="restaurant-desktop-menu"
              [attr.data-id]="menu.id"
              [ngClass]="{'mt-0': menu.default}"
            >
              <div
                *ngFor="let category of menu.categories, let isLastCat = last;"
                class="restaurant-desktop-category"
              >
                <h3 *ngIf="!category.default" class="text-center mb-1-75rem">
                  <span>{{ category.viewTitle }}</span>
                </h3>

                <div
                  *ngIf="category.description"
                  class="category-note border-light text-center mb-3"
                >
                  <div class="category-note-content d-inline-flex p-3">
                    {{ category.description }}
                  </div>
                </div>

                <div class="row px-n0-85rem">
                  <ng-container *ngFor="let food of category.foods, let isLast = last;">
                    <div
                      *ngIf="food.view.isColumns"
                      class="col-4 px-0-85rem mb-1-7rem cursor-pointer"
                      (click)="toView(menu, food, viewType.CLASSIC)"
                    >
                      <app-phantom [view]="food.view" [viewType]="viewType.COLUMNS"></app-phantom>

                      <app-div-rectangle>
                        <div class="card card-item-image">
                          <app-restaurant-food-image
                            *ngIf="!food.googleImage"
                            [restaurant]="restaurant"
                            [food]="food"
                          ></app-restaurant-food-image>

                          <div
                            *ngIf="food.googleImage"
                            [appBackgroundUrl]="food.images[0].path | googleImage: 360"
                            class="w-100 h-100 background-center-cover-no-repeat"
                          ></div>
                        </div>

                        <div *ngIf="!isDesktop" [ngClass]="{'list-bottom': isLast}"></div>
                      </app-div-rectangle>
                    </div>

                    <div *ngIf="food.view.isClassic" class="col-12 px-0-85rem mb-1-75rem">
                      <div class="row px-0--85rem">
                        <ng-container *ngIf="!food.gallery">
                          <div class="col-8 px-0-85rem">
                            <app-phantom
                              [view]="food.view"
                              [viewType]="viewType.CLASSIC"
                            ></app-phantom>

                            <app-classic-food-image
                              [food]="food"
                              (viewClick)="toView(menu, food, viewType.COLUMNS)"
                            ></app-classic-food-image>
                          </div>

                          <div class="col-4 px-0-85rem">
                            <div class="card w-100 h-100">
                              <app-classic-food-controls
                                [restaurant]="restaurant"
                                [food]="food"
                                (addClick)="addMeal($event, category.id)"
                                (openPostModal)="openFoodGalleryModal()"
                              ></app-classic-food-controls>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="food.gallery">
                          <div class="col-12 px-0-85rem h-60">
                            <app-phantom
                              [view]="food.view"
                              [viewType]="viewType.CLASSIC"
                            ></app-phantom>

                            <app-classic-food-image
                              [house]="true"
                              [food]="food"
                              [restaurant]="restaurant"
                              (viewClick)="toView(menu, food, viewType.COLUMNS)"
                              (galleryClick)="openFoodGalleryModal()"
                            ></app-classic-food-image>
                          </div>
                        </ng-container>
                      </div>
                    </div>

                    <div *ngIf="isLast && isLastCat && isLastMenu" class="w-100 h-20rem"></div>

                    <div
                      *ngIf="
                        menu.categories.length == 1 && category.foods.length == 1 &&
                        isLast && isLastCat && isLastMenu && isDesktop && isDataReady
                      "
                      [ngStyle]="{'paddingBottom': paddingBottom}"
                      class="w-100"
                    ></div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      [ngClass]="{
        'restaurant-desktop-sidebar-active blur-10': menuActive,
        'opacity-0': isSidenavLeftOrRightMouseOver
      }"
      class="restaurant-desktop-sidebar"
    >
      <div class="d-flex flex-column h-100">
        <div
          *ngIf="restaurant"
          class="restaurant-desktop-logo"
        >
          <a
            appHrefNone
            [ngClass]="{
              'mmmm-2x-red': !menuActive || logoMouseOver,
              'mmmm-2x-gray': menuActive && !logoMouseOver
            }"
            (mouseover)="logoMouseOver = true"
            (mouseleave)="logoMouseOver = false"
            (click)="logoClick()"
            class="icon-v2"
          ></a>
        </div>

        <div
          *ngIf="restaurant && !isSidenavLeftOrRightMouseOver"
          class="restaurant-desktop-controls"
        >
          <div *ngFor="let menu of menus" class="restaurant-desktop-controls-menu mt-3">
            <a
              appHrefNone
              appOpacityFullOnHover
              [ngClass]="{
                'opacity-35': !menuActive,
                'opacity-100': menuActive && menu === menuActive,
                'opacity-30': menuActive && menu !== menuActive
              }"
              class="text-uppercase text-white font-weight-500"
              (click)="selectMenu(menu)"
            >
              {{ menu.display(restaurant) }}
            </a>
          </div>

          <div *ngIf="menus.length == 0" class="restaurant-desktop-controls-menu mt-3">
            <a
              appHrefNone
              appOpacityFullOnHover
              class="text-uppercase text-white font-weight-500 opacity-35"
              (click)="clickMenu()"
            >
              PHOTO JOURNAL
            </a>
          </div>

          <div class="restaurant-desktop-controls-menu mt-3">
            <app-basket
              *ngIf="restaurant"
              [restaurant]="restaurant"
              (openRestaurantModal)="openRestaurantModal()"
            ></app-basket>
          </div>
        </div>

        <div class="flex-fill"></div>
      </div>
    </div>
  </div>
</div>

<ng-template #restaurantModalRef>
  <app-restaurant-modal [restaurant]="restaurant" class="h-100"></app-restaurant-modal>
</ng-template>

<ng-template #foodGalleryModalRef>
  <app-food-gallery class="bg-white"></app-food-gallery>
</ng-template>
