import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { QuillEditorComponent } from '../../../../vendor/libs/quill/quill.component';
import { CurrentRestaurant } from '../../../decorators/current-restaurant.decorator';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { IsLoggedIn } from '../../../decorators/is-logged-in.decorator';
import { RouteParamsValue } from '../../../decorators/route-params-value.decorator';
import { Food } from '../../../domains/food';
import { Post } from '../../../domains/post';
import { Restaurant } from '../../../domains/restaurant';
import { RestaurantSimple } from '../../../domains/restaurant-simple';
import { User } from '../../../domains/user';
import { RouteParamsType } from '../../../models/enums/route-params-type';
import { PostFoodFormGroup } from '../../../models/form-groups/post-food-form-group';
import { RestaurantRouteParams } from '../../../models/route-params/restaurant-route-params';
import { UserRouteParams } from '../../../models/route-params/user-route-params';
import { SegmentedButton } from '../../../models/segmented-button';
import { PostPageTypeWrapper } from '../../../models/types/post-page-type-wrapper';
import { PostType } from '../../../models/types/post-type';
import { FoodService } from '../../../services/food.service';
import { LoaderService } from '../../../services/loader.service';
import { NavigatorService } from '../../../services/navigator.service';
import { PostDataService } from '../../../services/post-data.service';
import { PostService } from '../../../services/post.service';
import { RestaurantService } from '../../../services/restaurant.service';
import { RouterService } from '../../../services/router.service';
import { ImageUtils } from '../../../utils/image-utils';

@Component({
  selector: 'app-post-new',
  templateUrl: './post-new.component.html',
  styleUrls: [
    './post-new.component.scss'
  ]
})
export class PostNewComponent implements OnInit {
  @ViewChild('quill') quill: QuillEditorComponent;
  @ViewChild('foodTitle') foodTitle: ElementRef;

  @IsLoggedIn() isLoggedIn: boolean;
  @CurrentUser() currentUser: User;
  @CurrentRestaurant() currentRestaurant: Restaurant;

  @RouteParamsValue(RouteParamsType.RESTAURANT) restaurantRouteParams: RestaurantRouteParams;
  @RouteParamsValue(RouteParamsType.USER) userRouteParams: UserRouteParams;

  restaurant: RestaurantSimple;
  isShareClicked = false;
  imageData: string;
  isProgressBarStarted = false;
  needToShowKeyboard = false;
  isCropperMode = true;
  croppedImage: string;
  rotation = 0;
  isInstagram = false;
  isRotated = false;
  showGrid = true;
  startY: number;
  post: Post;
  food: Food;
  postFoodFormGroup = new PostFoodFormGroup();
  isFormSubmitted = false;
  postPageType: PostPageTypeWrapper;
  postType: PostType;
  segments: SegmentedButton[];

  constructor(
    private postService: PostService,
    private navigatorService: NavigatorService,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private routerService: RouterService,
    private postDataService: PostDataService,
    private foodService: FoodService,
    private restaurantService: RestaurantService
  ) {
    this.postPageType = new PostPageTypeWrapper(activatedRoute.snapshot.data['postPageType']);
    this.postType = this.activatedRoute.snapshot.data['postType'];
  }

  ngOnInit() {
    if (this.postPageType.isGallery) {
      if (this.restaurantRouteParams?.foodIsGallery) {
        this.post = new Post(
          this.restaurantService.foodGalleryTypes[this.restaurantRouteParams.foodWithIdSlug]
        );
      } else {
        this.post = new Post(PostType.PLATE);
      }
    } else {
      this.post = new Post(this.postType);
    }

    this.segments = [
      new SegmentedButton('People', this.post.type.list.PEOPLE),
      new SegmentedButton('Plate', this.post.type.list.PLATE),
      new SegmentedButton('Place', this.post.type.list.PLACE_INSIDE)
    ];

    this.food = this.postDataService.food;
    const image = this.postDataService.image;

    if (!image || (this.postPageType.isGallery && !this.food)) {
      this.goBack();
    }

    this.imageData = image.path;

    if (image.igId) {
      this.isInstagram = true;
      this.isCropperMode = false;
    }

    this.post.images.push(image);

    if (!this.postPageType.isUser) {
      this.routerService.restaurantSubject.subscribe(restaurant => {
        this.restaurant = restaurant;
      });
    }

  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event) {
    this.startY = event.changedTouches[0].pageY;
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event) {
    const currentY = event.changedTouches[0].pageY;

    if (window.scrollY <= 0 && this.startY <= currentY) {
      event.preventDefault();

      return false;
    }
  }

  setCroppedImage(imageData: string) {
    this.croppedImage = imageData;
  }

  onShareClick() {
    this.loaderService.showLoader();
    this.isProgressBarStarted = true;

    if (this.isCropperMode || this.isRotated) {
      this.post.images[0].orient = 0;

      if (this.isCropperMode) {
        this.post.images[0].path = this.croppedImage;
      }
    }

    if (!this.isShareClicked) {
      this.isShareClicked = true;
      this.post.user = this.currentUser;

      this.restaurantService.clearAllCaches();

      this.getFood().then(food => {
        this.food = food;
        this.post.food = food;

        this.postService.addPost(this.post).subscribe(savedPost => {
          this.food = savedPost.food;
          this.post.food = savedPost.food;

          this.loaderService.hideLoader();
          this.isProgressBarStarted = false;

          if ((this.postPageType.isGallery && !this.post.type.isPlace) || this.post.type.isPlate) {
            this.navigatorService.goToFoodGallery(
              this.restaurantRouteParams,
              this.food,
              this.post,
              100
            );
          } else if (this.postPageType.isUser) {
            this.navigatorService.goToUserFromPost(this.post);
          } else if (this.post.type.isPlace) {
            this.navigatorService.goToUrl(
              `${this.restaurantRouteParams.restaurantPath}/house/${this.post.type.placeType}/photos`
            ).then();
          } else {
            this.navigatorService.goToRestaurant(this.restaurant.hostname);
          }
        });
      });
    }
  }

  private getFood(): Promise<Food> {
    return new Promise(resolve => {
      if (
        this.postPageType.isUser ||
        (this.postPageType.isRestaurant && (this.post.type.isPlace || this.post.type.isPeople))
      ) {
        resolve(null);
      } else if (
        this.postPageType.isGallery ||
        (this.postPageType.isRestaurant && this.post.type.isPlate && this.food)
      ) {
        resolve(this.food);
      } else {
        const foodNew = new Food();
        foodNew.title = this.postFoodFormGroup.form.value.title;
        foodNew.price = this.postFoodFormGroup.form.value.price;

        resolve(foodNew);
      }
    });
  }

  goBack() {
    if (this.userRouteParams) {
      this.navigatorService.goToUrl(this.userRouteParams.path).then();
    } else if (this.restaurantRouteParams) {
      if (this.restaurantRouteParams.hasFood) {
        this.navigatorService.goToUrl(this.restaurantRouteParams.foodPath + '/gallery').then();
      } else {
        this.navigatorService.goToHome();
      }
    } else {
      this.navigatorService.goToHome();
    }
  }

  rotateImage() {
    this.rotation += 90;

    if (!this.isCropperMode) {
      ImageUtils.cropImage(this.imageData, this.rotation).then(imageBase64 => {
        this.imageData = imageBase64;
        this.post.images[0].path = imageBase64;
        this.rotation = 0;
        this.isRotated = true;
      });
    }
  }

  toggleCropperMode() {
    this.isCropperMode = !this.isCropperMode;
  }

  focusEditor() {
    this.needToShowKeyboard = !this.needToShowKeyboard;

    if (this.needToShowKeyboard) {
      if (this.postPageType.isRestaurant && this.post.type.isPlate) {
        setTimeout(() => {
          this.foodTitle.nativeElement.focus();
        }, 200);
      } else {
        this.quill.quillEditor.focus();
      }
    } else {
      if (this.postPageType.isRestaurant && this.post.type.isPlate) {
        this.foodTitle.nativeElement.blur();
      } else {
        this.quill.quillEditor.blur();
      }
    }

    if (this.isCropperMode) {
      this.showGrid = !this.needToShowKeyboard;
    }
  }

  search: any = (text: Observable<string>) =>
    text.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) =>
        this.foodService.getByQuery(term).pipe(
          catchError(() => {
            return of([]);
          }),
        ),
      ),
    )

  formatter = (food: Food) => food.title;

  selectItem($event: NgbTypeaheadSelectItemEvent<any>) {
    this.food = $event.item;
    this.postFoodFormGroup.price.setValue(this.food.price);
  }

  onSubmit() {
    this.isFormSubmitted = true;

    if (this.postFoodFormGroup.form.valid) {
      this.food.title = this.postFoodFormGroup.title.value;
      this.food.price = this.postFoodFormGroup.price.value;

      this.onShareClick();
    }
  }

  onPostRestaurantTypeChange() {
    if (!this.post.type.isFood && !this.post.type.isPlate) {
      this.food = null;
    }
  }

  onInput($event: Event) {
    if (this.food && this.food.title !== $event.target['value']) {
      this.food = null;
    }
  }
}
