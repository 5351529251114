import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appFocus]'
})
export class FocusDirective implements AfterViewInit {
  @Input('appFocus')
  private focused = true;

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit(): void {
    if (this.focused) {
      this.el.nativeElement.focus();
    }
  }
}
