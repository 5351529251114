import { Directive, HostListener } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[appNgbDropdownOnHover]'
})
export class NgbDropdownOnHoverDirective {
  constructor(private ngbDropdown: NgbDropdown) {
  }

  @HostListener('mouseover')
  public onMouseOver(): void {
    if (!this.ngbDropdown.isOpen()) {
      this.ngbDropdown.open();
    }
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    if (this.ngbDropdown.isOpen()) {
      this.ngbDropdown.close();
    }
  }
}
